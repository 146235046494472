import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import SDATableProperties from "./SDATableProperties";
import ImportVariable from "./ImportVariable";
import SDTAVariableTableData from "./SDATableData";
import SDTMSettingsFilter from "./SDTMSettingsFilter";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import UseToggle from "../../../../../Hooks/UseToggle";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../Services/studyServices/studyOnboarding";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../NoDataFoundTable/NoDataFoundTable";

const SDTMSettings = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [variableList, setVariableList] = useState([]);
  const [versionId, setVersionId] = useState("");

  const { studyId } = useParams();

  const getSingleStudies = async () => {
    let data = await studyOnboardingService.getSingleStudies(studyId);
    setVersionId(data?.data?.[0]?.version || "");
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getCDISCVariablesList = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCVariablesList(studyId);
    setVariableList(data?.data);
    setLoader(false);
  };

  useEffect(() => {
    getSingleStudies(studyId);
  }, [studyId]);

  useEffect(() => {
    getCDISCVariablesList();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          {isFormOpen ? (
            <i
              title="Close Form"
              className="fa-solid fa-square-caret-left CP"
              onClick={() => setIsFormOpen("")}
            />
          ) : (
            <i
              title="Add Codelist"
              className="fa-solid fa-plus CP"
              onClick={() => setIsFormOpen(!isFormOpen)}
            />
          )}
          <div className="vr"></div>
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-filter"
            title="Filter"
            onClick={setToggle1}
          ></i>
        </div>
        {isFullScreen ? (
          <i
            class="fa-solid fa-maximize"
            title="Original Mode"
            onClick={toggleFullScreen}
          ></i>
        ) : (
          <i
            class="fa-solid fa-minimize"
            title="Full Mode"
            onClick={toggleFullScreen}
          ></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <ImportVariable
                versionId={versionId}
                getCDISCVariablesList={getCDISCVariablesList}
              />
            )}
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : variableList?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <SDTAVariableTableData
                  studyId={studyId}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  variableList={variableList}
                  getCDISCVariablesList={getCDISCVariablesList}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <SDATableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <SDTMSettingsFilter
        Show={!toggle1}
        Hide={setToggle1}
        getCDISCVariablesList={getCDISCVariablesList}
        Title={"Filter SDTM Settings"}
      />
    </div>
  );
};

export default SDTMSettings;
