import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../../Services/CDISC/cdiscServices";
import { FaSquarePlus, FaTrashCan } from "react-icons/fa6";

const AddFunctionValueManipulation = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [manipulation, setManipulation] = useState(false);
  let [newManipulation, setNewManipulation] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);
  let [columns, setColumns] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if (
      derivationData?.primaryDataset ||
      derivationData?.secondaryDataType ||
      derivationData?.cellDataset
    ) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ]);
    }
    if (derivationData?.functionManipulation) {
      setManipulation(true);
      setNewManipulation(derivationData?.functionManipulation || []);
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(
        derivationData?.primaryDataset,
        derivationData?.primaryDataType
      );
    }
  }

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    if (manipulation) {
      userData.functionManipulation = newManipulation;
    }
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const handleAddNewManipulation = () => {
    setNewManipulation([
      ...newManipulation,
      { id: newManipulation.length + 1, column: "", value: "" },
    ]);
  };

  const handleRemoveNewManipulation = (id) => {
    const updatedSections = newManipulation.filter(
      (section) => section.id !== id
    );
    setNewManipulation(updatedSections);
  };

  const handleSelectNewManipulationChange = (id, field, value) => {
    const updatedSections = newManipulation.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setNewManipulation(updatedSections);
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  const [functionList, setFunctionList] = useState([]);

  const getFunctionList = async () => {
    let data = await cdiscServices.getFunctionList(studyId, derivationId, "no")
    setFunctionList(data?.data)
  }

  useEffect(() => {
    getFunctionList()
  }, [derivationId]);

  return (
    <div className="py-2 px-3">
      <div className="position-relative border mb-3">
        <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
          <div className="hstack gap-2">
            <Form.Label className="mb-1 fw-bold">
              Manipulation Data <span className="text-danger">*</span>
            </Form.Label>
            <FaSquarePlus
              className="cursor-pointer"
              style={{ fontSize: 12 }}
              title="Add More"
              onClick={handleAddNewManipulation}
            />
          </div>
        </div>
        <div className="p-3">
          {newManipulation?.map((manu, index) => (
            <div key={index} className="hstack gap-2 mb-1">
              <Form.Select
                onChange={(e) =>
                  handleSelectNewManipulationChange(
                    manu.id,
                    "column",
                    e.target.value
                  )
                }
              >
                <option value="">Select Column</option>
                {(primaryDataType === "Merge" || primaryDataType === "Append"
                  ? uniqueColumnKey
                  : columns
                )?.map((option, index) => (
                  <option
                    selected={manu?.column === option.name}
                    key={index}
                    value={option?.name}
                  >
                    {option.name}
                  </option>
                ))}
              </Form.Select>
              <div>
                <InputGroup className="justify-content-center">
                  <InputGroup.Text
                    style={{
                      padding: "5px 9.7px",
                      fontSize: 10,
                      borderRadius: 3,
                    }}
                  >
                    =
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <Form.Select
                onChange={(e) =>
                  handleSelectNewManipulationChange(
                    manu.id,
                    "value",
                    e.target.value
                  )
                }
              >
                <option value="">Select Function</option>
                {functionList?.map((option, index) => (
                  <option
                    selected={manu?.value === option._id}
                    key={index}
                    value={option?._id}
                    className="text-capitalize"
                  >
                    {option.name} {`(${option.type} Function) `}
                  </option>
                ))}
              </Form.Select>
              <FaTrashCan
                onClick={() => handleRemoveNewManipulation(manu?.id)}
                style={{ fontSize: 25 }}
                className="CP text-danger"
                title="Remove Statement"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="text-end p-2">
        <button onClick={derivationCRFData} className="updateProfile">
          Save
        </button>
      </div>
    </div>
  );
};

export default AddFunctionValueManipulation;
