import React, { useState } from "react";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import AttributeProperties from "./AttributeProperties";
import UseToggle from "../../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const SDTAVariableTableData = ({

  studyId,
  tableRef,
  onMouseDown,
  variableList,
  getCDISCVariablesList,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const { toggle, setToggle } = UseToggle();
  const [variableListId, setVariableListId] = useState([]);

  const deleteCDISCVariables = async (e, id) => {
    setToggle(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Variable!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteCDISCVariables(id, studyId);
        if (results?.statusCode === 200) {
          getCDISCVariablesList();
        }
      }
    });
  };

  const viewDetails = (item) => {
    setToggle();
    setVariableListId(item);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = variableList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(variableList?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          tableRef={tableRef}
          id="resizable-table"
          className="text-nowrap tableLibrary custom-table"
          hover
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Version</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Class</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Domain</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Veriable Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Veriable Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Veriable Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Controlled Terms</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Role</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Comment</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Core</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>Code List</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Value Level</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 15)}
                style={{ width: 70, right: -1 }}
                className="text-center position-sticky"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.length > 0 &&
              result?.map((item, index) => (
                <tr
                  key={index}
                  className={`position-relative cursor-pointer ${
                    highlightedRow === index && "activeMenu"
                  }`}
                  onClick={() => {
                    setHighlightedRow(index);
                    viewDetails(item);
                  }}
                >
                  <td>{item.order}</td>
                  <td>{item.version}</td>
                  <td>{item.class}</td>
                  <td>{item.domain}</td>
                  <td>{item.name}</td>
                  <td>{item.label}</td>
                  <td>{item.type}</td>
                  <td>{item.terms}</td>
                  <td className="text-capitalize">
                    {item.role === "recordQualifier"
                      ? "Record Qualifier"
                      : item.role === "synonymQualifier"
                      ? "Synonym Qualifier"
                      : item.role === "variableQualifier"
                      ? "Variable Qualifier"
                      : item.role === "resultQualifier"
                      ? "Result Qualifier"
                      : item.role}
                  </td>
                  <td>{item.origin}</td>
                  <td>{item.mathod}</td>
                  <td>{item.comment}</td>
                  <td>{item.core}</td>
                  <td>{item.codeListData}</td>
                  <td className="text-capitalize">{item.valueLevel}</td>
                  <td
                    className="position-sticky bg-light"
                    style={{ right: -1 }}
                  >
                    {item.core === "perm" ? (
                      <div
                        className="text-center cursor-pointer"
                        title="Delete Source Data"
                        onClick={(e) => deleteCDISCVariables(e, item?._id)}
                      >
                        <i className="fa-solid fa-trash-can text-danger"></i>
                      </div>
                    ) : (
                      <div
                        className="text-center"
                        title="can't Delete Source Data"
                      >
                        <i className="fa-solid fa-trash-can text-secondary"></i>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={variableList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
      <AttributeProperties
        Show={!toggle}
        Hide={setToggle}
        variableListId={variableListId}
        Title={"STDM Detail"}
      />
    </>
  );
};

export default SDTAVariableTableData;
