import React from "react";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";

const Properties = ({ codeListId }) => {
  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        <Table
          id="resizable-table"
          striped
          hover
          bordered
          className="m-0 custom-table"
        >
          <thead className="position-sticky" style={{ top: -1 }}>
            <tr>
              <th style={{ width: 45 }}>Sr.</th>
              <th>Data Type</th>
              <th>Terms</th>
              <th>NCI Term Code</th>
              <th>Decode</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>1</td>
              <td className="text-capitalize">{codeListId?.dataType}</td>
              <td className="text-capitalize">{codeListId?.terms}</td>
              <td className="text-capitalize">{codeListId?.termCode}</td>
              <td className="text-capitalize">{codeListId?.decodeValue}</td>
            </tr>
          </tbody>
        </Table>
      </SplitPane>
    </>
  );
};

export default Properties;
