import React, { useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { MdBackup } from "react-icons/md";
import { FaCloudDownloadAlt } from "react-icons/fa";

const StudyMetadata = () => {
  let { studyId } = useParams()
  const [loader, setLoader] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");
  const extractStudyCIDSCData = async () => {
    setLoader(true)
    let userData = {}
    userData.studyId = studyId
    let data = await cdiscServices.extractStudyCIDSCData(userData)
    setDownloadURL(data?.data)
    if (data?.foundStatus?.statusCode === 200) {
      setLoader(false)
    }
  }
  return (
    <>
      <Container
        fluid
        className="overflow-auto"
        style={{ height: "calc(100vh - 115px)" }}
      >
        <>
          <Row>
            <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
              <div className="shadow-md border my-2 mx-1   overflow-hidden">
                <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
                  <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                    Study Overview
                  </Form.Label>
                </Form.Group>
                <div className="p-2">
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Name :</b> 0001-22
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Description :</b> An Open-Label, Balanced,
                      Randomized, Single-Dose, Two-Treatment, Two-Sequence,
                      Two-Period, Two Way Crossover Oral
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Protocol Name :</b> 0001-22
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Standard Name :</b> StandardName
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Standard Version :</b> 3.2
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Language :</b> en
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
          </Row>
          <div>
            {!downloadURL ?
              <button
                onClick={extractStudyCIDSCData}
                className="border bg-primary text-white rounded-1 p-1 px-2"
              >
                {loader ? (
                  <Spinner
                    className="me-1"
                    style={{ width: 15, height: 15 }}
                    animation="border"
                  />
                ) :
                  <MdBackup />
                }
                Generate Study Backup
              </button>
              :
              <a href={downloadURL}>
                <button
                  className="border bg-success text-white rounded-1 p-1 px-2"
                >
                  <FaCloudDownloadAlt /> Download BackUp Study
                </button>
              </a>
            }
          </div>
        </>
      </Container>
    </>
  );
};

export default StudyMetadata;
