import React from "react";
import { Offcanvas } from "react-bootstrap";
import "./CertificateReport.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Moment from "react-moment";

const ELearningCertificateReport = ({ Show, Hide, Title, userReport, userName, traningDate }) => {
  const handleDownloadPDF = () => {
    const input = document.querySelector("#content2");

    setTimeout(() => {
      html2canvas(input, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdfWidth = 700 * 0.264583;
        const pdfHeight = 540 * 0.264583;

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [pdfWidth, pdfHeight],
        });

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("certificate.pdf");
      });
    }, 1000);
  };
  return (
    <div>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 800 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <div className="cert-container print-m-0">
              <div id="content2" className="cert">
                <img
                  src="https://edgarsrepo.github.io/certificate.png"
                  className="cert-bg"
                  alt="Certificate Background"
                />
                <div className="cert-content">
                  <h2 className="other-font">Certificate of Training Completion</h2>
                  <span style={{ fontSize: "27px" }}>{userName}</span>
                  <br />
                  <span className="other-font">
                    <i>
                      <b>has completed the</b>
                    </i>
                  </span>
                  <br />
                  <span style={{ fontSize: "35px" }}>
                    <b>{userReport?.traningName}</b>
                  </span>
                  <br />
                  <small>
                    This is to certify that {userName} has successfully completed the training program titled {userReport?.traningName}, held on <Moment format="DD MMM YYYY">{traningDate}</Moment>.<br />
                    This training was conducted by {userReport?.companyName}.
                  </small>
                  <div className="bottom-txt">
                    <span><b>Generated By:</b> Galaxbiotech Pvt. Ltd.</span>
                    <span><b>Completed On:</b> <Moment format="DD MMM YYYY">{traningDate}</Moment></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2  bg-light shadow-sm text-end">
            <div className="hstack justify-content-between">
              <div>
                <button
                  className="border fontSize12 p-1 px-2 updateProfile"
                  onClick={handleDownloadPDF}
                >
                  Download
                </button>
              </div>
              <button
                className="border fontSize12 p-1 px-2 updateProfile"
                onClick={Hide}
              >
                Close
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ELearningCertificateReport;
