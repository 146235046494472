import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const STDMCodeListTableData = ({
  codeList,
  tableRef,
  onMouseDown,
  getCDISCCodeLists,
  setIsOpenProperty,
  setCodeListId,
  studyId
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const deleteStudyDataByCodeList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Code List!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyDataByCodeList(id, studyId);
        if (results?.statusCode === 200) {
          getCDISCCodeLists();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = codeList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(codeList?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          tableRef={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table"
          hover
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th style={{width: 60}} className="text-center" onMouseDown={(e) => onMouseDown(e, 1)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>NCI Codelist Code</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 70 }}>
                <div className="text-center">Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <tr
                key={index}
                className={`position-relative cursor-pointer ${
                  highlightedRow === index && "activeMenu"
                }`}
                onClick={() => {
                  setIsOpenProperty(true);
                  setCodeListId(item);
                  setHighlightedRow(index);
                }}
              >
                <td className="text-center">{item?.order}</td>
                <td>{item?.codeId}</td>
                <td>{item?.codeName}</td>
                <td>{item?.codeList}</td>
                <td>
                  <button
                    className="text-white border-danger bg-danger border-0"
                    style={{fontSize: 10, borderRadius: 3}}
                    title="Delete Supplementary"
                    onClick={() => deleteStudyDataByCodeList(item?._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={codeList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default STDMCodeListTableData;
