import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const VariableTableData = ({
  tableRef,
  onMouseDown,
  variableList,
  getCDISCVariablesList,
  setIsFormOpen,
  studyId,
}) => {
  const ref = useRef(null);

  const deleteCDISCVariables = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Variable!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteCDISCVariables(id, studyId);
        if (results?.statusCode === 200) {
          getCDISCVariablesList();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = variableList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(variableList?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table tableLibrary"
          hover
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Dataset</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Variable</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Length</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Significant</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Format</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Mandatory</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Codelist</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Pages</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>Predecessor</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Role</th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>Comment</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 16)}
                className="text-center position-sticky"
                style={{ width: 100, right: 50 }}
              >
                Update
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 17)}
                style={{ width: 100, right: -1 }}
                className="text-center position-sticky"
              >
                <div className="text-center">Delete</div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {result?.map((item, index) => (
              <tr key={index} className="position-relative CP">
                <td>{item.order}</td>
                <td>{item.domain}</td>
                <td>{item.name}</td>
                <td>{item.label}</td>
                <td className="text-capitalize">{item.type}</td>
                <td>{!item.type ? "" : item.type === "char" ? 200 : 8}</td>
                <td>{item.type === "float" ? item.significant : ""}</td>
                <td>{item.format}</td>
                <td className="text-capitalize">{item.core}</td>
                <td>{item.codeListData}</td>
                <td>{item.origin}</td>
                <td>{item.pages}</td>
                <td>{item.mathod}</td>
                <td>{item.predecessor}</td>
                <td className="text-capitalize">
                  {item.role.replace(/([A-Z])/g, " $1").trim()}
                </td>
                <td>{item.comment}</td>
                <td className="text-center position-sticky bg-light" style={{ right: 50 }}>
                  <button
                    onClick={() => setIsFormOpen(item)}
                    title="Update"
                    className="text-white border-success bg-success border-0"
                    style={{ fontSize: 10, borderRadius: 3 }}
                  >
                    Update
                  </button>
                </td>
                <td className="position-sticky bg-light" style={{ right: -1 }}>
                  {item.core === "perm" ? (
                    <button
                      className="text-white border-danger bg-danger border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                      title="Delete Variable."
                      onClick={(e) => deleteCDISCVariables(e, item?._id)}
                    >
                      Delete
                    </button>
                  ) : (
                    <button
                      className="border-danger-subtle bg-danger-subtle border-0"
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        cursor: "no-drop",
                      }}
                      title="Can't Delete This Variable."
                    >
                      Delete
                    </button>
                  )}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={variableList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default VariableTableData;
