import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import AddSDAForm from "./AddSDAForm";
import SDATableData from "./SDATableData";
import SDATableProperties from "./SDATableProperties";
import SDAFilter from "./SDAFilter";
import UseToggle from "../../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { studyOnboardingService } from "../../../../../Services/studyServices/studyOnboarding";
import { useParams } from "react-router";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../NoDataFoundTable/NoDataFoundTable";

const SourceDataAnalytics = ({ tableRef, onMouseDown }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [sourceData, setSourceData] = useState("");
  const [sourceDataId, setSourceDataId] = useState("");
  const [versionId, setVersionId] = useState("");
  const { studyId } = useParams();

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getCDISCSDA = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCSDA(studyId);
    setSourceData(data?.data);
    setLoader(false);
  };

  const getSingleStudies = async () => {
    let data = await studyOnboardingService.getSingleStudies(studyId);
    setVersionId(data?.data?.[0]?.version || "");
  };

  useEffect(() => {
    getCDISCSDA();
  }, []);

  useEffect(() => {
    getSingleStudies(studyId);
  }, [studyId]);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          {isFormOpen ? (
            <i
              title="Close Form"
              className="fa-solid fa-square-caret-left CP"
              onClick={() => setIsFormOpen("")}
            />
          ) : (
            <i
              title="Close Form"
              className="fa-solid fa-plus CP"
              onClick={() => setIsFormOpen(!isFormOpen)}
            />
          )}
          <div className="vr"></div>
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-filter"
            title="Filter"
            onClick={setToggle1}
          ></i>
        </div>
        {isFullScreen ? (
          <i
            class="fa-solid fa-maximize"
            title="Original Mode"
            onClick={toggleFullScreen}
          ></i>
        ) : (
          <i
            class="fa-solid fa-minimize"
            title="Full Mode"
            onClick={toggleFullScreen}
          ></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <AddSDAForm
                versionId={versionId}
                getCDISCSDA={getCDISCSDA}
                sourceDataId={sourceDataId}
                setSourceDataId={setSourceDataId}
              />
            )}
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : sourceData?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <SDATableData
                  studyId={studyId}
                  tableRef={tableRef}
                  sourceData={sourceData}
                  getCDISCSDA={getCDISCSDA}
                  onMouseDown={onMouseDown}
                  setIsFormOpen={setIsFormOpen}
                  setSourceDataId={setSourceDataId}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <SDATableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <SDAFilter
        Show={!toggle1}
        Hide={setToggle1}
        getCDISCSDA={getCDISCSDA}
        Title={"Filter Source Data Analytics"}
      />
    </div>
  );
};

export default SourceDataAnalytics;
