import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Form } from "react-bootstrap";
import { studyOnboardingService } from "../../Services/studyServices/studyOnboarding";
import { supplimentaryServices } from "../../Services/CDISC/supplimentaryServices";
import { useParams } from "react-router";

const StudyConfiguration = () => {
  const [versionList, setVersionList] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [selectedCRFData, setSelectedCRFData] = useState("");
  const { studyId } = useParams()
  const updateVersionStudyID = async (version) => {
    let userData = {}
    userData.version = version
    userData.studyId = studyId
    await studyOnboardingService.configurationStudyID(userData)
    getSingleStudies()
  }
  const updateCRFTypeStudyID = async (crfType) => {
    let userData = {}
    userData.crfType = crfType
    userData.studyId = studyId
    await studyOnboardingService.configurationStudyID(userData)
    getSingleStudies()
  }
  const getActiveVersions = async () => {
    let data = await supplimentaryServices.getActiveVersions()
    setVersionList(data?.data || [])
  }
  const getSingleStudies = async () => {
    let data = await studyOnboardingService.getSingleStudies(studyId)

    setSelectedVersion(data?.data?.[0]?.version || "")
    setSelectedCRFData(data?.data?.[0]?.crfType || "")
  }

  useEffect(() => {
    getActiveVersions();
    getSingleStudies();
  }, []);
  return (
    <div className="p-3">
      <div className="d-flex gap-3 w-100 m-auto">
        <Card className="w-100">
          <CardHeader className="fontSize12">Version Setting</CardHeader>
          <CardBody className="fontSize12">
            {versionList?.map((item, index) => (
              <Form.Check
                onChange={(e) => updateVersionStudyID(item._id)}
                key={index}
                type="radio"
                checked={selectedVersion === item._id}
                label={item?.version}
                name="version" />
            ))}
          </CardBody>
        </Card>
        <Card className="w-100">
          <CardHeader className="fontSize12">CRF Data Setting</CardHeader>
          <CardBody className="fontSize12">
            <Form.Check
              onChange={(e) => updateCRFTypeStudyID("custom")}
              type="radio"
              checked={selectedCRFData === "custom"}
              label="Custom"
              name="crf"
            />
            <Form.Check
              onChange={(e) => updateCRFTypeStudyID("predefined")}
              type="radio"
              checked={selectedCRFData === "predefined"}
              label="Predefined"
              name="crf" />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default StudyConfiguration;
