import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";

const RandomizeTabPropertiesData = ({ onMouseDown, tableRef, randomizerData }) => {
    const { studyId } = useParams()
    const [seedNumber, setSeedNumber] = useState("");
    const findRandomizerStudyParameter = async () => {
        let data = await randomizerServices.findRandomizerStudyParameter(studyId);
        setSeedNumber(data?.data?.randomNumberSeed);
    };

    useEffect(() => {
        findRandomizerStudyParameter();
    }, [studyId]);
    return (
        <>
            <SplitPane
                split="vertical"
                minSize={20}
                defaultSize="100%"
                style={{ height: "91%" }}
            >
                <div className="table-container tableLibrarySection">
                    <Table
                        id="resizable-table" striped
                        className="m-0 custom-table"
                        ref={tableRef}
                        bordered
                    >
                        <thead className="thead-sticky" style={{ top: -1 }}>
                            <tr>
                                <th onMouseDown={(e) => onMouseDown(e, 0)}>
                                    Attribute
                                </th>
                                <th onMouseDown={(e) => onMouseDown(e, 1)}>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{randomizerData?.name}</td>
                            </tr>

                            <tr>
                                <td>Description</td>
                                <td>{randomizerData?.description}</td>
                            </tr>
                            <tr>
                                <td>Seed Number</td>
                                <td>{seedNumber}</td>
                            </tr>
                            <tr>
                                <td>Last Randomization Date | Time</td>
                                <td><Moment format="DD MMM YYYY | HH:mm:ss">{randomizerData?.updatedAt}</Moment></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </SplitPane>
        </>
    );
};

export default RandomizeTabPropertiesData;
