import React from "react";

const Profilers = ({ profile, client1, projectid }) => {
  return (
    <>
      <div className="profiler">
        <ul className="d-block justify-content-between align-items-baseline list-unstyled mb-0 d-sm-flex profiler">
          <li>
            <span role="button" className="btn m-0 p-0">
              <img src={profile} alt="" className="navprofile" />
            </span>
          </li>

          <li className="text-white client1">Client 1</li>

          <li>
            <span role="button" className="btn m-0 p-0 clientssssss">
              <img src={client1} alt="" className="client1icon" />
            </span>
          </li>

          <li className="text-white projectid">
            <span className="projectid">Project ID-01</span>
          </li>

          <li>
            <img src={projectid} alt="" className="projectidicon" />
          </li>

          <li className="text-white studyid">Study ID-01</li>
        </ul>
      </div>
    </>
  );
};

export default Profilers;
