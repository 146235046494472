import React from "react";
import SidebarDropdowns from "./SidebarDropdowns";

const BottomFilebar = ({
  RemoveDropdowns,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  Dropdown7,
  Dropdown8,
  Dropdown9,
}) => {
  const sidebarDropdownProps = {
    RemoveDropdowns,
    Dropdown1,
    Dropdown2,
    Dropdown3,
    Dropdown4,
    Dropdown5,
    Dropdown6,
    Dropdown7,
    Dropdown8,
    Dropdown9,
  };

  return <SidebarDropdowns {...sidebarDropdownProps} />;
};

export default BottomFilebar;
