import React from "react";
import { Form, Offcanvas, Table } from "react-bootstrap";
import { rbqmStudyServices } from "../../../../Services/rbqmServices/rbqmStudyServices";
import Swal from "sweetalert2";

const ViewFormule = ({
  Show,
  Hide,
  Title,
  indicatorList,
  setLoader,
  id,
  getRiskAreaList,
  formuleValue,
  setFormuleValue,
  studyId,
}) => {
  const handleAddClick = (name) => {
    setFormuleValue((prevValue) => prevValue + (prevValue ? "" : "") + name);
  };

  const updateRiskAreaData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Risk Area Formula?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId;
        userData.id = id;
        userData.formula = formuleValue;
        let data = await rbqmStudyServices.updateRiskArea(userData);
        if (data?.statusCode === 200) {
          getRiskAreaList();
        }
        setLoader(false);
      }
    });
  };

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 87px)" }}
          >
            <Form.Group className="mb-2">
              <Table
                id="resizable-table"
                striped
                bordered
                className="m-0 custom-table"
              >
                <thead className="thead-sticky">
                  <tr>
                    <th style={{ width: 55 }}>S No.</th>
                    <th>Label</th>
                    <th>Name</th>
                    <th className="text-center" style={{ width: 70 }}>
                      Add Code
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {indicatorList?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.label}</td>
                      <td>{item.name}</td>
                      <td className="text-center" title="Copy Name">
                        <button
                          className="text-white border-success bg-success border-0 fs-10"
                          onClick={() => handleAddClick(item.name)}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form.Group>
            <Form.Group>
              <Form.Label className="mb-1 fw-bold">Formule</Form.Label>
              <Form.Control
                as="textarea"
                value={formuleValue} // Bind value to the state
                onChange={(e) => setFormuleValue(e.target.value)} // Allow manual edits in textarea
              />
            </Form.Group>
          </div>
          <div className="shadow w-100 bg-light text-end p-2">
            <button className="updateProfile" onClick={updateRiskAreaData}>
              Save
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ViewFormule;
