import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useParams } from "react-router";
import Randomize from "./Randomize/Randomize";
import EDCMainPanel from "../EDC/EDCMainPanel";
import RandomizerSidebar from "./RandomizerSidebar";
import AuditTrial from "./TrialDesign/Monitoring/AuditTrial";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import ResizerXManager from "../ResizerXManager/ResizerXManager";
import RandomizerDashboard from "./Randomize/RandomizerDashboard";
import { ResizableX, ResizableY, SidebarChild } from "../Layouts/Layouts";
import RecruitmentSummary from "./TrialDesign/Monitoring/RecruitmentSummary";
import NewTrialParametersDesign from "./TrialDesign/NewTrialParametersDesign";
import RequestManagement from "./TrialDesign/RequestManagement/RequestManagement";
import NewTrialfactorsDesign from "./TrialDesign/FactorDesign/NewTrialfactorsDesign";
import StudyIDStudySite from "../CommonComponents/DashboardBody/StudyIdData/SiteManagement/StudySite/StudyIDStudySite";
import CtmsTreatmentmanagement from "../CTMS/TreatmentData/CTMSTreatmentmanagements";
import Randomization from "./Randomize/Randomization";

const Randomizer = () => {
  const { showSidebar } = UseSidebarReducer();

  const [studyTabs, setStudyTabs] = useState([]);
  const { studyId } = useParams();
  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);
  const [collapseSidebar, setCollpaseSidebar] = useState(false);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const th = tableRef.current.querySelector(
          `th:nth-child(${resizingColumn + 1})`
        );
        th.style.width = `${width}px`;
        tableRef.current.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  const handleAddNewTab = (studyTabName, icon, studyTabId) => {
    let newTab = {
      studyTabId: studyTabId,
      studyTabName: studyTabName,
      icon: icon,
      openDT: new Date(),
      status: "active",
    };
    const updatedTabs = studyTabs?.map((studyTab) => ({
      ...studyTab,
      status: "inactive",
    }));
    const existTab = studyTabs?.some(
      (studyTab) => studyTab.studyTabId === studyTabId
    );
    if (existTab) {
      updatedTabs.forEach((element) => {
        if (element.studyTabId === studyTabId) {
          element.status = "active";
          element.studyTabName = studyTabName;
        }
      });
      setStudyTabs(updatedTabs || []);
    } else {
      setStudyTabs([...updatedTabs, newTab] || []);
    }
  };

  const checkActiveTab = (studyTabId) => {
    return studyTabs?.some(
      (item) => item.studyTabId === studyTabId && item.status === "active"
    );
  };

  const checkHomeActiveTab = () => {
    return studyTabs?.some((item) => item.status === "active");
  };

  const activeStudyTab = studyTabs?.some((item) => item.status === "active");

  useEffect(() => {
    const savedStudyTabs = JSON.parse(localStorage.getItem("studyTabs"));
    if (savedStudyTabs) {
      setStudyTabs(savedStudyTabs || []);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("studyTabs", JSON.stringify(studyTabs));
  }, [studyTabs]);

  return (
    <div>
      <section>
        <Navbar Profiler1 />
        <ResizableX>
          {!showSidebar && (
            <div id="div0" style={{ width: collapseSidebar ? 40 : 250 }}>
              <section className="middle-content">
                <div className="sidebar" id="myGroup">
                  <div className="menus">
                    <div className="sidebar-body">
                      {collapseSidebar ? (
                        <RandomizerSidebar
                          handleAddNewTab={handleAddNewTab}
                          checkActiveTab={checkActiveTab}
                          studyId={studyId}
                        />
                      ) : (
                        <RandomizerSidebar
                          handleAddNewTab={handleAddNewTab}
                          checkActiveTab={checkActiveTab}
                          studyId={studyId}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          <ResizerXManager collapseSidebar={collapseSidebar} />
          <ResizableY>
            <section className="middle-content " id="dashboard-section">
              <div className="inner-cont">
                <EDCMainPanel
                  setStudyTabs={setStudyTabs}
                  studyTabs={studyTabs}
                  activeStudyTab={activeStudyTab}
                  collapseSidebar={collapseSidebar}
                  setCollpaseSidebar={setCollpaseSidebar}
                />
                {!checkHomeActiveTab() && <RandomizerDashboard />}
                {checkActiveTab("siteList") && (
                  <StudyIDStudySite
                    viewTypeData
                    tableRef={tableRef}
                    handleAddNewTab={handleAddNewTab}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("trialFactorDesign") && (
                  <NewTrialfactorsDesign
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("treatmentManagement") && (
                  <CtmsTreatmentmanagement
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                    viewTypeData="edc"
                  />
                )}

                {checkActiveTab("randomizeId") && (
                  <Randomization
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("randomize") && (
                  <Randomize tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("requestManagement") && (
                  <RequestManagement
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("auditTrial") && (
                  <AuditTrial tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("recruitmentSummary") && (
                  <RecruitmentSummary
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("trialParameters") && (
                  <NewTrialParametersDesign
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}
              </div>
            </section>
          </ResizableY>
        </ResizableX>
        <Footer />
      </section>
    </div>
  );
};

export default Randomizer;
