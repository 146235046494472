import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { userServices } from "../../../Services/userServices";
import { studyOnboardingService } from "../../../Services/studyServices/studyOnboarding";
import EDCLogo from "../../../img/edc.png";
import ETMFLogo from "../../../img/ETMF.png";
import IWRSLogo from "../../../img/IWRS.png";
import CDISCLogo from "../../../img/CDISC.png";
import RBQMLogo from "../../../img/RBQM.png";

const CtmsDashboardData = () => {
  const { studyId } = useParams();
  const [DBPPermission, setDBPPermission] = useState([]);
  const [DVPermission, setDVPermission] = useState([]);
  const [LivePermission, setLivePermission] = useState([]);
  const [QCPermission, setQCPermission] = useState([]);
  const [SDVLivePermission, setSDVLivePermission] = useState([]);
  const [SDVQCPermission, setSDVQCPermission] = useState([]);

  const [DBPSwitchCheck, setDBPSwitchCheck] = useState("");
  const [DVSwitchCheck, setDVSwitchCheck] = useState("");
  const [LiveSwitchCheck, setLiveSwitchCheck] = useState("");
  const [QCSwitchCheck, setQCSwitchCheck] = useState("");
  const [SDVLiveSwitchCheck, setSDVLiveSwitchCheck] = useState("");
  const [SDVQCSwitchCheck, setSDVQCSwitchCheck] = useState("");

  const [studyPermissionId, setStudyPermissionId] = useState("");
  const [studyManagement, setStudyManagement] = useState("");
  const [assignStudyPermission, setAssignStudyPermission] = useState("");
  const [assignETMFStudyPermission, setAssignETMFStudyPermission] =
    useState("");
  const [assignIWRSStudyPermission, setAssignIWRSStudyPermission] =
    useState("");
  const [assignCDISCStudyPermission, setAssignCDISCStudyPermission] =
    useState("");
  const [assignRBQMStudyPermission, setAssignRBQMStudyPermission] =
    useState("");

  const [DBPCheck, setDBPCheck] = useState("");
  const [DVCheck, setDVCheck] = useState("");
  const [LiveCheck, setLiveCheck] = useState("");
  const [QCCheck, setQCCheck] = useState("");
  const [SDVLiveCheck, setSDVLiveCheck] = useState("");
  const [SDVQCCheck, setSDVQCCheck] = useState("");
  const [studyStatus, setStudyStatus] = useState("");
  const [studyStatusData, setStudyStatusData] = useState("");

  const [ETMFStudyManagement, setETMFStudyManagement] = useState("");
  const [ETMFDBPPermission, setETMFDBPPermission] = useState([]);
  const [ETMFLivePermission, setETMFLivePermission] = useState([]);
  const [ETMFQCPermission, setETMFQCPermission] = useState([]);
  const [ETMFDBPCheck, setETMFDBPCheck] = useState("");
  const [ETMFLiveCheck, setETMFLiveCheck] = useState("");
  const [ETMFQCCheck, setETMFQCCheck] = useState("");

  const [IWRSStudyManagement, setIWRSStudyManagement] = useState("");
  const [IWRSDBPPermission, setIWRSDBPPermission] = useState([]);
  const [IWRSLivePermission, setIWRSLivePermission] = useState([]);
  const [IWRSQCPermission, setIWRSQCPermission] = useState([]);
  const [IWRSDBPCheck, setIWRSDBPCheck] = useState("");
  const [IWRSLiveCheck, setIWRSLiveCheck] = useState("");
  const [IWRSQCCheck, setIWRSQCCheck] = useState("");

  const [CDISCStudyManagement, setCDISCStudyManagement] = useState("");
  const [CDISCDBPPermission, setCDISCDBPPermission] = useState([]);
  const [CDISCLivePermission, setCDISCLivePermission] = useState([]);
  const [CDISCQCPermission, setCDISCQCPermission] = useState([]);
  const [CDISCDBPCheck, setCDISCDBPCheck] = useState("");
  const [CDISCLiveCheck, setCDISCLiveCheck] = useState("");
  const [CDISCQCCheck, setCDISCQCCheck] = useState("");

  const [RBQMStudyManagement, setRBQMStudyManagement] = useState("");
  const [RBQMDBPPermission, setRBQMDBPPermission] = useState([]);
  const [RBQMLivePermission, setRBQMLivePermission] = useState([]);
  const [RBQMQCPermission, setRBQMQCPermission] = useState([]);
  const [RBQMDBPCheck, setRBQMDBPCheck] = useState("");
  const [RBQMLiveCheck, setRBQMLiveCheck] = useState("");
  const [RBQMQCCheck, setRBQMQCCheck] = useState("");

  const [projectID, setProjectID] = useState("");
  const [projectLabel, setProjectLabel] = useState("");

  const [studyLabel, setStudyLabel] = useState("");
  const getETMFUserPermission = async () => {
    let permission = await userServices.getETMFUserPermission(
      studyId,
      projectID
    );
    setETMFDBPPermission(permission?.DBPPermission);
    setETMFLivePermission(permission?.LivePermission);
    setETMFQCPermission(permission?.QCPermission);
  };

  const getStudyUserPermission = async () => {
    let permission = await userServices.getStudyUserPermission(
      studyId,
      projectID
    );

    setDBPPermission(permission?.DBPPermission);
    setDVPermission(permission?.DVPermission);
    setLivePermission(permission?.LivePermission);
    setQCPermission(permission?.QCPermission);
    setSDVLivePermission(permission?.SDVLivePermission);
    setSDVQCPermission(permission?.SDVQCPermission);
    setDBPSwitchCheck(permission?.DBPCheck);
    setDVSwitchCheck(permission?.DVCheck);
    setLiveSwitchCheck(permission?.LiveCheck);
    setQCSwitchCheck(permission?.QCCheck);
    setSDVLiveSwitchCheck(permission?.SDVLiveCheck);
    setSDVQCSwitchCheck(permission?.SDVQCCheck);
  };

  const getIWRSUserPermission = async () => {
    let permission = await userServices.getIWRSUserPermission(
      studyId,
      projectID
    );
    setIWRSDBPPermission(permission?.DBPPermission);
    setIWRSLivePermission(permission?.LivePermission);
    setIWRSQCPermission(permission?.QCPermission);
  };

  const getCDISCUserPermission = async () => {
    let permission = await userServices.getCDISCUserPermission(
      studyId,
      projectID
    );
    setCDISCDBPPermission(permission?.DBPPermission);
    setCDISCLivePermission(permission?.LivePermission);
    setCDISCQCPermission(permission?.QCPermission);
  };

  const getRBQMUserPermission = async () => {
    let permission = await userServices.getRBQMUserPermission(
      studyId,
      projectID
    );
    setRBQMDBPPermission(permission?.DBPPermission);
    setRBQMLivePermission(permission?.LivePermission);
    setRBQMQCPermission(permission?.QCPermission);
  };

  const getProjectIdByStudyId = async () => {
    let projectId = await studyOnboardingService.getProjectIdByStudyId(studyId);
    setProjectID(projectId?.data?.project_id);
    setProjectLabel(projectId?.data?.projectLabel);
    setStudyLabel(projectId?.data?.studyLabel);
  };

  const viewSelectedItemWithSign = async () => {
    setStudyStatus(studyStatusData);
    setStudyStatusData("active");
    let permissionData = await userServices.getStudyUserPermission(
      studyId,
      projectID
    );
    let etmfPermissionData = await userServices.getETMFUserPermission(
      studyId,
      projectID
    );
    let iwrsPermissionData = await userServices.getIWRSUserPermission(
      studyId,
      projectID
    );
    let cdiscPermissionData = await userServices.getCDISCUserPermission(
      studyId,
      projectID
    );
    let rbqmPermissionData = await userServices.getRBQMUserPermission(
      studyId,
      projectID
    );

    setStudyPermissionId(
      permissionData?.permissionId ? permissionData?.permissionId : ""
    );
    setStudyManagement(permissionData?.permissionRole);
    setDBPPermission(permissionData?.DBPPermission);
    setDVPermission(permissionData?.DVPermission);
    setLivePermission(permissionData?.LivePermission);
    setQCPermission(permissionData?.QCPermission);
    setSDVLivePermission(permissionData?.SDVLivePermission);
    setSDVQCPermission(permissionData?.SDVQCPermission);
    setDBPCheck(permissionData?.DBPCheck);
    setDVCheck(permissionData?.DVCheck);
    setLiveCheck(permissionData?.LiveCheck);
    setQCCheck(permissionData?.QCCheck);
    setSDVLiveCheck(permissionData?.SDVLiveCheck);
    setSDVQCCheck(permissionData?.SDVQCCheck);

    setETMFStudyManagement(etmfPermissionData?.permissionRole);
    setETMFDBPPermission(etmfPermissionData?.DBPPermission);
    setETMFLivePermission(etmfPermissionData?.LivePermission);
    setETMFQCPermission(etmfPermissionData?.QCPermission);
    setETMFDBPCheck(etmfPermissionData?.DBPCheck);
    setETMFLiveCheck(etmfPermissionData?.LiveCheck);
    setETMFQCCheck(etmfPermissionData?.QCCheck);

    setIWRSStudyManagement(iwrsPermissionData?.permissionRole);
    setIWRSDBPPermission(iwrsPermissionData?.DBPPermission);
    setIWRSLivePermission(iwrsPermissionData?.LivePermission);
    setIWRSQCPermission(iwrsPermissionData?.QCPermission);
    setIWRSDBPCheck(iwrsPermissionData?.DBPCheck);
    setIWRSLiveCheck(iwrsPermissionData?.LiveCheck);
    setIWRSQCCheck(iwrsPermissionData?.QCCheck);

    setCDISCStudyManagement(cdiscPermissionData?.permissionRole);
    setCDISCDBPPermission(cdiscPermissionData?.DBPPermission);
    setCDISCLivePermission(cdiscPermissionData?.LivePermission);
    setCDISCQCPermission(cdiscPermissionData?.QCPermission);
    setCDISCDBPCheck(cdiscPermissionData?.DBPCheck);
    setCDISCLiveCheck(cdiscPermissionData?.LiveCheck);
    setCDISCQCCheck(cdiscPermissionData?.QCCheck);

    setRBQMStudyManagement(rbqmPermissionData?.permissionRole);
    setRBQMDBPPermission(rbqmPermissionData?.DBPPermission);
    setRBQMLivePermission(rbqmPermissionData?.LivePermission);
    setRBQMQCPermission(rbqmPermissionData?.QCPermission);
    setRBQMDBPCheck(rbqmPermissionData?.DBPCheck);
    setRBQMLiveCheck(rbqmPermissionData?.LiveCheck);
    setRBQMQCCheck(rbqmPermissionData?.QCCheck);
  };

  const previousRole = JSON.parse(localStorage.getItem("role"));
  const previousMode = JSON.parse(localStorage.getItem("StudyMode"));
  const ctmsPermissionData = JSON.parse(
    localStorage.getItem("ctmsPermissionData")
  );

  const loginStudyEDCPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("studyId"));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignStudyPermission === "DBP"
          ? "DBP MODE"
          : assignStudyPermission === "DV"
          ? "DV Mode"
          : assignStudyPermission === "LIVE"
          ? "Live Mode"
          : assignStudyPermission === "QC"
          ? "QC Mode"
          : assignStudyPermission === "SDVLIVE"
          ? "SDV Live Mode"
          : assignStudyPermission === "SDVQC"
          ? "SDV QC Mode"
          : "No Mode"
      )
    );

    window.location.href = `/studyId/${studyId}`;
    localStorage.setItem(
      "studyPermissionData",
      JSON.stringify(
        assignStudyPermission === "DBP"
          ? DBPPermission[0]
          : assignStudyPermission === "DV"
          ? DVPermission[0]
          : assignStudyPermission === "LIVE"
          ? LivePermission[0]
          : assignStudyPermission === "QC"
          ? QCPermission[0]
          : assignStudyPermission === "SDVLIVE"
          ? SDVLivePermission[0]
          : assignStudyPermission === "SDVQC"
          ? SDVQCPermission[0]
          : ""
      )
    );
    localStorage.setItem(
      "studyPermissionId",
      JSON.stringify(studyPermissionId)
    );
    localStorage.setItem("studyManagement", JSON.stringify(studyManagement));
  };

  const loginStudyETMFPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("etmf"));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignETMFStudyPermission === "DBP"
          ? "DBP MODE"
          : assignETMFStudyPermission === "LIVE"
          ? "Live Mode"
          : assignETMFStudyPermission === "QC"
          ? "QC Mode"
          : "No Mode"
      )
    );

    let etmfStudyData = await studyOnboardingService.etmfStudyDatabaseStatus(
      studyId
    );
    window.location.href = `/etmf/${studyId}`;
    localStorage.setItem(
      "etmfPermissionData",
      JSON.stringify(
        assignETMFStudyPermission === "DBP"
          ? ETMFDBPPermission[0]
          : assignETMFStudyPermission === "LIVE"
          ? ETMFLivePermission[0]
          : assignETMFStudyPermission === "QC"
          ? ETMFQCPermission[0]
          : "No Mode"
      )
    );
    localStorage.setItem(
      "etmfStudyManagement",
      JSON.stringify(ETMFStudyManagement)
    );
    localStorage.setItem(
      "etmfDatabaseStatus",
      JSON.stringify(etmfStudyData?.etmfDatabaseStatus)
    );
  };

  const loginStudyIWRSPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("iwrs"));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignIWRSStudyPermission === "DBP"
          ? "DBP MODE"
          : assignIWRSStudyPermission === "LIVE"
          ? "Live Mode"
          : assignIWRSStudyPermission === "QC"
          ? "QC Mode"
          : "No Mode"
      )
    );
    window.location.href = `/iwrs/${studyId}`;
    localStorage.setItem(
      "iwrsPermissionData",
      JSON.stringify(
        assignIWRSStudyPermission === "DBP"
          ? IWRSDBPPermission[0]
          : assignIWRSStudyPermission === "LIVE"
          ? IWRSLivePermission[0]
          : assignIWRSStudyPermission === "QC"
          ? IWRSQCPermission[0]
          : ""
      )
    );
    localStorage.setItem(
      "iwrsStudyManagement",
      JSON.stringify(IWRSStudyManagement)
    );
    // localStorage.setItem(
    //   "iwrsDatabaseStatus",
    //   JSON.stringify(iwrsStudyData?.iwrsDatabaseStatus)
    // );
  };

  const loginStudyCDISCPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("cdisc"));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignCDISCStudyPermission === "DBP"
          ? "DBP MODE"
          : assignCDISCStudyPermission === "LIVE"
          ? "Live Mode"
          : assignCDISCStudyPermission === "QC"
          ? "QC Mode"
          : "No Mode"
      )
    );
    window.location.href = `/cdisc/${studyId}`;
    localStorage.setItem(
      "cdiscPermissionData",
      JSON.stringify(
        assignCDISCStudyPermission === "DBP"
          ? CDISCDBPPermission[0]
          : assignCDISCStudyPermission === "LIVE"
          ? CDISCLivePermission[0]
          : assignCDISCStudyPermission === "QC"
          ? CDISCQCPermission[0]
          : ""
      )
    );
    localStorage.setItem(
      "cdiscStudyManagement",
      JSON.stringify(CDISCStudyManagement)
    );
    // localStorage.setItem(
    //   "cdiscDatabaseStatus",
    //   JSON.stringify(cdiscStudyData?.cdiscDatabaseStatus)
    // );
  };

  const loginStudyRBQMPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("rbqm"));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignRBQMStudyPermission === "DBP"
          ? "DBP MODE"
          : assignRBQMStudyPermission === "LIVE"
          ? "Live Mode"
          : assignRBQMStudyPermission === "QC"
          ? "QC Mode"
          : "No Mode"
      )
    );
    window.location.href = `/rbqm/${studyId}`;
    localStorage.setItem(
      "rbqmPermissionData",
      JSON.stringify(
        assignRBQMStudyPermission === "DBP"
          ? RBQMDBPPermission[0]
          : assignRBQMStudyPermission === "LIVE"
          ? RBQMLivePermission[0]
          : assignRBQMStudyPermission === "QC"
          ? RBQMQCPermission[0]
          : ""
      )
    );
    localStorage.setItem(
      "rbqmStudyManagement",
      JSON.stringify(RBQMStudyManagement)
    );
    // localStorage.setItem(
    //   "rbqmDatabaseStatus",
    //   JSON.stringify(rbqmStudyData?.rbqmDatabaseStatus)
    // );
  };

  const [greeting, setGreeting] = useState("");
  const userName = JSON.parse(localStorage.getItem("firstName"));
  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setGreeting(greeting);
  };

  useEffect(() => {
    getGreeting();
  }, []);

  useEffect(() => {
    getProjectIdByStudyId();
  }, [studyId]);

  useEffect(() => {
    getETMFUserPermission();
    getIWRSUserPermission();
    getStudyUserPermission();
    getCDISCUserPermission();
    getRBQMUserPermission();
    viewSelectedItemWithSign();
  }, [projectID]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            md={4}
            sm={12}
            className="overflow-auto py-2 border-end"
            style={{ height: "calc(100vh - 155px" }}
          >
            {!(
              !(
                ctmsPermissionData?.edcApplicationDBP ||
                ctmsPermissionData?.edcApplicationDV ||
                ctmsPermissionData?.edcApplication
              ) || !studyManagement
            ) && (
              <Card className="mb-2 bg-light shadow-sm border">
                <Card.Body>
                  <div className="hstack gap-3 mb-1">
                    <div className="study_icopn lh-1">
                      <div className="  bg-white shadow-sm p-1">
                        <img
                          src={EDCLogo}
                          alt=""
                          style={{ width: 50, height: 50 }}
                        />
                      </div>
                      <Badge
                        className="  w-100 bg-success"
                        style={{ fontSize: 9 }}
                      >
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax EDC
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> :{" "}
                        {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> :{" "}
                        {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border  "
                      style={{ fontSize: 11 }}
                      onChange={(e) => setAssignStudyPermission(e.target.value)}
                      disabled={
                        !(
                          ctmsPermissionData?.edcApplicationDBP ||
                          ctmsPermissionData?.edcApplicationDV ||
                          ctmsPermissionData?.edcApplication
                        ) || !studyManagement
                      }
                    >
                      <option>Select Environment</option>
                      <>
                        {DBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {DVCheck || DVSwitchCheck ? (
                          <option value={"DV"}>DV Mode</option>
                        ) : (
                          ""
                        )}
                        {LiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {SDVLiveCheck || SDVLiveSwitchCheck ? (
                          <option value={"SDVLIVE"}>SDV Live Mode</option>
                        ) : (
                          ""
                        )}
                        {QCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                        {SDVQCCheck || SDVQCSwitchCheck ? (
                          <option value={"SDVQC"}>SDV QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={
                        !assignStudyPermission ||
                        !(
                          ctmsPermissionData?.edcApplicationDBP ||
                          ctmsPermissionData?.edcApplicationDV ||
                          ctmsPermissionData?.edcApplication
                        ) ||
                        !studyManagement
                      }
                      onClick={loginStudyEDCPanel}
                      className="updateProfile fs11 px-3 pb-1"
                    >
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}
            {!(
              !(
                ctmsPermissionData?.etmfApplicationDBP ||
                ctmsPermissionData?.etmfApplicationDV ||
                ctmsPermissionData?.etmfApplication
              ) || !ETMFStudyManagement
            ) && (
              <Card className="mb-2 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn lh-1">
                      <div className="  bg-white shadow-sm p-1">
                        <img
                          src={ETMFLogo}
                          alt=""
                          style={{ width: 50, height: 50 }}
                        />
                      </div>
                      <Badge
                        className="  w-100 bg-success"
                        style={{ fontSize: 9 }}
                      >
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax ETMF
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> :{" "}
                        {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> :{" "}
                        {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border  "
                      style={{ fontSize: 11 }}
                      onChange={(e) =>
                        setAssignETMFStudyPermission(e.target.value)
                      }
                      disabled={
                        !(
                          ctmsPermissionData?.etmfApplicationDBP ||
                          ctmsPermissionData?.etmfApplicationDV ||
                          ctmsPermissionData?.etmfApplication
                        ) || !ETMFStudyManagement
                      }
                    >
                      <option>Select Environment</option>
                      <>
                        {ETMFDBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {ETMFLiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {ETMFQCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={
                        !assignETMFStudyPermission ||
                        !(
                          ctmsPermissionData?.etmfApplicationDBP ||
                          ctmsPermissionData?.etmfApplicationDV ||
                          ctmsPermissionData?.etmfApplication
                        ) ||
                        !ETMFStudyManagement
                      }
                      onClick={loginStudyETMFPanel}
                      className="updateProfile fs11 px-3 pb-1 "
                    >
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}
            {!(
              !(
                ctmsPermissionData?.iwrsApplicationDBP ||
                ctmsPermissionData?.iwrsApplicationDV ||
                ctmsPermissionData?.iwrsApplication
              ) || !IWRSStudyManagement
            ) && (
              <Card className="mb-2 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn lh-1">
                      <div className="  bg-white shadow-sm p-1">
                        <img
                          src={IWRSLogo}
                          alt=""
                          style={{ width: 50, height: 50 }}
                        />
                      </div>
                      <Badge
                        className="  w-100 bg-success"
                        style={{ fontSize: 9 }}
                      >
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax IWRS
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> :{" "}
                        {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> :{" "}
                        {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border  "
                      style={{ fontSize: 11 }}
                      onChange={(e) =>
                        setAssignIWRSStudyPermission(e.target.value)
                      }
                      disabled={
                        !(
                          ctmsPermissionData?.randomizerApplicationDBP ||
                          ctmsPermissionData?.randomizerApplicationDV ||
                          ctmsPermissionData?.randomizerApplication
                        ) || !IWRSStudyManagement
                      }
                    >
                      <option>Select Environment</option>
                      <>
                        {IWRSDBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {IWRSLiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {IWRSQCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={
                        !assignIWRSStudyPermission ||
                        !(
                          ctmsPermissionData?.randomizerApplicationDBP ||
                          ctmsPermissionData?.randomizerApplicationDV ||
                          ctmsPermissionData?.randomizerApplication
                        ) ||
                        !IWRSStudyManagement
                      }
                      onClick={loginStudyIWRSPanel}
                      className="updateProfile fs11 px-3 pb-1 "
                    >
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}
            {!(
              !(
                ctmsPermissionData?.cdiscApplicationDBP ||
                ctmsPermissionData?.cdiscApplicationDV ||
                ctmsPermissionData?.cdiscApplication
              ) || !CDISCStudyManagement
            ) && (
              <Card className="mb-2 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn lh-1">
                      <div className="  bg-white shadow-sm p-1">
                        <img
                          src={CDISCLogo}
                          alt=""
                          style={{ width: 50, height: 50 }}
                        />
                      </div>
                      <Badge
                        className="  w-100 bg-success"
                        style={{ fontSize: 9 }}
                      >
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax CDISC
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> :{" "}
                        {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> :{" "}
                        {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border  "
                      style={{ fontSize: 11 }}
                      onChange={(e) =>
                        setAssignCDISCStudyPermission(e.target.value)
                      }
                      disabled={
                        !(
                          ctmsPermissionData?.cdiscApplicationDBP ||
                          ctmsPermissionData?.cdiscApplicationDV ||
                          ctmsPermissionData?.cdiscApplication
                        )
                      }
                    >
                      <option>Select Environment</option>
                      <>
                        {CDISCDBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {CDISCLiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {CDISCQCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={
                        !assignCDISCStudyPermission ||
                        !(
                          ctmsPermissionData?.cdiscApplicationDBP ||
                          ctmsPermissionData?.cdiscApplicationDV ||
                          ctmsPermissionData?.cdiscApplication
                        )
                      }
                      onClick={loginStudyCDISCPanel}
                      className="updateProfile fs11 px-3 pb-1 "
                    >
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}
            {!(
              !(
                ctmsPermissionData?.rbqmApplicationDBP ||
                ctmsPermissionData?.rbqmApplicationDV ||
                ctmsPermissionData?.rbqmApplication
              ) || !RBQMStudyManagement
            ) && (
              <Card className="mb-2 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn lh-1">
                      <div className="  bg-white shadow-sm p-1">
                        <img
                          src={RBQMLogo}
                          alt=""
                          style={{ width: 50, height: 50 }}
                        />
                      </div>
                      <Badge
                        className="  w-100 bg-success"
                        style={{ fontSize: 9 }}
                      >
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax RBQM
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> :{" "}
                        {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> :{" "}
                        {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border  "
                      style={{ fontSize: 11 }}
                      onChange={(e) =>
                        setAssignRBQMStudyPermission(e.target.value)
                      }
                      disabled={
                        !(
                          ctmsPermissionData?.rbqmApplicationDBP ||
                          ctmsPermissionData?.rbqmApplicationDV ||
                          ctmsPermissionData?.rbqmApplication
                        )
                      }
                    >
                      <option>Select Environment</option>
                      <>
                        {RBQMDBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {RBQMLiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {RBQMQCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={
                        !assignRBQMStudyPermission ||
                        !(
                          ctmsPermissionData?.rbqmApplicationDBP ||
                          ctmsPermissionData?.rbqmApplicationDV ||
                          ctmsPermissionData?.rbqmApplication
                        )
                      }
                      onClick={loginStudyRBQMPanel}
                      className="updateProfile fs11 px-3 pb-1 "
                    >
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col
            md={8}
            sm={12}
            className="overflow-auto py-2"
            style={{ height: "calc(100vh - 155px" }}
          >
            <div className="text-center">
              <h5 style={{ fontWeight: 500 }}>{greeting}, {userName}</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CtmsDashboardData;
