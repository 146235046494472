import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import { Badge, Col, Container, Form, Placeholder, Row, Table } from "react-bootstrap";
import { studyOnboardingService } from "../../../../Services/studyServices/studyOnboarding";

const StudyManagementData = () => {
  const { studyId } = useParams();
  const [essentialData, setEssentialData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [studyLabel, setStudyLabel] = useState("");
  const getProtocolMetadataData = async () => {
    setLoader(true)
    let data = await studyOnboardingService.getProtocolMetadataData(studyId)
    setEssentialData(data?.data?.filter(item => item.type === "pre"))
    setAdditionalData(data?.data?.filter(item => item.type === "post"))
    setLoader(false)
  }
  const getProjectIdByStudyId = async () => {
    let projectId = await studyOnboardingService.getProjectIdByStudyId(studyId);
    setStudyLabel(projectId?.data?.studyLabel);
  };
  useEffect(() => {
    getProjectIdByStudyId()
    getProtocolMetadataData()
  }, []);
  return (
    <>
      <Container
        fluid
        className="overflow-auto"
        style={{ height: "calc(100vh - 156px)" }}
      >
        {loader ? (
          <SpinerDataLoader />
         ) :
          <Row>
            <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
              <div className="shadow-md border my-2 mx-1   overflow-hidden">
                <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-body-secondary">
                  <Form.Label
                    className="m-0 text-nowrap bold"
                    style={{ fontSize: 13 }}
                  >
                    Study Overview
                  </Form.Label>
                </Form.Group>
                <div className="p-2 bg-light">
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Number :</b>{" "}
                      {!studyLabel ?
                        < Placeholder animation="glow" className="w-100">
                          <Placeholder xs={12} />
                        </Placeholder>
                        : studyLabel}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0">
                      <b>Study Title :</b> {essentialData?.[2]?.parameterValue ? essentialData?.[2]?.parameterValue : "N/A"}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Active Study Version :</b> v1.0.0
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Status :</b> <Badge bg="success"  >Active</Badge>
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
            <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
              <div className="shadow-md border my-2 mx-1   overflow-hidden">
                <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-body-secondary">
                  <Form.Label
                    className="m-0 text-nowrap bold"
                    style={{ fontSize: 13 }}
                  >
                    Study Essential
                  </Form.Label>
                </Form.Group>
                <div className="d-flex gap-3 p-2 bg-light" style={{ flexFlow: "wrap" }}>
                  <Table className="custom-table">
                    <tbody>
                      {essentialData?.map((item) => (
                        item.parameterName === "TITLE" ? "" :
                          <tr>
                            <td style={{ width: 200 }}><b>{item.parameterLabel} : </b></td>
                            <td>
                              <div style={{ textWrap: "balance" }}>
                                {item.parameterValue ? item.parameterValue : "N/A"}
                              </div>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Form.Group>
            {additionalData.length > 0 &&
              <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
                <div className="shadow-md border my-2 mx-1   overflow-hidden">
                  <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-body-secondary">
                    <Form.Label
                      className="m-0 text-nowrap bold"
                      style={{ fontSize: 13 }}
                    >
                      Additional Details
                    </Form.Label>
                  </Form.Group>
                  <div className="d-flex gap-3 p-2 bg-light" style={{ flexFlow: "wrap" }}>
                    <Table className="custom-table">
                      <tbody>
                        {additionalData?.map((item) => (
                          <tr>
                            <td style={{ width: 200 }}><b>{item.parameterLabel} : </b></td>
                            <td>
                              <div style={{ textWrap: "balance" }}>
                              {item.parameterValue ? item.parameterValue : "N/A"}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Form.Group>
            }
          </Row>
        }
      </Container>
    </>
  );
};

export default StudyManagementData;
