import React, { useEffect, useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { randomizerServices } from "../../../../Services/IWRS/randomizerServices";

const RandomizationTableData = ({ tableRef, onMouseDown, factorData, setOptions, studyId }) => {
  const ref = useRef(null);
  const [selectOption, setSelectOption] = useState([]);
  const genderList = [
    { option: "male" },
    { option: "female" },
    { option: "unknown" },
    { option: "undifferentiated" },
    { option: "other" },
  ]

  const findUpdateRandomizerFactorOrder = async () => {
    let data = await randomizerServices.findUpdateRandomizerFactorOrder(studyId)
    setSelectOption(data?.data)
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = factorData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(factorData?.length / itemsPerPage);

  const addUpdateRandomizerFactorOrder = async (name, order) => {
    let userData = {}
    userData.studyId = studyId
    userData.name = name
    userData.order = order
    let data = await randomizerServices.addUpdateRandomizerFactorOrder(userData)
    if (data?.statusCode === 200) {
      findUpdateRandomizerFactorOrder()
    }
  }

  useEffect(() => {
    findUpdateRandomizerFactorOrder();
  }, [studyId]);

  return (
    <>
      <Table
        ref={tableRef}
        id="resizable-table"
        striped
        className="custom-table"
        hover
      >
        <thead className="thead-sticky">
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Lable</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Randomization Option</th>
            <th
              onMouseDown={(e) => onMouseDown(e, 4)}
              style={{ width: 60 }}
              className="text-center"
            >
              View
            </th>
          </tr>
        </thead>
        <tbody ref={ref}>
          <tr onClick={() => setOptions(genderList)}>
            <td>0</td>
            <td>gender</td>
            <td>Gender</td>
            <td className="p-0">
              <Form.Select
                className="p-0 px-2 bg-transparent rounded-0 border-0"
                onChange={(e) => addUpdateRandomizerFactorOrder("gender", e.target.value)}
              >
                <option value="">Select Option</option>
                <option selected={selectOption?.some(data => data.name === "gender" && data.order === "random")} value="random">
                  Random
                </option>
                <option selected={selectOption?.some(data => data.name === "gender" && data.order === "order")} value="order">
                  Order
                </option>
              </Form.Select>
            </td>
            <td className="text-center">
              <button
                className="text-white border-success bg-success border"
                style={{ fontSize: 10 }}
              >
                View
              </button>
            </td>
          </tr>
          {result?.map((item, index, i) => (
            <>
              <tr onClick={() => setOptions(item.options)} key={index}>
                <td>{index + 1 + startIndex}</td>
                <td>{item.columnValue}</td>
                <td>{item.name}</td>
                <td className="p-0">
                  <Form.Select
                    className="p-0 px-2 bg-transparent rounded-0 border-0"
                    onChange={(e) => addUpdateRandomizerFactorOrder(item.columnValue, e.target.value)}
                  >
                    <option value="">Select Option</option>
                    <option selected={selectOption?.some(data => data.name === item.columnValue && data.order === "random")} value="random">
                      Random
                    </option>
                    <option selected={selectOption?.some(data => data.name === item.columnValue && data.order === "order")} value="order">
                      Order
                    </option>
                  </Form.Select>
                </td>
                <td className="text-center">
                  <button
                    className="text-white border-success bg-success border"
                    style={{ fontSize: 10 }}
                  >
                    View
                  </button>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={factorData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default RandomizationTableData;
