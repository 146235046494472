import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

function RandomizerSidebar({ handleAddNewTab, checkActiveTab }) {
  const [openSites, setOpenSites] = useState(false);
  // const [openRandomizations, setOpenRandomizations] = useState(false);
  // const [openTrialDesign, setOpenTrialDesign] = useState(false);
  return (
    <>
      <div className="link__tab">
        <div
          onClick={() => setOpenSites(!openSites)}
          aria-expanded={openSites}
          className={"text-start h-auto tab_url w-100"}
          style={{ background: "#ccd6dd" }}
        >
          <div className="hstack justify-content-between">
            <div>
              <i className={"me-2 ps-0 fa-solid  fa-folder"}></i>
              <span>StudyID</span>
            </div>
          </div>
        </div>
      </div>

      <Collapse in={true}>
        <div>
          <div
            className={`CP small link__tab ${
              checkActiveTab("siteList") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab("Sites", "fa-solid fa-address-card", "siteList");
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Site Management
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("treatmentManagement") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Treatment Management",
                "fa-solid fa-address-card",
                "treatmentManagement"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Treatment Management
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("auditTrial") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Audit Trial",
                "fa-solid fa-address-card",
                "auditTrial"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Audit Trail
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("trialFactorDesign") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Factor",
                "fa-solid fa-address-card",
                "trialFactorDesign"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Factor
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("trialParameters") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Trial Design",
                "fa-solid fa-address-card",
                "trialParameters"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Trial Design
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("randomizeId") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Randomize",
                "fa-solid fa-shuffle ",
                "randomizeId"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-shuffle  ps-2 me-2 small"></i>
              Randomize
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("requestManagement") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Request Management",
                "fa-solid fa-address-card",
                "requestManagement"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Request Management
            </div>
          </div>

          {/* <div className="link__tab">
            <div
              className={`text-start h-auto tab_url w-100 ${openRandomizations === true ? "sidebar__active" : ""
                }`}
              onClick={() => setOpenRandomizations(!openRandomizations)}
              aria-expanded={openRandomizations}
            >
              <div className="hstack justify-content-between">
                <div>
                  <i
                    className={`me-2 ps-2 fa-solid ${!openRandomizations === true
                        ? "fa-dice-six"
                        : "fa-dice-one"
                      }`}
                  ></i>
                  <span>Randomization</span>
                </div>
                <i
                  className={`fa-solid ${openRandomizations === true
                      ? "fa-angle-down"
                      : "fa-angle-right"
                    }`}
                ></i>
              </div>
            </div>
          </div>
          <Collapse in={openRandomizations}>
            <div>
              <div className="link__tab">
                <div
                  className={`text-start h-auto tab_url w-100 ${openTrialDesign === true ? "sidebar__active" : ""
                    }`}
                  onClick={() => setOpenTrialDesign(!openTrialDesign)}
                  aria-expanded={openTrialDesign}
                >
                  <div className="hstack justify-content-between">
                    <div>
                      <i
                        className={`me-2 ps-3 fa-solid ${!openTrialDesign === true
                            ? "fa-dice-six"
                            : "fa-dice-one"
                          }`}
                      ></i>
                      <span>Trial Design</span>
                    </div>
                    <i
                      className={`fa-solid ${openTrialDesign === true
                          ? "fa-angle-down"
                          : "fa-angle-right"
                        }`}
                    ></i>
                  </div>
                </div>
              </div>
              <Collapse in={openTrialDesign}>
                <div>
                  <div
                    className={`CP small link__tab ${checkActiveTab("treatmentDesign") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Treatments",
                        "fa-solid fa-address-card",
                        "treatmentDesign"
                      );
                    }}
                  >
                    <div className="text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-building ps-4 me-2 small"></i>
                      Treatments
                    </div>
                  </div>

                  <div
                    className={`CP small link__tab ${checkActiveTab("trialFactorDesign") && "selectedMenu"
                      }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Factors",
                        "fa-solid fa-address-card",
                        "trialFactorDesign"
                      );
                    }}
                  >
                    <div className="text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-building ps-4 me-2 small"></i>
                      Factors
                    </div>
                  </div>

                
                </div>
              </Collapse>
            
            </div>
          </Collapse> */}
        </div>
      </Collapse>
    </>
  );
}

export default RandomizerSidebar;
