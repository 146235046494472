import config from "../config/config.json";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../Helpers/Responses";
import { headers } from "../Helpers/Headers";
import axios from "axios";
export const elearningServices = {
  getClientELearningUser,
  addStudyElearning,
  getStudyELearningData,
  getAssignedStudyELearningData,
  updateStudyELearning,
  deleteStudyELearningData,
  updateStudyELearningStatus,
  assignStudyELearningUser,

  addStudyElearningQuestion,
  getStudyELearningQuestionData,
  updateStudyELearningQuestionData,
  updateStudyELearningQuestionStatus,
  deleteStudyELearningQuestionData,
  getStudyELearningQuestionForAttempt,
  getStudyELearningTraningProgressReport,
  getStudyELearningTraningProgressReportByUser,
  restartElearningQuestionQuizData,
  getStudyELearningTraningUsers,
  addStudyElearningUserAnswer
};
//get e learning data
async function getClientELearningUser(studyID, trainingId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientELearningUser/${studyID}/${trainingId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// add ElearningMeta data
async function addStudyElearning(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addStudyElearning`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
//get e learning data
async function getStudyELearningData(studyID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningData/${studyID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//get e learning data
async function getAssignedStudyELearningData(studyID, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAssignedStudyELearningData/${studyID}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Update elearning data
async function updateStudyELearning(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearning`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function updateStudyELearningStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearningStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignStudyELearningUser(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudyELearningUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//delete elearning data
async function deleteStudyELearningData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyELearningData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//get e learning data
async function getStudyELearningQuestionData(traningId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningQuestionData/${traningId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//get e learning data
async function getStudyELearningQuestionForAttempt(traningId, studyId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningQuestionForAttempt/${traningId}/${studyId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//get e learning data
async function getStudyELearningTraningProgressReport(traningId, studyId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningTraningProgressReport/${traningId}/${studyId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//get e learning data
async function getStudyELearningTraningProgressReportByUser(traningId, userId, studyId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningTraningProgressReportByUser/${traningId}/${userId}/${studyId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//delete elearning data
async function restartElearningQuestionQuizData(traningId, questionId, studyId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}restartElearningQuestionQuizData/${traningId}/${questionId}/${studyId}/${type}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//get e learning data
async function getStudyELearningTraningUsers(traningId, studyId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningTraningUsers/${traningId}/${studyId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// add ElearningMeta data
async function updateStudyELearningQuestionData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearningQuestionData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function addStudyElearningQuestion(formData) {
  const response = await axios.post(
    `${config.API_URL_USER_ONBOARDING}addStudyElearningQuestion`,
    formData
  );
  const data = response?.data;
  handleResponse(data);
  return data;
}

async function addStudyElearningUserAnswer(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addStudyElearningUserAnswer`,
    headersWithAuth("POST", userData, headers)
  );
  const data = response?.data;
  if(userData?.category === "finalQuiz"){
    let Message = {
      statusCode: 200,
      customMessage: "Traning has been Successfully Completed.",
    };
    handleResponse(Message);
  }
  return data;
}

//Update elearning data
async function updateStudyELearningQuestionStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearningQuestionStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//delete elearning data
async function deleteStudyELearningQuestionData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyELearningQuestionData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}
