import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import SplitPane from "react-split-pane";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const CellDatasetVariable = ({ isFormOpen, domainId, studyId }) => {
  const [variables, setVariables] = useState([]);
  const getCDISCCellVariable = async () => {
    let data = await cdiscServices.getCDISCCellVariable(studyId, domainId)
    setVariables(data?.data)
  }

  useEffect(() => {
    getCDISCCellVariable();
  }, [domainId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = variables?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(variables?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={100}
        defaultSize={"100%"}
      >
        <div>
          <div className="overflow-auto">
            <Table
              id="resizable-table"
              className="text-nowrap custom-table"
              hover
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: 40 }}>Sr.</th>
                  <th>Name</th>
                  <th>Label</th>
                  <th>Type</th>
                  <th>Permission Type</th>
                </tr>
              </thead>
              <tbody>
                {variables?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.column}</td>
                    <td>{item.label}</td>
                    <td className="text-capitalize">{item.type}</td>
                    <td className="text-capitalize">{item.permissionType}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={variables?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
        </div>
      </SplitPane>
    </>
  );
};

export default CellDatasetVariable;
