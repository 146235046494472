import React from "react";
import { Table } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import RandomizedRequestButtonData from "./RandomizedRequestButtonData";

const RandomizeTableData = ({ randomizerList }) => {
  const { toggle, setToggle } = UseToggle();

  return (
    <>
      <div
        className="text-center m-0"
        style={{ fontSize: "13px", color: "#333" }}
      >
        {randomizerList?.length > 0 && (
          <Table className="custom-table">
            <thead>
              <tr>
                {Object.keys(randomizerList[0])?.map((column, index) => (
                  column === "Treatment" ? "" :
                  column === "SiteId" ? "" :
                  column === "Group" ? "" :
                  <th className="text-capitalize" key={index}>
                    {column === "groupId" ? "Group" : column === "siteName" ? "Site Id" : column === "subjectId" ? "Subject Id" : column === "treatmentName" ? "Treatment" : column}
                  </th>
                ))}
                <th className="text-center">Status</th>
                <th className="text-center">Request</th>
              </tr>
            </thead>
            <tbody>
              {randomizerList?.map((item, index) => (
                <tr key={index} className="CP">
                  {Object.keys(item)?.map((key, subIndex) => (
                    key === "Treatment" ? "" :
                    key === "SiteId" ? "" :
                    key === "Group" ? "" :
                    <td key={subIndex}>{item[key]}</td>
                  ))}
                  <td className="text-success text-center">Blinding</td>
                  <td className="text-center">
                    <button
                      className="text-white border-success bg-success border"
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        padding: "2px 8px",
                      }}
                    >
                      Request
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      <RandomizedRequestButtonData
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </>
  );
};

export default RandomizeTableData;
