import React from "react";
import CopyRight from "../Login/CopyRight";
import { useNavigate } from "react-router-dom";

const NotFoundFormat = ({ SRC, MSG, MSG1 }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <img src={SRC} alt="" className=" mb-3" style={{ height: "40vh" }} />

        <div className="tnc">
          <h1 className="copyright text-center mb-3 bold">We are Sorry...</h1>

          <div className="text-center copyright mb-5">
            <div>{MSG}</div>

            <div>{MSG1}</div>
          </div>

          <button
            type="submit"
            className="mb-2 w-100 btn signinbutton"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>

          <CopyRight />
        </div>
      </div>
    </>
  );
};

export default NotFoundFormat;
