import { useState } from "react";

const UseHideShowColumn = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);
  const [isChecked11, setIsChecked11] = useState(false);
  const [isChecked12, setIsChecked12] = useState(false);
  const [isChecked13, setIsChecked13] = useState(false);
  const [isChecked14, setIsChecked14] = useState(false);
  const [isChecked15, setIsChecked15] = useState(false);
  const [isChecked16, setIsChecked16] = useState(false);
  const [isChecked17, setIsChecked17] = useState(false);
  const [isChecked18, setIsChecked18] = useState(false);
  const [isChecked19, setIsChecked19] = useState(false);
  const [isChecked20, setIsChecked20] = useState(false);
  const [isChecked21, setIsChecked21] = useState(false);
  const [isChecked22, setIsChecked22] = useState(false);
  const [isChecked23, setIsChecked23] = useState(false);
  const [isChecked24, setIsChecked24] = useState(false);

  const [tableColumn, setTableColumn] = useState(true);
  const [tableColumn1, setTableColumn1] = useState(true);
  const [tableColumn2, setTableColumn2] = useState(true);
  const [tableColumn3, setTableColumn3] = useState(true);
  const [tableColumn4, setTableColumn4] = useState(true);
  const [tableColumn5, setTableColumn5] = useState(true);
  const [tableColumn6, setTableColumn6] = useState(true);
  const [tableColumn7, setTableColumn7] = useState(true);
  const [tableColumn8, setTableColumn8] = useState(true);
  const [tableColumn9, setTableColumn9] = useState(true);
  const [tableColumn10, setTableColumn10] = useState(true);
  const [tableColumn11, setTableColumn11] = useState(true);
  const [tableColumn12, setTableColumn12] = useState(true);
  const [tableColumn13, setTableColumn13] = useState(true);
  const [tableColumn14, setTableColumn14] = useState(true);
  const [tableColumn15, setTableColumn15] = useState(true);
  const [tableColumn16, setTableColumn16] = useState(true);
  const [tableColumn17, setTableColumn17] = useState(true);
  const [tableColumn18, setTableColumn18] = useState(true);
  const [tableColumn19, setTableColumn19] = useState(true);
  const [tableColumn20, setTableColumn20] = useState(true);
  const [tableColumn21, setTableColumn21] = useState(true);
  const [tableColumn22, setTableColumn22] = useState(true);
  const [tableColumn23, setTableColumn23] = useState(true);
  const [tableColumn24, setTableColumn24] = useState(true);

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCheck1 = (e) => {
    setIsChecked1(e.target.checked);
  };

  const handleCheck2 = (e) => {
    setIsChecked2(e.target.checked);
  };

  const handleCheck3 = (e) => {
    setIsChecked3(e.target.checked);
  };

  const handleCheck4 = (e) => {
    setIsChecked4(e.target.checked);
  };

  const handleCheck5 = (e) => {
    setIsChecked5(e.target.checked);
  };

  const handleCheck6 = (e) => {
    setIsChecked6(e.target.checked);
  };

  const handleCheck7 = (e) => {
    setIsChecked7(e.target.checked);
  };

  const handleCheck8 = (e) => {
    setIsChecked8(e.target.checked);
  };

  const handleCheck9 = (e) => {
    setIsChecked9(e.target.checked);
  };

  const handleCheck10 = (e) => {
    setIsChecked10(e.target.checked);
  };

  const handleCheck11 = (e) => {
    setIsChecked11(e.target.checked);
  };

  const handleCheck12 = (e) => {
    setIsChecked12(e.target.checked);
  };

  const handleCheck13 = (e) => {
    setIsChecked13(e.target.checked);
  };

  const handleCheck14 = (e) => {
    setIsChecked14(e.target.checked);
  };

  const handleCheck15 = (e) => {
    setIsChecked15(e.target.checked);
  };

  const handleCheck16 = (e) => {
    setIsChecked16(e.target.checked);
  };

  const handleCheck17 = (e) => {
    setIsChecked17(e.target.checked);
  };

  const handleCheck18 = (e) => {
    setIsChecked18(e.target.checked);
  };

  const handleCheck19 = (e) => {
    setIsChecked19(e.target.checked);
  };

  const handleCheck20 = (e) => {
    setIsChecked20(e.target.checked);
  };

  const handleCheck21 = (e) => {
    setIsChecked21(e.target.checked);
  };

  const handleCheck22 = (e) => {
    setIsChecked22(e.target.checked);
  };

  const handleCheck23 = (e) => {
    setIsChecked23(e.target.checked);
  };

  const handleCheck24 = (e) => {
    setIsChecked24(e.target.checked);
  };

  const showColumn = () => {
    isChecked && setTableColumn(false);
    !isChecked && setTableColumn(true);

    isChecked1 && setTableColumn1(false);
    !isChecked1 && setTableColumn1(true);

    isChecked2 && setTableColumn2(false);
    !isChecked2 && setTableColumn2(true);

    isChecked3 && setTableColumn3(false);
    !isChecked3 && setTableColumn3(true);

    isChecked4 && setTableColumn4(false);
    !isChecked4 && setTableColumn4(true);

    isChecked5 && setTableColumn5(false);
    !isChecked5 && setTableColumn5(true);

    isChecked6 && setTableColumn6(false);
    !isChecked6 && setTableColumn6(true);

    isChecked7 && setTableColumn7(false);
    !isChecked7 && setTableColumn7(true);

    isChecked8 && setTableColumn8(false);
    !isChecked8 && setTableColumn8(true);

    isChecked9 && setTableColumn9(false);
    !isChecked9 && setTableColumn9(true);

    isChecked10 && setTableColumn10(false);
    !isChecked10 && setTableColumn10(true);

    isChecked11 && setTableColumn11(false);
    !isChecked11 && setTableColumn11(true);

    isChecked12 && setTableColumn12(false);
    !isChecked12 && setTableColumn12(true);

    isChecked13 && setTableColumn13(false);
    !isChecked13 && setTableColumn13(true);

    isChecked14 && setTableColumn14(false);
    !isChecked14 && setTableColumn14(true);

    isChecked15 && setTableColumn15(false);
    !isChecked15 && setTableColumn15(true);

    isChecked16 && setTableColumn16(false);
    !isChecked16 && setTableColumn16(true);

    isChecked17 && setTableColumn17(false);
    !isChecked17 && setTableColumn17(true);

    isChecked18 && setTableColumn18(false);
    !isChecked18 && setTableColumn18(true);

    isChecked19 && setTableColumn19(false);
    !isChecked19 && setTableColumn19(true);

    isChecked20 && setTableColumn20(false);
    !isChecked20 && setTableColumn20(true);

    isChecked21 && setTableColumn21(false);
    !isChecked21 && setTableColumn21(true);

    isChecked22 && setTableColumn22(false);
    !isChecked22 && setTableColumn22(true);

    isChecked23 && setTableColumn23(false);
    !isChecked23 && setTableColumn23(true);

    isChecked24 && setTableColumn24(false);
    !isChecked24 && setTableColumn24(true);
  };

  return {
    handleCheck,
    handleCheck1,
    handleCheck2,
    handleCheck3,
    handleCheck4,
    handleCheck5,
    handleCheck6,
    handleCheck7,
    handleCheck8,
    handleCheck9,
    handleCheck10,
    handleCheck11,
    handleCheck12,
    handleCheck13,
    handleCheck14,
    handleCheck15,
    handleCheck16,
    handleCheck17,
    handleCheck18,
    handleCheck19,
    handleCheck20,
    handleCheck21,
    handleCheck22,
    handleCheck23,
    handleCheck24,

    tableColumn,
    tableColumn1,
    tableColumn2,
    tableColumn3,
    tableColumn4,
    tableColumn5,
    tableColumn6,
    tableColumn7,
    tableColumn8,
    tableColumn9,
    tableColumn10,
    tableColumn11,
    tableColumn12,
    tableColumn13,
    tableColumn14,
    tableColumn15,
    tableColumn16,
    tableColumn17,
    tableColumn18,
    tableColumn19,
    tableColumn20,
    tableColumn21,
    tableColumn22,
    tableColumn23,
    tableColumn24,

    showColumn,
  };
};

export default UseHideShowColumn;
