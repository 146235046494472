import React, { useState } from "react";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studyVisitsServices";
import { useParams } from "react-router";

const EventSignature = ({ Show, Hide, Title, studyUniqueId }) => {
  let {studyId} = useParams()

  let [userId, setUserId] = useState("")
  let [password, setPassword] = useState("")
  let [comment, setComment] = useState("")
  let [loader, setLoader] = useState("")
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const reviewStudyVisit = async () => {
    setLoader(true)
    let userData = {}
    userData.userId = userId
    userData.password = password
    userData.review = comment
    userData.status = "verified"
    userData.study_id = studyId
    userData.mode = StudyMode
    userData.studyUniqueId = studyUniqueId
    let data = await studyOnboardingService.reviewStudyVisit(userData, "reviewStudyVisit")
    if (data?.statusCode === 200) {
      setLoader(false)
      setUserId("")
      setPassword("")
      setComment("")
      Hide()
    } else {
      setLoader(false)
    }
  }
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="p-3 overflow-auto" style={{ height: "calc(100vh - 89px)" }}>
            <div className="signInTerms mb-3">
              <p className="fontSize12 m-0">
                You are required to provide a signature for this activity in order
                to fully complete it. This records that you are signing off theat
                you have taken, and completed this online review activity. This
                will resilt in the completion record being recorded in your review
                history.
              </p>
            </div>
            <Form.Group className="mb-2">
              <Form.Label className="fw-bold fontSize12">
                User Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                 
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter User Name" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="fw-bold fontSize12">
                Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Passoword" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="fw-bold fontSize12">
                Comment <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                 
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Type Comment here..." style={{ height: 100 }} />
            </Form.Group>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button onClick={Hide} className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2">Clear</button>
            <button
              disabled={!userId || !password || !comment || loader}
              onClick={reviewStudyVisit}
              className="border fontSize12   p-1 px-2 updateProfile">
              {loader && (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              )}
              Verify
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EventSignature;
