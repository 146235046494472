import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";
import config from "../../config/config.json";

export const rbqmStudyServices = {
  // Risk Category
  addNewRiskCategory,
  searchRiskCategory,
  getRiskCategoryList,
  deleteRiskCategories,
  updateRiskCategory,

  // Risk Area
  addNewRiskArea,
  searchRiskArea,
  getRiskAreaList,
  deleteRiskArea,
  updateRiskArea,

  // Risk Management
  addNewRiskManagement,
  getRiskManagementList,
  deleteRiskManagement,
  updateRiskManagement,
};

// Add New Risk Management
async function addNewRiskManagement(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyRiskManagement`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Risk Management
async function getRiskManagementList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyRiskManagementList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete  Risk Management
async function deleteRiskManagement(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyRiskManagement/${studyId}/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Risk Management
async function updateRiskManagement(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyRiskManagement`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add New Risk Area
async function addNewRiskArea(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyRiskArea`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Search Risk Area
async function searchRiskArea(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}searchStudyRiskArea`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Risk Area
async function getRiskAreaList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyRiskAreaList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete  Risk Area
async function deleteRiskArea(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyRiskArea/${studyId}/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Risk Area
async function updateRiskArea(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyRiskArea`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add New Risk Category
async function addNewRiskCategory(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyRiskCategory`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Search Risk Category
async function searchRiskCategory(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}searchStudyRiskCategory`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Risk Category
async function getRiskCategoryList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyRiskCategoryList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete  Risk Category
async function deleteRiskCategories(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyRiskCategories/${studyId}/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Risk Category
async function updateRiskCategory(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyRiskCategory`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
