import React from "react";
import { useState } from "react";
import { userServices } from "../../../Services/userServices";
import "../../../Styles/Login.scss";

const TwoFactorAuth = () => {

  const [OTP, setOTP] = useState("");
  let authType = JSON.parse(localStorage.getItem("authType"));
  const twpFactorAuthenticationVerify = async () => {
    let endPoint = authType === "apps" ? userServices.verifyTwoFAForAppLogin : userServices.verifyTwoFAForEmailLogin
    let userData = {}
    userData.otp = OTP
    let data = await endPoint(userData)
    if (data?.statusCode === 200) {
      setOTP("")
      localStorage.setItem("twoFA", JSON.stringify(false));
      window.location.reload()
    }
  }

  const logout = async () => {
    await userServices.logout();
  };

  return (
    <>
      <div className="Login_Page">
        <div className="login__page_wrapper">
          <h3 className="signintittle text-center m-0">Two factor Authentication</h3>
          <div className="text-center py-2">
          {authType === "apps" ? "(By Authenticator App)" : "(By Email)"}
          </div>
          <div className="loginPage pt-3">
            <label htmlFor="userId" className="pb-2 loginPageuserid">
              OTP
            </label>
            <br />
            <input
              type="number"
              className="form-control useridinput"
              id="userId"
              placeholder="Enter6 Digit Your OTP"
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
              required
            />
          </div>
          <div className="hstack justify-content-center mb-2">
            <button onClick={twpFactorAuthenticationVerify} className="mt-3 w-100 btn signinbutton">
              Verify
            </button>
          </div>
          <div className="hstack justify-content-center">
            <div onClick={logout} className="mt-2 w-100 text-center" style={{cursor: "pointer"}}>
              Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuth;
