import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const UpdateMethod = ({
  studyId,
  isFormOpen,
  getCDISCMathods,
}) => {
  let [expressionContext, setExpressionContext] = useState("");
  let [expressionCode, setExpressionCode] = useState("");
  let [document, setDocument] = useState("");
  let [pages, setPages] = useState("");

  const updateStudyMathodMetadata = async() => {
    let userData = {}
    userData.studyId = studyId
    userData.uniqueId = isFormOpen?._id
    userData.expressionContext = expressionContext
    userData.expressionCode = expressionCode
    userData.document = document
    userData.pages = pages
    let data = await cdiscServices.updateStudyMathodMetadata(userData)
    if(data?.statusCode === 200){
      getCDISCMathods()
    }
  }

  useEffect(() => {
    setExpressionContext(isFormOpen?.expressionContext);
    setExpressionCode(isFormOpen?.expressionCode);
    setDocument(isFormOpen?.document);
    setPages(isFormOpen?.pages);
  }, [isFormOpen]);

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Expression Context <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Expression Context"
          onChange={(e) => setExpressionContext(e.target.value)}
          value={expressionContext}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Expression Code <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Expression Code"
          onChange={(e) => setExpressionCode(e.target.value)}
          value={expressionCode}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Document <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Document"
          onChange={(e) => setDocument(e.target.value)}
          value={document}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button  onClick={updateStudyMathodMetadata} className="updateProfile">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </div>
  );
};

export default UpdateMethod;
