import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import CTMSHeaderMenu from "./CTMSHeaderMenu";
import UseToggle from "../../../Hooks/UseToggle";
import AddUpdateCTMSRows from "./AddUpdateCTMSRows";
import CTMSTreatmentTableData from "./CTMSTreatmentTableData";
import AddUpdateCTMSColumn from "./AddUpdateCTMSColumn";
import CTMSTableProperty from "./CTMSTreatmentsTableProperty";
import { ctmsServices } from "../../../Services/studyServices/ctmsServices";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../NoDataFoundTable/NoDataFoundTable";

const CTMSTreatmentManagement = ({
  tableRef,
  onMouseDown,
  viewTypeData,
}) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
  } = UseToggle();
  const { studyId } = useParams();
  const [id, setId] = useState("");
  const [rowId, setRowId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [updatedType, setUpdatedType] = useState("");
  const [subjectLimit, setSubjectLimit] = useState("");
  const [studyUniqueId, setStudyUniqueId] = useState("");
  const [metedataComment, setMetedataComment] = useState("");
  const [selectedNames, setSelectedNames] = useState([]);
  const [customMessage, setCustomMessage] = useState("");
  const [studyDynamicRow, setStudyDynamicRow] = useState([]);
  const [studyDynamicColumn, setStudyDynamicColumn] = useState([]);
  const [studyDynamicColumnID, setStudyDynamicColumnID] = useState("");
  const [updateStudyDynamicRow, setUpdateStudyDynamicRow] = useState([]);
  let allArray = ["Name"];

  let StudyMode = JSON.parse(localStorage.getItem("StudyMode")) || "DBP MODE";

  const [formValues, setFormValues] = useState([]);
  const [name, setName] = useState("");
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyDynamicRow?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyDynamicRow?.length / itemsPerPage);

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...formValues];
    newValues[i][name] = value;

    setFormValues(newValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { name: "", columnValue: "", types: "" }]);
  };

  const removeFormFields = (e, i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);

    setFormValues(newFormValues);
  };

  const onChangeInput = (e, i) => {
    const { name, value } = e.target;

    let items1 = [...studyDynamicColumn];
    items1[i][name] = value;

    setStudyDynamicColumn(items1);
  };

  const getStudyTreatmentColumnData = async () => {
    setLoader(true);
    let data = await ctmsServices.getStudyTreatmentColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort();
      setStudyDynamicColumn(Object.values(finalData?.[0].column));
      setUpdateStudyDynamicColumn(Object.values(finalData?.[0].column) || []);

      setStudyDynamicColumnID(finalData?.[0]._id);
    } else {
      setStudyDynamicColumn("");
      setStudyDynamicColumnID("");
    }
    setLoader(false);
  };

  const setIdUpdateRowTable = (item) => {
    setRowId(item?._id);
    setUpdateStudyDynamicRow(Object?.values(item?.row || []));
    setUpdatedType("editRow");
  };

  const cleanForAddNewSiteData = () => {
    setId("");
    getStudyTreatmentColumnData();
    setSiteId("");
    setSubjectLimit("");
    setStudyUniqueId("");
    setUpdatedType("addRow");
  };

  const createStudyTreatmentColumn = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Add New Column for Treatment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let datas = {};
        setLoading(true);
        datas.studyID = studyId;
        datas.dynamicColumnData = formValues;
        let data = await ctmsServices.createStudyTreatmentColumn(datas);
        if (data?.statusCode === 200) {
          getStudyTreatmentColumnData();
          getTablePropertyData();
          setFormValues([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const updateStudyTreatmentColumn = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Treatment Column!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (updateStudyDynamicColumn.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "At least one value is required to update!",
          });
          return;
        }
        let datas = {};
        datas.id = studyDynamicColumnID;
        datas.studyID = studyId;
        datas.dynamicColumnData = updateStudyDynamicColumn;
        let data = await ctmsServices.updateStudyTreatmentColumn(datas);
        if (data.statusCode === 200) {
          getStudyTreatmentRowData();
          getTablePropertyData();
          getStudyTreatmentColumnData();
          setFormValues([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const AddStudyTreatmentRow = async (e) => {
    cleanForAddNewSiteData();
    e.preventDefault();
    let datas = {};
    setLoading(true);
    datas.studyID = studyId;
    datas.name = name;
    datas.columnId = studyDynamicColumnID;
    datas.dynamicColumnData = studyDynamicColumn;
    let result = await ctmsServices.AddStudyTreatmentRow(datas);
    setName("")
    await getStudyTreatmentRowData();
    if (result?.statusCode === 200) {
      cleanForAddNewSiteData();
      setLoading(false);
    } else {
      setLoading(false);
    }
    setStatusCode(result?.statusCode);
    setCustomMessage(result.customMessage);
  };

  const getStudyTreatmentRowData = async () => {
    let data = await ctmsServices.getStudyTreatmentRowData(
      studyId,
      studyDynamicColumnID
    );
    let initialData = Object.values(data?.data === undefined ? "" : data?.data);

    let finalData = initialData?.sort()?.reverse();
    setStudyDynamicRow(finalData)
  };

  const UpdateStudyTreatmentRow = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Treatment Row!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let datas = {};
        datas.studyID = studyId;
        datas.id = rowId;
        datas.name = name;
        datas.rowUpdateData = studyDynamicColumn;
        let data = await ctmsServices.UpdateStudyTreatmentRow(datas);
        await getStudyTreatmentRowData();
        if (data?.statusCode === 200) {
          setLoading(false);
          cleanForAddNewSiteData();
        } else {
          setLoading(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const deleteStudyTreatmentRowData = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Treatment data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let data = await ctmsServices.deleteStudyTreatmentRowData(studyId, id);
        if (data?.statusCode === 200) {
          getStudyTreatmentRowData();
        }
      }
    });
  };

  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  // UPDATE COLUMN DATA ONCHANGE..........................................

  const [updateStudyDynamicColumn, setUpdateStudyDynamicColumn] = useState([]);

  const addUpdateOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicColumn];
    newValues[i][name] = value;

    setUpdateStudyDynamicColumn(newValues);
  };

  const removeAddUpdateFields = (e, i) => {
    let newFormValues = [...updateStudyDynamicColumn];
    newFormValues.splice(i, 1);
    setUpdateStudyDynamicColumn(newFormValues);

    let originalColumnLength = studyDynamicColumn?.length;
    let finalIndex = i - originalColumnLength;
    if (finalIndex >= 0) {
      let newAddFormValues = [...formValues];
      newAddFormValues.splice(finalIndex, 1);
      setFormValues(newAddFormValues);
    }
  };

  // UPDATE ROW DATA ONCHANGE ..........................................

  const UpdateRowOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setUpdateStudyDynamicRow(newValues);
  };

  const UpdateHistoryOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setMetedataComment(newValues);
  };

  const getTablePropertyData = async () => {
    let data = await ctmsServices.getTablePropertyData(
      "studyTreatment",
      studyDynamicColumnID,
      studyId
    );
    let columnProperty = data?.data?.columnData;
    let finalColumnProperty =
      columnProperty === undefined ? allArray : columnProperty;
    setSelectedNames(finalColumnProperty);
  };

  const addTableAndRegularView = async (e, viewType) => {
    let datas = {};
    datas.studyId = studyId;
    datas.category = "studyTreatment";
    datas.StudyMode = StudyMode;
    datas.viewType = viewType;
    await ctmsServices.addTableAndRegularView(datas);
    getTablePropertyData()
  };

  const getStudyRightSiteData = (item) => {
  };

  useEffect(() => {
    setUpdateStudyDynamicColumn([...studyDynamicColumn, ...formValues] || []);
  }, [studyDynamicColumnID, formValues]);

  useEffect(() => {
    getTablePropertyData();
    getStudyTreatmentRowData();
  }, [studyDynamicColumnID]);

  useEffect(() => {
    getStudyTreatmentColumnData();
  }, []);

  return (
    <>
      {StudyMode === "DBP MODE" && (
        <CTMSHeaderMenu
          studyDynamicColumn={studyDynamicColumn}
          setUpdatedType={setUpdatedType}
          tableProperty={setToggle}
          filterData={setToggle1}
          studyDynamicRow={studyDynamicRow}
          updatedType={updatedType}
          cleanForAddNewSiteData={cleanForAddNewSiteData}
          addTableAndRegularView={addTableAndRegularView}
          filter={filter}
          setFilter={setFilter}
          getStudyTreatmentRowData={getStudyTreatmentRowData}
          viewTypeData={viewTypeData}
        />
      )}
      <SplitPane
        split="horizontal"
        style={{
          height: "calc(100vh - 187px)",
        }}
        className="position-relative"
        defaultSize={"100%"}
      >
        <SplitPane
          split="vertical"
          minSize={20}
          defaultSize={updatedType ? "30%" : "100%"}
        >
          {StudyMode === "DBP MODE" &&
            updatedType &&
            (updatedType === "addColumn" || updatedType === "editColumn" ? (
              <AddUpdateCTMSColumn
                formValues={formValues}
                addHandleChange={handleChange}
                updateHandleChange={addUpdateOnChange}
                addFormFields={addFormFields}
                removeFormFields={removeFormFields}
                handleSubmit0={updateStudyTreatmentColumn}
                handleSubmit={createStudyTreatmentColumn}
                updateStudyDynamicColumn={updateStudyDynamicColumn}
                studyDynamicColumn={studyDynamicColumn}
                removeAddUpdateFields={removeAddUpdateFields}
                loader={loading}
                statusCode={statusCode}
                customMessage={customMessage}
              />
            ) : (
              (updatedType === "addRow" || updatedType === "editRow") &&
              studyDynamicColumn?.length > 0 && (
                <AddUpdateCTMSRows
                  formValues={formValues}
                  setFormValues={setFormValues}
                  updateHandleChange={UpdateRowOnChange}
                  addHandleChange={onChangeInput}
                  addFormFields={addFormFields}
                  removeFormFields={removeFormFields}
                  siteId={siteId}
                  setSiteId={setSiteId}
                  subjectLimit={subjectLimit}
                  setSubjectLimit={setSubjectLimit}
                  handleSubmit1={AddStudyTreatmentRow}
                  handleSubmit2={UpdateStudyTreatmentRow}
                  studyUniqueId={studyUniqueId}
                  studyDynamicColumnData={updateStudyDynamicRow}
                  studyAddDynamicColumnData={studyDynamicColumn}
                  cleanForAddNewSiteData={cleanForAddNewSiteData}
                  UpdateHistoryOnChange={UpdateHistoryOnChange}
                  onChangeInput={onChangeInput}
                  loading={loading}
                  statusCode={statusCode}
                  customMessage={customMessage}
                  rowId={rowId}
                  setName={setName}
                  name={name}
                />
              )
            ))}
          {loader ? (
            <SpinerDataLoader />
          ) : studyDynamicColumn?.length === 0 ? (
            <NoDataFoundTable MSG="CTMS Data not found" />
          ) : (
            <CTMSTreatmentTableData
              studyDynamicColumn={studyDynamicColumn}
              hideColumn={selectedNames}
              studyDynamicRow={result}
              setIdUpdateRowTable={setIdUpdateRowTable}
              handleSubmit3={deleteStudyTreatmentRowData}
              getStudyRightSiteData={getStudyRightSiteData}
              tableRef={tableRef}
              onMouseDown={onMouseDown}
              totalPage={totalPage}
              startIndex={startIndex}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={result.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
              setUpdatedType={setUpdatedType}
              viewTypeData={viewTypeData}
              setIsSectionVisible={setIsSectionVisible}
              isSectionVisible={isSectionVisible}
            />
          )}
        </SplitPane>
      </SplitPane>
      <CTMSTableProperty
        Show={!toggle}
        Hide={setToggle}
        allArray={allArray}
        selectedNames={selectedNames}
        setSelectedNames={setSelectedNames}
        studyDynamicColumn={studyDynamicColumn}
        studyId={studyId}
        studyDynamicColumnID={studyDynamicColumnID}
        getStudyTreatmentColumnData={getStudyTreatmentColumnData}
        getStudyTreatmentRowData={getStudyTreatmentRowData}
        Title={"Table Property"}
        viewTypeData={viewTypeData}
      />
    </>
  );
};

export default CTMSTreatmentManagement;
