import React from "react";
import { BsGrid } from "react-icons/bs";

const SubjectHeaderMenu = ({
  studyDynamicColumn,
  cleanForAddNewSiteData,
  setUpdatedType,
  tableProperty,
  filterData,
  studyDynamicRow,
  addTableAndRegularView,
  updatedType,
  setFilter,
  filter,
  getStudySubjectRowData,
  breadcrumbData,
  handleAddNewTab,
}) => {
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  return (
    <>
      {breadcrumbData?.length > 0 && (
        <div className="border-bottom" style={{ height: 20 }}>
          <ul id="breadcrumb">
            {breadcrumbData?.map((item, index) => (
              <li key={index}>
                {breadcrumbData?.length === index + 1 ? (
                  <a>{item?.tabName}</a>
                ) : (
                  <a
                    onClick={() =>
                      handleAddNewTab(
                        item?.currentTabName,
                        "fa-solid fa-database",
                        item?.uniqueId,
                        item?.pageId,
                        item?.breadcrumb
                      )
                    }
                  >
                    {item?.tabName}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          {studyDynamicColumn?.length > 0 &&
            (StudyMode === "DBP MODE" ||
              StudyMode === "Live Mode" ||
              StudyMode === "QC Mode") && (
              <>
                {updatedType !== "addRow" ? (
                  <i
                    onClick={cleanForAddNewSiteData}
                    title="Add Site Row"
                    className="fa-solid fa-plus-square CP"
                  />
                ) : (
                  <i
                    onClick={() => setUpdatedType("")}
                    title="Table Full Mode"
                    className="fa-solid fa-table-list"
                  ></i>
                )}
                <div className="vr"></div>
                {updatedType === "editRow" && (
                  <>
                    <i
                      onClick={() => setUpdatedType("")}
                      title="Table Full Mode"
                      className="fa-solid fa-table-list"
                    ></i>
                    <div className="vr"></div>
                  </>
                )}
              </>
            )}
          {studyDynamicColumn?.length > 0 && (
            <>
              <i
                onClick={tableProperty}
                title="Table Properties"
                className="fa-solid fa-list CP"
              />
              <div className="vr"></div>
            </>
          )}
          {studyDynamicRow?.length > 0 && (
            <>
              <i
                onClick={filterData}
                title="Filter"
                className="fa-solid fa-filter CP"
              />
            </>
          )}
          {filter && (
            <i
              onClick={() => {
                setFilter(false);
                getStudySubjectRowData();
              }}
              title="Clear Filter"
              className="fa-solid fa-filter-circle-xmark CP red"
            />
          )}
        </div>
        <i
          className="fa-solid fa-table-list CP"
          onClick={(e) => addTableAndRegularView(e, "regular")}
          title="Switch to Grid View"
        ></i>
      </div>
    </>
  );
};

export default SubjectHeaderMenu;
