import React from 'react'
import { HomeLineSection } from '../../../Layouts/Layouts'

const RecruitmentSummary = () => {
  return (
    <>
        <HomeLineSection>
        <div className="hstack justify-content-between w-100">
          <div className="hstack gap-2">
            <i className="fa-solid fa-home"></i>
          </div>
        </div>
      </HomeLineSection>
      <div>
        hii
      </div>
    </>
  )
}

export default RecruitmentSummary
