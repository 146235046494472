import React from "react";

const EventHeaderMenu = ({
  studyDynamicColumn,
  cleanForAddNewEventData,
  setUpdatedType,
  tableProperty,
  filterData,
  updatedType,
  studyDynamicRow,
  filter,
  setFilter,
  getStudyVisitRowData,
  viewTypeData,
}) => {
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  return (
    <>
      <div className="p-1 hstack justify-content-between border-bottom">
        <div className="hstack gap-2 toolbar">
          {StudyMode === "DBP MODE" &&
            (studyDynamicColumn?.length > 0 ? (
              <>
                {viewTypeData === "ctms" &&
                  (updatedType !== "addRow" ? (
                    <>
                      <i
                        onClick={cleanForAddNewEventData}
                        title="Add Event Row"
                        className="fa-solid fa-plus-square CP"
                      />
                      <div className="vr"></div>
                    </>
                  ) : (
                    <>
                      <i
                        onClick={() => setUpdatedType("")}
                        title="Table Full Mode"
                        className="fa-solid fa-table-list"
                      ></i>
                      <div className="vr"></div>
                    </>
                  ))}
                {viewTypeData === "ctms" && updatedType === "editRow" && (
                  <>
                    <i
                      onClick={() => setUpdatedType("")}
                      title="Table Full Mode"
                      className="fa-solid fa-table-list"
                    ></i>
                    <div className="vr"></div>
                  </>
                )}
                {viewTypeData === "ctms" &&
                  (updatedType !== "editColumn" ? (
                    <>
                      <i
                        onClick={() => setUpdatedType("editColumn")}
                        title="Edit Event Column"
                        className="fa-regular fa-pen-to-square CP"
                      />
                      <div className="vr"></div>
                    </>
                  ) : (
                    <>
                      <i
                        onClick={() => setUpdatedType("")}
                        title="Table Full Mode"
                        className="fa-solid fa-table-list"
                      ></i>
                      <div className="vr"></div>
                    </>
                  ))}
              </>
            ) : (
              viewTypeData === "ctms" && (
                <>
                  <i
                    onClick={() => {
                      setUpdatedType("addColumn");
                    }}
                    title="Add Event Column"
                    className="fa-solid fa-plus-square CP"
                  />
                  <div className="vr"></div>
                </>
              )
            ))}
          {studyDynamicColumn?.length > 0 && (
            <>
              <i
                onClick={tableProperty}
                title="Table Properties"
                className="fa-solid fa-list CP"
              />
              <div className="vr"></div>
            </>
          )}
          {studyDynamicRow?.length > 0 && (
            <i
              onClick={filterData}
              title="Filter"
              className="fa-solid fa-filter CP"
            />
          )}
          {filter && (
            <>
              <div className="vr"></div>
              <i
                onClick={() => {
                  setFilter(false);
                  getStudyVisitRowData();
                }}
                title="Clear Filter"
                className="fa-solid fa-filter-circle-xmark CP red"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EventHeaderMenu;
