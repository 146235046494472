import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySitesServices";
import { FaCheck } from "react-icons/fa6";

const TableProperty = ({
  Show,
  Hide,
  Title,
  allArray,
  selectedNames,
  setSelectedNames,
  studyDynamicColumn,
  studyId,
  studyDynamicColumnID,
  getStudySiteColumnData,
  getStudySiteRowData,
  viewTypeData,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  let totalColumnLength = allArray?.length + studyDynamicColumn?.length;

  const handleCheckboxChange = (name) => {
    let updatedNames;
    if (selectedNames.includes(name)) {
      updatedNames = selectedNames.filter(
        (selectedName) => selectedName !== name
      );
    } else {
      updatedNames = [...selectedNames, name];
    }
    setSelectedNames(updatedNames);
    if (totalColumnLength === updatedNames?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allNames = studyDynamicColumn?.map(({ name }) => name);
      setSelectedNames([...allNames, ...allArray]);
    } else {
      setSelectedNames([]);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmitTableProperty = async () => {
    let datas = {};
    datas.id = studyId;
    datas.category = "studySite";
    datas.columnId = studyDynamicColumnID;
    datas.columnData = selectedNames;
    let data = await studyOnboardingService.addTableProperty(datas);
    if (data.statusCode === 200) {
      Hide();
      getStudySiteColumnData();
      getStudySiteRowData();
    }
  };

  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              checked={selectAll || totalColumnLength === selectedNames?.length}
              onChange={handleSelectAll}
              label="Select All"
            />
          </div>
          <div className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              name="Site Id"
              checked={selectedNames?.includes("Site Id")}
              onChange={() => handleCheckboxChange("Site Id")}
              id="edit_label"
              label="Site Id"
            />
          </div>
          <div className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              name="Number of Subject"
              checked={selectedNames?.includes("Number of Subject")}
              onChange={() => handleCheckboxChange("Number of Subject")}
              id="edit_label"
              label="Number of Subject"
            />
          </div>
          {viewTypeData === "edc" && (
            <div className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                name="Status"
                checked={selectedNames?.includes("Status")}
                onChange={() => handleCheckboxChange("Status")}
                id="edit_label"
                label="Status"
              />
            </div>
          )}
          {studyDynamicColumn &&
            studyDynamicColumn.map(({ name }, i) => (
              <div key={i} className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  name={name}
                  checked={selectedNames?.includes(name)}
                  onChange={() => handleCheckboxChange(name)}
                  label={name}
                />
              </div>
            ))}
          {viewTypeData === "ctms" && (
            <>
              <div className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  name="Query Status"
                  checked={selectedNames?.includes("Query Status")}
                  onChange={() => handleCheckboxChange("Query Status")}
                  id="edit_label"
                  label="Query Status"
                />
              </div>
              <div className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  name="Query Date | Time"
                  checked={selectedNames?.includes("Query Date | Time")}
                  onChange={() => handleCheckboxChange("Query Date | Time")}
                  id="edit_label"
                  label="Query Date | Time"
                />
              </div>
              <div className="border-top text-end my-2 pt-2">
                <button
                  onClick={() => handleSubmitTableProperty()}
                  className="submitbutton save__all__data_action"
                >
                  <FaCheck /> Apply
                </button>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TableProperty;
