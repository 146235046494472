import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Button, Modal, Table } from "react-bootstrap";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";
import UseToggle from "../../../../../../../Hooks/UseToggle";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySubjectsServices";

const SiteSubjectReviewHistory = ({ onMouseDown, tableRef, studyUniqueId }) => {
  const [reviewHistory, setReviewHistory] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);

  const { setToggle, toggle } = UseToggle();
  const [comment, setComment] = useState("");

  const getStudySubjectReviewData = async () => {
    setTableLoader(true);
    let data = await studyOnboardingService.getStudySubjectReviewData(studyUniqueId);
    setReviewHistory(data?.data || []);
    setTableLoader(false)
  };

  useEffect(() => {
    getStudySubjectReviewData();
  }, [studyUniqueId]);

  const handleRowReviewMessage = (e, comment) => {
    setToggle();
    setComment(comment);
  };

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize={"100%"} style={{ height: "84%" }}>
        {tableLoader ? (
          <SpinerDataLoader />
        ) : reviewHistory?.length === 0 ? (
          <NoDataFoundTable MSG="Review history not found" />
        ) : (
          <>
            <Table
              id="resizable-table"
              striped
              className="custom-table"
              style={{ tableLayout: "auto" }}
              hover
              bordered
              ref={tableRef}
            >
              <thead className="position-sticky top-0">
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Subject Id
                  </th>
                  <th
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Version
                  </th>
                  <th className="text-center" onMouseDown={(e) => onMouseDown(e, 3)}>Status</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Reviewed By</th>
                  <th onMouseDown={(e) => onMouseDown(e, 5)}>Review Date</th>
                  <th onMouseDown={(e) => onMouseDown(e, 6)}>Review Time</th>
                  <th onMouseDown={(e) => onMouseDown(e, 7)}>View</th>
                </tr>
              </thead>
              <tbody>
                {reviewHistory?.map((item, index) => (
                  <tr className="relative">
                    <td>{index + 1}.</td>
                    <td>{item.subjectId}</td>
                    <td className="text-center">{item.version}</td>
                    <td className={`text-center text-capitalize fw-bold ${item.status === "verified" ? "text-success" : "text-secondary"}`}>{item.status}</td>
                    <td title={item.reviewerID}>{item.fullName}</td>
                    <td>
                      <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                    </td>
                    <td>
                      <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                    </td>
                    <td>
                      {item.review ?
                        <Button
                          onClick={(e) => handleRowReviewMessage(e, item.review)}
                          style={{ fontSize: 10, borderRadius: 3, padding: "2px 8px" }}
                          className="text-white border-success bg-success border">
                          View Review
                        </Button>
                        :
                        "N/A"
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </SplitPane>
      <Modal
        show={!toggle}
        onHide={setToggle}
        style={{ background: "#7f7f7fbf" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>{comment ? comment : "No Comment"}</>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SiteSubjectReviewHistory;
