import React from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { rbqmStudyServices } from "../../../../Services/rbqmServices/rbqmStudyServices";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const ViewNotification = ({
  Show,
  Hide,
  Title,
  notificationData,
  id,
  setNotificationData,
  setLoader,
  getRiskAreaList,
  indicatorLength,
  studyId,
}) => {
  const updateRiskAreaData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Risk Area Notification?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId;
        userData.id = id;
        userData.notification = notificationData;
        let data = await rbqmStudyServices.updateRiskArea(userData);
        if (data?.statusCode === 200) {
          getRiskAreaList();
        }
        setLoader(false);
      }
    });
  };

  const handleIndicatorChange = (index, field, value) => {
    const updatedNotificationData = [...notificationData];
    updatedNotificationData[index][field] = value;
    setNotificationData(updatedNotificationData);
  };

  const handleAddIndicator = () => {
    setNotificationData([
      ...notificationData,
      { from: "", to: "", subject: "", message: "" },
    ]);
  };

  const handleRemoveNotificationData = (index) => {
    const updatedNotificationData = notificationData.filter(
      (_, i) => i !== index
    );
    setNotificationData(updatedNotificationData);
  };

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="hstack justify-content-between position-sticky top-0 bg-light p-3">
            <Form.Label className="mb-1 fw-bold">Notification</Form.Label>
            <button
              title="Click to Add More Notification"
              className="text-white border-success bg-success border-0 fs-10"
              onClick={handleAddIndicator}
              style={{ fontSize: 11 }}
            >
              <i className="fa-solid fa-square-plus"></i> Add More
            </button>
          </div>
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 145px" }}
          >
            {notificationData?.map((item, index) => (
              <div className="py-2 px-3">
                <div className="border border-light p-3 shadow-sm position-relative">
                  <div className="hstack gap-2 mb-1" key={index}>
                    <Form.Group className="w-100 mb-2">
                      <Form.Label className="fw-bold mb-1">From</Form.Label>
                      <Form.Select
                        value={item?.from}
                        onChange={(e) =>
                          handleIndicatorChange(index, "from", e.target.value)
                        }
                      >
                        <option value="">Select Indicator</option>
                        {Array.from({ length: indicatorLength })?.map(
                          (item, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="w-100 mb-2">
                      <Form.Label className="fw-bold mb-1">To</Form.Label>
                      <Form.Select
                        value={item?.to}
                        onChange={(e) =>
                          handleIndicatorChange(index, "to", e.target.value)
                        }
                      >
                        <option value="">Select Indicator</option>
                        {Array.from({ length: indicatorLength })?.map(
                          (item, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Form.Group>
                    {notificationData.length > 1 && (
                      <div
                        title="Remove this Section"
                        className="text-danger w-auto p-0 p-2 text-danger position-absolute top-0 end-0"
                        style={{ fontSize: 12, cursor: "pointer" }}
                        onClick={() => handleRemoveNotificationData(index)}
                      >
                        <FaTrashAlt />
                      </div>
                    )}
                  </div>
                  <Form.Group className="mb-2">
                    <Form.Control
                      value={item?.subject}
                      onChange={(e) =>
                        handleIndicatorChange(index, "subject", e.target.value)
                      }
                      type="text"
                      placeholder="Type Subject"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Control
                      value={item?.message}
                      onChange={(e) =>
                        handleIndicatorChange(index, "message", e.target.value)
                      }
                      as={`textarea`}
                      style={{ height: 150 }}
                    />
                  </Form.Group>
                </div>
              </div>
            ))}
          </div>
          <Form.Group className="text-end p-2 bg-light w-100">
            <button onClick={updateRiskAreaData} className="updateProfile">
              Save
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
export default ViewNotification;
