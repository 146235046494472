import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import axios from "axios";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const formReportServices = {
  addUpdateReportStudyMetadata,
  getReportStudyMetadataData,
  uploadReportLogo,
  findReportStudyMetadataData,
};

//  Upload Logo
async function uploadReportLogo(formData) {
  const response = await axios.post(
    `${config.API_URL_USER_ONBOARDING}uploadReportLogo`,
    formData
  );
  const data = response?.data;
  handleResponse(data);
  return data;
}

async function addUpdateReportStudyMetadata(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addUpdateReportStudyMetadata`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function getReportStudyMetadataData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getReportStudyMetadataData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findReportStudyMetadataData(studyId, type) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findReportStudyMetadataData/${studyId}/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
