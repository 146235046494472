import React, { useState } from "react";
import SplitPane from "react-split-pane";
import { Table } from "react-bootstrap";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";

const RandomizeTabRequestData = ({ tableRef, onMouseDown }) => {
    const [loader, setLoadeer] = useState("");
    const data = Array.from({ length: 10 }, (v, i) => ({
        sr: i + 1,
        requestedBy: `Gaurav Chauhan ${i + 1}`,
        remarks: `DBP`,
        requestedDateTime: new Date().toLocaleString(),
        status: i % 2 === 0 ? "Completed" : "Pending",
    }));
    return (
        <>
            <SplitPane
                split="vertical"
                minSize={20}
                defaultSize="100%"
                style={{ height: "91%" }}
            >
                {loader ? (
                    <SpinerDataLoader />
                ) : (
                    <div className="table-container tableLibrarySection">
                        <Table
                            id="resizable-table"
                            striped
                            hover
                            className="m-0 custom-table"
                        >
                            <thead className="thead-sticky top-0">
                                <tr>
                                    <th style={{ width: 45 }}>Sr.</th>
                                    <th>Requested By</th>
                                    <th>Remark</th>
                                    <th>Requested Date | Time</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.sr}</td>
                                        <td>{item.requestedBy}</td>
                                        <td>{item.remarks}</td>
                                        <td>{item.requestedDateTime}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </SplitPane>
        </>
    );
};

export default RandomizeTabRequestData;
