import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const RandomizeSideData = ({ siteList, factorData }) => {
  let [subject, setSubject] = useState("");
  let [remark, setRemark] = useState("");
  let [gender, setGender] = useState("");
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">Site</Form.Label>
          <Form.Select style={{ fontSize: 11 }}>
            <option>Select Site List</option>
            {siteList?.map((site) => (
              <option key={site._id} value={site.siteId}>
                {site.siteIdLabel}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">Subject Id</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            placeholder="Enter Subject Id"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">Gender</Form.Label>
          <Form.Select
            style={{ fontSize: 11 }}
            onChange={(e) => setGender(e.target.value)}
          >
            <option>Select Gender</option>
            <option selected={gender === "male"} value="male">
              Male
            </option>
            <option selected={gender === "female"} value="female">
              Female
            </option>
            <option selected={gender === "unknown"} value="unknown">
              Unknown
            </option>
            <option selected={gender === "undifferentiated"} value="undifferentiated">
              Undifferentiated
            </option>
            <option selected={gender === "other"} value="other">
              Other
            </option>
          </Form.Select>
        </Form.Group>
        {factorData?.map((item, index) => (
          <Form.Group key={index} className="mb-2">
            <Form.Label className="mb-1 fw-bold">{item.name}</Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
            >
              <option>Select {item.name}</option>
              {item?.options?.map((data, key) => (
                <option key={key} value={data.option}>
                  {data.option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        ))}
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">Remarks</Form.Label>
          <Form.Control
            as="textarea"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            placeholder="Write Remarks Here"
          />
        </Form.Group>
        <div className="text-end mb-3 px-1">
          <Button
            className="updateProfile"
            style={{ width: 100, fontSize: "13px", marginRight: "5px" }}
          >
            Randomize
          </Button>
        </div>
      </div>
    </>
  );
};

export default RandomizeSideData;
