import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

const RBQMDashboard = () => {

  const [greeting, setGreeting] = useState("");
  const userName = JSON.parse(localStorage.getItem("firstName"));
  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setGreeting(greeting);
  };

  useEffect(() => {
    getGreeting();
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="chart__overview">
          <Col md={12} className="border-bottom mb-2">
            <div className="text-center py-2">
              <h5 className="m-0" style={{ fontWeight: 500 }}>
                {greeting}, {userName}
              </h5>
              <p className="m-0">Welcome to your RBQM dashboard.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RBQMDashboard;
