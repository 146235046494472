import React from "react";

const CTMSHeaderMenu = ({
    studyDynamicColumn,
    cleanForAddNewSiteData,
    setUpdatedType,
    tableProperty,
    filterData,
    studyDynamicRow,
    updatedType,
    setFilter,
    filter,
    getStudySiteRowData,
    viewTypeData
}) => {
    const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
    return (
        <>
            <div
                className="hstack justify-content-between border-bottom px-2"
                style={{ padding: "2.4px" }}
            >
                <div className="hstack gap-2 toolbar">
                    {(viewTypeData === "ctms" && StudyMode === "DBP MODE") &&
                        (studyDynamicColumn?.length > 0 ? (
                            <>
                                {updatedType !== "addRow" ? (
                                    <i
                                        onClick={cleanForAddNewSiteData}
                                        title="Add Site Row"
                                        className="fa-solid fa-plus-square CP"
                                    />
                                ) : (
                                    <i
                                        onClick={() => setUpdatedType("")}
                                        title="Table Full Mode"
                                        className="fa-solid fa-table-list"
                                    ></i>
                                )}
                                <div className="vr"></div>
                                {updatedType === "editRow" && (
                                    <>
                                        <i
                                            onClick={() => setUpdatedType("")}
                                            title="Table Full Mode"
                                            className="fa-solid fa-table-list"
                                        ></i>
                                        <div className="vr"></div>
                                    </>
                                )}
                                {updatedType !== "editColumn" ? (
                                    <i
                                        onClick={() => setUpdatedType("editColumn")}
                                        title="Edit Site Column"
                                        className="fa-regular fa-pen-to-square CP"
                                    />
                                ) : (
                                    <i
                                        onClick={() => setUpdatedType("")}
                                        title="Table Full Mode"
                                        className="fa-solid fa-table-list"
                                    ></i>
                                )}
                                <div className="vr"></div>
                            </>
                        ) : (
                            <>
                                <i
                                    onClick={() => {
                                        setUpdatedType("addColumn");
                                    }}
                                    title="Add Site Column"
                                    className="fa-solid fa-plus-square CP"
                                />
                                <div className="vr"></div>
                            </>
                        ))
                    }
                    {studyDynamicColumn?.length > 0 && (
                        <>
                            <i
                                onClick={tableProperty}
                                title="Table Properties"
                                className="fa-solid fa-list CP"
                            />
                            <div className="vr"></div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CTMSHeaderMenu;
