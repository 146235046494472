import config from "../config/config.json";
import { headers } from "../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../Helpers/Responses";

export const userServices = {
  login,
  logout,
  getCurrentUserDetails,
  changePassword,
  forgotPassword,
  resetPassword,
  getUserPermission,
  getStudyUserPermission,
  getUserLoginHistoryData,
  getUserFailedLoginData,
  generateQRCode,
  getQRData,
  checkQRCode,
  enableTwoFactorAuth,
  disableTwoFactorAuth,
  showQRCode,
  twoFactorAuthType,
  getTwoFactorAuthType,
  emailOTPFor2FA,
  enableTwoFactorAuthByEmail,
  disableTwoFactorAuthByEmail,
  verifyTwoFAForEmailLogin,
  verifyTwoFAForAppLogin,
  disableTwoFactorAuthByAdmin,
  getETMFUserPermission,
  getCTMSUserPermission,
  getIWRSUserPermission,
  getCDISCUserPermission,
  getRBQMUserPermission
};

async function login(userId, password, roleData, fcmToken, history) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userId, password, roleData, fcmToken }),
  };

  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}login`,
    requestOptions
  );

  const data = await response.json();
  handleResponse(data);

  let firstName = data?.userData?.firstName;
  let lastName = data?.userData?.lastName;
  let role = data?.userData?.roleType;
  let authType = data?.userData?.authType;
  let twoFA = data?.userData?.twoFA;
  let permission = data?.userData?.permissions;
  let updatePassword = data?.userData?.updatePassword;
  if (data.statusCode === 200) {
    let permissionData = await getUserPermission(userId)
    localStorage.setItem("permissionData", JSON.stringify(permissionData));
    localStorage.setItem("token", JSON.stringify(data.token));
    localStorage.setItem("twoFA", JSON.stringify(twoFA));
    localStorage.setItem("authType", JSON.stringify(authType));
    localStorage.setItem("uniqueData", JSON.stringify(userId));
    localStorage.setItem("role", JSON.stringify(role));
    localStorage.setItem("permission", JSON.stringify(permission));
    localStorage.setItem("updatePassword", JSON.stringify(updatePassword));
    localStorage.setItem(
      "firstName",
      JSON.stringify(firstName != null ? firstName : "No")
    );
    localStorage.setItem(
      "lastName",
      JSON.stringify(lastName != null ? lastName : "")
    );
    const roleType = JSON.parse(localStorage.getItem("role"));

    if (roleType === "client") {
      window.location.href = "/clientRole";
    } else if (roleType === "user") {
      window.location.href = "/userRole";
    }
  }
  return data;
}

// async function logoutData() {
//   let data = await getCurrentUserDetails();
//   let logoutData = data[0]?.user_id.logout;
//   if (logoutData) {
//     localStorage.clear();
//     window.location.href = "/login";
//   }
// }

// logoutData();


async function logout(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}logout`,
    headersWithAuth("PUT", userData, headers)
  );

  const data = await response.json();
  handleResponse(data);
  if(data?.statusCode === 200){
    localStorage.removeItem("studyTabs");
    localStorage.clear();
    window.location.href = "/login";
  }
}

async function getCurrentUserDetails() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCurrentUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getUserPermission(userId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserPermission/${userId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyUserPermission(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyUserPermission/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getETMFUserPermission(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getETMFUserPermission/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getIWRSUserPermission(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getIWRSUserPermission/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getCDISCUserPermission(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCUserPermission/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRBQMUserPermission(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRBQMUserPermission/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getCTMSUserPermission(studyId, projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCTMSUserPermission/${studyId}/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function changePassword(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}changePassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  if (data.statusCode === 200) {
    localStorage.setItem("updatePassword", JSON.stringify(true));
    window.location.reload();
  }
}

async function resetPassword(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}resetPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  if (data.statusCode) {
    window.location.href = "/login";
  }
}

async function forgotPassword(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}forgotPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getUserLoginHistoryData(userId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserLoginHistoryData/${userId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getUserFailedLoginData(userId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserFailedLoginData/${userId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Generate QR code
async function generateQRCode() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get QR Data
async function getQRData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getQRData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Show QR code
async function showQRCode() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}showQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Check QR & OTP
async function checkQRCode(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}checkQRCode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Enable Two Factor Auth 
async function enableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}enableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Disable Two Factor Auth 
async function disableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}disableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Get Auth Type
async function getTwoFactorAuthType() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTwoFactorAuthType`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Auth Type
async function twoFactorAuthType(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}twoFactorAuthType`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Send OTP for Email two factor auth
async function emailOTPFor2FA(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}emailOTPFor2FA`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Disable Email Two Factor Auth 
async function disableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}disableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Enable Email Two Factor Auth 
async function enableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}enableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Verify Two FA for Email Login
async function verifyTwoFAForEmailLogin(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}verifyTwoFAForEmailLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Verify Two FA for App Login
async function verifyTwoFAForAppLogin(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}verifyTwoFAForAppLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Disable Two Factor Auth by Admin
async function disableTwoFactorAuthByAdmin(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}disableTwoFactorAuthByAdmin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}