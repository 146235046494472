import React from 'react'
import { Modal } from 'react-bootstrap'

const AddFilter = ({ Show, Hide }) => {
    return (
        <>
            <Modal show={Show} onHide={Hide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>CRF Filter Coming soon...</Modal.Body>
                <Modal.Footer className='m-0'>
                    <button className='updateProfile' onClick={Hide}>
                        Apply
                    </button>
                </Modal.Footer>
            </Modal></>
    )
}

export default AddFilter