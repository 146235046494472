import React from "react";
import { Col, Container, Form, Offcanvas, Row } from "react-bootstrap";
import ELearningReportChart from "./ELearningReportChart";

const ELearningProgressReport = ({ Show, Hide, Title, userReport }) => {

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <ELearningReportChart
              finalPoint={[userReport?.currentAnswer, userReport?.wrongAnswer]}
            />
            <div className="shadow-md border my-2 bg-light overflow-auto">
              <Form.Group className="border-bottom pb-1 text-center">
                <Form.Label className="m-0 fw-bold">Quiz Name Title</Form.Label>
              </Form.Group>
              <Container fluid>
                <Row>
                <Form.Group as={Col} md={8} className="border-end">
                    <Form.Label>Total Question</Form.Label>
                    <br />
                    <Form.Label>Correct Question</Form.Label>
                    <br />
                    <Form.Label>Wrong Question</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="text-end">
                    <Form.Label>{userReport?.noOfQuestion}</Form.Label>
                    <br />
                    <Form.Label>{userReport?.currectPoints}</Form.Label>
                    <br />
                    <Form.Label>{userReport?.wrongPoint}</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} className="border-end">
                    <Form.Label className="fw-bold">Result</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="text-end">
                    <Form.Label className="fw-bold">{userReport?.currentAnswer?.toFixed(2)}%</Form.Label>
                  </Form.Group>
                </Row>
              </Container>
            </div>
          </div>
          <div className="p-2 bg-light shadow-sm text-end">
            <button className="border fontSize12 p-1 px-2 updateProfile" onClick={Hide}>
              Close
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ELearningProgressReport;
