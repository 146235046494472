import React, { useState } from "react";
import SplitPane from "react-split-pane";
import { Table } from "react-bootstrap";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";

const RandomizeTabActivityData = ({ tableRef, onMouseDown }) => {
    const [loader, setLoadeer] = useState("");
    const data = Array.from({ length: 10 }, (v, i) => ({
        sr: i + 1,
        status: i % 2 === 0 ? "Completed" : "Pending",
        type: `Type ${(i % 4) + 1}`,
        activities: `Activity ${i + 1}`,
        category: `Category ${(i % 3) + 1}`,
        mode: `DBP`,
        activityBy: `User ${i + 1}`,
        activityDateTime: new Date().toLocaleString(),
    }));
    return (
        <>
            <SplitPane
                split="vertical"
                minSize={20}
                defaultSize="100%"
                style={{ height: "91%" }}
            >
                {loader ? (
                    <SpinerDataLoader />
                ) : (
                    <div className="table-container tableLibrarySection">
                        <Table
                            id="resizable-table"
                            striped
                            hover
                            className="m-0 custom-table"
                        >
                            <thead className="thead-sticky top-0">
                                <tr>
                                    <th style={{ width: 45 }}>Sr.</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Activities</th>
                                    <th>Category</th>
                                    <th>Mode</th>
                                    <th>Activity By</th>
                                    <th>Activity Date | Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.sr}</td>
                                        <td>{item.status}</td>
                                        <td>{item.type}</td>
                                        <td>{item.activities}</td>
                                        <td>{item.category}</td>
                                        <td>{item.mode}</td>
                                        <td>{item.activityBy}</td>
                                        <td>{item.activityDateTime}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </SplitPane>
        </>
    );
};

export default RandomizeTabActivityData;
