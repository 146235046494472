import React, { useEffect, useState } from "react";
import { Col, Container, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studyVisitsServices";

const StudyEventFilter = ({
  Show,
  Hide,
  Title,
  setFilter,
  filter,
  setStudyDynamicRow,
  getStudyVisitRowData,
  studyDynamicColumnID,
  viewTypeData
}) => {
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const { studyId } = useParams();
  const [repeat, setRepeat] = useState("");
  const [initiate, setInitiate] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  const [loader, setLoader] = useState(false);

  const filterStudyVisitRowData = async () => {
    let userData = {};
    setLoader(true);
    userData.studyId = studyId;
    userData.studyMode = StudyMode;
    userData.columnId = studyDynamicColumnID;
    userData.repeat = repeat;
    userData.initiate = initiate;
    userData.queryStatus = queryStatus;
    let data = await studyOnboardingService.filterStudyVisitRowData(userData);
    let initialData = Object.values(data.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    setStudyDynamicRow(finalData);
    setFilter(true);
    setLoader(false);
    Hide();
  };

  const clearFilter = () => {
    getStudyVisitRowData();
    setFilter(false);
    Hide();
  };

  useEffect(() => {
    if (!filter) {
      setRepeat("");
      setInitiate("");
      setQueryStatus("");
    }
  }, [filter]);
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Container fluid>
              <Row className="mb-2">
                <Col md={4} className="text-end">
                  <Form.Label>
                    <b>Initiate :</b>
                  </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Select onChange={(e) => setInitiate(e.target.value)}>
                    <option value="">Select Initiate Type</option>
                    <option
                      selected={initiate == "afterFirstVisits"}
                      value="afterFirstVisits"
                    >
                      After First Visits
                    </option>
                    <option selected={initiate == "anyTime"} value="anyTime">
                      Any Time
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-end">
                  <Form.Label>
                    <b>Repeat :</b>
                  </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Select onChange={(e) => setRepeat(e.target.value)}>
                    <option value="">Select Repeat Type</option>
                    <option selected={repeat == "yes"} value="yes">
                      Yes
                    </option>
                    <option selected={repeat == "no"} value="no">
                      No
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              {viewTypeData === "ctms" &&
                <Row className="mb-2">
                  <Col md={4} className="text-end">
                    <Form.Label>
                      <b>Query Status :</b>
                    </Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Select onChange={(e) => setQueryStatus(e.target.value)}>
                      <option value="">Select Query Status</option>
                      <option selected={queryStatus == "open"} value="open">
                        Open
                      </option>
                      <option selected={queryStatus == "closed"} value="closed">
                        Closed
                      </option>
                      <option
                        selected={queryStatus == "cancelled"}
                        value="cancelled"
                      >
                        Cancelled
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
              }
            </Container>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={filterStudyVisitRowData}
              disabled={loader || !(repeat || queryStatus || initiate)}
              className="border fontSize12   p-1 px-2 updateProfile"
            >
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i className="fa-solid fa-check me-1"></i>
              )}
              Apply Filter
            </button>
            {filter && (
              <button
                onClick={clearFilter}
                className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
              >
                <i className="fa-solid fa-xmark me-1"></i>
                Clear Filter
              </button>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default StudyEventFilter;
