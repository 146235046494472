import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const studyOnboardingService = {
  studySubjectDetailsData,
  studySiteDetailsData,
  createStudySiteColumn,
  updateStudySiteColumn,
  getStudySiteColumnData,
  AddStudySiteRow,
  reviewStudySite,
  getStudySiteReviewData,
  UpdateStudySiteRow,
  getStudySiteRowData,
  deleteStudySiteRowData,
  deleteStudySubjectRowDataBasedOnSite,
  getStudySiteAttributeByID,
  getStudySiteHistoryByID,
  exportStudySiteDataInMultiFormat,
  exportStudySiteAuditTrialDataInMultiFormat,
  getStudySiteAttributes,
  addTableProperty,
  addModuleOrder,
  getTablePropertyData,
  addDataViewTableProperty,
  getDataViewTablePropertyData,
  addTableAndRegularView,
  findTableAndRegularViewData,
  getStudySiteWithSubjectData,
  getStudySubjectRowData,
  getStudySubjectColumnData,
  generateStudySiteQuery,
  getStudySiteQueryList,
  assignStudySiteQuery,
  updateStatusStudySiteQuery,
  getStudySiteQueryDiscussionList,
  sendStudySiteQueryDiscussion,
  filterStudySiteRowData
};

async function getStudySubjectRowData(
  studyId,
  StudyMode,
  columnId,
  siteUniqueId,
  page,
  limit
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectRowData/${studyId}/${StudyMode}/${columnId}/${siteUniqueId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectColumnData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectColumnData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// async function AddStudySubjectRow(datas) {
//   const response = await fetch(
//     `${config.API_URL_USER_ONBOARDING}AddStudySubjectRow`,
//     headersWithAuth("POST", datas, headers)
//   );
//   const data = await response.json();
//   await handleResponse(data);
// }

async function studySiteDetailsData(studyId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}studySiteDetailsData/${studyId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function studySubjectDetailsData(studyId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}studySubjectDetailsData/${studyId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function createStudySiteColumn(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createStudySiteColumn`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data;
}

async function addTableProperty(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addTableProperty`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function addModuleOrder(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addModuleOrder`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function addTableAndRegularView(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addTableAndRegularView`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function findTableAndRegularViewData(studyId, category, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findTableAndRegularViewData/${studyId}/${category}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTablePropertyData(category, columnId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTablePropertyData/${category}/${columnId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function addDataViewTableProperty(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addDataViewTableProperty`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function getDataViewTablePropertyData(category, formUniqueId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDataViewTablePropertyData/${category}/${formUniqueId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteWithSubjectData(studyId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteWithSubjectData/${studyId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteColumnData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteColumnData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function AddStudySiteRow(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}AddStudySiteRow`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function reviewStudySite(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}reviewStudySite`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getStudySiteReviewData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteReviewData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteAttributes() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteAttributes`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteRowData(studyId, columnId, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteRowData/${studyId}/${columnId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateStudySiteColumn(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudySiteColumn/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function UpdateStudySiteRow(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}UpdateStudySiteRow/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function deleteStudySiteRowData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudySiteRowData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();  
  return data;

}

async function deleteStudySubjectRowDataBasedOnSite(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudySubjectRowDataBasedOnSite/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteAttributeByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteAttributeByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteHistoryByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteHistoryByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function exportStudySiteDataInMultiFormat(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportStudySiteDataInMultiFormat/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportStudySiteAuditTrialDataInMultiFormat(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportStudySiteAuditTrialDataInMultiFormat/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

//Generate Site Query
async function generateStudySiteQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateStudySiteQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Generate Site Query
async function assignStudySiteQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudySiteQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Update Status Site Query
async function updateStatusStudySiteQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusStudySiteQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

async function getStudySiteQueryList(studyUniqueId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteQueryList/${studyUniqueId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteQueryDiscussionList(queryId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteQueryDiscussionList/${queryId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Send Study Site Query Discussion
async function sendStudySiteQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendStudySiteQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Filter Study Site Row Data
async function filterStudySiteRowData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}filterStudySiteRowData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}