import React, { useEffect, useState } from "react";
import { Collapse, Form, InputGroup, Spinner } from "react-bootstrap";
import { CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import STDMCommentTableProperties from "./STDMCommentTableProperties";
import STDMCommentTableData from "./STDMCommentTableData";
import { GoSearch } from "react-icons/go";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import UseToggle from "../../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { useParams } from "react-router";
import UpdateComment from "./UpdateComment";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../NoDataFoundTable/NoDataFoundTable";

const STDMComment = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [stdmComment, setSTDMComment] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState("");

  const { studyId } = useParams();

  const getCDISCComments = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCComments(studyId);
    setSTDMComment(data?.data || []);
    setLoader(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    getCDISCComments();
  }, []);

  const HeaderTools = [
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
  ];

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          {isFormOpen && (
            <>
              <i
                title="Close Form"
                className="fa-solid fa-square-caret-left CP"
                onClick={() => setIsFormOpen("")}
              />
              <div className="vr"></div>
            </>
          )}
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-magnifying-glass"
            title="Search"
            onClick={() => setOpenFilter(!openFilter)}
          ></i>
          <Collapse in={openFilter} dimension="width">
            <div>
              <InputGroup style={{ width: 200 }}>
                <Form.Control
                  type="search"
                  className="px-2"
                  placeholder="Search..."
                  style={{ fontSize: 10, borderRadius: "0", padding: 0 }}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  style={{ padding: "1px 5px" }}
                >
                  <GoSearch />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Collapse>
        </div>
        {isFullScreen ? (
          <i class="fa-solid fa-maximize" title="Original Mode" onClick={toggleFullScreen}></i>
        ) : (
          <i class="fa-solid fa-minimize" title="Full Mode" onClick={toggleFullScreen}></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <UpdateComment
                isFormOpen={isFormOpen}
                getCDISCComments={getCDISCComments}
                studyId={studyId}
              />
            )}
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : stdmComment?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <STDMCommentTableData
                  studyId={studyId}
                  stdmComment={stdmComment}
                  setIsFormOpen={setIsFormOpen}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  getCDISCComments={getCDISCComments}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <STDMCommentTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default STDMComment;
