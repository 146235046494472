export const SidebarChild = ({ children }) => (
  <div id="div0" className="div0">
    <section className="middle-content">{children}</section>
  </div>
);

export const ResizableX = ({ children }) => (
  <div id="app">
    <div className="resizable-x">{children}</div>
  </div>
);

export const ResizableY = ({ children }) => (
  <div className="resizable-y">
    <div className="div1">{children}</div>
  </div>
);

export const DashboardSection = ({ children }) => (
  <section className="middle-content " id="dashboard-section">
    <div className="inner-cont">{children}</div>
  </section>
);

export const HomeLineSection = ({ children }) => (
  <div className="home-line px-2 pt-1 hstack bg-light">{children}</div>
);

export const HomeLine2Wrapper = ({
  selectedStudySiteId,
  selectedStudySubjectId,
  selectedStudyVisitsId,
  selectedStudyVisitsInitiateId,
  selectedStudyVisitsForm,
  StudySiteId,
  StudySubjectId,
  selectedStudyEventsId,

  InitiateVisitId,
  StudyVisitId,
  StudyVisitForm,
  siteManagementTab,
  siteSubjectTab,
  studyVisitTab,
  studyVisitFormTab,
  studyEventManagement,
}) => (
  <>
    {!selectedStudyEventsId ? (
      <div className="border-bottom" style={{ height: 20 }}>
        <ul id="breadcrumb">
          {StudySiteId && (
            <li>
              <a onClick={() => siteManagementTab()}>
                {StudySiteId &&
                  (selectedStudySiteId ? `Site : ${selectedStudySiteId}` : "")}
              </a>
            </li>
          )}
          {StudySubjectId && (
            <li>
              <a onClick={() => siteSubjectTab()}>
                <span className="icon icon-double-angle-right"></span>{" "}
                {StudySubjectId &&
                  (selectedStudySubjectId
                    ? ` Subject : ${selectedStudySubjectId}`
                    : "")}
              </a>
            </li>
          )}
          {InitiateVisitId && (
            <li>
              <a onClick={() => studyVisitTab()}>
                <span className="icon icon-rocket"> </span>{" "}
                {InitiateVisitId &&
                  (selectedStudyVisitsInitiateId
                    ? ` Event : ${selectedStudyVisitsInitiateId}`
                    : "")}
              </a>
            </li>
          )}
          {StudyVisitId && (
            <li>
              <a onClick={() => studyVisitTab()}>
                <span className="icon icon-arrow-down"> </span>{" "}
                {StudyVisitId &&
                  (selectedStudyVisitsId
                    ? ` Event : ${selectedStudyVisitsId}`
                    : "")}
              </a>
            </li>
          )}
          {StudyVisitForm && (
            <li>
              <a onClick={() => studyVisitFormTab()}>
                <span className="icon icon-arrow-down"> </span>{" "}
                {StudyVisitForm &&
                  (selectedStudyVisitsForm
                    ? ` ${selectedStudyVisitsForm}`
                    : "")}
              </a>
            </li>
          )}
        </ul>
      </div>
    ) : (
      <ul id="breadcrumb" style={{ height: 20 }}>
        {StudyVisitId && (
          <li>
            <a onClick={() => studyEventManagement()}>
              {StudyVisitId &&
                (selectedStudyEventsId
                  ? ` Event : ${selectedStudyEventsId}`
                  : "")}
            </a>
          </li>
        )}
      </ul>
    )}
  </>
);

export const DashboardBodySection = ({ children }) => (
  <div className="dashboardBody">{children}</div>
);

export const SingleTableWrapper = ({ children }) => (
  <div className="dashboard-body dataextract-dashboard-body">
    <div className="adverseevent">
      <div className="table-responsive-assignstudy">{children}</div>
    </div>
  </div>
);

export const ThreeTableWrapper = ({ children }) => (
  <div className="dashboard-body clienttable-dashboard-body">
    <div className="d-flex cdashmain">{children}</div>
  </div>
);

export const ThreeTableLeft = ({ children }) => (
  <div className="cdashleft border-end">
    <div className="table-responsive-cdashleft ">{children}</div>
  </div>
);

export const ThreeTableLeftDerived = ({ children }) => (
  <div className="Derived__veriable border-end">
    <div className="table-responsive-cdashleft ">{children}</div>
  </div>
);

export const ThreeTableLongRight = ({ children }) => (
  <div className="cdashleft border-end">
    <div className="table-responsive-cdashleft ">{children}</div>
  </div>
);

export const ThreeTableLongRight2 = ({ children }) => (
  <div className="cdashleft border-end">
    <div className="">{children}</div>
  </div>
);

export const ThreeTableSHortLeftRight = ({ children }) => (
  <div className="cdashShortleft border-end">
    <div className="table-responsive-cdashLongleft ">{children}</div>
  </div>
);

export const DashboardTableLeft = ({ children }) => (
  <div className="cdashleft border-end">
    <div className="table-responsive-dashboard ">{children}</div>
  </div>
);

export const DashboardStudyTableLeft = ({ children }) => (
  <div className="cdashleft border-end">
    <div className="table-responsive-dashboard-study ">{children}</div>
  </div>
);

export const ThreeTableRight = ({ children }) => (
  <div className="cdashright">{children}</div>
);

export const ThreeTableRightDerived = ({ children }) => (
  <div className="Derived__veriable__right">{children}</div>
);

export const ThreeTableRightTop = ({ children }) => (
  <div className="cdashrighttop">
    <div className="table-responsive-cdashrighttop">{children}</div>
  </div>
);

export const ThreeTableRightBottom = ({ children, bottom }) => (
  <div className={!bottom && "cdashrightbottom"}>
    <div className="table-responsive-cdashrightbottom">{children}</div>
  </div>
);

export const UserTableWrapper = ({ children }) => (
  <div className="dashboard-body adverseeventcoding-dashboard-body">
    <div className="usersmain">{children}</div>
  </div>
);

export const UserTableLeft = ({ children }) => (
  <div className="leftdiv border-end">
    <div className="table-responsive-userleftleft">{children}</div>
  </div>
);

export const ActiveClientTableLeft = ({ children }) => (
  <div className="leftdiv border-end">
    <div className="position-relative">{children}</div>
  </div>
);

export const UserTableRight = ({ children }) => (
  <div className="userright">{children}</div>
);

export const UserTableRightTop = ({ children }) => (
  <div className="table-responsive-userrighttop">{children}</div>
);

export const UserTableRightBottom = ({ children }) => (
  <div className="userrightbottom">
    <div className="table-responsive-userrightbottom">{children}</div>
  </div>
);

export const TwoTableWrapper = ({ children }) => (
  <div className="dashboard-body clienttable-dashboard-body">
    <div className="d-flex visitmain">{children}</div>
  </div>
);

export const TwoTableLeft = ({ children }) => (
  <div className="visitleft">
    <div className="table-responsive-visit">{children}</div>
  </div>
);

export const TwoTableRight = ({ children }) => (
  <div className="visitright">
    <div className="table-responsive-visit">{children}</div>
  </div>
);

export const SubjectFormStatusTableWrapper = ({ children }) => (
  <div className="dashboard-body sitestatus-dashboard-body">
    <div className="SubjectFormStatusmain">
      <div className="table-responsive-sitestatus">{children}</div>
    </div>
  </div>
);

export const SideBarMenus = ({ children }) => (
  <div className="sidebar" id="myGroup">
    <div className="menus">{children}</div>
  </div>
);

export const SideBarBody = ({ children }) => (
  <div className="sidebar-body" style={{ maxHeight: "calc(100vh - 156px)" }}>
    {children}
  </div>
);

export const CollapseButton = ({ children, Href, OnClick }) => (
  <a
    className="check-sub-nav-here CP w-100"
    data-bs-toggle="collapse"
    href={Href}
    aria-expanded="false"
    aria-controls="auditstab1"
    onClick={OnClick}
  >
    {children}
  </a>
);

export const CollapseBody = ({ children, HrefID }) => (
  <div className="collapse client-collapse  w-100" id={HrefID}>
    <div className="card card-body w-100">{children}</div>
  </div>
);

export const ListGroupWrapper = ({ children, Center }) => (
  <li
    className={`list-group-item d-flex ${
      !Center && "justify-content-between"
    } align-baseline`}
  >
    {children}
  </li>
);

export const SmallSpan = ({ children }) => (
  <span className="small">{children}</span>
);
