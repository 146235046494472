import React, { useEffect, useRef, useState } from "react";
import { SlDiamond } from "react-icons/sl";
import { CgShapeRhombus } from "react-icons/cg";
import { MdOutlineStraight } from "react-icons/md";
import { IoTriangleOutline } from "react-icons/io5";
import { LuRectangleHorizontal } from "react-icons/lu";
import { PiHashStraight, PiHeartStraightBold } from "react-icons/pi";
import { FaRegCircle, FaRegSquare, FaRegStar } from "react-icons/fa";
import { Collapse } from "react-bootstrap";

const InsertShape = ({ onShapeInsert }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleShapeClick = (shape) => {
    onShapeInsert(shape);
    setIsOpen(false);
  };

  return (
    <>
      <div ref={dropdownRef}>
        <div
          onClick={toggleOpen}
          className="position-relative"
          title="Insert Shapes"
        >
          Shapes
          {/* <PiShapesLight /> */}
        </div>
        <Collapse in={isOpen}>
          <div
            className="position-absolute start-0 bg-white border rounded p-2"
            style={{ width: 200, top: 20 }}
          >
            <div className="hstack flex-wrap gap-2" style={{ fontSize: 35 }}>
              <div onClick={() => handleShapeClick("circle")}>
                <FaRegCircle />
              </div>
              <div onClick={() => handleShapeClick("rectangle")}>
                <LuRectangleHorizontal />
              </div>
              <div onClick={() => handleShapeClick("square")}>
                <FaRegSquare />
              </div>
              <div onClick={() => handleShapeClick("star")}>
                <FaRegStar />
              </div>
              <div onClick={() => handleShapeClick("triangle")}>
                <IoTriangleOutline />
              </div>
              <div onClick={() => handleShapeClick("rhombus")}>
                <CgShapeRhombus />
              </div>
              <div onClick={() => handleShapeClick("line")}>
                <MdOutlineStraight />
              </div>
              <div onClick={() => handleShapeClick("heart")}>
                <PiHeartStraightBold />
              </div>
              <div onClick={() => handleShapeClick("hash")}>
                <PiHashStraight />
              </div>
              <div onClick={() => handleShapeClick("diamond")}>
                <SlDiamond />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default InsertShape;
