import React, { useState } from "react";
import SplitPane from "react-split-pane";
import AddCustomValueManipulation from "./Manipulation/AddCustomValueManipulation";
import AddFunctionValueManipulation from "./Manipulation/AddFunctionValueManipulation";
import AddColumnValueManipulation from "./Manipulation/AddColumnValueManipulation";

const ManipulationTools = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [activeTab, setActiveTab] = useState("");
  return (
    <>
      <SplitPane
        split="vertical"
        className="position-relative"
        defaultSize="15%"
      >
        <div>
          <span onClick={() => setActiveTab("addCustomValue")} className={`CP ps-3 small linkClass ${activeTab === "addCustomValue" && "selectedMenu"}`}>Custom Value</span>
          <span onClick={() => setActiveTab("addColumnValue")} className={`CP ps-3 small linkClass ${activeTab === "addColumnValue" && "selectedMenu"}`}>Column Value</span>
          <span onClick={() => setActiveTab("addValueByFunction")} className={`CP ps-3 small linkClass ${activeTab === "addValueByFunction" && "selectedMenu"}`}>Value by Function</span>
        </div>
        {activeTab === "addCustomValue" ?
          <AddCustomValueManipulation
            studyId={studyId}
            derivationId={derivationId}
            getDomainDerivationList={getDomainDerivationList}
          />
          : activeTab === "addColumnValue" ?
            <AddColumnValueManipulation
              studyId={studyId}
              derivationId={derivationId}
              getDomainDerivationList={getDomainDerivationList}
            />
            : activeTab === "addValueByFunction" ?
              <AddFunctionValueManipulation
                studyId={studyId}
                derivationId={derivationId}
                getDomainDerivationList={getDomainDerivationList}
              />
              : ""}
      </SplitPane>
    </>
  );
};

export default ManipulationTools;
