import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse, Form, InputGroup } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { clientLevelFormServices } from "../../../../../Services/formServices/clientLevelFormServices";
import ClockPicker from "./ClockPicker";

const CrfViewRightTop = ({
  dataEntryMode,
  connectUniqueIdData,
  dataEntryFormByIdEndPoint,
  dataEnteriesValueShow,
  formUniqueId,
  studyUniqueId,
  selectedFormId,
  selectedForm,
  dataEntryInput,
  setScaleInput,
  scaleInput,
  setUploadInput,
  uploadInput,
  setDataEntryInput,
  setOptionValueData,
  optionValueData,
  storeDataEntry,
  setNumberInput,
  numberInput,
  setTextInput,
  textInput,
  setTextUnit,
  setDropdownId,
  setDropdownOption,
  setRadioId,
  setRadioOption,
  setCheckboxId,
  setCheckboxValueData,
  checkboxId,
  checkboxValueData,
  dropdownId,
  dropdownOption,
  radioId,
  radioOption,
  textunit,
  textAdditionalData,
  setTextAdditionalData,
  numberAdditionalData,
  setNumberAdditionalData,
  checkboxAdditionalData,
  setCheckboxAdditionalData,
  radioAdditionalData,
  setRadioAdditionalData,
  dropdownAdditionalData,
  setDropdownAdditionalData,
  setNumberUnit,
  setTextareaInput,
  textareaInput,
  numberunit,
  setDay,
  day,
  setMonth,
  month,
  setYear,
  year,
  setHour,
  hour,
  setMinute,
  minute,
  setSecond,
  second,
  setFormat,
  format,
  timeCalendar,
  dateCalendar,
  inputFormDataEntry,
  fieldLockFreeze,
  setInputFormDataEntry,
  dayCheck,
  setDayCheck,
  monthCheck,
  setMonthCheck,
  yearCheck,
  setYearCheck,
  hourCheck,
  setHourCheck,
  minuteCheck,
  setMinuteCheck,
  secondCheck,
  setSecondCheck,
}) => {

  useEffect(() => {
    setDataEntryInput(inputFormDataEntry?.value)
  }, [inputFormDataEntry]);
  const [dataEntiesValue, setDataEntiesValue] = useState("");
  const [textUnitList, setTextUnitList] = useState([]);
  const [numberUnitList, setNumberUnitList] = useState([]);
  const [additionalOption, setAdditionalOption] = useState([]);
  const [clear, setClear] = useState(false);
  const [clock, setClock] = useState(false);
  const DaysRef = useRef(null);
  const MonthsRef = useRef(null);
  const YearsRef = useRef(null);
  const HourRef = useRef(null);
  const MinuteRef = useRef(null);
  const SecondRef = useRef(null);
  const ClockRef = useRef(null);

  const getColor = (index, options, clickedIndex) => {
    if (index <= clickedIndex) {
      const gradientStep = Math.round((index / options?.length || 0) * 255);
      return `rgb(${gradientStep}, ${255 - gradientStep}, 0)`;
    } else {
      return 'transparent';
    }
  };

  useEffect(() => {
    const getdataEntry = async () => {
      const res = await clientLevelFormServices.getReviewHistoryByID(
        dataEntryFormByIdEndPoint,
        formUniqueId,
        StudyMode,
        selectedFormId
      );
    };

    getdataEntry();

    const getTextUnit = async () => {
      let data = await clientLevelFormServices.getTextUnitForCRFView(formUniqueId, selectedFormId);
      setTextUnitList(data?.data)
    };


    getTextUnit();

    const getNumberUnit = async () => {
      let data = await clientLevelFormServices.getNumberUnitForCRFView(formUniqueId, selectedFormId);
      setNumberUnitList(data?.data)
    };

    getNumberUnit();

    const getAdditionalCategoryOption = async () => {
      let data = await clientLevelFormServices.getAdditionalCategoryOption(formUniqueId, selectedFormId);
      setAdditionalOption(data?.data)
    };

    getAdditionalCategoryOption();

  }, [dataEntryFormByIdEndPoint, formUniqueId, selectedFormId]);

  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  useEffect(() => {
    let endPointData;
    if (connectUniqueIdData) {
      endPointData = dataEntryMode === "specialForm" ? clientLevelFormServices.getdataEntryFormByIdForSpecialLogForm(
        "getdataEntryFormByIdForSpecialLogForm",
        formUniqueId,
        dataEntryMode,
        StudyMode,
        studyUniqueId,
        selectedFormId,
        connectUniqueIdData
      ) : clientLevelFormServices.getdataEntryFormByIdForOtherLogForm(
        "getdataEntryFormByIdForLog",
        formUniqueId,
        dataEntryMode,
        StudyMode,
        studyUniqueId,
        selectedFormId,
        connectUniqueIdData
      );
    } else {
      endPointData = dataEntryMode === "specialForm" ? clientLevelFormServices.getdataEntryFormByIdForSpecialForm(
        'getdataEntryFormByIdForSpecialForm',
        formUniqueId,
        dataEntryMode,
        StudyMode,
        studyUniqueId,
        selectedFormId
      ) : clientLevelFormServices.getdataEntryFormByIdForOtherForm(
        'getdataEntryFormById',
        formUniqueId,
        dataEntryMode,
        StudyMode,
        studyUniqueId,
        selectedFormId
      );
    }

    const getDataEntryValue = async () => {
      const res = await endPointData;
      setDataEntiesValue(res?.data);
    };

    getDataEntryValue();
  }, [dataEnteriesValueShow, formUniqueId, dataEntryMode, studyUniqueId, selectedFormId]);

  const handleChangeFileUpload = async (e) => {
    const uploadedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", uploadedFile);
    let result = await clientLevelFormServices.uploadDocumentData(
      formData
    );
    let data = {
      value: result?.data
    }

    storeDataEntry(data)
    setUploadInput(result?.data);
    setDataEntryInput(data);
  }

  const [checkBoxArray, setCheckBoxArray] = useState(dataEntryInput?.value || [])

  const handleCheckboxChange = (id, value, additionalCategoryData = null, additionalCategoryKey = null, type) => {
    let data = {}
    if (additionalCategoryData && additionalCategoryKey !== null) {
      if (additionalCategoryData && additionalCategoryKey !== null) {
        if (type === "checkbox") {
          let additionalCheckBoxData = dataEntryInput?.[`additionalCategory${additionalCategoryKey + 1}`] || dataEntryInput[`additionalCategory${additionalCategoryKey + 1}`] || [];
          const updatedAdditionalValues = [...additionalCheckBoxData];
          const labelIndex = updatedAdditionalValues.indexOf(additionalCategoryData);
          if (labelIndex === -1) {
            updatedAdditionalValues.push(additionalCategoryData);
          } else {
            updatedAdditionalValues.splice(labelIndex, 1);
          }
          data[`additionalCategory${additionalCategoryKey + 1}`] = updatedAdditionalValues || [];
        } else {
          data[`additionalCategory${additionalCategoryKey + 1}`] = additionalCategoryData;
        }
      }
    } else {
      let checkBoxData = checkBoxArray || []
      const updatedValues = [...checkBoxData];
      const labelIndex = updatedValues.indexOf(value);
      if (labelIndex === -1) {
        updatedValues.push(value);
      } else {
        updatedValues.splice(labelIndex, 1);
      }
      data.value = updatedValues || [];
      const selectedData = [...optionValueData]
      const labelIndexData = selectedData.indexOf(id);
      if (labelIndexData === -1) {
        selectedData.push(id);
      } else {
        selectedData.splice(labelIndexData, 1);
      }
      setCheckboxId(id)
      setCheckBoxArray(updatedValues)
      setOptionValueData(selectedData);
      setCheckboxValueData(value)
    }
    let finalData = { ...dataEntryInput, ...data }
    console.log(">>finalData", finalData);
    
    setInputFormDataEntry(null)
    storeDataEntry(finalData);
    setDataEntryInput(finalData);
  };

  const handleDateChange = () => {
    let data = {
      day: !dayCheck ? (day ? day : (inputFormDataEntry?.value?.day === undefined ? day : inputFormDataEntry?.value?.day)) : "",
      month: !monthCheck ? (month ? month : (inputFormDataEntry?.value?.month === undefined ? month : inputFormDataEntry?.value?.month)) : "",
      year: !yearCheck ? (year ? year : (inputFormDataEntry?.value?.year === undefined ? year : inputFormDataEntry?.value?.year)) : "",
    }
    storeDataEntry(data)
    setDataEntryInput(data);
  };

  useEffect(() => {
    if (selectedForm?.type === "date") {
      handleDateChange()
    }
  }, [day, month, year]);

  const handleTimeChange = () => {
    let data = {
      hour: !hourCheck ? (hour ? hour : (inputFormDataEntry?.value?.hour === undefined ? hour : inputFormDataEntry?.value?.hour)) : "",
      minute: !minuteCheck ? (minute ? minute : (inputFormDataEntry?.value?.minute === undefined ? minute : inputFormDataEntry?.value?.minute)) : "",
      second: !secondCheck ? (second ? second : (inputFormDataEntry?.value?.second === undefined ? second : inputFormDataEntry?.value?.second)) : "",
      format: format ? format : (inputFormDataEntry?.value?.format === undefined ? format : inputFormDataEntry?.value?.format),
    }
    storeDataEntry(data)
    setDataEntryInput(data);
  };

  useEffect(() => {
    if (selectedForm?.type === "time") {
      handleTimeChange()
    }
  }, [hour, minute, second, format]);

  const [customType, setCustomType] = useState("");
  const handleTextChange = (textInput, textunit, additionalCategoryData = null, additionalCategoryKey = null, type) => {
    let data = {}
    if (additionalCategoryData && additionalCategoryKey !== null) {
      if (type === "checkbox") {
        let additionalCheckBoxData = dataEntryInput?.[`additionalCategory${additionalCategoryKey + 1}`] || dataEntryInput[`additionalCategory${additionalCategoryKey + 1}`] || [];
        const updatedAdditionalValues = [...additionalCheckBoxData];
        const labelIndex = updatedAdditionalValues.indexOf(additionalCategoryData);
        if (labelIndex === -1) {
          updatedAdditionalValues.push(additionalCategoryData);
        } else {
          updatedAdditionalValues.splice(labelIndex, 1);
        }
        data[`additionalCategory${additionalCategoryKey + 1}`] = updatedAdditionalValues || [];
      } else {
        data[`additionalCategory${additionalCategoryKey + 1}`] = additionalCategoryData;
      }
    } else {
      if (textInput || textunit) {
        let textInputData = textInput ? textInput : (dataEntryInput?.value === undefined ? textInput : dataEntryInput?.value);
        let textUnitData = textunit ? textunit : (dataEntryInput?.unit === undefined ? textunit : dataEntryInput?.unit);
        data.value = textInputData;
        data.unit = textUnitData;
        data.custom = customType ? true : false;
      }
    }
    let finalData = { ...dataEntryInput, ...data }
    storeDataEntry(finalData)
    setDataEntryInput(finalData);
  };

  const handleTextareaChange = (textareaInput) => {
    let textareaInputData = textareaInput ? textareaInput : (inputFormDataEntry?.value?.value === undefined ? textareaInput : inputFormDataEntry?.value?.value);
    let data = {
      value: textareaInputData,
    }
    storeDataEntry(data)
    setTextareaInput(textareaInputData);
    setDataEntryInput(data);
  };

  const handleNumberChange = (numberInput, numberunit, additionalCategoryData = null, additionalCategoryKey = null, type) => {
    let data = {}
    if (additionalCategoryData && additionalCategoryKey !== null) {
      if (type === "checkbox") {
        let additionalCheckBoxData = dataEntryInput?.[`additionalCategory${additionalCategoryKey + 1}`] || dataEntryInput[`additionalCategory${additionalCategoryKey + 1}`] || [];
        const updatedAdditionalValues = [...additionalCheckBoxData];
        const labelIndex = updatedAdditionalValues.indexOf(additionalCategoryData);
        if (labelIndex === -1) {
          updatedAdditionalValues.push(additionalCategoryData);
        } else {
          updatedAdditionalValues.splice(labelIndex, 1);
        }
        data[`additionalCategory${additionalCategoryKey + 1}`] = updatedAdditionalValues || [];
      } else {
        data[`additionalCategory${additionalCategoryKey + 1}`] = additionalCategoryData;
      }
    } else {
      if (numberInput || numberunit) {
        let numberInputData = numberInput ? numberInput : (dataEntryInput?.value === undefined ? numberInput : dataEntryInput?.value);
        let numberUnitData = numberunit ? numberunit : (dataEntryInput?.unit === undefined ? numberunit : dataEntryInput?.unit);
        data.value = numberInputData;
        data.unit = numberUnitData;
        data.custom = customType ? true : false;
      }
    }
    let finalData = { ...dataEntryInput, ...data }
    storeDataEntry(finalData)
    setDataEntryInput(finalData);
  };

  const handleRadioChange = (e, options, additionalCategoryData = null, additionalCategoryKey = null, type) => {
    let data = {}
    if (additionalCategoryData && additionalCategoryKey !== null) {
      if (type === "checkbox") {
        let additionalCheckBoxData = dataEntryInput?.[`additionalCategory${additionalCategoryKey + 1}`] || dataEntryInput[`additionalCategory${additionalCategoryKey + 1}`] || [];
        const updatedAdditionalValues = [...additionalCheckBoxData];
        const labelIndex = updatedAdditionalValues.indexOf(additionalCategoryData);
        if (labelIndex === -1) {
          updatedAdditionalValues.push(additionalCategoryData);
        } else {
          updatedAdditionalValues.splice(labelIndex, 1);
        }
        data[`additionalCategory${additionalCategoryKey + 1}`] = updatedAdditionalValues || [];
      } else {
        data[`additionalCategory${additionalCategoryKey + 1}`] = additionalCategoryData;
      }
    } else {
      const optionIds = (options || [])?.map(item => item.id);
      let selectedId = e.target.id
      data.value = e.target.value;
      let selectedData = [...optionValueData]
      selectedData = selectedData?.filter(id => !optionIds.includes(id));
      const labelIndexData = selectedData.indexOf(Number(selectedId));
      if (labelIndexData === -1) {
        selectedData.push(Number(selectedId));
      } else {
        selectedData.splice(labelIndexData, 1);
      }
      setRadioId(e)
      setOptionValueData(selectedData);
      setRadioOption(options)
    }
    let finalData = { ...dataEntryInput, ...data }
    setClear(false)
    storeDataEntry(finalData)
    setDataEntryInput(finalData);
  };

  const cleanRadioButton = (e) => {
    setClear(true)
    storeDataEntry("")
    setDataEntryInput("");
    setOptionValueData("");
  }

  const handleDropdownChange = (e, options, additionalCategoryData = null, additionalCategoryKey = null, type) => {
    let data = {}
    if (additionalCategoryData && additionalCategoryKey !== null) {
      if (additionalCategoryData && additionalCategoryKey !== null) {
        if (type === "checkbox") {
          let additionalCheckBoxData = dataEntryInput?.[`additionalCategory${additionalCategoryKey + 1}`] || dataEntryInput[`additionalCategory${additionalCategoryKey + 1}`] || [];
          const updatedAdditionalValues = [...additionalCheckBoxData];
          const labelIndex = updatedAdditionalValues.indexOf(additionalCategoryData);
          if (labelIndex === -1) {
            updatedAdditionalValues.push(additionalCategoryData);
          } else {
            updatedAdditionalValues.splice(labelIndex, 1);
          }
          data[`additionalCategory${additionalCategoryKey + 1}`] = updatedAdditionalValues || [];
        } else {
          data[`additionalCategory${additionalCategoryKey + 1}`] = additionalCategoryData;
        }
      }
    } else {
      const optionIds = options?.map(item => item.id);
      let selectedId = e.target[e.target.selectedIndex].id
      data.value = e.target[e.target.selectedIndex].title;
      let selectedData = [...optionValueData]
      selectedData = selectedData?.filter(id => !optionIds.includes(id));
      const labelIndexData = selectedData.indexOf(Number(selectedId));
      if (labelIndexData === -1) {
        selectedData.push(Number(selectedId));
      } else {
        selectedData.splice(labelIndexData, 1);
      }
      setDropdownId(e)
      setDropdownOption(options)
      setOptionValueData(selectedData);
    }
    let finalData = { ...dataEntryInput, ...data }
    storeDataEntry(finalData)
    setDataEntryInput(finalData);
  };

  const handleScaleInputChange = (scaleInput) => {
    let scaleInputData = scaleInput ? scaleInput : (inputFormDataEntry?.value?.value === undefined ? scaleInput : inputFormDataEntry?.value?.value);
    let data = {
      value: scaleInputData,
    }
    storeDataEntry(data)
    setScaleInput(scaleInputData);
    setDataEntryInput(data);
  };

  const days = Array.from({ length: 31 }, (_, index) => {
    const day = index + 1;
    return day.toString().padStart(2, '0');
  });
  const hours12 = Array.from({ length: 12 }, (_, index) => {
    const hour = index + 1;
    return hour.toString().padStart(2, '0');
  });
  const hours24 = Array.from({ length: 24 }, (_, index) => {
    const hour = index + 1;
    return hour.toString().padStart(2, '0');
  });
  const hours = selectedForm?.timeFormating === "12" ? hours12 : hours24;

  const minuteSecond = Array.from({ length: 60 }, (_, index) => {
    const minuteSecondData = index + 1;
    return minuteSecondData.toString().padStart(2, '0');
  });
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 150 }, (_, index) => currentYear - index);
  const textMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];
  const numberMonths = Array.from({ length: 12 }, (_, index) => {
    const numberMonth = index + 1;
    return numberMonth.toString().padStart(2, '0');
  });

  const months = selectedForm?.format === "YYYY_MMM_DD" ||
    selectedForm?.format === "DD/MMM/YYYY" ||
    selectedForm?.format === "YYYY/MMM/DD" ||
    selectedForm?.format === "DD_MMM_YYYY" ||
    selectedForm?.format === "YYYY-MMM-DD" ||
    selectedForm?.format === "DD-MMM-YYYY" ? textMonths :
    selectedForm?.format === "YYYY_MM_DD" ||
      selectedForm?.format === "DD/MM/YYYY" ||
      selectedForm?.format === "YYYY/MM/DD" ||
      selectedForm?.format === "DD_MM_YYYY" ||
      selectedForm?.format === "YYYY-MM-DD" ? numberMonths : textMonths

  let minRangeValue = selectedForm.minRange
  let maxRangeValue = selectedForm.maxRange

  let splitDateMinRange;
  let splitDateMaxRange;

  if (selectedForm.type === "date") {
    splitDateMinRange = minRangeValue?.split("-")
    splitDateMaxRange = maxRangeValue?.split("-")
  }

  let dayMinRange = splitDateMinRange?.[2]
  let monthMinRange = splitDateMinRange?.[1]
  let yearMinRange = splitDateMinRange?.[0]

  let dayMaxRange = splitDateMaxRange?.[2]
  let monthMaxRange = splitDateMaxRange?.[1]
  let yearMaxRange = splitDateMaxRange?.[0]

  let splitTimeMinRange;
  let splitTimeMaxRange;

  if (selectedForm.type === "time") {
    splitTimeMinRange = minRangeValue?.split(":")
    splitTimeMaxRange = maxRangeValue?.split(":")
  }

  let hourMinRange = splitTimeMinRange?.[0]
  let minuteMinRange = splitTimeMinRange?.[1]
  let secondMinRange = splitTimeMinRange?.[2]

  let hourMaxRange = splitTimeMaxRange?.[0]
  let minuteMaxRange = splitTimeMaxRange?.[1]
  let secondMaxRange = splitTimeMaxRange?.[2]

  // Date range 
  const [openDay, setOpenDay] = useState(false);
  const handleDayChange = (day) => {
    setDay(day);
    const regex = /^(UNK|[0-2]?\d|3[01])$/;
    let checkDay = regex.test(day)
    if (!checkDay) {
      setDayCheck("Enter only 2 Digit Number (Less then and Equal to 31) and UNK in Capital Latter")
    } else {
      setDayCheck("")
    }
  }

  const [openMonth, setOpenMonth] = useState(false);
  const handleMonthChange = (month) => {
    setMonth(month);
    const regex = /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|UNK|\b(0[1-9]|[12][0-9]|3[01])\b)$/;
    let checkMonth = regex.test(month)
    if (!checkMonth) {
      setMonthCheck("Enter only Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, oct, Nov, Dec and UNK")
    } else {
      setMonthCheck("")
    }
  }

  const [openYear, setOpenYear] = useState(false);
  const handleYearChange = (year) => {
    setYear(year);
    const regex = /^(UNK|\d{4})$/;
    let checkYear = regex.test(year)
    if (!checkYear) {
      setYearCheck("Enter only 4 Digit Number or UNK in Capital Latter")
    } else {
      setYearCheck("")
    }
  }

  // Time Range 
  const [openClock, setOpenClock] = useState(false);

  const [openHour, setOpenHour] = useState(false);
  const handleHoursChange = (hour) => {
    setHour(hour);
    const regex = /^(UNK|[0-5]?\d|25)$/;
    let checkHour = regex.test(hour)
    if (!checkHour) {
      setHourCheck("Enter only (Less then and Equal to 24) or UNK in Capital Latter")
    } else {
      setHourCheck("")
    }
  }
  const [openMinut, setOpenMinut] = useState(false);
  const handleMinuteChange = (minute) => {
    setMinute(minute);
    const regex = /^(UNK|[0-5]?\d|60)$/;
    let checkMinute = regex.test(minute)
    if (!checkMinute) {
      setMinuteCheck("Enter only 2 Digit Number (Less then and Equal to 60) or UNK in Capital Latter")
    } else {
      setMinuteCheck("")
    }
  }
  const [openSecond, setOpenSecond] = useState(false);
  const handleSecondChange = (second) => {
    setSecond(second);
    const regex = /^(UNK|[0-5]?\d|60)$/;
    let checkSecond = regex.test(second)
    if (!checkSecond) {
      setSecondCheck("Enter only 2 Digit Number (Less then and Equal to 60) or UNK in Capital Latter")
    } else {
      setSecondCheck("")
    }
  }

  const handleClockChange = (e) => setClock(e.target.value);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (DaysRef.current && !DaysRef.current.contains(event.target)) {
        setOpenDay(false);
      }
      if (MonthsRef.current && !MonthsRef.current.contains(event.target)) {
        setOpenMonth(false);
      }
      if (YearsRef.current && !YearsRef.current.contains(event.target)) {
        setOpenYear(false);
      }
      if (HourRef.current && !HourRef.current.contains(event.target)) {
        setOpenHour(false);
      }
      if (MinuteRef.current && !MinuteRef.current.contains(event.target)) {
        setOpenMinut(false);
      }
      if (SecondRef.current && !SecondRef.current.contains(event.target)) {
        setOpenSecond(false);
      }
      if (ClockRef.current && !ClockRef.current.contains(event.target)) {
        setOpenClock(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="pt-3 px-4">
        {selectedForm?.type === "text" ? (
          <>
            <div className="d-flex gap-3 mb-2">
              <Form.Group>
                <Form.Label className="mb-1 fw-bold">
                  {selectedForm?.label}
                </Form.Label>
                {selectedForm?.fieldHelp && (
                  <i
                    data-tooltip-id="tooltip-1"
                    className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                  ></i>
                )}
                <Form.Control
                  type={selectedForm?.type}
                  value={dataEntryInput?.value || dataEntiesValue?.[0]?.value?.value}
                  maxLength={selectedForm?.property}
                  disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                    (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                  onChange={(e) => handleTextChange(e.target.value, dataEntryInput?.unit)}
                  placeholder={selectedForm?.placeholder}
                  required={selectedForm?.required === "true" ? true : false}

                  style={{ width: 350 }}
                />
              </Form.Group>
              {selectedForm?.unitLabel &&
                <Form.Group className="mt-1">
                  <div className="d-flex gap-1 hstack">
                    <Form.Label className="mb-1 fw-bold">
                      {selectedForm?.unitLabel}
                    </Form.Label>
                    {selectedForm?.unitFieldHelp && (
                      <i
                        data-tooltip-id="tooltip-2"
                        className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                      ></i>
                    )}
                  </div>
                  {(customType === "textCustom" || (dataEntryInput?.custom || dataEntiesValue?.[0]?.value?.custom)) ?
                    <Form.Control
                      type={"text"}
                      style={{ width: 350 }}
                      value={dataEntryInput?.unit || dataEntiesValue?.[0]?.value?.unit}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      onChange={(e) => handleTextChange(dataEntryInput?.value, e.target.value)}
                      placeholder={"Enter Custom Unit"}
                      className="  w-150"
                    />
                    :
                    <Form.Select
                      onChange={(e) => { handleTextChange(dataEntryInput?.value, e.target.value); setCustomType(e.target[e.target.selectedIndex].id) }}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option selected value="">Select Unit</option>
                      <option id="textCustom" value="">Custom Unit</option>
                      {textUnitList?.map((item) => (
                        <option
                          selected={(item.unit === dataEntryInput?.unit) || (item.unit === dataEntiesValue?.[0]?.value?.unit)}
                          value={selectedForm?.value} >
                          {item.unit}
                        </option>
                      ))}
                    </Form.Select>
                  }
                </Form.Group>
              }
            </div>
            {selectedForm?.additionalCategory?.length > 0 && (
              selectedForm?.additionalCategory?.map((additionalData, key) => (
                <Form.Group key={key} className="mb-2">
                  <div className="d-flex gap-1 hstack">
                    <Form.Label className="mb-1 fw-bold">
                      {additionalData[`additionalCategoryLabel${key + 1}`]}
                    </Form.Label>
                    {additionalData[`additionalCategoryHelp${key + 1}`] && (
                      <i
                        title={additionalData[`additionalCategoryHelp${key + 1}`]}
                        className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                      ></i>
                    )}
                  </div>
                  {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ?
                    <Form.Select
                      onChange={(e) => { handleTextChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option selected value="">{additionalData[`additionalCategoryPlaceholder${key + 1}`]}</option>
                      {(additionalData?.options || [])?.map((option) => (
                        <option
                          selected={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                          value={additionalData?.value} >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                    : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ?
                      (additionalData?.options || [])?.map((option, index) => (
                        <div key={index} className="hstack gap-2">
                          <Form.Check
                            onChange={(e) => { handleTextChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type="checkbox"
                            checked={dataEntryInput?.[`additionalCategory${key + 1}`]?.includes(option.value) || (dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]?.includes(option.value))}
                            id={option?.value}
                            value={option?.value}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                          />
                          <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                        </div>
                      ))
                      : additionalData[`additionalCategoryType${key + 1}`] === "radio" ?
                        (additionalData?.options || [])?.map((option, index) => (
                          <div key={index} className="hstack gap-2">
                            <Form.Check
                              onChange={(e) => { handleTextChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              checked={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                              type="radio"
                              name="group1"
                              id={option?.value}
                              value={option?.value}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            />
                            <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                          </div>
                        ))
                        : (additionalData[`additionalCategoryType${key + 1}`] === "text" || additionalData[`additionalCategoryType${key + 1}`] === "number" || additionalData[`additionalCategoryType${key + 1}`] === "date" || additionalData[`additionalCategoryType${key + 1}`] === "time") ?
                          <Form.Control
                            onChange={(e) => { handleTextChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type={additionalData[`additionalCategoryType${key + 1}`]}
                            value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                            style={{ width: 350 }}
                          />
                          : additionalData[`additionalCategoryType${key + 1}`] === "textarea" ?
                            <Form.Control
                              onChange={(e) => { handleTextChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              as={additionalData[`additionalCategoryType${key + 1}`]}
                              value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                              placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                              style={{ width: 350 }}
                            /> : ""
                  }
                </Form.Group>
              )))}
            <span>
              {selectedForm?.type === "text"
                && parseInt(textInput) <
                parseInt(selectedForm?.property)
                && `Please Enter Maximum Value ${selectedForm?.property}`
              }
            </span>
          </>
        ) : selectedForm?.type === "textarea" ? (
          <Form.Group>
            <Form.Label className="fw-bold mb-1">
              {selectedForm?.label}
            </Form.Label>
            {selectedForm?.fieldHelp && (
              <i
                data-tooltip-id="tooltip-1"
                className="fa-solid fa-circle-question ms-2 fontSize12"
              ></i>
            )}
            <Form.Control
              as={"textarea"}
              value={(textareaInput || (inputFormDataEntry?.value?.value === undefined ? textareaInput : inputFormDataEntry?.value?.value) || dataEntiesValue?.[0]?.value?.value)}
              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
              onChange={(e) => handleTextareaChange(e.target.value)}
              placeholder={selectedForm?.placeholder}
              required={selectedForm?.required === "true" ? true : false}
              style={{ height: 150, width: 400, resize: "both" }}
              className="  overflow-auto textarea-1"
            />
          </Form.Group>
        ) : selectedForm?.type === "scale" ? (
          <Form.Group>
            <Form.Label className="fw-bold mb-1">
              {selectedForm?.label}
            </Form.Label>
            {selectedForm?.fieldHelp && (
              <i
                data-tooltip-id="tooltip-1"
                className="fa-solid fa-circle-question ms-2 fontSize12"
              ></i>
            )}
            <div className="hstack">
              {(selectedForm.options || []).map((item, index) => (
                (StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                  (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))
                  ?
                  <div
                    key={index}
                    className={`scaleTemparature hstack position-relative justify-content-center fontSize12 ${item.value === scaleInput ? 'clicked' : ''}`}
                    style={{ cursor: "no-drop", backgroundColor: getColor(index, selectedForm.options, selectedForm.options?.findIndex(item => item.value === (scaleInput || dataEntiesValue?.[0]?.value?.value || inputFormDataEntry?.value?.value))) }}
                  >
                    <span style={{ position: 'absolute', fontSize: '10px' }}>
                      {item.label}
                    </span>
                  </div>
                  :
                  <div
                    key={index}
                    className={`scaleTemparature hstack position-relative justify-content-center fontSize12`}
                    style={{ cursor: "pointer", backgroundColor: getColor(index, selectedForm.options, selectedForm.options?.findIndex(item => item.value === (scaleInput || dataEntiesValue?.[0]?.value?.value || inputFormDataEntry?.value?.value))) }}
                    onClick={(e) => handleScaleInputChange(item.value)}
                  >
                    <span style={{ position: 'absolute', fontSize: '10px' }}>
                      {item.label}
                    </span>
                  </div>
              ))}
            </div>
          </Form.Group>
        ) : selectedForm?.type === "upload" ? (
          <Form.Group>
            <Form.Label className="fw-bold mb-1">
              {selectedForm?.label}
            </Form.Label>
            {selectedForm?.fieldHelp && (
              <i
                data-tooltip-id="tooltip-1"
                className="fa-solid fa-circle-question ms-2 fontSize12"
              ></i>
            )}
            <div className="hstack gap-2">
              <Form.Control
                type={"file"}
                onChange={(e) => handleChangeFileUpload(e)}
                disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                  (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                placeholder={selectedForm?.placeholder}
                accept={(selectedForm?.fileTypes || [])?.map(item => item.value)?.join(", ")}
                required={selectedForm?.required === "true" ? true : false}
                className="w-50"
              />
              {(uploadInput || (inputFormDataEntry?.value?.value === undefined ? uploadInput : inputFormDataEntry?.value?.value) || dataEntiesValue?.[0]?.value?.value) &&
                <a target="_blank" href={(uploadInput || (inputFormDataEntry?.value?.value === undefined ? uploadInput : inputFormDataEntry?.value?.value) || dataEntiesValue?.[0]?.value?.value)}>
                  <button className="bg-main border   fontSize12 text-white p-1 px-3">Download</button>
                </a>
              }
            </div>
            <Form.Text style={{ fontSize: 10, fontWeight: 600 }}><b>Supported File:</b> {selectedForm?.fileTypes ? (selectedForm?.fileTypes)?.map(item => item.label)?.join(", ") : "Any File"}</Form.Text>
          </Form.Group>
        ) : selectedForm?.type === "number" ? (
          <>
            <div className="d-flex gap-3 mb-2">
              <Form.Group>
                <Form.Label className="fw-bold mb-1">
                  {selectedForm?.label}
                </Form.Label>
                {selectedForm?.fieldHelp && (
                  <i
                    data-tooltip-id="tooltip-1"
                    className="fa-solid fa-circle-question fontSize12 ms-2"
                  ></i>
                )}
                <Form.Control
                  type={selectedForm?.type}
                  min={selectedForm?.numberType === "wholeNumber" ? "0" : ""}
                  value={(selectedForm?.numberType === "wholeNumber" ? Number(dataEntryInput?.value)?.match(/^-?\d+$/) || Number(dataEntiesValue?.[0]?.value?.value)
                    : Number(dataEntryInput?.value) || Number(dataEntiesValue?.[0]?.value?.value))}
                  maxLength={selectedForm?.property}
                  disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                    (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                  onChange={(e) => handleNumberChange(e.target.value, dataEntryInput?.unit)}
                  placeholder={selectedForm?.placeholder}
                  required={selectedForm?.required === "true" ? true : false}

                  style={{ width: 350 }}
                />
              </Form.Group>
              {selectedForm?.unitLabel &&
                <Form.Group>
                  <Form.Label className="fw-bold mb-1">
                    {selectedForm?.unitLabel}
                  </Form.Label>
                  {selectedForm?.unitFieldHelp && (
                    <i
                      data-tooltip-id="tooltip-2"
                      className="fa-solid fa-circle-question fontSize12 ms-2"
                    ></i>
                  )}
                  {(customType === "textCustom" || (dataEntryInput?.custom || dataEntiesValue?.[0]?.value?.custom)) ?
                    <Form.Control
                      type={"text"}
                      value={dataEntryInput?.unit || dataEntiesValue?.[0]?.value?.unit}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      onChange={(e) => handleNumberChange(dataEntryInput?.value, e.target.value)}
                      placeholder={"Enter Custom Unit"}

                      style={{ width: 350 }}
                    />
                    :
                    <Form.Select
                      onChange={(e) => { handleNumberChange(dataEntryInput?.value, e.target.value); setCustomType(e.target[e.target.selectedIndex].id) }}
                      className="  w-150"
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option value="">Select Unit</option>
                      <option id="numberCustom" value="">Custom Unit</option>
                      {numberUnitList?.map((item) => (
                        <option
                          selected={(item.unit === dataEntryInput?.unit) || (item.unit === dataEntiesValue?.[0]?.value?.unit)}
                          value={selectedForm?.value} >
                          {item.unit}
                        </option>
                      ))}
                    </Form.Select>
                  }
                </Form.Group>
              }
            </div>
            <div>
              {selectedForm?.numberType === "wholeNumber" &&
                <div className="error__log"><span>{/^-?\d+$/.test(numberInput) || /^-?\d+$/.test(dataEntiesValue?.[0]?.value?.value) ? "" : "Please Enter Only Whole Number"}</span></div>
              }
              <div className="error__log">
                <span>
                  {selectedForm?.type === "number"
                    ? parseInt(numberInput || dataEntiesValue?.[0]?.value?.value) <
                      parseInt(selectedForm?.minRange)
                      ? `Please Enter Minimum Value ${selectedForm?.minRange}`
                      : parseInt(numberInput || dataEntiesValue?.[0]?.value?.value) >
                        parseInt(selectedForm?.maxRange)
                        ? `Please Enter Maximum Value ${selectedForm?.maxRange}`
                        : ""
                    : ""}
                </span>
              </div>
            </div>
            {selectedForm?.additionalCategory?.length > 0 && (
              selectedForm?.additionalCategory?.map((additionalData, key) => (
                <Form.Group key={key} className="mb-2">
                  <div className="d-flex gap-1 hstack">
                    <Form.Label className="mb-1 fw-bold">
                      {additionalData[`additionalCategoryLabel${key + 1}`]}
                    </Form.Label>
                    {additionalData[`additionalCategoryHelp${key + 1}`] && (
                      <i
                        title={additionalData[`additionalCategoryHelp${key + 1}`]}
                        className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                      ></i>
                    )}
                  </div>
                  {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ?
                    <Form.Select
                      onChange={(e) => { handleNumberChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option selected value="">{additionalData[`additionalCategoryPlaceholder${key + 1}`]}</option>
                      {(additionalData?.options || [])?.map((option) => (
                        <option
                          selected={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                          value={additionalData?.value} >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                    : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ?
                      (additionalData?.options || [])?.map((option, index) => (
                        <div key={index} className="hstack gap-2">
                          <Form.Check
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            onChange={(e) => { handleNumberChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type="checkbox"
                            checked={dataEntryInput?.[`additionalCategory${key + 1}`]?.includes(option.value) || (dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]?.includes(option.value))}
                            id={option?.value}
                            value={option?.value}
                          />
                          <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                        </div>
                      ))
                      : additionalData[`additionalCategoryType${key + 1}`] === "radio" ?
                        (additionalData?.options || [])?.map((option, index) => (
                          <div key={index} className="hstack gap-2">
                            <Form.Check
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                              onChange={(e) => { handleNumberChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              checked={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                              type="radio"
                              name="group1"
                              id={option?.value}
                              value={option?.value}
                            />
                            <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                          </div>
                        ))
                        : (additionalData[`additionalCategoryType${key + 1}`] === "text" || additionalData[`additionalCategoryType${key + 1}`] === "number" || additionalData[`additionalCategoryType${key + 1}`] === "date" || additionalData[`additionalCategoryType${key + 1}`] === "time") ?
                          <Form.Control
                            onChange={(e) => { handleNumberChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type={additionalData[`additionalCategoryType${key + 1}`]}
                            value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                            style={{ width: 350 }}
                          />
                          : additionalData[`additionalCategoryType${key + 1}`] === "textarea" ?
                            <Form.Control
                              onChange={(e) => { handleNumberChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              as={additionalData[`additionalCategoryType${key + 1}`]}
                              value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                              placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                              style={{ width: 350 }}
                            /> : ""
                  }
                </Form.Group>
              )))}
          </>
        ) : selectedForm?.type === "date" ? (
          selectedForm?.format === "YYYY_MMM_DD" || selectedForm?.format === "YYYY/MMM/DD" || selectedForm?.format === "YYYY-MMM-DD" || selectedForm?.format === "YYYY_MM_DD" || selectedForm?.format === "YYYY/MM/DD" || selectedForm?.format === "YYYY-MM-DD" ?
            <>
              <Form className="d-flex gap-3">
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1 me-2">
                    {selectedForm?.yearLabel}
                  </Form.Label>
                  {selectedForm?.yearFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-yearLabel`}
                      className="fa-solid fa-circle-question fontSize12"
                    ></i>
                  )}
                  <Form.Control
                    type="text"

                    style={{ width: 276 }}
                    value={(year || (inputFormDataEntry?.value?.year === undefined ? year : inputFormDataEntry?.value?.year) || dataEntiesValue?.[0]?.value?.year)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setYear(e.target.value); handleDateChange(); handleYearChange(e.target.value); }}
                    onClick={() => setOpenYear(!openYear)}
                    placeholder="yyyy"
                    maxLength="4"
                    size="5"
                    title="Enter year as a 4-digit number."
                  />
                  <Collapse in={openYear} className="position-absolute w-100 shadow z-1 bg-white fontSize12 overflow-auto selectValues" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap "
                      ref={YearsRef}
                    >
                      {years?.map((number) => (
                        <div
                          onClick={(e) => { setYear(number); handleDateChange(); handleYearChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (year ? year : (inputFormDataEntry?.value?.year ? inputFormDataEntry?.value?.year : dataEntiesValue?.[0]?.value?.year))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setYear("UNK"); handleDateChange(); handleYearChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (year ? year : (inputFormDataEntry?.value?.year ? inputFormDataEntry?.value?.year : dataEntiesValue?.[0]?.value?.year))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1 me-2">
                    {selectedForm?.monthLabel}
                  </Form.Label>
                  {(selectedForm?.monthFieldHelp || selectedForm?.minuteFieldHelp) && (
                    <i
                      data-tooltip-id={`tooltip-monthLabel"}`}
                      className="fa-solid fa-circle-question fontSize12"
                    ></i>
                  )}
                  <Form.Control
                    type="text"

                    style={{ width: 141 }}
                    value={(month || (inputFormDataEntry?.value?.month === undefined ? month : inputFormDataEntry?.value?.month) || dataEntiesValue?.[0]?.value?.month)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setMonth(e.target.value); handleDateChange(); handleMonthChange(e.target.value); }}
                    placeholder="mmm"
                    onClick={() => setOpenMonth(!openMonth)}
                    size="3"
                    title="Please enter the month of the year as a one or two-digit number, or as a name. The valid numeric range is from 1 to 12."
                  />
                  <Collapse in={openMonth} className="position-absolute w-100 shadow z-1 bg-white fontSize12 selectValues overflow-auto" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap"
                      ref={MonthsRef}
                    >
                      {months?.map((item) => (
                        <div
                          onClick={(e) => { setMonth(item); handleDateChange(); handleMonthChange(item); }}
                          className={`border-bottom px-3 CP py-1 ${((item === (month ? month : (inputFormDataEntry?.value?.month ? inputFormDataEntry?.value?.month : dataEntiesValue?.[0]?.value?.month))) && "active")}`}
                          value={selectedForm?.value} >
                          {item}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setMonth("UNK"); handleDateChange(); handleMonthChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (month ? month : (inputFormDataEntry?.value?.month ? inputFormDataEntry?.value?.month : dataEntiesValue?.[0]?.value?.month))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1 me-2">
                    {selectedForm?.dayLabel}
                  </Form.Label>
                  {(selectedForm?.dayFieldHelp || selectedForm?.hourFieldHelp) && (
                    <i
                      data-tooltip-id={`tooltip-dayLabel`}
                      className="fa-solid fa-circle-question fontSize12"
                    ></i>
                  )}
                  <Form.Control
                    type="text"
                    value={(day || (inputFormDataEntry?.value?.day === undefined ? day : inputFormDataEntry?.value?.day) || dataEntiesValue?.[0]?.value?.day)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setDay(e.target.value); handleDateChange(); handleDayChange(e.target.value); }}
                    placeholder="dd"
                    onClick={() => setOpenDay(!openDay)}
                    maxLength="3"

                    style={{ width: 141 }}
                    size="3"
                    title="Please enter the day of the month as a one or two-digit number. The valid range is from 1 to 31."
                  />
                  <Collapse
                    in={openDay} className="position-absolute w-100 shadow z-1 bg-white fontSize12 selectValues overflow-auto" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap"
                      ref={DaysRef}
                    >
                      {days?.map((number) => (
                        <div
                          onClick={(e) => { setDay(number); handleDateChange(); handleDayChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (day ? day : (inputFormDataEntry?.value?.day ? inputFormDataEntry?.value?.day : dataEntiesValue?.[0]?.value?.day))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setDay("UNK"); handleDateChange(); handleDayChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (day ? day : (inputFormDataEntry?.value?.day ? inputFormDataEntry?.value?.day : dataEntiesValue?.[0]?.value?.day))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                {/* {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                  (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))
                  ? "" :
                  <Form.Group className="toolbar">
                    <Form.Label className="mb-2 d-block text-white">Select By Calendar</Form.Label>
                    <div className="position-relative overflow-hidden">
                      <i
                        className="fa-solid fa-calendar-days cursor-pointer"
                        style={{ fontSize: 20, cursor: 'pointer' }}
                      ></i>
                      <Form.Control
                        type="date"
                        value={dateCalendar}
                        className="position-absolute top-0 start-0 p-0 opacity-0 cursor-pointer"
                        style={{ fontSize: 18, width: 23 }}
                      />
                    </div>
                  </Form.Group>
                } */}
              </Form>
              {(dayCheck && day) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Day Error</b> : {dayCheck}
                  </span>
                </div>
              }
              {(monthCheck && month) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Month Error</b> : {monthCheck}
                  </span>
                </div>
              }
              {(yearCheck && year) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Year Error</b> : {yearCheck}
                  </span>
                </div>
              }
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "date"
                    ? parseInt(year) <
                      parseInt(yearMinRange)
                      ? `Please Enter Minimum Year ${yearMinRange}`
                      : parseInt(year) >
                        parseInt(yearMaxRange)
                        ? `Please Enter Maximum Year ${yearMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "date"
                    ? parseInt(month) <
                      parseInt(monthMinRange)
                      ? `Please Enter Minimum Month ${monthMinRange}`
                      : parseInt(month) >
                        parseInt(monthMaxRange)
                        ? `Please Enter Maximum Month ${monthMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>

              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "date"
                    ? parseInt(day) <
                      parseInt(dayMinRange)
                      ? `Please Enter Minimum Day ${dayMinRange}`
                      : parseInt(day) >
                        parseInt(dayMaxRange)
                        ? `Please Enter Maximum Day ${dayMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
            </>
            :
            <>
              <Form className="d-flex gap-3">
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1 me-2">
                    {selectedForm?.dayLabel}
                  </Form.Label>
                  {(selectedForm?.dayFieldHelp || selectedForm?.hourFieldHelp) && (
                    <i
                      data-tooltip-id={`tooltip-dayLabel`}
                      className="fa-solid fa-circle-question fontSize12"
                    ></i>
                  )}
                  <Form.Control
                    type="text"
                    value={(day || (inputFormDataEntry?.value?.day === undefined ? day : inputFormDataEntry?.value?.day) || dataEntiesValue?.[0]?.value?.day)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setDay(e.target.value); handleDateChange(); handleDayChange(e.target.value); }}
                    placeholder="dd"
                    onClick={() => setOpenDay(!openDay)}
                    maxLength="3"

                    style={{ width: 141 }}
                    size="3"
                    title="Please enter the day of the month as a one or two-digit number. The valid range is from 1 to 31."
                  />
                  <Collapse
                    in={openDay} className="position-absolute w-100 shadow z-1 bg-white fontSize12 selectValues overflow-auto" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap"
                      ref={DaysRef}
                    >
                      {days?.map((number) => (
                        <div
                          onClick={(e) => { setDay(number); handleDateChange(); handleDayChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (day ? day : (inputFormDataEntry?.value?.day ? inputFormDataEntry?.value?.day : dataEntiesValue?.[0]?.value?.day))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setDay("UNK"); handleDateChange(); handleDayChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (day ? day : (inputFormDataEntry?.value?.day ? inputFormDataEntry?.value?.day : dataEntiesValue?.[0]?.value?.day))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1 me-2">
                    {selectedForm?.monthLabel}
                  </Form.Label>
                  {(selectedForm?.monthFieldHelp || selectedForm?.minuteFieldHelp) && (
                    <i
                      data-tooltip-id={`tooltip-monthLabel"}`}
                      className="fa-solid fa-circle-question fontSize12"
                    ></i>
                  )}
                  <Form.Control
                    type="text"

                    style={{ width: 141 }}
                    value={(month || (inputFormDataEntry?.value?.month === undefined ? month : inputFormDataEntry?.value?.month) || dataEntiesValue?.[0]?.value?.month)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setMonth(e.target.value); handleDateChange(); handleMonthChange(e.target.value); }}
                    placeholder="mmm"
                    onClick={() => setOpenMonth(!openMonth)}
                    size="3"
                    title="Please enter the month of the year as a one or two-digit number, or as a name. The valid numeric range is from 1 to 12."
                  />
                  <Collapse in={openMonth} className="position-absolute w-100 shadow z-1 bg-white fontSize12 selectValues overflow-auto" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap"
                      ref={MonthsRef}
                    >
                      {months?.map((item) => (
                        <div
                          onClick={(e) => { setMonth(item); handleDateChange(); handleMonthChange(item); }}
                          className={`border-bottom px-3 CP py-1 ${((item === (month ? month : (inputFormDataEntry?.value?.month ? inputFormDataEntry?.value?.month : dataEntiesValue?.[0]?.value?.month))) && "active")}`}
                          value={selectedForm?.value} >
                          {item}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setMonth("UNK"); handleDateChange(); handleMonthChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === dataEntiesValue?.[0]?.value?.month) || ("UNK" === inputFormDataEntry?.value?.month) || "UNK" === month) && "active"}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1 me-2">
                    {selectedForm?.yearLabel}
                  </Form.Label>
                  {selectedForm?.yearFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-yearLabel`}
                      className="fa-solid fa-circle-question fontSize12"
                    ></i>
                  )}
                  <Form.Control
                    type="text"

                    style={{ width: 276 }}
                    value={(year || (inputFormDataEntry?.value?.year === undefined ? year : inputFormDataEntry?.value?.year) || dataEntiesValue?.[0]?.value?.year)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setYear(e.target.value); handleDateChange(); handleYearChange(e.target.value); }}
                    onClick={() => setOpenYear(!openYear)}
                    placeholder="yyyy"
                    maxLength="4"
                    size="5"
                    title="Enter year as a 4-digit number."
                  />
                  <Collapse in={openYear} className="position-absolute w-100 shadow z-1 bg-white fontSize12 overflow-auto selectValues" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap "
                      ref={YearsRef}
                    >
                      {years?.map((number) => (
                        <div
                          onClick={(e) => { setYear(number); handleDateChange(); handleYearChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (year ? year : (inputFormDataEntry?.value?.year ? inputFormDataEntry?.value?.year : dataEntiesValue?.[0]?.value?.year))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setYear("UNK"); handleDateChange(); handleYearChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (year ? year : (inputFormDataEntry?.value?.year ? inputFormDataEntry?.value?.year : dataEntiesValue?.[0]?.value?.year))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                {/* {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                  (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))
                  ? "" :
                  <Form.Group className="toolbar">
                    <Form.Label className="mb-2 d-block text-white">Select By Calendar</Form.Label>
                    <div className="position-relative overflow-hidden">
                      <i
                        className="fa-solid fa-calendar-days cursor-pointer"
                        style={{ fontSize: 20, cursor: 'pointer' }}
                      ></i>
                      <Form.Control
                        type="date"
                        value={dateCalendar}
                        className="position-absolute top-0 start-0 p-0 opacity-0 cursor-pointer"
                        style={{ fontSize: 18, width: 23 }}
                      />
                    </div>
                  </Form.Group>
                } */}
              </Form>
              {(dayCheck && day) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Day Error</b> : {dayCheck}
                  </span>
                </div>
              }
              {(monthCheck && month) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Month Error</b> : {monthCheck}
                  </span>
                </div>
              }
              {(yearCheck && year) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Year Error</b> : {yearCheck}
                  </span>
                </div>
              }
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "date"
                    ? parseInt(day) <
                      parseInt(dayMinRange)
                      ? `Day Error * : Please Enter Minimum Day ${dayMinRange}`
                      : parseInt(day) >
                        parseInt(dayMaxRange)
                        ? `Day Error * : Please Enter Maximum Day ${dayMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "date"
                    ? parseInt(month) <
                      parseInt(monthMinRange)
                      ? `Month Error * : Please Enter Minimum Month ${monthMinRange}`
                      : parseInt(month) >
                        parseInt(monthMaxRange)
                        ? `Month Error * : Please Enter Maximum Month ${monthMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "date"
                    ? parseInt(year) <
                      parseInt(yearMinRange)
                      ? `Year Error * : Please Enter Minimum Year ${yearMinRange}`
                      : parseInt(year) >
                        parseInt(yearMaxRange)
                        ? `Year Error * : Please Enter Maximum Year ${yearMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
            </>
        ) : selectedForm?.type === "time" ? (
          selectedForm?.format === "HH:MM:SS" ?
            <>
              <Form className="d-flex gap-3">
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1">
                    {selectedForm?.hourLabel}
                  </Form.Label>
                  {selectedForm?.hourFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-hourLabel`}
                      className="fa-solid fa-circle-question fontSize12 ms-2"
                    ></i>
                  )}
                  <Form.Control
                    type="text"
                    value={(hour || (inputFormDataEntry?.value?.hour === undefined ? hour : inputFormDataEntry?.value?.hour) || dataEntiesValue?.[0]?.value?.hour)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setHour(e.target.value); handleTimeChange(); handleHoursChange(e.target.value); }}
                    placeholder="HH"
                    maxLength="3"
                    onClick={() => setOpenHour(!openHour)}

                    style={{ width: 141 }}
                    title="Enter Hours as a 2-digit number. The valid range is from 0 to 23."
                  />
                  <Collapse
                    in={openHour} className="position-absolute w-100 shadow z-1 bg-white fontSize12 selectValues overflow-auto" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap" ref={HourRef}>
                      {hours?.map((number) => (
                        <div
                          onClick={(e) => { setHour(number); handleTimeChange(); handleHoursChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (hour ? hour : (inputFormDataEntry?.value?.hour ? inputFormDataEntry?.value?.hour : dataEntiesValue?.[0]?.value?.hour))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setHour("UNK"); handleTimeChange(); handleHoursChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (hour ? hour : (inputFormDataEntry?.value?.hour ? inputFormDataEntry?.value?.hour : dataEntiesValue?.[0]?.value?.hour))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1">
                    {selectedForm?.minuteLabel}
                  </Form.Label>
                  {selectedForm?.minuteFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-minuteLabel`}
                      className="fa-solid fa-circle-question fontSize12 ms-2"
                    ></i>
                  )}
                  <Form.Control
                    type="text"
                    value={(minute || (inputFormDataEntry?.value?.minute === undefined ? minute : inputFormDataEntry?.value?.minute) || dataEntiesValue?.[0]?.value?.minute)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setMinute(e.target.value); handleTimeChange(); handleMinuteChange(e.target.value); }}
                    placeholder="MM"
                    maxLength="3"
                    onClick={() => setOpenMinut(!openMinut)}

                    style={{ width: 141 }}
                    title="Enter Minute as a 2-digit number. The valid numeric range is from 0 to 59."
                  />
                  <Collapse in={openMinut} className="position-absolute w-100 shadow z-1 bg-white overflow-auto fontSize12 selectValues" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap" ref={MinuteRef}>
                      {minuteSecond?.map((number) => (
                        <div
                          onClick={(e) => { setMinute(number); handleTimeChange(); handleMinuteChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (minute ? minute : (inputFormDataEntry?.value?.minute ? inputFormDataEntry?.value?.minute : dataEntiesValue?.[0]?.value?.minute))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setMinute("UNK"); handleTimeChange(); handleMinuteChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (minute ? minute : (inputFormDataEntry?.value?.minute ? inputFormDataEntry?.value?.minute : dataEntiesValue?.[0]?.value?.minute))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1">
                    {selectedForm?.secondLabel}
                  </Form.Label>
                  {selectedForm?.secondFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-secondLabel`}
                      className="fa-solid fa-circle-question fontSize12 ms-2"
                    ></i>
                  )}
                  <Form.Control
                    type="text"

                    style={{ width: 276 }}
                    value={(second || (inputFormDataEntry?.value?.second === undefined ? second : inputFormDataEntry?.value?.second) || dataEntiesValue?.[0]?.value?.second)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setSecond(e.target.value); handleTimeChange(); handleSecondChange(e.target.value); }}
                    onClick={() => setOpenSecond(!openSecond)}
                    placeholder="SS"
                    maxLength="3"
                    title="Enter Second as a 2-digit number."
                  />
                  <Collapse in={openSecond} className="position-absolute w-100 shadow z-1 bg-white fontSize12 overflow-auto selectValues" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap" ref={SecondRef}>
                      {minuteSecond?.map((number) => (
                        <div
                          onClick={(e) => { setSecond(number); handleTimeChange(); handleSecondChange(number); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (second ? second : (inputFormDataEntry?.value?.second ? inputFormDataEntry?.value?.second : dataEntiesValue?.[0]?.value?.second))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setSecond("UNK"); handleTimeChange(); handleSecondChange("UNK"); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (second ? second : (inputFormDataEntry?.value?.second ? inputFormDataEntry?.value?.second : dataEntiesValue?.[0]?.value?.second))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                {selectedForm?.timeFormating === "12" &&
                  <Form.Group className="position-relative">
                    <Form.Label className="fw-bold mb-1 text-white">
                      Select Format
                    </Form.Label>
                    {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked")) ?
                      <InputGroup className="mb-3  ">
                        <InputGroup.Text style={{ borderRadius: "3px 0 0 3px" }} className={`fontSize12 opacity-50 ${(("am" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>AM</InputGroup.Text>
                        <InputGroup.Text style={{ borderRadius: "0 3px 3px 0" }} className={`fontSize12 opacity-50 ${(("pm" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>PM</InputGroup.Text>
                      </InputGroup>
                      :
                      <InputGroup className="mb-3  ">
                        <InputGroup.Text onClick={(e) => { setFormat("am"); handleTimeChange(); }} style={{ borderRadius: "3px 0 0 3px" }} className={`fontSize12 CP ${(("am" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>AM</InputGroup.Text>
                        <InputGroup.Text onClick={(e) => { setFormat("pm"); handleTimeChange(); }} style={{ borderRadius: "0 3px 3px 0" }} className={`fontSize12 CP ${(("pm" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>PM</InputGroup.Text>
                      </InputGroup>
                    }
                  </Form.Group>
                }
                {/* {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                  (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))
                  ? "" :
                  <Form.Group className="toolbar" style={{ width: 300 }}>
                    <Form.Label className="mb-2 d-block text-white">Select By Clock</Form.Label>
                    <i
                      className="fa-regular fa-clock"
                      style={{ fontSize: 20, cursor: 'pointer' }}
                      onClick={() => setOpenClock(!openClock)}
                    ></i>
                    <Collapse in={openClock} >
                      <div ref={ClockRef}>
                        <ClockPicker />
                      </div>
                    </Collapse>
                  </Form.Group>
                } */}
              </Form>
              {(hourCheck && hour) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Hour Error</b> : {hourCheck}
                  </span>
                </div>
              }
              {(minuteCheck && minute) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Minute Error</b> : {minuteCheck}
                  </span>
                </div>
              }
              {(secondCheck && second) &&
                <div className="d-block error__log">
                  <span style={{ fontSize: 11 }}>
                    <b>Second Error</b> : {secondCheck}
                  </span>
                </div>
              }
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "time"
                    ? parseInt(hour) <
                      parseInt(hourMinRange)
                      ? `Please Enter Minimum Hour ${hourMinRange}`
                      : parseInt(hour) >
                        parseInt(hourMaxRange)
                        ? `Please Enter Maximum Hour ${hourMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "time"
                    ? parseInt(minute) <
                      parseInt(minuteMinRange)
                      ? `Please Enter Minimum Minute ${minuteMinRange}`
                      : parseInt(minute) >
                        parseInt(minuteMaxRange)
                        ? `Please Enter Maximum Minute ${minuteMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "time"
                    ? parseInt(second) <
                      parseInt(secondMinRange)
                      ? `Please Enter Minimum Second ${secondMinRange}`
                      : parseInt(second) >
                        parseInt(secondMaxRange)
                        ? `Please Enter Maximum Second ${secondMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
            </>
            : selectedForm?.format === "HH:MM" &&
            <>
              <Form className="d-flex gap-3">
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1">
                    {selectedForm?.hourLabel}
                  </Form.Label>
                  {selectedForm?.hourFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-hourLabel`}
                      className="fa-solid fa-circle-question fontSize12 ms-2"
                    ></i>
                  )}
                  <Form.Control
                    type="text"
                    value={(hour || (inputFormDataEntry?.value?.hour === undefined ? hour : inputFormDataEntry?.value?.hour) || dataEntiesValue?.[0]?.value?.hour)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setHour(e.target.value); handleTimeChange(); handleHoursChange(e); }}
                    placeholder="HH"
                    maxLength="3"
                    onClick={() => setOpenHour(!openHour)}

                    style={{ width: 141 }}
                    title="Enter Hours as a 2-digit number. The valid range is from 0 to 23."
                  />
                  <Collapse
                    in={openHour} className="position-absolute w-100 shadow z-1 bg-white fontSize12 selectValues overflow-auto" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap" ref={HourRef}>
                      {hours?.map((number) => (
                        <div
                          onClick={(e) => { setHour(number); handleTimeChange(); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (hour ? hour : (inputFormDataEntry?.value?.hour ? inputFormDataEntry?.value?.hour : dataEntiesValue?.[0]?.value?.hour))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setHour("UNK"); handleTimeChange(); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (hour ? hour : (inputFormDataEntry?.value?.hour ? inputFormDataEntry?.value?.hour : dataEntiesValue?.[0]?.value?.hour))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                <Form.Group className="position-relative">
                  <Form.Label className="fw-bold mb-1">
                    {selectedForm?.minuteLabel}
                  </Form.Label>
                  {selectedForm?.minuteFieldHelp && (
                    <i
                      data-tooltip-id={`tooltip-minuteLabel`}
                      className="fa-solid fa-circle-question fontSize12 ms-2"
                    ></i>
                  )}
                  <Form.Control
                    type="text"
                    value={(minute || (inputFormDataEntry?.value?.minute === undefined ? minute : inputFormDataEntry?.value?.minute) || dataEntiesValue?.[0]?.value?.minute)}
                    disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                    onChange={(e) => { setMinute(e.target.value); handleTimeChange(); handleMinuteChange(e); }}
                    placeholder="MM"
                    maxLength="3"
                    onClick={() => setOpenMinut(!openMinut)}

                    style={{ width: 141 }}
                    title="Enter Minute as a 2-digit number. The valid numeric range is from 0 to 59."
                  />
                  <Collapse in={openMinut} className="position-absolute w-100 shadow z-1 bg-white overflow-auto fontSize12 selectValues" style={{ top: 60, maxHeight: 120 }}>
                    <div className="hstack flex-wrap" ref={MinuteRef}>
                      {minuteSecond?.map((number) => (
                        <div
                          onClick={(e) => { setMinute(number); handleTimeChange(); }}
                          className={`border-bottom px-3 CP py-1 ${((number === (minute ? minute : (inputFormDataEntry?.value?.minute ? inputFormDataEntry?.value?.minute : dataEntiesValue?.[0]?.value?.minute))) && "active")}`}
                          value={selectedForm?.value} >
                          {number}
                        </div>
                      ))}
                      <div
                        onClick={(e) => { setMinute("UNK"); handleTimeChange(); }}
                        className={`px-3 CP py-1 fontSize12 ${(("UNK" === (minute ? minute : (inputFormDataEntry?.value?.minute ? inputFormDataEntry?.value?.minute : dataEntiesValue?.[0]?.value?.minute))) && "active")}`}
                        value={selectedForm?.value} >
                        {"UNK"}
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
                {selectedForm?.timeFormating === "12" &&
                  <Form.Group className="position-relative">
                    <Form.Label className="fw-bold mb-1 text-white">
                      Select Format
                    </Form.Label>
                    {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                      (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked")) ?
                      <InputGroup className="mb-3  ">
                        <InputGroup.Text style={{ borderRadius: "3px 0 0 3px" }} className={`fontSize12 opacity-50 ${(("am" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>AM</InputGroup.Text>
                        <InputGroup.Text style={{ borderRadius: "0 3px 3px 0" }} className={`fontSize12 opacity-50 ${(("pm" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>PM</InputGroup.Text>
                      </InputGroup>
                      :
                      <InputGroup className="mb-3  ">
                        <InputGroup.Text onClick={(e) => { setFormat("am"); handleTimeChange(); }} style={{ borderRadius: "3px 0 0 3px" }} className={`fontSize12 CP ${(("am" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>AM</InputGroup.Text>
                        <InputGroup.Text onClick={(e) => { setFormat("pm"); handleTimeChange(); }} style={{ borderRadius: "0 3px 3px 0" }} className={`fontSize12 CP ${(("pm" === (format ? format : (inputFormDataEntry?.value?.format ? inputFormDataEntry?.value?.format : dataEntiesValue?.[0]?.value?.format))) && "bg-main text-white")}`}>PM</InputGroup.Text>
                      </InputGroup>
                    }
                  </Form.Group>
                }
                {/* {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                  (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))
                  ? "" :
                  <Form.Group className="toolbar" style={{ width: 300 }}>
                    <Form.Label className="mb-2 d-block text-white">Select By Clock</Form.Label>
                    <i
                      className="fa-regular fa-clock"
                      style={{ fontSize: 20, cursor: 'pointer' }}
                      onClick={() => setOpenClock(!openClock)}
                    ></i>
                    <Collapse in={openClock} >
                      <div ref={ClockRef}>
                        <ClockPicker />
                      </div>
                    </Collapse>
                  </Form.Group>
                } */}
              </Form>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "time"
                    ? parseInt(hour) <
                      parseInt(hourMinRange)
                      ? `Please Enter Minimum Hour ${hourMinRange}`
                      : parseInt(hour) >
                        parseInt(hourMaxRange)
                        ? `Please Enter Maximum Hour ${hourMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
              <div className="d-block error__log">
                <span>
                  {selectedForm?.type === "time"
                    ? parseInt(minute) <
                      parseInt(minuteMinRange)
                      ? `Please Enter Minimum Minute ${minuteMinRange}`
                      : parseInt(minute) >
                        parseInt(minuteMaxRange)
                        ? `Please Enter Maximum Minute ${minuteMaxRange}`
                        : ""
                    : ""}
                </span>
              </div>
            </>
        ) : selectedForm?.type === "checkbox" ? (
          <>
            <div className="d-flex gap-3 mb-2">
              <Form.Group>
                <Form.Label className="fw-bold mb-1">
                  {selectedForm?.label}
                </Form.Label>
                {selectedForm?.fieldHelp && (
                  <i
                    data-tooltip-id="tooltip-1"
                    className="fa-solid fa-circle-question"
                  ></i>
                )}
                {selectedForm?.options?.map(({ id, label, value }) => (
                  <div key={label} className="hstack gap-2">
                    <Form.Check
                      type="checkbox"
                      id={id}
                      value={value}
                      checked={(() => {
                        if (Array.isArray(checkBoxArray) && checkBoxArray.length > 0) {
                          return checkBoxArray.includes(value);
                        }

                        if (Array.isArray(dataEntryInput?.value) && dataEntryInput?.value?.length > 0) {
                          return dataEntryInput?.value?.includes(value);
                        }

                        if (dataEntryInput?.value && Array.isArray(dataEntryInput?.value)) {
                          return dataEntryInput?.value?.includes(value);
                        }

                        if (dataEntiesValue?.[0]?.value?.value != null) {
                          const values = Object.values(dataEntiesValue[0].value?.value);
                          return values.includes(value);
                        }

                        return false;
                      })()}
                      onChange={(e) => handleCheckboxChange(id, value)}
                      disabled={
                        ["SDV Live Mode", "SDV QC Mode"].includes(StudyMode) ||
                        ["freezed", "locked"].includes(fieldLockFreeze?.[0]?.freezeLockStatus)
                      }
                    />
                    <Form.Label className="fontSize12 m-0">{label}</Form.Label>
                  </div>
                ))}
              </Form.Group>
            </div>
            {selectedForm?.additionalCategory?.length > 0 && (
              selectedForm?.additionalCategory?.map((additionalData, key) => (
                <Form.Group key={key} className="mb-2">
                  <div className="d-flex gap-1 hstack">
                    <Form.Label className="mb-1 fw-bold">
                      {additionalData[`additionalCategoryLabel${key + 1}`]}
                    </Form.Label>
                    {additionalData[`additionalCategoryHelp${key + 1}`] && (
                      <i
                        title={additionalData[`additionalCategoryHelp${key + 1}`]}
                        className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                      ></i>
                    )}
                  </div>
                  {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ?
                    <Form.Select
                      onChange={(e) => { handleCheckboxChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option selected value="">{additionalData[`additionalCategoryPlaceholder${key + 1}`]}</option>
                      {(additionalData?.options || [])?.map((option) => (
                        <option
                          selected={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                          value={additionalData?.value} >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                    : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ?
                      (additionalData?.options || [])?.map((option, index) => (
                        <div key={index} className="hstack gap-2">
                          <Form.Check
                            onChange={(e) => { handleCheckboxChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type="checkbox"
                            checked={dataEntryInput?.[`additionalCategory${key + 1}`]?.includes(option.value) || (dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]?.includes(option.value))}
                            id={option?.value}
                            value={option?.value}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                          />
                          <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                        </div>
                      ))
                      : additionalData[`additionalCategoryType${key + 1}`] === "radio" ?
                        (additionalData?.options || [])?.map((option, index) => (
                          <div key={index} className="hstack gap-2">
                            <Form.Check
                              onChange={(e) => { handleCheckboxChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              checked={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                              type="radio"
                              name="group1"
                              id={option?.value}
                              value={option?.value}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            />
                            <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                          </div>
                        ))
                        : (additionalData[`additionalCategoryType${key + 1}`] === "text" || additionalData[`additionalCategoryType${key + 1}`] === "number" || additionalData[`additionalCategoryType${key + 1}`] === "date" || additionalData[`additionalCategoryType${key + 1}`] === "time") ?
                          <Form.Control
                            onChange={(e) => { handleCheckboxChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type={additionalData[`additionalCategoryType${key + 1}`]}
                            value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                            style={{ width: 350 }}
                          />
                          : additionalData[`additionalCategoryType${key + 1}`] === "textarea" ?
                            <Form.Control
                              onChange={(e) => { handleCheckboxChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              as={additionalData[`additionalCategoryType${key + 1}`]}
                              value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                              placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                              style={{ width: 350 }}
                            /> : ""
                  }
                </Form.Group>
              )))}
          </>
        ) : selectedForm?.type === "radio" ? (
          <>
            <div className="d-flex gap-3 mb-2">
              <Form.Group>
                <Form.Label className="fw-bold mb-1">
                  {selectedForm?.label}
                </Form.Label>
                {selectedForm?.fieldHelp && (
                  <i
                    data-tooltip-id="tooltip-1"
                    className="fa-solid fa-circle-question"
                  ></i>
                )}
                {(selectedForm?.options || [])?.map(({ id, label, value }) => (
                  <div key={id} className="hstack gap-2">
                    <Form.Check
                      type="radio"
                      id={id}
                      value={value}
                      checked={clear ? false : ((dataEntryInput?.value === value) || (dataEntiesValue?.[0]?.value?.value === value))}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true : ((fieldLockFreeze?.[0]?.freezeLockStatus === 'freezed') || (fieldLockFreeze?.[0]?.freezeLockStatus === 'locked')))}
                      name="group1"
                      onChange={(e) => handleRadioChange(e, selectedForm?.options)}
                      placeholder={selectedForm?.placeholder}
                      required={selectedForm?.required === 'true'}
                    />
                    <Form.Label className="fontSize12 m-0">{label}</Form.Label>
                  </div>
                ))}
                {!(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true : ((fieldLockFreeze?.[0]?.freezeLockStatus === 'freezed') || (fieldLockFreeze?.[0]?.freezeLockStatus === 'locked'))) &&
                  <button className="border   fontSize12 text-white bg-danger" onClick={(e) => cleanRadioButton(e)}>Clear</button>
                }
              </Form.Group>
            </div>
            {selectedForm?.additionalCategory?.length > 0 && (
              selectedForm?.additionalCategory?.map((additionalData, key) => (
                <Form.Group key={key} className="mb-2">
                  <div className="d-flex gap-1 hstack">
                    <Form.Label className="mb-1 fw-bold">
                      {additionalData[`additionalCategoryLabel${key + 1}`]}
                    </Form.Label>
                    {additionalData[`additionalCategoryHelp${key + 1}`] && (
                      <i
                        title={additionalData[`additionalCategoryHelp${key + 1}`]}
                        className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                      ></i>
                    )}
                  </div>
                  {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ?
                    <Form.Select
                      onChange={(e) => { handleRadioChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option selected value="">{additionalData[`additionalCategoryPlaceholder${key + 1}`]}</option>
                      {(additionalData?.options || [])?.map((option) => (
                        <option
                          selected={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                          value={additionalData?.value} >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                    : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ?
                      (additionalData?.options || [])?.map((option, index) => (
                        <div key={index} className="hstack gap-2">
                          <Form.Check
                            onChange={(e) => { handleRadioChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type="checkbox"
                            checked={dataEntryInput?.[`additionalCategory${key + 1}`]?.includes(option.value) || (dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]?.includes(option.value))}
                            id={option?.value}
                            value={option?.value}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                          />
                          <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                        </div>
                      ))
                      : additionalData[`additionalCategoryType${key + 1}`] === "radio" ?
                        (additionalData?.options || [])?.map((option, index) => (
                          <div key={index} className="hstack gap-2">
                            <Form.Check
                              onChange={(e) => { handleRadioChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              checked={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                              type="radio"
                              name="group1"
                              id={option?.value}
                              value={option?.value}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            />
                            <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                          </div>
                        ))
                        : (additionalData[`additionalCategoryType${key + 1}`] === "text" || additionalData[`additionalCategoryType${key + 1}`] === "number" || additionalData[`additionalCategoryType${key + 1}`] === "date" || additionalData[`additionalCategoryType${key + 1}`] === "time") ?
                          <Form.Control
                            onChange={(e) => { handleRadioChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type={additionalData[`additionalCategoryType${key + 1}`]}
                            value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                            style={{ width: 350 }}
                          />
                          : additionalData[`additionalCategoryType${key + 1}`] === "textarea" ?
                            <Form.Control
                              onChange={(e) => { handleRadioChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              as={additionalData[`additionalCategoryType${key + 1}`]}
                              value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                              placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                              style={{ width: 350 }}
                            /> : ""
                  }
                </Form.Group>
              )))}
          </>
        ) : (
          <>
            <div className="d-flex gap-3 mb-2">
              <Form.Group>
                <Form.Label className="fw-bold mb-1">
                  {selectedForm?.label}
                </Form.Label>
                {selectedForm?.fieldHelp && (
                  <i
                    data-tooltip-id="tooltip-1"
                    className="fa-solid fa-circle-question mb-1 ms-2 fontSize12"
                  ></i>
                )}
                <Form.Select
                  onChange={(e) => handleDropdownChange(e, selectedForm?.options)}
                  disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                    (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                  style={{ width: 200 }}
                >
                  <option value="">Select</option>
                  {selectedForm?.options?.map(({ id, label }) => (
                    <option
                      id={id}
                      title={label}
                      selected={((dataEntiesValue?.[0]?.value?.value === label) || (dataEntryInput?.value === label))}
                      value={selectedForm?.label} >
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            {selectedForm?.additionalCategory?.length > 0 && (
              selectedForm?.additionalCategory?.map((additionalData, key) => (
                <Form.Group key={key} className="mb-2">
                  <div className="d-flex gap-1 hstack">
                    <Form.Label className="mb-1 fw-bold">
                      {additionalData[`additionalCategoryLabel${key + 1}`]}
                    </Form.Label>
                    {additionalData[`additionalCategoryHelp${key + 1}`] && (
                      <i
                        title={additionalData[`additionalCategoryHelp${key + 1}`]}
                        className="fa-solid fa-circle-question fontSize12 ms-2 mb-1"
                      ></i>
                    )}
                  </div>
                  {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ?
                    <Form.Select
                      onChange={(e) => { handleDropdownChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                      disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                        (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                      style={{ width: 200 }}
                    >
                      <option selected value="">{additionalData[`additionalCategoryPlaceholder${key + 1}`]}</option>
                      {(additionalData?.options || [])?.map((option) => (
                        <option
                          selected={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                          value={additionalData?.value} >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                    : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ?
                      (additionalData?.options || [])?.map((option, index) => (
                        <div key={index} className="hstack gap-2">
                          <Form.Check
                            onChange={(e) => { handleDropdownChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type="checkbox"
                            checked={dataEntryInput?.[`additionalCategory${key + 1}`]?.includes(option.value) || (dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]?.includes(option.value))}
                            id={option?.value}
                            value={option?.value}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                          />
                          <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                        </div>
                      ))
                      : additionalData[`additionalCategoryType${key + 1}`] === "radio" ?
                        (additionalData?.options || [])?.map((option, index) => (
                          <div key={index} className="hstack gap-2">
                            <Form.Check
                              onChange={(e) => { handleDropdownChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              checked={(option.value === dataEntryInput?.[`additionalCategory${key + 1}`]) || (option.value === dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`])}
                              type="radio"
                              name="group1"
                              id={option?.value}
                              value={option?.value}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            />
                            <Form.Label className="fontSize12 m-0">{option?.value}</Form.Label>
                          </div>
                        ))
                        : (additionalData[`additionalCategoryType${key + 1}`] === "text" || additionalData[`additionalCategoryType${key + 1}`] === "number" || additionalData[`additionalCategoryType${key + 1}`] === "date" || additionalData[`additionalCategoryType${key + 1}`] === "time") ?
                          <Form.Control
                            onChange={(e) => { handleDropdownChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                            type={additionalData[`additionalCategoryType${key + 1}`]}
                            value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                            disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                              (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                            placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                            style={{ width: 350 }}
                          />
                          : additionalData[`additionalCategoryType${key + 1}`] === "textarea" ?
                            <Form.Control
                              onChange={(e) => { handleDropdownChange(false, false, e.target.value, key, additionalData[`additionalCategoryType${key + 1}`]); }}
                              as={additionalData[`additionalCategoryType${key + 1}`]}
                              value={dataEntryInput?.[`additionalCategory${key + 1}`] || dataEntiesValue?.[0]?.value[`additionalCategory${key + 1}`]}
                              disabled={(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode" ? true :
                                (fieldLockFreeze?.[0]?.freezeLockStatus === "freezed" || fieldLockFreeze?.[0]?.freezeLockStatus === "locked"))}
                              placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]}
                              style={{ width: 350 }}
                            /> : ""
                  }
                </Form.Group>
              )))}
          </>
        )}
        {selectedForm?.type === "date" || selectedForm?.type === "time" ?
          <Form.Group>
            <ReactTooltip
              id={`tooltip-${selectedForm?.type === "date" ? "dayLabel" : "hourLabel"}`}
              place="top"
              content={selectedForm?.type === "date" ? selectedForm?.dayFieldHelp : selectedForm?.hourFieldHelp}
            />
            <ReactTooltip
              id={`tooltip-${selectedForm?.type === "date" ? "monthLabel" : "minuteLabel"}`}
              place="top"
              content={selectedForm?.type === "date" ? selectedForm?.monthFieldHelp : selectedForm?.minuteFieldHelp}
            />
            <ReactTooltip
              id={`tooltip-${selectedForm?.type === "date" ? "yearLabel" : "secondLabel"}`}
              place="top"
              content={selectedForm?.type === "date" ? selectedForm?.yearFieldHelp : selectedForm?.secondFieldHelp}
            />
          </Form.Group>
          :
          <Form.Group>
            <ReactTooltip
              id="tooltip-1"
              place="top"
              content={selectedForm?.fieldHelp}
            />
            <ReactTooltip
              id="tooltip-2"
              place="top"
              content={selectedForm?.unitFieldHelp}
            />
          </Form.Group>
        }
      </div>
    </>
  );
};

export default CrfViewRightTop;
