import React from "react";
import RestrictionImage from "../image/Restriction/Restrict.png";
import NotFoundFormat from "../PageNotFound/NotFoundFormat";

const Restriction = () => {
  return (
    <>
      <NotFoundFormat
        SRC={RestrictionImage}
        MSG="The page your'are trying to access has restricted access."
        MSG1="Please refer to your system administrator"
      />
    </>
  );
};

export default Restriction;
