import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { usersOnboardingService } from "../../../../../../../../Services/usersOnboarding";
import { userServices } from "../../../../../../../../Services/userServices";
import StudySiteQueryDiscussionComponent from "./StudySiteQueryDiscussionComponent";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../../../NoDataFoundTable/NoDataFoundTable";
import { studyOnboardingService } from "../../../../../../../../Services/studyServices/studySitesServices";
import SplitPane from "react-split-pane";

const StudySiteQueryListComponent = ({
  onMouseDown,
  tableRef,
  isFullScreen,
  siteUniqueId,
  getStudySiteRowData,
}) => {
  const { studyId } = useParams();
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const [currentUserId, setCurrentUserId] = useState("");
  const [status, setStatus] = useState("");
  const [queryId, setQueryId] = useState("");
  const [queryGenerator, setQueryGenerator] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [queryList, setQueryList] = useState([]);
  const [EDCUser, setEDCUser] = useState([]);

  const getStudySiteQueryList = async () => {
    setTableLoader(true);
    const data = await studyOnboardingService.getStudySiteQueryList(
      siteUniqueId,
      studyId
    );
    setQueryList(data?.data || []);
    setTableLoader(false);
  };

  const getStudySiteQueryListData = async () => {
    const data = await studyOnboardingService.getStudySiteQueryList(
      siteUniqueId,
      studyId
    );
    setQueryList(data?.data || []);
  };

  const getEDCClientUser = async () => {
    let data = await usersOnboardingService.getEDCClientUser(studyId);
    setEDCUser(data?.data);
  };

  const currentDetails = async (e) => {
    let userData = await userServices.getCurrentUserDetails(e);
    setCurrentUserId(userData?.[0].user_id?.userId);
  };

  const getBackgroundColor = (status) => {
    switch (status) {
      case "open":
        return "#ff9b9b";
      case "cancel":
        return "#e2e0e0";
      case "closed":
        return "#c3ffc3";
      case "inprogress":
        return "#f5d9bd";
      default:
        return "#e2e0e0";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "red";
      case "cancel":
        return "grey";
      case "closed":
        return "green";
      case "inprogress":
        return "orange";
      default:
        return "black";
    }
  };

  const getDiscussionStatusColor = (status) => {
    switch (status) {
      case "answer":
        return "green";
      case "awaiting":
        return "grey";
    }
  };

  const openQueryDiscussion = (status, queryId, queryBy, subjectId) => {
    setStatus(status);
    getBackgroundColor(status);
    setQueryId(queryId);
    setQueryGenerator(queryBy);
    setSubjectId(subjectId);
  };

  const assignStudySiteQuery = async (studyUniqueId, queryId, assignedTo) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Assign this user for Site?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Assign it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTableLoader(true);
        let userData = {};
        userData.studyUniqueId = studyUniqueId;
        userData.assignedTo = assignedTo;
        userData.queryId = queryId;
        userData.studyId = studyId;
        userData.mode = StudyMode;
        let data = await studyOnboardingService.assignStudySiteQuery(userData);
        if (data?.statusCode === 200) {
          getStudySiteQueryList();
          setTableLoader(false);
        } else {
          setTableLoader(false);
        }
      }
    });
  };

  useEffect(() => {
    getEDCClientUser();
    getStudySiteQueryList();
    currentDetails();
    setStatus("");
    getBackgroundColor("");
    setQueryId("");
    setQueryGenerator("");
    setSubjectId("");
  }, [siteUniqueId]);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={status ? "70%" : "100%"}
      >
        {tableLoader ? (
          <SpinerDataLoader />
        ) : queryList?.length === 0 ? (
          <NoDataFoundTable MSG="Query not found" />
        ) : (
          <div className={`${isFullScreen ? "pdf_viwer_section_full" : ""}`}>
            <Table
              ref={tableRef}
              id="resizable-table"
              striped
              className="custom-table"
              hover
            >
              <thead className="fixed_property_header top-0">
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th
                    style={{ width: 80 }}
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Query ID
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Query By</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Subject</th>
                  <th
                    style={{ width: 150 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Assigned
                  </th>
                  <th
                    style={{ width: 150 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Generated Date | Time
                  </th>
                  <th
                    style={{ width: 80 }}
                    onMouseDown={(e) => onMouseDown(e, 5)}
                  >
                    Status
                  </th>
                  <th
                    style={{ width: 80 }}
                    onMouseDown={(e) => onMouseDown(e, 6)}
                  >
                    Response
                  </th>
                </tr>
              </thead>
              <tbody>
                {queryList?.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative CP ${
                      queryId === item.queryId && "selected-row"
                    }`}
                  >
                    <td
                      onClick={() =>
                        openQueryDiscussion(
                          item.status,
                          item.queryId,
                          item.queryBy,
                          item.subjectId
                        )
                      }
                      title="View Details"
                    >
                      {index + 1}
                    </td>
                    <td
                      onClick={() =>
                        openQueryDiscussion(
                          item.status,
                          item.queryId,
                          item.queryBy,
                          item.subjectId
                        )
                      }
                      title="View Details"
                    >
                      #{item.queryId}
                    </td>
                    <td
                      onClick={() =>
                        openQueryDiscussion(
                          item.status,
                          item.queryId,
                          item.queryBy,
                          item.subjectId
                        )
                      }
                      title="View Details"
                    >
                      {item.queyUserData}
                    </td>
                    <td
                      onClick={() =>
                        openQueryDiscussion(
                          item.status,
                          item.queryId,
                          item.queryBy,
                          item.subjectId
                        )
                      }
                      title="View Details"
                    >
                      {item.subject}
                    </td>
                    <td className="p-0">
                      <Form.Select
                        onChange={(e) =>
                          assignStudySiteQuery(
                            item.studyUniqueId,
                            item.queryId,
                            e.target.value
                          )
                        }
                        className="p-1 border-0 rounded-0"
                        style={{ backgroundColor: "transparent" }}
                        disabled={item.status != "open"}
                      >
                        <option>Select User</option>
                        {EDCUser?.map((data, key) => (
                          <option
                            key={key}
                            title={data?.fullName}
                            selected={data.userId === item.assignedTo}
                            value={data?.userId}
                          >
                            {data?.fullName}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td
                      onClick={() =>
                        openQueryDiscussion(
                          item.status,
                          item.queryId,
                          item.queryBy,
                          item.subjectId
                        )
                      }
                      title="View Details"
                    >
                      <Moment format="DD MMM YYYY | HH:mm:ss">
                        {item?.createdAt}
                      </Moment>
                    </td>
                    <td
                      onClick={() =>
                        openQueryDiscussion(
                          item.status,
                          item.queryId,
                          item.queryBy,
                          item.subjectId
                        )
                      }
                      title="View Details"
                    >
                      <div
                        className="text-capitalize"
                        style={{
                          color: getStatusColor(item.status),
                        }}
                      >
                        {item.status}
                      </div>
                    </td>
                    {item.queryBy === currentUserId ? (
                      <td
                        onClick={() =>
                          openQueryDiscussion(
                            item.status,
                            item.queryId,
                            item.queryBy,
                            item.subjectId
                          )
                        }
                        title="View Details"
                      >
                        <div
                          className="text-capitalize"
                          style={{
                            color: getDiscussionStatusColor(
                              item.senderAwaiting
                            ),
                          }}
                        >
                          {item.senderAwaiting}
                        </div>
                      </td>
                    ) : (
                      <td
                        onClick={() =>
                          openQueryDiscussion(
                            item.status,
                            item.queryId,
                            item.queryBy,
                            item.subjectId
                          )
                        }
                        title="View Details"
                      >
                        <div
                          className="text-capitalize"
                          style={{
                            color: getDiscussionStatusColor(
                              item.receiverAwaiting
                            ),
                          }}
                        >
                          {item.receiverAwaiting}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {status && queryId && queryList?.length > 0 && (
          <StudySiteQueryDiscussionComponent
            isFullScreen={isFullScreen}
            setStatus={setStatus}
            getBackgroundColor={getBackgroundColor}
            getStudySiteQueryList={getStudySiteQueryListData}
            siteUniqueId={siteUniqueId}
            status={status}
            queryId={queryId}
            queryGenerator={queryGenerator}
            currentUserId={currentUserId}
            subjectId={subjectId}
            StudyMode={StudyMode}
            studyId={studyId}
            getStudySiteRowData={getStudySiteRowData}
          />
        )}
      </SplitPane>
    </>
  );
};

export default StudySiteQueryListComponent;
