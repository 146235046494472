import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import RandomizeSideData from "./RandomizeSideData";
import RandomizeTableData from "./RandomizeTableData";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import { studyAccessManagement } from "../../../Services/studyAccessManagement";
import EnvelopeNewDesign from "../TrialDesign/EnvelopDesign/EnevelopNewDesign";
import RandomizeFilterData from "./RandomizeFilterData";
import UseToggle from "../../../Hooks/UseToggle";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import { ctmsServices } from "../../../Services/studyServices/ctmsServices";
import { studyOnboardingService } from "../../../Services/studyServices/studySubjectsServices";
import { Spinner } from "react-bootstrap";
const Randomize = ({ onMouseDown, tableRef, randomizerId }) => {
  const [updateType, setUpdateType] = useState("");
  const { toggle, setToggle } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [siteList, setSiteList] = useState([])
  const [randomizerList, setRandomizerList] = useState([])
  const [randomizeStatus, setRandomizeStatus] = useState("");
  const [loadData, setLoadData] = useState(false);
  const genderList = [
    { option: "Male" },
    { option: "Female" },
    { option: "Unknown" },
    { option: "Undifferentiated" },
    { option: "Other" },
  ]
  const { studyId } = useParams();
  const [isTableView, setIsTableView] = useState(false)

  const handleToggleView = () => {
    setIsTableView(!isTableView);
  };

  const handleAddNewLearning = () => {
    setUpdateType("add");
  };

  const findRandomizerStudyParameter = async () => {
    let data = await randomizerServices.findRandomizerStudyParameter(studyId);
    setRandomizeStatus(data?.data?.randomizeStatus);
  };

  const studySiteListData = async () => {
    let data = await studyAccessManagement.studySiteListData(studyId)
    setSiteList(data?.data)
  }

  const [factorData, setFactorData] = useState([])
  const [treatment, setTreatment] = useState([])
  const [columnId, setColumnId] = useState("")
  const getStudySubjectColumnForFactor = async () => {
    let data = await studyOnboardingService.getStudySubjectColumnForFactor(studyId)
    setFactorData(data?.data || [])
  }

  const getStudyTreatmentColumnData = async () => {
    let data = await ctmsServices.getStudyTreatmentColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort();
      setColumnId(finalData?.[0]._id);
    } else {
      setColumnId("");
    }
  };

  const getStudyTreatmentRowData = async () => {
    let data = await ctmsServices.getStudyTreatmentRowData(
      studyId,
      columnId
    );
    let initialData = Object.values(data?.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    setTreatment(finalData)
  };

  useEffect(() => {
    getStudyTreatmentRowData();
  }, [columnId]);

  const findRandomizedData = async () => {
    setLoadData(true)
    let data = await randomizerServices.findRandomizedData(studyId, randomizerId)
    setRandomizerList(data?.data || [])
    setLoadData(false)
  }

  const studyRandomizerAtOnce = async () => {
    setLoader(true)
    let userData = {}
    userData.randomizerId = randomizerId
    userData.siteList = siteList
    userData.studyId = studyId
    userData.factors = factorData
    userData.genderList = genderList
    userData.treatment = treatment
    let data = await randomizerServices.studyRandomizerAtOnce(userData)
    if (data?.statusCode === 200) {
      findRandomizedData()
    }
    setLoader(false)
  }

  useEffect(() => {
    findRandomizedData();
  }, [randomizerId])

  useEffect(() => {
    getStudyTreatmentColumnData();
    getStudySubjectColumnForFactor();
    findRandomizerStudyParameter();
    studySiteListData()
  }, [studyId])

  return (
    <>
      <div className="position-relative">
        <div className="p-1 hstack justify-content-between border-bottom">
          <div className="hstack gap-2 toolbar">
            {updateType ? (
              <i
                onClick={() => setUpdateType("")}
                title="Table Full Mode"
                className="fa-solid fa-table-list CP"
              ></i>
            ) : (
              loader ?
                <Spinner style={{ height: 15, width: 15, cursor: "no-drop" }} />
                :
                <i
                  onClick={randomizeStatus === "no" ? handleAddNewLearning : studyRandomizerAtOnce}
                  title={randomizeStatus === "no" ?  "Single Randomize" : "Randomize At once"}
                  className="fa-solid fa-shuffle CP"
                />
            )}
            <div className="vr"></div>
            <i
              onClick={setToggle}
              title="Filter"
              className="fa-solid fa-filter CP"
            ></i>
          </div>

          <button
            onClick={handleToggleView}
            className="m-0 CP bg-main   text-white hstack"
            title={isTableView ? "Switch to Grid View" : "Switch to Table View"}
            style={{ fontSize: 13 }}
          >
            <i className={`fa-solid ${isTableView ? "fa-toggle-on" : "fa-toggle-off"} me-2`}></i>
            {isTableView ? "Grid View" : "Table View"}
          </button>
        </div>
        {isTableView ? (
          <EnvelopeNewDesign />
        ) : (
          <SplitPane
            split="horizontal"
            style={{ height: "calc(100vh - 455px)" }}
            className="position-relative"
            defaultSize={"100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={updateType ? "25%" : "100%"}
            >
              {loadData ? (
                <SpinerDataLoader />
              ) : (
                updateType && (
                  <RandomizeSideData
                    handleAddNewLearning={handleAddNewLearning}
                    siteList={siteList}
                    factorData={factorData}
                  />
                )
              )}
              {loadData ? (
                <SpinerDataLoader />
              ) : (
                <RandomizeTableData
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  randomizerList={randomizerList}
                />
              )}
            </SplitPane>
          </SplitPane>
        )}
      </div>
      <RandomizeFilterData
        Show={!toggle}
        Hide={setToggle}
        Title={"Randomize Properties"}
      />
    </>
  );
};

export default Randomize;
