import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const supplimentaryServices = {
  getActiveVersions,
  getActiveClass,
  getActiveDomains,
  getActiveVariableMathods,
  getActiveVariableComments,
  getSTDMCodeList,

  // Client Value Lavel
  addNewClientValueLevel,
  getClientValueLevelData,
  deleteClientValueLevelData,
  updateClientValueLevelData,

  getSTDMList,
  getClientCodeList,
  getValueLevelVariablesList,
  getWithoutValueLevelVariablesList,
  getSupplimentalMathodsList,
  getSupplimentalCommentsList,
  getSupplimentalsList,
  getClientLevelCodeList,

  addNewSupplimentalMathod,
  updateSupplimentalMathods,
  changeSupplimentalMathodsStatus,
  deleteSupplimentalMathods,

  addNewSupplimentalComment,
  changeSupplimentalCommentsStatus,
  updateSupplimentalComments,
  deleteSupplimentalComments,

  addNewSupplimental,
  updateSupplimentals,
  deleteSupplimentals,
  getSTDMTerminologyCodeList,
  addNewClientCodeList,
  updateClientCodeList,
  deleteClientCodeList,
};

// Delete STDM Code
async function deleteClientCodeList(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteClientCodeList/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Client Code List
async function updateClientCodeList(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateClientCodeList`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New STDM Code
async function addNewClientCodeList(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewClientCodeList`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get STDM Code Data
async function getSTDMTerminologyCodeList(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSTDMTerminologyCodeList/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get STDM Code Data
async function getClientCodeList(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientCodeList/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete STDM Code
async function deleteSupplimentals(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteSupplimentals/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Client Code List
async function updateSupplimentals(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSupplimentals`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// add New Supplimental
async function addNewSupplimental(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewSupplimental`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Delete Sponser
async function deleteSupplimentalComments(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteSupplimentalComments/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Sponser
async function updateSupplimentalComments(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSupplimentalComments`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function changeSupplimentalCommentsStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}changeSupplimentalCommentsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add Project
async function addNewSupplimentalComment(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewSupplimentalComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Delete Project
async function deleteSupplimentalMathods(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteSupplimentalMathods/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Project Status
async function changeSupplimentalMathodsStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}changeSupplimentalMathodsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Project
async function updateSupplimentalMathods(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSupplimentalMathods`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add Project
async function addNewSupplimentalMathod(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewSupplimentalMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Client Level CodeList
async function getClientLevelCodeList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientLevelCodeList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalsList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSupplimentalsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalCommentsList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSupplimentalCommentsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// Get All project List for Table
async function getSupplimentalMathodsList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSupplimentalMathodsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Version
async function getActiveVersions() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveVersions`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Version
async function getActiveClass(versionId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveClass/${versionId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Domain
async function getActiveDomains(classId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveDomains/${classId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable Method
async function getActiveVariableMathods() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveVariableMathods`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable Comment
async function getActiveVariableComments() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveVariableComments`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get STDM Code List
async function getSTDMCodeList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSTDMCodeList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Client Value Level
async function addNewClientValueLevel(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewClientValueLevel`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Client Value Level Data
async function getClientValueLevelData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientValueLevelData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Client Value Level Data
async function deleteClientValueLevelData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteClientValueLevelData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Client Value Level Data
async function updateClientValueLevelData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateClientValueLevelData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get STDM List Data
async function getSTDMList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSTDMList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Value Level Variable List for Table
async function getValueLevelVariablesList(domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getValueLevelVariablesList/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Without Value Level Variable List for Table
async function getWithoutValueLevelVariablesList(domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getWithoutValueLevelVariablesList/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
