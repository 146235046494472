import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { studySettingControlServices } from "../../../../../../Services/studyServices/studySettingControlServices";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studyVisitsServices";

const GenerateStudyQuery = ({ Show, Hide, Title, studyUniqueId, Submit, getStudyVisitRowData }) => {
    const { studyId } = useParams()
    const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
    const [subjectList, setSubjectList] = useState([])
    const [messageList, setMessageList] = useState([])
    const [subject, setSubject] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [subjectType, setSubjectType] = useState("")
    const [message, setMessage] = useState("")
    const [messageType, setMessageType] = useState("")
    const [loader, setLoader] = useState(false)

    const getActiveQuerySubjectList = async () => {
        let data = await studySettingControlServices.getActiveQuerySubjectListByCategory("edc")
        setSubjectList(data?.data)
    }

    const getActiveQueryMessageList = async (subjectId) => {
        setSubjectId(subjectId)
        let data = await studySettingControlServices.getActiveQueryMessageList(subjectId)
        setMessageList(data?.data)
    }

    const generateStudyVisitQuery = async () => {
        let datas = {}
        setLoader(true)
        datas.studyId = studyId
        datas.studyUniqueId = studyUniqueId
        datas.query = message
        datas.studyMode = StudyMode
        datas.subject = subject
        datas.subjectId = subjectId
        let result = await studyOnboardingService.generateStudyVisitQuery(datas)
        if (result.statusCode === 200) {
            clearData()
            getStudyVisitRowData()
        } else {
            clearData()
            getStudyVisitRowData()
        }
    }

    const clearData = () => {
        setSubject("")
        setMessage("")
        setMessageList([])
        setSubjectType("")
        setMessageType("")
        setLoader(false)
        Hide()
    }

    useEffect(() => {
        getActiveQuerySubjectList();
    }, []);
    return (
        <>
            <Modal show={Show} onHide={clearData} placement="end">
                <Modal.Header closeButton>
                    <Modal.Title>{Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid className="p-0">
                        <Row className="mb-2">
                            <Form.Group as={Col} md={12} className="mb-2">
                                <Form.Label className="mb-1">
                                    <b>Query Subject</b> <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                    onChange={(e) => { setSubject(e.target.value); setSubjectType(e.target[e.target.selectedIndex].id); getActiveQueryMessageList(e.target[e.target.selectedIndex].title) }}
                                >
                                    <option value="">Select Subject</option>
                                    <option id="customSubject" value="">Custom Subject</option>
                                    {subjectList?.map((item, index) => (
                                        <option id="dynamicSubject" key={index} value={item?.subject} title={item?._id}>{item?.subject}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            {subjectType === "dynamicSubject" ?
                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-1">
                                        <b>Query Message</b> <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        onChange={(e) => { setMessage(e.target.value); setMessageType(e.target[e.target.selectedIndex].id); }}
                                    >
                                        <option value="">Select Message</option>
                                        <option id="customMessage" value="">Custom Message</option>
                                        {messageList?.map((item, index) => (
                                            <option id="dynamicMessage" key={index} value={item?.message}>{item?.message}</option>
                                        ))}
                                    </Form.Select>
                                    {messageType === "customMessage" &&
                                        <Form.Group as={Col} md={12} className="mt-2">
                                            <Form.Label className="mb-1">
                                                <b>Custom Query Message</b> <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Custom Query Message"
                                                onChange={(e) => setMessage(e.target.value)}
                                                value={message}
                                            />
                                        </Form.Group>
                                    }
                                </Form.Group>
                                : subjectType === "customSubject" &&
                                <>
                                    <Form.Group as={Col} md={12} className="mb-2">
                                        <Form.Label className="mb-1">
                                            <b>Custom Query Subject</b> <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Custom Query Subject"
                                            onChange={(e) => setSubject(e.target.value)}
                                            value={subject}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={12} className="mb-2">
                                        <Form.Label className="mb-1">
                                            <b>Custom Query Message</b> <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Custom Query Message"
                                            onChange={(e) => setMessage(e.target.value)}
                                            value={message}
                                        />
                                    </Form.Group>
                                </>
                            }
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end">
                        <button
                            disabled={!message || !subject || loader}
                            onClick={generateStudyVisitQuery}
                            className="updateProfile">
                            {loader ? <Spinner className="me-1" style={{ width: 15, height: 15 }} animation="border" /> :
                                <i className="fa-solid fa-clipboard-question me-1" ></i>
                            }
                            {Submit}</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GenerateStudyQuery;
