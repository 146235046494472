import React, { useEffect, useState } from "react";
import {
  FaCircleCheck,
  FaCircleXmark,
} from "react-icons/fa6";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Table, Offcanvas } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { usersOnboardingService } from "../../../../Services/usersOnboarding";
import { NoDataFoundTable, SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import Pagination from "../../../ETMF/Pagination/Pagination";
import FilterAuditTrailData from "./FilterAuditTrailData";

const AuditTrial = () => {
  const { studyId } = useParams();
  const [filtered, setFiltered] = useState(false);
  const [recentActivity, setRecentActivity] = useState([]);
  const [activityUser, setActivityUser] = useState([]);
  const [activityCategory, setActivityCategory] = useState([]);
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [activityBy, setActivityBy] = useState("");
  const [status, setStatus] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getStudyActivitiesByModule = async () => {
    setTableLoader(true);
    let data = await usersOnboardingService.getStudyActivitiesByModule(
      "studyUniqueId",
      studyId,
      "all"
    );
    setRecentActivity(data?.data || []);
    setTableLoader(false);
  };

  const recentStudyActivityDataForFilter = async () => {
    let data = await usersOnboardingService.recentStudyActivityDataForFilter(
      studyId
    );
    setActivityUser(data?.userList);
    setActivityCategory(data?.categoryList);
  };

  const filterRecentStudyActivityData = async () => {
    let userData = {};
    setTableLoader(true);
    userData.studyId = studyId;
    userData.category = category;
    userData.type = type;
    userData.activityBy = activityBy;
    userData.status = status;
    let data = await usersOnboardingService.filterRecentStudyActivityData(
      userData
    );
    if (data?.statusCode === 200) {
      setRecentActivity(data?.data || []);
      setFiltered(true);
      setTableLoader(false);
    } else {
      setTableLoader(false);
      setRecentActivity([]);
      setFiltered(true);
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = recentActivity?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(recentActivity?.length / itemsPerPage);
  const handleResetFilterData = () => {
    setCategory("");
    setType("");
    setActivityBy("");
    setStatus("");
    setFiltered(false);
    getStudyActivitiesByModule();
    handleClose();
  };

  useEffect(() => {
    getStudyActivitiesByModule();
    recentStudyActivityDataForFilter();
  }, []);

  return (
    <>
      <div className="p-1 hstack justify-content-between border-bottom">
        <div className="hstack gap-2 toolbar">
          <i
            onClick={handleShow}
            title="Filter"
            className="fa-solid fa-filter CP"
          />
          {filtered && (
            <i
              onClick={() => handleResetFilterData()}
              title="Clear Filter"
              className="fa-solid fa-filter-circle-xmark CP red"
            />
          )}
        </div>
      </div>
      <SplitPane
        split="vertical"
        className="position-relative"
        minSize={20}
        defaultSize="100%"
        style={{ height: "calc(100vh - 187px)", overflowX: "hidden" }}
      >
        {tableLoader ? (
          <SpinerDataLoader />
        ) : activityUser?.length > 0 || result?.length > 0 ? (
          <div>
            <Table
              id="resizable-table"
              striped
              className="custom-table"
              style={{ tableLayout: "auto" }}
            >
              <thead className="fixed_property_header">
                <tr>
                  <th style={{ width: 40 }} className="text-center">Sr. WDAEFRGTHYJUK</th>
                  <th className="text-center">Status</th>
                  <th>Type</th>
                  <th>Activities</th>
                  <th>Category</th>
                  <th>Mode</th>
                  <th>Activity By</th>
                  <th>Activity Date</th>
                  <th>Activity Time</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr className="relative">
                    <td className="text-center">{index + 1}.</td>
                    <td
                      title={item.status === "200" ? "Success" : "Failed"}
                      className="text-center"
                    >
                      {item.status === "200" ? (
                        <FaCircleCheck className="text-success" />
                      ) : (
                        <FaCircleXmark className="text-danger" />
                      )}
                    </td>
                    <td>
                      {item.type}
                    </td>
                    <td>{item.activities}</td>
                    <td className="text-capitalize">
                      {item.module === "workflowTask"
                        ? "Workflow Task"
                        : item.module}
                    </td>
                    <td>{item.mode}</td>
                    <td title={item.activityBy}>{item.activityByName}</td>
                    <td>
                      <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                    </td>
                    <td>
                      <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPage={totalPage}
                totalItems={recentActivity?.length}
              />
            </div>
          </div>
        ) : (
          <NoDataFoundTable MSG="Logs not found!" />
        )}
      </SplitPane>

      <Offcanvas
        className={show ? "" : "d-none"}
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <FilterAuditTrailData
            filterRecentStudyActivityData={filterRecentStudyActivityData}
            status={status}
            setStatus={setStatus}
            type={type}
            setType={setType}
            category={category}
            setCategory={setCategory}
            activityCategory={activityCategory}
            activityBy={activityBy}
            setActivityBy={setActivityBy}
            activityUser={activityUser}
            handleResetFilterData={handleResetFilterData}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AuditTrial;
