import React from "react";
import { CrfViewContextProvider } from "./UseCrfViewContext";
import { Provider } from "react-redux";
import Store from "../Redux/Store/Store";
import { ProjectLevelFormContextProvider } from "./ClientRoleContexts/ProjectLevelFormContext";
import { CrfEditorUpdateDataContextProvider } from "./ClientRoleContexts/CrfEditorUpdateDataContext";
import { StudyVisitContextProvider } from "./UseStudyVisitContext";
import { StudySubjectContextProvider } from "./UseStudySubjectContext";
import { AppProvider } from "../Components/CommonComponents/ResizeTable/ResizeTable";

const ContextProviders = ({ children }) => {
  return (
    <>
      <Provider store={Store}>
        <ProjectLevelFormContextProvider>
          <CrfEditorUpdateDataContextProvider>
            <StudyVisitContextProvider>
              <StudySubjectContextProvider>
                <AppProvider>
                  <CrfViewContextProvider>{children}</CrfViewContextProvider>
                </AppProvider>
              </StudySubjectContextProvider>
            </StudyVisitContextProvider>
          </CrfEditorUpdateDataContextProvider>
        </ProjectLevelFormContextProvider>
      </Provider>
    </>
  );
};

export default ContextProviders;
