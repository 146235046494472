import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const WhereClausesTableData = ({
  whereClauseData,
  tableRef,
  onMouseDown,
  getWhereClauseData,
  studyId
}) => {
  const deleteStudyValueLevelData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Where Clause!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyValueLevelData(id, studyId);
        if (results?.statusCode === 200) {
          getWhereClauseData();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = whereClauseData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(whereClauseData?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          tableRef={tableRef}
          id="resizable-table"
          className="text-nowrap mb-1 custom-table"
          hover
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Dataset</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Variable</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Comparator</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Value</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 60 }}>
                <div className="text-center">Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <tr key={index} className="position-relative CP">
                <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                <td>{item.whereclauseId}</td>
                <td>{item.domain}</td>
                <td>{item.variable}</td>
                <td>{item.Comparator}</td>
                <td>{item.value}</td>
                <td>
                  <button
                    className="text-white border-danger bg-danger border-0"
                    style={{fontSize: 10, borderRadius: 3}}
                    title="Delete Supplementary"
                    onClick={() => deleteStudyValueLevelData(item?._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={whereClauseData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default WhereClausesTableData;
