import React from "react";
import { Form, Offcanvas, Table } from "react-bootstrap";

const TemplateColumnProperties = ({
  Show,
  Hide,
  Title,
  tableRef,
  onMouseDown,
  columnList,
  setColumnList,
  checkboxValues,
  setCheckboxValues,
  studyTabs,
  setStudyTabs,
  refreshDerivedDatasets,
}) => {
  const handleSelectAllCheckboxes = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckboxValues = isChecked
      ? columnList.map((item) => item.name)
      : [];

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = columnList.map((item) => ({
      ...item,
      isChecked,
    }));

    setColumnList(updatedForms);
  };

  const handleCheckboxChange = (name, isChecked) => {
    const updatedCheckboxValues = isChecked
      ? [...checkboxValues, name]
      : checkboxValues.filter((item) => item !== name);

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = columnList.map((item) =>
      item.name === name ? { ...item, isChecked } : item
    );

    setColumnList(updatedForms);
  };

  let hideTableProperties =
    checkboxValues?.length === 0 || checkboxValues?.length === undefined
      ? []
      : checkboxValues;

  const updateDerivationColumnData = async () => {
    let data = studyTabs?.find((item) => item.status === "active");
    let objectData = data?.ids;
    let newData = {
      _id: objectData?._id,
      label: objectData?.label,
      column: objectData?.column,
      showColumn: checkboxValues,
      row: objectData?.row,
    };

    studyTabs.forEach((element) => {
      if (element.studyTabId === data?.studyTabId) {
        element.ids = newData;
      }
    });
    localStorage.setItem("studyTabs", JSON.stringify(studyTabs));
    setStudyTabs(studyTabs || []);
    Hide();
    refreshDerivedDatasets();
  };

  return (
    <>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 550 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Table
              tableRef={tableRef}
              id="resizable-table"
              className="text-nowrap custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    <Form.Check
                      type="checkbox"
                      onChange={(e) => handleSelectAllCheckboxes(e)}
                      checked={
                        columnList?.length === 0
                          ? false
                          : hideTableProperties?.length === columnList?.length
                      }
                    />
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Label</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Type</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Permission</th>
                </tr>
              </thead>
              <tbody>
                {columnList?.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative cursor-pointer`}
                  >
                    <td className="text-center">
                      <Form.Check
                        type="checkbox"
                        name={item?.name}
                        id={item?.name}
                        checked={hideTableProperties?.includes(item?.name)}
                        onChange={(e) =>
                          handleCheckboxChange(e.target.name, e.target.checked)
                        }
                      />
                    </td>
                    <td title={item.name}>{item?.name}</td>
                    <td title={item.label}>{item?.label}</td>
                    <td title={item.type} className="text-capitalize">
                      {item?.type}
                    </td>
                    <td title={item.check} className="text-capitalize">
                      {item?.check}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={updateDerivationColumnData}
              className="border p-1 px-2 updateProfile"
            >
              Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TemplateColumnProperties;
