import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import Multiselect from "multiselect-react-dropdown";
import SplitPane from "react-split-pane";

const RetainTools = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [retain, setRetain] = useState(false);
  let [retainColumns, setRetainColumns] = useState([]);
  let [retainCustoms, setRetainCustoms] = useState("");
  let [templateIds, setTemplateIds] = useState([]);
  let [columns, setColumns] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if(derivationData?.primaryDataset || derivationData?.secondaryDataType || derivationData?.cellDataset){
      setTemplateIds([
        {templateId: derivationData?.primaryDataset || ""}, 
        {templateId: derivationData?.secondaryDataset || ""}, 
        {templateId: derivationData?.cellDataset || ""}, 
      ])
    }
    if (derivationData?.retainColumns) {
      setRetain(true);
      setRetainColumns(
        derivationData?.retainColumns?.split("||")?.map((key) => ({
          label: key.trim(),
          value: key.trim(),
        }))
      );
    }
    if (derivationData?.retainCustoms) {
      setRetain(true);
      setRetainCustoms(derivationData?.retainCustoms);
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(derivationData?.primaryDataset, derivationData?.primaryDataType)
    }
  }

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    if (retain) {
      userData.retainColumns = retainColumns
        ?.map((item) => item.label)
        .join("||");
      userData.retainCustoms = retainCustoms;
    } else {
      userData.retainColumns = "";
      userData.retainCustoms = "";
    }
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  useEffect(() => {
    setRetain(true)
  }, [retainColumns, retainCustoms]);

  return (
    <>
      <SplitPane
        split="horizontal"
        className="position-relative"
        defaultSize="100%"
      >
        <div className="py-2 px-3">
          <div className="p-3 rounded" style={{ border: "1px dashed #dee2e6" }}>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Dataset Column</Form.Label>
              <Multiselect
                displayValue="label"
                options={((primaryDataType === "Merge" || primaryDataType === "Append") ? uniqueColumnKey : columns)?.map((keys, key) => ({
                  label: keys?.name,
                  value: keys?.name,
                }))}
                placeholder="Select Dataset Column"
                selectedValues={retainColumns || []}
                onSelect={(selectedList) => setRetainColumns(selectedList)}
                onRemove={(removedList) => setRetainColumns(removedList)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Custom Column Name
              </Form.Label>
              <Form.Control
                value={retainCustoms}
                as="textarea"
                onChange={(e) => setRetainCustoms(e.target.value)}
                placeholder="Enter Custom Column Name Use Seperator ||"
              />
            </Form.Group>
          </div>
          <div className="text-end p-2">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default RetainTools;
