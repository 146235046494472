import React from 'react'
import ADRGPdfDataDetails from './CDRGPdfDataDetails'

const CDRGParentFile = () => {
  return (
    <>
      <ADRGPdfDataDetails />
    </>
  )
}

export default CDRGParentFile
