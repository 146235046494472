import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import Multiselect from "multiselect-react-dropdown";
import SplitPane from "react-split-pane";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const MergeConfigurationToolsTab = ({
  studyId,
  crfDatasets,
  derivationId,
  type,
  getDomainDerivationList,
  derivedData,
  datasetDeomain,
}) => {
  let [primaryKeys, setPrimaryKeys] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");
  let [primaryDataset, setPrimaryDataset] = useState("");
  let [secondaryDataType, setSecondaryDataType] = useState("");
  let [secondaryDataset, setSecondaryDataset] = useState("");
  let [procedureType, setProcedureType] = useState("");
  let [label, setLabel] = useState("");
  let [cellDatasetName, setCellDatasetName] = useState("");
  let [selectedOptions, setSelectedOptions] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);
  let [statements, setStatements] = useState([]);
  let [retain, setRetain] = useState(false);
  let [retainColumns, setRetainColumns] = useState([]);
  let [retainCustoms, setRetainCustoms] = useState("");
  let [keep, setKeep] = useState(false);
  let [keepColumns, setKeepColumns] = useState([]);
  let [keepCustoms, setKeepCustoms] = useState("");
  let [drop, setDrop] = useState(false);
  let [dropColumns, setDropColumns] = useState([]);
  let [dropCustoms, setDropCustoms] = useState("");
  let [statementString, setStatementString] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    setPrimaryDataType(derivationData?.primaryDataType);
    setPrimaryDataset(derivationData?.primaryDataset);
    setSecondaryDataType(derivationData?.secondaryDataType);
    setSecondaryDataset(derivationData?.secondaryDataset);
    let primaryKey = derivationData?.primaryKey || [];
    setSelectedOptions(
      primaryKey?.map((item) => ({
        label: item,
        value: item,
      }))
    );
    setProcedureType(derivationData?.procedureType);
    setLabel(derivationData?.label);
    setCellDatasetName(derivationData?.cellDataset);

    if (
      derivationData?.primaryDataset ||
      derivationData?.secondaryDataType ||
      derivationData?.cellDataset
    ) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ]);
    }
    if (
      derivationData?.keepColumns?.length > 0 ||
      derivationData?.keepCustoms
    ) {
      setKeepColumns(derivationData?.keepColumns || []);
      setKeepCustoms(derivationData?.keepCustoms || "");
      setKeep(true);
    } else {
      setKeepColumns([]);
      setKeepCustoms("");
      setKeep(false);
    }
    if (
      derivationData?.dropColumns?.length > 0 ||
      derivationData?.dropCustoms
    ) {
      setDropColumns(derivationData?.dropColumns || []);
      setDropCustoms(derivationData?.dropCustoms || "");
      setDrop(true);
    } else {
      setDropColumns([]);
      setDropCustoms("");
      setDrop(false);
    }
    if (derivationData?.retainColumns) {
      setRetain(true);
      setRetainColumns(
        derivationData?.retainColumns?.split("||")?.map((key) => ({
          label: key.trim(),
          value: key.trim(),
        }))
      );
    }
    if (derivationData?.retainCustoms) {
      setRetainCustoms(derivationData?.retainCustoms);
      setRetain(true);
    }
    setStatements(derivationData?.statements);
  };

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.primaryDataType = primaryDataType;
    userData.primaryDataset = primaryDataset;
    userData.secondaryDataType = secondaryDataType;
    userData.secondaryDataset = secondaryDataset;
    userData.primaryKey = selectedOptions?.map((item) => item.value);
    userData.label = label;
    userData.cellDataset = cellDatasetName;
    userData.procedureType = type === "Append" ? "append" : procedureType;
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const updateTemplateId = (templateId) => {
    let checkId = templateIds?.some((item) => item.templateId === templateId);
    if (checkId) {
      setTemplateIds((preDerivation) =>
        preDerivation.map((item) =>
          item.templateId === templateId
            ? {
              ...item,
              templateId: templateId,
            }
            : item
        )
      );
    } else {
      if (templateId) {
        setTemplateIds((prevItems) => [
          ...prevItems,
          { templateId: templateId },
        ]);
      }
    }
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setPrimaryKeys(data?.data || []);
  };

  const conditionString = () => {
    let result = "";
    if (keep) {
      let keepColumn = keepColumns?.length
        ? keepColumns.map((k) => k.label).join("', '")
        : "";
      let keepCustom = keepCustoms
        ? keepCustoms
          .split(" || ")
          .map((item) => item.trim())
          .join("', '")
        : "";

      if (keepColumn || keepCustom) {
        result += `KEEP COLUMN = ['${[keepColumn, keepCustom]
          .filter(Boolean)
          .join("', '")}'] \n`;
      }
    }
    if (drop) {
      let dropColumn = dropColumns?.length
        ? dropColumns.map((k) => k.label).join("', '")
        : "";
      let dropCustom = dropCustoms
        ? dropCustoms
          .split(" || ")
          .map((item) => item.trim())
          .join("', '")
        : "";

      if (dropColumn || dropCustom) {
        result += `DROP COLUMN = ['${[dropColumn, dropCustom]
          .filter(Boolean)
          .join("', '")}'] \n`;
      }
    }
    if (retain) {
      let retainColumn = retainColumns?.length
        ? retainColumns.map((item) => item.label).join(", ")
        : "";
      let retainCustom = retainCustoms
        ? retainCustoms
          .split("||")
          .map((item) => item.trim())
          .join(", ")
        : "";

      let retainValues = [retainColumn, retainCustom]
        .filter(Boolean)
        .join(", ");

      if (retainValues) {
        result += `RETAIN = ['${retainValues}'] \n`;
      }
    }
    statements?.forEach((item) => {
      if (item.type === "if") {
        const keys = item.keys.map((k) => k.label).join("', '");
        if (item.keyType === "column") {
          result += `IF ${item.key} = ${item.value} \n`;
        } else {
          result += `IF ${item.keyType} = ${`['${keys}']`
            } \n`;
        }
      } else if (item.type === "elseif") {
        const keys = item.keys.map((k) => k.label).join("', '");
        if (item.keyType === "column") {
          result += `ELSEIF ${item.key} = ${item.value} \n`;
        } else {
          result += `ELSEIF ${item.keyType} = ${`['${keys}']`
            } \n`;
        }
      } else if (item.type === "then") {
        result += `THENDO ${item.columnName} = '${item.value}' \n`;
      } else if (item.type === "else") {
        result += `ELSE ${item.columnName} = '${item.value}'\n`;
      } else if (item.type === "end") {
        result += `ENDDO \n`;
      }
    });
    setStatementString(result);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  useEffect(() => {
    if (statements?.length > 0 || keep || drop || retain) {
      conditionString();
    }
  }, [statements]);

  return (
    <>
      <SplitPane
        split="vertical"
        className="position-relative"
        defaultSize="70%"
      >
        <div className="py-2 px-3">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 fw-bold">
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </Form.Group>
          <div
            className="py-2 px-3 mb-2 rounded position-relative"
            style={{ border: "1px dashed #dee2e6" }}
          >
            <Form.Label
              className="m-0 fw-bold position-absolute"
              style={{ top: -12, left: 10 }}
            >
              Dataset Configuration
            </Form.Label>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label className="mb-1 fw-bold">
                  Primary Dataset <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="mb-1"
                  onChange={(e) => setPrimaryDataType(e.target.value)}
                >
                  <option value="">Select Dataset Type</option>
                  <option selected={primaryDataType === "crf"} value="crf">
                    Primary CRF Datasets
                  </option>
                  <option
                    selected={primaryDataType === "derivation"}
                    value="derivation"
                  >
                    Derivation Datasets
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6">
                {primaryDataType === "crf" && (
                  <>
                    <Form.Label className="mb-1 fw-bold">
                      Primary CRF Dataset Name{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPrimaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select CRF Data</option>
                      {crfDatasets?.map((item, index) => (
                        <option
                          selected={primaryDataset === item.uniqueId}
                          key={index}
                          value={item?.uniqueId}
                        >
                          {item.datasetName}
                        </option>
                      ))}
                    </Form.Select>
                  </>
                )}
                {primaryDataType === "derivation" && (
                  <>
                    <Form.Label className="mb-1 fw-bold">
                      Derivation Dataset Name{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setPrimaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select Derivation Data</option>
                      {derivedData?.map(
                        (item, index) =>
                          item.logStatus === 200 && (
                            <option
                              selected={primaryDataset === item.id}
                              key={index}
                              value={item?.id}
                            >
                              {item.label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  Secondary Dataset {type === "Merge" && <span className="text-danger">*</span>}
                </Form.Label>
                <Form.Select
                  className="mb-1"
                  onChange={(e) => setSecondaryDataType(e.target.value)}
                >
                  <option value="">Select Dataset Type</option>
                  <option selected={secondaryDataType === "crf"} value="crf">
                    CRF Datasets
                  </option>
                  <option
                    selected={secondaryDataType === "derivation"}
                    value="derivation"
                  >
                    Derivation Datasets
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-2">
                {secondaryDataType === "crf" && (
                  <>
                    <Form.Label className="mb-1 fw-bold">
                      Secondary CRF Dataset Name{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setSecondaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select CRF Data</option>
                      {crfDatasets?.map((item, index) => (
                        <option
                          selected={secondaryDataset === item.uniqueId}
                          key={index}
                          value={item?.uniqueId}
                        >
                          {item.datasetName}
                        </option>
                      ))}
                    </Form.Select>
                  </>
                )}
                {secondaryDataType === "derivation" && (
                  <>
                    <Form.Label className="mb-1 fw-bold">
                      Secondary Derivation Dataset Name{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setSecondaryDataset(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select Derivation Data</option>
                      {derivedData?.map(
                        (item, index) =>
                          item.logStatus === 200 && (
                            <option
                              selected={secondaryDataset === item.id}
                              key={index}
                              value={item?.id}
                            >
                              {item.label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="12" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  Cell Dataset Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setCellDatasetName(e.target.value);
                    updateTemplateId(e.target.value);
                  }}
                  className="mb-1"
                >
                  <option value="">Select Cell Dataset Data</option>
                  {datasetDeomain?.map((item, index) => (
                    <option
                      selected={cellDatasetName === item._id}
                      key={index}
                      value={item?._id}
                    >
                      {item.dataset}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            {type === "Merge" &&
              <>
                <Form.Group className="mb-2 w-100">
                  <Form.Label className="mb-1 fw-bold">
                    Primary Key <span className="text-danger">*</span>
                  </Form.Label>
                  {primaryKeys?.length > 0 ? (
                    <Multiselect
                      displayValue="label"
                      options={primaryKeys.map((keys) => ({
                        label: keys?.name,
                        value: keys?.name,
                      }))}
                      selectedValues={selectedOptions}
                      onSelect={(selectedList, selectedItem) => {
                        setSelectedOptions(selectedList);
                      }}
                      onRemove={(selectedList, removedItem) => {
                        setSelectedOptions(selectedList);
                      }}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Select Primary Key"
                      value={selectedOptions?.map((item) => item.value)?.join(", ")}
                    />
                  )}
                </Form.Group>
                <Form.Group className="mb-2 w-100">
                  <Form.Label className="mb-1 fw-bold">
                    Merge Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select onChange={(e) => setProcedureType(e.target.value)}>
                    <option value="">Select Merge Type</option>
                    <option selected={procedureType === "inner"} value="inner">
                      Inner Join
                    </option>
                    <option selected={procedureType === "outer"} value="outer">
                      Outer Join
                    </option>
                    <option selected={procedureType === "left"} value="left">
                      Left Join
                    </option>
                    <option selected={procedureType === "right"} value="right">
                      Right Join
                    </option>
                    <option selected={procedureType === "cross"} value="cross">
                      Cross Join
                    </option>
                  </Form.Select>
                </Form.Group>
              </>
            }
          </div>
          <div className="text-end p-2">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </div>
        <div className="overflow-hidden h-100">
          <SyntaxHighlighter
            language="javascript"
            style={dracula}
            className="h-100"
          >
            {(statements?.length > 0 || keep || drop || retain) &&
              statementString}
          </SyntaxHighlighter>
        </div>
      </SplitPane>
    </>
  );
};

export default MergeConfigurationToolsTab;
