import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import Multiselect from "multiselect-react-dropdown";
import SplitPane from "react-split-pane";

const KeepTools = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  let [keep, setKeep] = useState(false);
  let [keepColumns, setKeepColumns] = useState([]);
  let [keepCustoms, setKeepCustoms] = useState("");
  let [templateIds, setTemplateIds] = useState([]);
  let [columns, setColumns] = useState([]);
  let [primaryDataType, setPrimaryDataType] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if (derivationData?.primaryDataset || derivationData?.secondaryDataType || derivationData?.cellDataset) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ])
    }
    if (
      derivationData?.keepColumns?.length > 0 ||
      derivationData?.keepCustoms
    ) {
      setKeepColumns(derivationData?.keepColumns || []);
      setKeepCustoms(derivationData?.keepCustoms || "");
      setKeep(true);
    } else {
      setKeepColumns([]);
      setKeepCustoms("");
      setKeep(false);
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(derivationData?.primaryDataset, derivationData?.primaryDataType)
    }
  };

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    if (keep) {
      userData.keepColumns = keepColumns;
      userData.keepCustoms = keepCustoms;
    } else {
      userData.keepColumns = [];
      userData.keepCustoms = "";
    }
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  useEffect(() => {
    setKeep(true)
  }, [keepColumns, keepCustoms]);

  return (
    <>
      <SplitPane
        split="horizontal"
        className="position-relative"
        defaultSize="100%"
      >
        <div className="py-2 px-3">
          <div className="p-3 rounded" style={{ border: "1px dashed #dee2e6" }}>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Dataset Column</Form.Label>
              <Multiselect
                displayValue="label"
                options={((primaryDataType === "Merge" || primaryDataType === "Append") ? uniqueColumnKey : columns)?.map((keys, key) => ({
                  label: keys?.name,
                  value: keys?.name,
                }))}
                placeholder="Select Dataset Column"
                selectedValues={keepColumns || []}
                onSelect={(selectedList) => setKeepColumns(selectedList)}
                onRemove={(removedList) => setKeepColumns(removedList)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Custom Column Name
              </Form.Label>
              <Form.Control
                value={keepCustoms}
                as="textarea"
                onChange={(e) => setKeepCustoms(e.target.value)}
                placeholder="Enter Custom Column Name Use Seperator ||"
              />
            </Form.Group>
          </div>
          <div className="text-end p-2">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default KeepTools;
