import React from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { Form, Offcanvas, Table } from "react-bootstrap";

const RandomizedRequestButtonData = ({ Show, Hide }) => {
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} style={{ width: 400 }} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Request For Unblinding</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div>
            <Form.Group className="p-2 mb-0">
              <Form.Label style={{ fontWeight: 500 }}>Remarks:</Form.Label>
              <Form.Control as="textarea" rows={4} placeholder="Add Remarks" />
            </Form.Group>
            <div className="p-2 hstack justify-content-end gap-2 position-sticky bottom-0">
              <button
                className="updateProfile"
                style={{ width: 93, fontSize: "14px" }}
              >
                Request
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};

export default RandomizedRequestButtonData;
