import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { studyOnboardingService } from "../../../Services/studyServices/studySitesServices";

const CTMSTableProperty = ({
    Show,
    Hide,
    Title,
    allArray,
    selectedNames,
    setSelectedNames,
    studyDynamicColumn,
    studyId,
    studyDynamicColumnID,
    getStudyTreatmentColumnData,
    getStudyTreatmentRowData,
    viewTypeData,
}) => {
    const [selectAll, setSelectAll] = useState(false);
    let totalColumnLength = allArray?.length + studyDynamicColumn?.length;

    const handleCheckboxChange = (name) => {
        let updatedNames;
        if (selectedNames.includes(name)) {
            updatedNames = selectedNames.filter(
                (selectedName) => selectedName !== name
            );
        } else {
            updatedNames = [...selectedNames, name];
        }
        setSelectedNames(updatedNames);
        if (totalColumnLength === updatedNames?.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };

    const handleSelectAll = () => {
        if (!selectAll) {
            const allNames = studyDynamicColumn?.map(({ name }) => name);
            setSelectedNames([...allNames, ...allArray]);
        } else {
            setSelectedNames([]);
        }
        setSelectAll(!selectAll);
    };

    const handleSubmitTableProperty = async () => {
        let datas = {};
        datas.id = studyId;
        datas.category = "studyTreatment";
        datas.columnId = studyDynamicColumnID;
        datas.columnData = selectedNames;
        let data = await studyOnboardingService.addTableProperty(datas);
        if (data.statusCode === 200) {
            Hide();
            getStudyTreatmentColumnData();
            getStudyTreatmentRowData();
        }
    };

    return (
        <>
            <Offcanvas show={Show} onHide={Hide} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{Title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex align-items-center">
                        <Form.Check
                            type="checkbox"
                            checked={selectAll || totalColumnLength === selectedNames?.length}
                            onChange={handleSelectAll}
                            label="Select All"
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Check
                            type="checkbox"
                            name="Name"
                            checked={selectedNames?.includes("Name")}
                            onChange={() => handleCheckboxChange("Name")}
                            id="edit_label"
                            label="Name"
                        />
                    </div>
                    {studyDynamicColumn &&
                        studyDynamicColumn.map(({ name }, i) => (
                            <div key={i} className="d-flex align-items-center">
                                <Form.Check
                                    type="checkbox"
                                    name={name}
                                    checked={selectedNames?.includes(name)}
                                    onChange={() => handleCheckboxChange(name)}
                                    label={name}
                                />
                            </div>
                        ))}

                    <div className="border-top text-end my-2 pt-2">
                        <button
                            onClick={() => handleSubmitTableProperty()}
                            className="submitbutton save__all__data_action"
                        >
                            <FaCheck /> Apply
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default CTMSTableProperty;
