import React from "react";
import { Form, Offcanvas } from "react-bootstrap";

const RiskAreaFilterData = ({ Show, Hide, Title, studyId }) => {
  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div
            onClick={Hide}
            className="close_modal text-white CP"
            title="Close"
          >
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold" style={{ fontSize: 13 }}>
              Select Domain<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select>
              <option>Domain 1</option>
              <option>Domain 2</option>
              <option>Domain 3</option>
              <option>Domain 4</option>
            </Form.Select>
          </Form.Group>
          <div className="justify-content-end my-2 pt-2 d-flex gap-2">
            <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13 submitbutton">
              Apply
            </button>
            <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13 submitbutton">
              Reset
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
export default RiskAreaFilterData;
