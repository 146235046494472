import React from "react";
import { InputGroup, Form } from "react-bootstrap";

const Pagination = ({
  itemsPerPage,
  setItemsPerPage,
  pageNumber,
  setPageNumber,
  totalPage,
  totalItems,
}) => {
  return (
    <div className="p-2 add_sponsor_field hstack justify-content-between bg-white">
      {totalItems > itemsPerPage && (
        <div className="d-flex gap-2">
          <div className="inputforSecelct">
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                RESULTS/ PAGE
              </InputGroup.Text>
              <Form.Select
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setPageNumber(1);
                }}
                aria-label="Default select example"
                style={{ padding: "3px 30px 4px 10px" }}
              >
                <option selected={itemsPerPage === 5} value={5}>
                  5
                </option>
                <option selected={itemsPerPage === 10} value={10}>
                  10
                </option>
                <option selected={itemsPerPage === 15} value={15}>
                  15
                </option>
                <option selected={itemsPerPage === 25} value={25}>
                  25
                </option>
                <option selected={itemsPerPage === 50} value={50}>
                  50
                </option>
                <option selected={itemsPerPage === 100} value={100}>
                  100
                </option>
                <option selected={itemsPerPage === 500} value={500}>
                  500
                </option>
              </Form.Select>
            </InputGroup>
          </div>
          <div className="inputforSecelct">
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">PAGE</InputGroup.Text>
              <Form.Control
                type="number"
                onChange={(e) => setPageNumber(Number(e.target.value))}
                value={pageNumber}
                required
                style={{ width: 65, padding: 3 }}
                min={1}
                max={totalPage}
              />
              <InputGroup.Text id="inputGroupPrepend">
                / {totalPage}
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      )}
      {totalItems > 0 && (
        <div style={{ fontSize: 13 }} className="text-nowrap">
          <b>Total Rows: {totalItems}</b>
        </div>
      )}
    </div>
  );
};

export default Pagination;
