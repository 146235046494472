import React from "react";
import { Table } from "react-bootstrap";
import { NoDataFoundTable } from "../../../../../NoDataFoundTable/NoDataFoundTable";

const SubjectMetadata = ({ studyDynamicColumn }) => {
  const genderList = [
    { value: "Male" },
    { value: "Female" },
    { value: "Unknown" },
    { value: "Undifferentiated" },
    { value: "Other" },
  ]
  return (
    <>
      {studyDynamicColumn?.length > 0 ?
        <Table className="m-0 custom-table" bordered>
          <thead>
            <tr>
              <th style={{ width: 45 }}>S No.</th>
              <th>Column</th>
              <th>Value</th>
              <th>Type</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Gender</td>
              <td>gender</td>
              <td>text</td>
              <td style={{ whiteSpace: "normal" }}>{genderList?.map((option, i) => option.value).join(", ")}</td>
            </tr>
            {studyDynamicColumn?.map((item, index) => (
              <tr>
                <td>{index + 2}</td>
                <td>{item.name}</td>
                <td>{item.columnValue}</td>
                <td>{item.types}</td>
                <td style={{ whiteSpace: "normal" }}>{item.options?.map((option, i) => option.option).join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        :
        <NoDataFoundTable MSG="Subject Column not found" />
      }
    </>
  );
};

export default SubjectMetadata;
