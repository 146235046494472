import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";

const LegacyPopup = ({ Show, Hide, Title, studyId, rowIndex, columnKey, updatedObject, updatedData, setUpdatedData, getStudyDataTemplateEntryData, fieldType, uniqueId }) => {
  const [date, setDate] = useState({});
  const [time, setTime] = useState({});
  const [loader, setLoader] = useState(false);

  const hours = Array.from({ length: 24 }, (_, i) => i + 1);
  const minuteSecond = Array.from({ length: 60 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "UNK", 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1969 }, (_, i) => 1970 + i).reverse();

  const handleDateChange = (day, month, year) => {
    setDate({
      day: day,
      month: month,
      year: year,
    })
    let UpdateDate = `${day}-${month}-${year}`
    updatedObject[columnKey] = UpdateDate;
    const updatedUserData = {
      ...updatedData,
      [rowIndex]: { ...updatedObject },
    };
    setUpdatedData(updatedUserData);
  };

  const handleTimeChange = (hour, minute, second) => {
    setTime({
      hour: hour,
      minute: minute,
      second: second,
    })
    let UpdateTime = `${hour}:${minute}:${second}`
    updatedObject[columnKey] = UpdateTime;
    const updatedUserData = {
      ...updatedData,
      [rowIndex]: { ...updatedObject },
    };
    setUpdatedData(updatedUserData);
  };

  const handleBlur = async () => {
    setLoader(true)
    const userData = {
      uniqueId: uniqueId,
      studyId: studyId,
      rowData: updatedObject,
    };
    const data = await cdiscServices.updateStudyDataEntryData(userData);
    if (data?.statusCode === 200) {
      getStudyDataTemplateEntryData(studyId, uniqueId);
      clearFilterData()
    }
    setLoader(false)
  }

  const clearFilterData = () => {
    Hide();
  };

  useEffect(() => {
    let findData = updatedObject[columnKey]
    let dateArray = findData?.split("-")
    let timeArray = findData?.split(":")
    if (fieldType === "date") {
      let dateData = {
        day: dateArray?.[0] || "UNK",
        month: dateArray?.[1] || "UNK",
        year: dateArray?.[2] || "UNK",
      }
      setDate(dateData);
    }
    if (fieldType === "time") {
      let dateData = {
        hour: timeArray?.[0] || "UNK",
        minute: timeArray?.[1] || "UNK",
        second: timeArray?.[2] || "UNK",
      }
      setTime(dateData);
    }
  }, [updatedObject, columnKey]);

  return (
    <>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 400 }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="pt-2 px-3 overflow-auto"
            style={{ height: "calc(100vh - 84px)" }}
          >
            {fieldType === "date" &&
              <div className="hstack gap-2">
                <Form.Group className="mb-2 position-relative">
                  <Form.Label className="fw-bold mb-1">Day</Form.Label>
                  <Form.Select
                    onChange={(e) => handleDateChange(e.target.value, date?.month, date?.year)}
                  >
                    <option>Select Day</option>
                    <option selected={"UNK" === date?.day} value={"UNK"}>UNK</option>
                    {days.map((day) => (
                      <option selected={day === Number(date?.day)} key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2 position-relative">
                  <Form.Label className="fw-bold mb-1">Month</Form.Label>
                  <Form.Select
                    onChange={(e) => handleDateChange(date?.day, e.target.value, date?.year)}
                  >
                    <option>Select Month</option>
                    {months.map((month, index) => (
                      <option selected={month === date?.month} key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2 position-relative">
                  <Form.Label className="fw-bold mb-1">Year</Form.Label>
                  <Form.Select
                    onChange={(e) => handleDateChange(date?.day, date?.month, e.target.value)}
                  >
                    <option>Select Year</option>
                    <option selected={"UNK" === date?.year} value={"UNK"}>UNK</option>
                    {years.map((year) => (
                      <option selected={year === Number(date?.year)} key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            }
            {fieldType === "time" &&
              <div className="hstack gap-2">
                <Form.Group className="mb-2 position-relative">
                  <Form.Label className="fw-bold mb-1">Hour</Form.Label>
                  <Form.Select onChange={(e) => handleTimeChange(e.target.value, time?.minute, time?.second)}>
                    <option>Select Hour</option>
                    <option selected={"UNK" === time?.hour} value={"UNK"}>UNK</option>
                    {hours.map((hour) => (
                      <option selected={hour === Number(time?.hour)} key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2 position-relative">
                  <Form.Label className="fw-bold mb-1">Minute</Form.Label>
                  <Form.Select onChange={(e) => handleTimeChange(time?.hour, e.target.value, time?.second)}>
                    <option>Select Minute</option>
                    {minuteSecond.map((minute, index) => (
                      <option selected={minute === Number(time?.minute)} key={index} value={minute}>
                        {minute}
                      </option>
                    ))}
                    <option selected={"UNK" === time?.minute} value={"UNK"}>UNK</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2 position-relative">
                  <Form.Label className="fw-bold mb-1">Second</Form.Label>
                  <Form.Select onChange={(e) => handleTimeChange(time?.hour, time?.minute, e.target.value)}>
                    <option>Select Second</option>
                    <option selected={"UNK" === time?.second} value={"UNK"}>UNK</option>
                    {minuteSecond.map((second) => (
                      <option selected={second === Number(time?.second)} key={second} value={second}>
                        {second}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            }
          </div>
          <div className="justify-content-end py-2 px-3 shadow-sm bg-light w-100 hstack gap-2">
             <button
              disabled={loader}
              onClick={handleBlur}
              className="updateProfile">
              {loader ? <Spinner style={{ width: 15, height: 15 }} /> : <FaCheck />} Update
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LegacyPopup;
