import React from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import { FaCirclePlus } from "react-icons/fa6";
import { CircleXIcon } from "../../../Assets/Icons/GlobalIcons";

const AddUpdateCTMSColumn = ({
    addFormFields,
    removeFormFields,
    formValues,
    handleSubmit0,
    handleSubmit,
    updateStudyDynamicColumn,
    addHandleChange,
    updateHandleChange,
    studyDynamicColumn,
    removeAddUpdateFields,
    loader,
    statusCode,
    customMessage,
}) => {

    let checkUpdateDunamicValue = (updateStudyDynamicColumn || [])?.map((item) =>
        (!item.name || !item.columnValue || !item.types)
    );
    let checkUpdateFilledData = checkUpdateDunamicValue?.filter((item) => item === false);
    let dynamicUpdateConditionData =
        updateStudyDynamicColumn?.length === checkUpdateFilledData?.length;

    let checkAddDunamicValue = (formValues || [])?.map((item) =>
        (!item.name || !item.columnValue || !item.types)
    );
    let checkAddFilledData = checkAddDunamicValue?.filter((item) => item === false);
    let dynamicAddConditionData =
        formValues?.length === checkAddFilledData?.length;

    return (
        <div className="p-2">
            <Form.Group>
                <Form.Label className="mb-1 fw-bold">
                    {studyDynamicColumn?.length > 0 ? "Update" : "Add"} Column Data <span className="text-danger">*</span>
                    {studyDynamicColumn?.length === 0 && (
                        <FaCirclePlus
                            className="ms-2 CP"
                            title="Add More"
                            onClick={addFormFields}
                        />
                    )}
                </Form.Label>
                {studyDynamicColumn?.length > 0
                    ? updateStudyDynamicColumn?.map(({ name, columnValue, types }, i) => (
                        <div className="hstack mb-2" key={i}>
                            <Form.Control
                                className="inputboxborder"
                                placeholder="Enter Column Label"
                                name="name"
                                value={name}
                                onChange={(e) => updateHandleChange(e, i)}
                            />
                            <Form.Control
                                type="text"
                                className="inputboxborder mx-2"
                                placeholder="Enter Column Value"
                                name="columnValue"
                                value={columnValue}
                                onChange={(e) => updateHandleChange(e, i)}
                            />
                            <Form.Select
                                style={{ width: "40%" }}
                                className="inputboxborder me-2"
                                id="autoSizingSelect"
                                name="types"
                                value={types}
                                onChange={(e) => updateHandleChange(e, i)}
                            >
                                <option hidden>Select</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                                <option value="time">Time</option>
                            </Form.Select>
                            {updateStudyDynamicColumn?.length > 1 && (
                                <CircleXIcon OnClick={(e) => removeAddUpdateFields(e, i)} />
                            )}
                        </div>
                    ))
                    : formValues?.map(({ name, columnValue, types }, i) => (
                        <div className="hstack mb-2" key={i}>
                            <Form.Control
                                className="inputboxborder"
                                placeholder="Enter Column Label"
                                name="name"
                                value={name}
                                onChange={(e) => addHandleChange(e, i)}
                            />
                            <Form.Control
                                type="text"
                                className="inputboxborder mx-2"
                                placeholder="Enter Column Value"
                                name="columnValue"
                                value={columnValue}
                                onChange={(e) => addHandleChange(e, i)}
                            />
                            <Form.Select
                                style={{ width: "40%" }}
                                className="inputboxborder me-2"
                                id="autoSizingSelect"
                                name="types"
                                value={types}
                                onChange={(e) => addHandleChange(e, i)}
                            >
                                <option hidden>Select Type</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                                <option value="time">Time</option>
                            </Form.Select>
                            <CircleXIcon OnClick={(e) => removeFormFields(e, i)} />
                        </div>
                    ))}
                <div className="text-end mb-2">
                    {studyDynamicColumn?.length > 0 ? (
                        <button
                            className="submitbutton"
                            onClick={handleSubmit0}
                            disabled={loader || !dynamicUpdateConditionData}
                            title={
                                !dynamicUpdateConditionData
                                    ? "Fill all mandatory fields"
                                    : ""
                            }
                        >
                            {loader ? (
                                <Spinner
                                    className="me-1"
                                    style={{ width: 15, height: 15 }}
                                    animation="border"
                                />
                            ) : (
                                <i className="fa-solid fa-pen-to-square me-1"></i>
                            )}
                            Update Column
                        </button>
                    ) : (
                        <button
                            className="submitbutton"
                            onClick={handleSubmit}
                            disabled={loader || !dynamicAddConditionData}
                            title={
                                !dynamicAddConditionData
                                    ? "Fill all mandatory fields"
                                    : ""
                            }
                        >
                            {loader ? (
                                <Spinner
                                    className="me-1"
                                    style={{ width: 15, height: 15 }}
                                    animation="border"
                                />
                            ) : (
                                <i className="fa-solid fa-square-plus me-1"></i>
                            )}
                            Add Column
                        </button>
                    )}
                </div>
                {customMessage && (
                    <Alert variant={statusCode === 200 ? "success" : "danger"}>
                        {customMessage}
                    </Alert>
                )}
            </Form.Group>
        </div>
    );
};

export default AddUpdateCTMSColumn;
