import React, { useState } from "react";
import { BsScissors } from "react-icons/bs";
import { RiAlignLeft } from "react-icons/ri";
import { LiaPasteSolid } from "react-icons/lia";
import InsertShape from "../InsertDesign/InsertShape";
import InsertEmoji from "../InsertDesign/InsertEmoji";
import InsertPictures from "../InsertDesign/InsertPictures";
import LayoutSizeDesign from "../LayoutDesigns/LayoutSizeDesign";
import TableProperties from "../InsertDesign/Table/TableProperties";
import LayoutMarginDesign from "../LayoutDesigns/LayoutMarginDesign";
import LayoutColumnDesign from "../LayoutDesigns/LayoutColumnDesign";
import InsertTableDesign from "../InsertDesign/Table/InsertTableDesign";
import { GrCopy, GrTextAlignCenter, GrUnderline } from "react-icons/gr";
import { PiPaintBrushHouseholdFill, PiTextItalic } from "react-icons/pi";
import LayoutOrientationDesign from "../LayoutDesigns/LayoutOrientationDesign";
import {
  MdFormatAlignJustify,
  MdOutlineFormatAlignRight,
} from "react-icons/md";
import { Form } from "react-bootstrap";
import { BiCollapse, BiExpand } from "react-icons/bi";
import ReactToPrint from "react-to-print";

const Toolbar = ({
  setOrientation,
  componentRef,
  handleMarginChange,
  onSizeChange,
  onColumnChange,
  onShapeInsert,
  onInsertTable,
  setActiveTab,
  activeTab,
  tableProperties,
  updateTableProperties,
  addRow,
  addColumn,
  deleteRow,
  deleteColumn,
  onToggleHeader,
  onToggleFooter,
  onPrint,
  onConvertToPDF,
  onEmojiInsert,
  handleImageUpload,
  handleCellChange,
  selectedCell,
  toggleFullScreen,
  isFullScreen,
  loadFormData
}) => {
  const [fontSize, setFontSize] = useState(11);
  const [show, setShow] = useState(false);
  const [bgColor, setBgColor] = useState("#ffffff");
  const [fontColor, setFontColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Calibri (Body)");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCommand = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const applyTextFormat = (format) => {
    if (format === "normal") {
      document.execCommand("formatBlock", false, "p");
    } else {
      document.execCommand("formatBlock", false, format);
    }
  };

  const handleFontSizeChange = (size) => {
    document.execCommand("fontSize", false, "7");
    const elements = document.getElementsByTagName("font");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].size === "7") {
        elements[i].removeAttribute("size");
        elements[i].style.fontSize = `${size}px`;
      }
    }
  };

  const handleHyperlink = () => {
    let url = prompt("Enter the URL");

    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      document.execCommand("createLink", false, url);

      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const anchorNode = selection.anchorNode;
        const parentElement = anchorNode?.parentElement;

        if (parentElement && parentElement.tagName === "A") {
          parentElement.setAttribute("target", "_blank");
          parentElement.style.cursor = "pointer";
          parentElement.addEventListener("click", (e) => {
            window.open(parentElement.href, "_blank");
          });
        }
      }
    }
  };

  const renderHomeToolbar = () => (
    <div className="p-2 border-bottom">
      <div className="hstack gap-2 text-nowrap blank overflow-auto">
        <button
          className="border"
          title="paste"
          onClick={() => handleCommand("paste")}
        >
          <LiaPasteSolid />
        </button>
        <button
          className="border"
          title="cut"
          onClick={() => handleCommand("cut")}
        >
          <BsScissors />
        </button>
        <button
          className="border"
          title="copy"
          onClick={() => handleCommand("copy")}
        >
          <GrCopy />
        </button>
        <button className="border" title="Format Painter">
          <PiPaintBrushHouseholdFill />
        </button>
        <Form.Select
          className="border bg-transparent p-1"
          style={{ fontSize: 10, width: "max-content" }}
          value={fontFamily}
          onChange={(e) => handleCommand("fontName", e.target.value)}
        >
          <option>Calibri (Body)</option>
          <option>Arial</option>
          <option>Times New Roman</option>
          <option>Verdana</option>
          <option>Georgia</option>
          <option>Roboto</option>
        </Form.Select>
        <Form.Select
          className="border bg-transparent p-1 pe-3"
          value={fontSize}
          style={{ fontSize: 10, width: 70 }}
          onChange={(e) => {
            setFontSize(e.target.value);
            handleFontSizeChange(e.target.value);
          }}
        >
          {[...Array(45)].map((_, index) => {
            const size = index + 6;
            return (
              <option key={size} value={size}>
                {size}
              </option>
            );
          })}
        </Form.Select>
        <button
          className="border"
          title="Bold"
          style={{ fontWeight: "bold" }}
          onClick={() => handleCommand("bold")}
        >
          B
        </button>
        <button
          className="border"
          title="Italic"
          onClick={() => handleCommand("italic")}
        >
          <PiTextItalic />
        </button>
        <button
          className="border"
          title="Underline"
          onClick={() => handleCommand("underline")}
        >
          <GrUnderline />
        </button>
        <Form.Control
          className="border bg-transparent p-0"
          style={{ fontSize: 5, width: 50, borderRadius: 0 }}
          type="color"
          value={fontColor}
          onChange={(e) => handleCommand("foreColor", e.target.value)}
          title="Font Color"
        />
        <Form.Control
          className="border bg-transparent p-0"
          style={{ fontSize: 5, width: 50, borderRadius: 0 }}
          type="color"
          value={bgColor}
          onChange={(e) => {
            handleCommand("hiliteColor", e.target.value);
          }}
          title="Background Color"
        />
        <button
          className="border"
          title="Align left"
          onClick={() => handleCommand("justifyLeft")}
        >
          <RiAlignLeft />
        </button>
        <button
          className="border"
          title="Align center"
          onClick={() => handleCommand("justifyCenter")}
        >
          <GrTextAlignCenter />
        </button>
        <button
          className="border"
          title="Align right"
          onClick={() => handleCommand("justifyRight")}
        >
          <MdOutlineFormatAlignRight />
        </button>
        <button
          className="border"
          title="Justify"
          onClick={() => handleCommand("justifyFull")}
        >
          <MdFormatAlignJustify />
        </button>
        <button className="border" title="Header" onClick={onToggleHeader}>
          Header
        </button>
        <button className="border" title="Footer" onClick={onToggleFooter}>
          Footer
        </button>
        <ReactToPrint
          trigger={() => (
            <button className="border" title="Print" onClick={onPrint}>
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
        <button className="border" title="Convert" onClick={onConvertToPDF}>
          Convert To PDF
        </button>
        <button className="border" title="Hyperlink" onClick={handleHyperlink}>
          Hyperlink
        </button>
        <button
          className="border"
          title="Table Properties"
          onClick={handleShow}
        >
          Table Properties
        </button>
        <Form.Select
          className="border bg-transparent p-1"
          style={{ fontSize: 10, width: 250 }}
          onChange={(e) => applyTextFormat(e.target.value)}
        >
          <option value="normal">Normal</option>
          <option value="h1">Heading 1</option>
          <option value="h2">Heading 2</option>
          <option value="h3">Heading 3</option>
          <option value="h4">Heading 4</option>
          <option value="h5">Heading 5</option>
          <option value="h6">Heading 6</option>
        </Form.Select>
      </div>
    </div>
  );

  const renderInsertToolbar = () => (
    <div className="hstack gap-2 p-2 border-bottom">
      <button className="border position-relative">
        <InsertPictures handleImageUpload={handleImageUpload} />
      </button>
      <button className="border position-relative">
        <InsertTableDesign onInsertTable={onInsertTable} />
      </button>
      <button className="border position-relative">Chart</button>
      <button className="border position-relative">
        <InsertShape onShapeInsert={onShapeInsert} />
      </button>
      <button className="border position-relative">
        <InsertEmoji onEmojiInsert={onEmojiInsert} />
      </button>
    </div>
  );

  const renderLayoutToolbar = () => (
    <div className="hstack gap-2 p-2 border-bottom">
      <button className="border position-relative">
        <LayoutMarginDesign handleMarginChange={handleMarginChange} />
      </button>
      <button className="border position-relative">
        <LayoutOrientationDesign setOrientation={setOrientation} />
      </button>
      <button className="border position-relative">
        <LayoutSizeDesign onSelectSize={onSizeChange} />
      </button>
      <button className="border position-relative">
        <LayoutColumnDesign onSelectColumn={onColumnChange} />
      </button>
    </div>
  );

  const renderToolbar = () => {
    switch (activeTab) {
      case "Home":
        return renderHomeToolbar();
      case "Insert":
        return renderInsertToolbar();
      case "Layout":
        return renderLayoutToolbar();
      default:
        return renderHomeToolbar();
    }
  };

  return (
    <div className="position-sticky top-0 z-1">
      <div className=" hstack justify-content-between p-2 border-bottom ">
        <div className="hstack gap-2 ">
          <button
            className={`updateProfile ${activeTab === "Home" ? "" : "bg-white text-dark"
              }`}
            onClick={() => setActiveTab("Home")}
          >
            Home
          </button>
          <button
            className={`updateProfile ${activeTab === "Insert" ? "" : "bg-white text-dark"
              }`}
            onClick={() => setActiveTab("Insert")}
          >
            Insert
          </button>
          <button
            className={`updateProfile ${activeTab === "Layout" ? "" : "bg-white text-dark"
              }`}
            onClick={() => setActiveTab("Layout")}
          >
            Layout
          </button>
          <Form.Select
            onChange={(e) => loadFormData(e.target.value)}
            className={`updateProfile bg-white text-dark`}
          >
            <option>Select Report type</option>
            <option value="single">Single Field Report</option>
            <option value="group">Group Field Report</option>
          </Form.Select>
        </div>
        <button
          className="tabButtons fontSize12 border-0  "
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      {renderToolbar()}
      <TableProperties
        show={show}
        handleClose={handleClose}
        tableProperties={tableProperties}
        updateTableProperties={updateTableProperties}
        addRow={addRow}
        addColumn={addColumn}
        deleteRow={deleteRow}
        deleteColumn={deleteColumn}
        handleCellChange={handleCellChange}
        selectedCell={selectedCell}
      />
    </div>
  );
};

export default Toolbar;
