import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Form } from "react-bootstrap";

const SiteEntryProgressGraph = ({ siteEntryProgress }) => {
  const [chartData, setChartData] = useState({
    series: [siteEntryProgress || 0],
    options: {
      chart: { type: "radialBar" },
      plotOptions: {
        radialBar: {
          hollow: { size: "70%" },
          track: { background: "#e7e7e7" },
          dataLabels: {
            value: {
              formatter: function (val) {
                return parseFloat(val).toFixed(2) + "%";
              },
              color: "#000",
            },
            name: { color: "#103C5E" },
          },
        },
      },
      fill: { colors: ["#103C5E"] },
      labels: ["Percent"],
    },
  });

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [siteEntryProgress || 0],
    }));
  }, [siteEntryProgress]);

  return (
    <>
      <div className="text-center">
        <Form.Label className="mb-0 fw-bold">Data Entry Progresss</Form.Label>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="radialBar"
        height={240}
      />
    </>
  );
};

export default SiteEntryProgressGraph;
