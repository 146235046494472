import React from "react";
import "./Loading.scss";

const Loading = ({ TableLoader }) => {
  return (
    <>
      <div className={`${TableLoader ? "tableSpinner" : "spinner"}`}>
        <div className="circle one"/>
        <div className="circle two"/>
        <div className="circle three"/>
      </div>
    </>
  );
};

export default Loading;
