import React, { useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";

const FactorTableData = ({ tableRef, onMouseDown, options }) => {
  const ref = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = options?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(options?.length / itemsPerPage);

  return (
    <>
      <Table
        ref={tableRef}
        id="resizable-table"
        striped
        className="custom-table"
        hover
      >
        <thead className="thead-sticky">
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Factor</th>
          </tr>
        </thead>
        <tbody ref={ref}>
          {result?.map((item, index, i) => (
            <>
              <tr key={index}>
                <td>{index + 1 + startIndex}</td>
                <td className="text-capitalize">{item.option}</td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={options?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default FactorTableData;
