import React, { useEffect, useState } from "react";
import SubjectReportChart from "./SubjectReportChart";
import SplitPane from "react-split-pane";
import QueryReportChart from "./QueryReportChart";
import SiteSDVDonutGraph from "./SiteSDVDonutGraph";
import SiteEntryProgressGraph from "./SiteEntryProgressGraph";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../../../Services/studyServices/studySubjectsServices";

const StudySiteReport = ({studyUniqueId}) => {
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const [siteSDV, setSiteSDV] = useState([]);
  const [siteEntryProgress, setSiteEntryProgress] = useState(0);
  const [siteQuery, setSiteQuery] = useState([]);
  const [subjectStatusReport, setSubjectStatusReport] = useState([]);
  const { studyId } = useParams();
  const getStudySiteEntryProgress = async (studyUniqueId) => {
    let data = await studyOnboardingService.getStudySiteEntryProgress(
      studyId,
      studyUniqueId,
      StudyMode
    );

    setSiteEntryProgress(data.data || 0);
  };

  const getStudySiteSDVGraphData = async (studyUniqueId) => {
    let data = await studyOnboardingService.getStudySiteSDVGraphData(
      studyId,
      studyUniqueId,
      StudyMode
    );

    setSiteSDV(data.data || []);
  };

  const getStudySiteQueryGraphData = async (studyUniqueId) => {
    let data = await studyOnboardingService.getStudySiteQueryGraphData(
      studyId,
      studyUniqueId,
      StudyMode
    );
    setSiteQuery(data.data);
  };

  const getStudySubjectStatusHistoryGraphData = async (studyUniqueId) => {
    let data = await studyOnboardingService.getStudySubjectStatusHistoryGraphData(
      studyId,
      StudyMode,
      studyUniqueId
    );
    setSubjectStatusReport(data.data);
  };
  useEffect(() => {
    if(studyUniqueId){
      getStudySiteQueryGraphData(studyUniqueId);
      getStudySiteSDVGraphData(studyUniqueId);
      getStudySiteEntryProgress(studyUniqueId);
      getStudySubjectStatusHistoryGraphData(studyUniqueId);
    }
  }, [studyUniqueId]);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "80%" }}
      >
        <div className="m-2">
          <div className="d-flex gap-2">
            <div className="bg-light border" style={{width: 350}}>
              <SubjectReportChart subjectStatusReport={subjectStatusReport} />
            </div>
            <div className="bg-light border" style={{width: 350}}>
              <QueryReportChart siteQuery={siteQuery} />
            </div>
             <div className="bg-light border" style={{width: 350}}>
              <SiteSDVDonutGraph siteSDV={siteSDV}/>
            </div>
             <div className="bg-light border" style={{width: 350}}>
              <SiteEntryProgressGraph siteEntryProgress={siteEntryProgress}/>
            </div>
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default StudySiteReport;
