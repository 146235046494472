import React, { useState } from "react";
import RandomizeTabPropertiesData from "./RandomizeTabPropertiesData";
import RandomizeTabActivityData from "./RandomizeTabActivityData";
import RandomizeTabRequestData from "./RandomizeTabRequestData";
import Randomize from "./Randomize";

const RandomizeTabData = ({ setIsSectionVisible, tableRef, onMouseDown, randomizerData, randomizerId }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const [activeTabs, setActiveTabs] = useState("properties");
  return (
    <>
      <div
        className={`pt-1 bg-light ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between border-bottom">
          <div className="hstack me-1 gap-1">
            {["properties", "randomize", "request", "activity"].map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible("")}
              ></i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
      </div>
      {activeTabs === "properties" ? (
        <RandomizeTabPropertiesData
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizerData={randomizerData}
        />
      ) : activeTabs === "request" ? (
        <RandomizeTabRequestData
          tableRef={tableRef}
          onMouseDown={onMouseDown}
        />
      ) : activeTabs === "randomize" ? (
        <Randomize
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizerId={randomizerId}
        />
      ) : activeTabs === "activity" ? (
        <RandomizeTabActivityData
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          randomizerId={randomizerId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RandomizeTabData;
