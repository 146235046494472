import React from "react";
import { Form, Table } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import SplitPane from "react-split-pane";

const AddDatasetPropertyTable = ({ isFormOpen }) => {
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={isFormOpen ? "20%" : "100%"}
      >
        {isFormOpen && (
          <div>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">oprion1 </Form.Label>
              <Form.Control type="text" placeholder="Enter Data" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">oprion1 </Form.Label>
              <Form.Control type="text" placeholder="Enter Data" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">oprion1 </Form.Label>
              <Form.Control type="text" placeholder="Enter Data" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">oprion1 </Form.Label>
              <Form.Control type="text" placeholder="Enter Data" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">oprion1 </Form.Label>
              <Form.Control type="text" placeholder="Enter Data" />
            </Form.Group>
            <Form.Group className="text-end">
              <button className="updateProfile">
                <FaPlusSquare style={{ marginBottom: 2 }} /> Add Data
              </button>
            </Form.Group>
          </div>
        )}
        <div>
          <div className="overflow-auto">
            <Table
              id="resizable-table"
              className="text-nowrap custom-table"
              hover
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: 40 }}>Sr.</th>
                  <th>Dataset Name</th>
                  <th>Dataset Label</th>
                  <th>Import Date | Time</th>
                  <th style={{ width: 60 }}>
                    <div className="text-center">Delete</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>120</td>
                  <td>120</td>
                  <td>12</td>
                  <td>
                    <div
                      className="text-center cursor-pointer"
                      title="Delete Supplementary"
                    >
                      <i className={`fa-solid fa-trash-can text-danger`}></i>
                    </div>
                  </td>
                </tr>
                {/* {result?.map((item, index) => (
              <tr key={index} className="position-relative CP">
                <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                <td>{item?.dataset}</td>
                <td>{item?.keyVariables}</td>
                <td>{item?.comments}</td>
                <td>
                  <div
                    className="text-center cursor-pointer"
                    title="Delete Supplementary"
                    onClick={() => deleteStudyDataByDomainId(item?._id)}
                  >
                    <i className={`fa-solid fa-trash-can text-danger`}></i>
                  </div>
                </td>
              </tr>
            ))} */}
              </tbody>
            </Table>
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default AddDatasetPropertyTable;
