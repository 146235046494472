import React, { useState } from "react";
import { Table } from "react-bootstrap";
import UseToggle from "../../../../Hooks/UseToggle";
import RequestManagementFilterData from "./RequestManagementFilterData";
import RequestmgmtOffcanvasData from "./RequestmgmtOffcanvasData";
import Moment from "react-moment";

const RequestManagement = ({ tableRef, onMouseDown }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [updateType, setUpdateType] = useState("");

  const handleAddNewLearning = () => {
    setUpdateType("add");
    setToggle();
  };

  const factorData = [
    {
      _id: "1",
      randomizationNumber: "007",
      site: "site001",
      subjectId: "subj0013",
      treatmentCode: "Treat001",
      dateTime: "2024-05-29 11:36:54 UTC",
      status: "pending",
      remarks: "name changed",
      requestedBy: "Gaurav Chauhan",
    },
    {
      _id: "2",
      randomizationNumber: "008",
      site: "site001",
      subjectId: "subj0013",
      treatmentCode: "Treat001",
      dateTime: "2024-05-29 11:36:54 UTC",
      status: "completed",
      remarks: "name changed",
      requestedBy: "Gaurav Chauhan",
    },
    {
      _id: "3",
      randomizationNumber: "008",
      site: "site001",
      subjectId: "subj0013",
      treatmentCode: "Treat001",
      dateTime: "2024-05-29 11:36:54 UTC",
      status: "pending",
      remarks: "name changed",
      requestedBy: "Gaurav Chauhan",
    },
    {
      _id: "4",
      randomizationNumber: "009",
      site: "site001",
      subjectId: "subj0013",
      treatmentCode: "Treat001",
      dateTime: "2024-05-29 11:36:54 UTC",
      status: "completed",
      remarks: "name changed",
      requestedBy: "Gaurav Chauhan",
    },
  ];

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "green";
      case "pending":
        return "red";
      default:
        return "inherit";
    }
  };

  return (
    <>
      <div className="p-1 hstack justify-content-between border-bottom">
        <div className="hstack gap-2 toolbar">
          {updateType ? (
            <i
              onClick={() => setUpdateType("")}
              title="Table Full Mode"
              className="fa-solid fa-filter CP"

            ></i>
          ) : (
            <i
              onClick={handleAddNewLearning}
              title="Add New Randomization"
              className="fa-solid fa-filter CP"
            />
          )}
        </div>
      </div>
      <div className="m-0 p-0" style={{ fontSize: "13px", color: "#333" }}>
        <Table className="custom-table request">
          <thead>
            <tr>
              <th>Randomization No.</th>
              <th>Site</th>
              <th>Subject ID</th>
              <th>Treatment Code</th>
              <th>Date & Time</th>
              <th>Status</th>
              <th>Remakrs</th>
              <th>Requested By</th>
            </tr>
          </thead>
          <tbody>
            {factorData?.map((item, index) => (
              <tr key={index} onClick={setToggle1}>
                <td>{item.randomizationNumber}</td>
                <td>{item.site}</td>
                <td>{item.subjectId}</td>
                <td>{item.treatmentCode}</td>
                <td><Moment format="DD MMM YYYY | HH:mm" withTitle>{item.dateTime}</Moment></td>
                <td
                  className="text-capitalize"
                  style={{
                    color: getStatusColor(item.status),
                    fontWeight: "500",
                  }}
                >
                  {item.status}
                </td>
                <td>{item.remarks}</td>
                <td>{item.requestedBy}</td>

              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <RequestManagementFilterData
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <RequestmgmtOffcanvasData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Request Management Properties"}
      />
    </>
  );
};

export default RequestManagement;
