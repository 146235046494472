import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const UpdateComment = ({
  studyId,
  isFormOpen,
  getCDISCComments,
}) => {
  let [document, setDocument] = useState("");
  let [pages, setPages] = useState("");

  const updateStudyCommentMetadata = async () => {
    let userData = {}
    userData.studyId = studyId
    userData.uniqueId = isFormOpen?._id
    userData.document = document
    userData.pages = pages
    let data = await cdiscServices.updateStudyCommentMetadata(userData)
    if (data?.statusCode === 200) {
      getCDISCComments()
    }
  }

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Document <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Document"
          onChange={(e) => setDocument(e.target.value)}
          value={document}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button onClick={updateStudyCommentMetadata} className="updateProfile">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </div>
  );
};

export default UpdateComment;
