import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const ELearningReportChart = ({ finalPoint = [0, 0] }) => {
  const [series, setSeries] = useState(finalPoint);
  const [totalLength, setTotalLength] = useState(0);
  const [chartOptions, setChartOptions] = useState(null); 

  useEffect(() => {
    if (finalPoint && finalPoint.length) {
      const total = finalPoint.reduce((a, b) => a + b, 0);
      setTotalLength(total);
      setSeries(finalPoint);
    }
  }, [finalPoint]);

  useEffect(() => {
    if (totalLength > 0) {
      setChartOptions({
        chart: {
          type: 'donut',
        },
        labels: ['Correct Answer', 'Wrong Answer'],
        colors: [
          "#198754",
          "#e33545"
        ],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value}`,
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => `${Math.round(value * totalLength / 100)}`,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
            },
          },
        ],
      });
    }
  }, [totalLength]);

  if (!chartOptions) {
    return <div>Loading chart...</div>;
  }
  return (
    <div>
      <div id="chart">
        <ReactApexChart 
          options={chartOptions} 
          series={series} 
          type="donut" 
          height={150} 
        />
      </div>
    </div>
  );
};

export default ELearningReportChart;
