import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";

function CDISCSidebar({ handleAddNewTab, checkActiveTab }) {
  const [openSites, setOpenSites] = useState(false);
  const [openSTDMData, setOpenSTDMData] = useState(false);
  const [openSTDMTabulation, setOpenSTDMTabulation] = useState(false);
  const [openStudySettings, setOpenStudySettings] = useState(false);
  return (
    <>
      <div className="link__tab">
        <div
          onClick={() => setOpenSites(!openSites)}
          aria-expanded={openSites}
          className={"text-start h-auto tab_url w-100"}
          style={{ background: "#ccd6dd" }}
          title="StudyID"
        >
          <div className="hstack justify-content-between">
            <div>
              <i className={"me-2 fa-solid fa-folder"}></i>
            </div>
          </div>
        </div>
      </div>

      <Collapse in={true}>
        <div>
          <div
            className={`CP small link__tab ${
              checkActiveTab("cdiscStudyDetails") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Study Details",
                "fa-solid fa-address-card",
                "cdiscStudyDetails"
              );
            }}
            title=" Study Details"
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building me-2 small"></i>
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("protocolMetadata") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Study Protocol",
                "fa-solid fa-address-card",
                "protocolMetadata"
              );
            }}
            title=" Study Protocol"
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building me-2 small"></i>
            </div>
          </div>

          <div
            className={`CP small link__tab ${
              checkActiveTab("auditTrial") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "Audit Trial",
                "fa-solid fa-address-card",
                "auditTrial"
              );
            }}
            title="Audit Trail"
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building me-2 small"></i>
            </div>
          </div>

          <div
            className={`CP small link__tab ${openSTDMData && "selectedMenu"}`}
            onClick={() => setOpenSTDMData(!openSTDMData)}
            title="SDTM"
          >
            <div className="hstack justify-content-between">
              <span className="text-start h-auto tab_url w-100">
                <span>
                  <i
                    className={`ps-0 small fa-solid ${
                      openSTDMData ? "fa-folder-open" : "fa-folder"
                    }`}
                  ></i>
                </span>
              </span>
            </div>
          </div>
          <Collapse in={openSTDMData} dimension={"width"}>
            <div
              className="position-absolute bg-white border-top border-end w-200"
              style={{ zIndex: 99 }}
            >
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmDataset") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Datasets",
                    "fa-solid fa-address-card",
                    "sdtmDataset"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Datasets
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmVariable") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Variable",
                    "fa-solid fa-address-card",
                    "sdtmVariable"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Variable
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmValueLavel") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Value Lavel",
                    "fa-solid fa-address-card",
                    "sdtmValueLavel"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Value Lavel
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmWhereClause") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Where Clause",
                    "fa-solid fa-address-card",
                    "sdtmWhereClause"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Where Clause
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmCodeList") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Code List",
                    "fa-solid fa-address-card",
                    "sdtmCodeList"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Code List
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmMethod") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Method",
                    "fa-solid fa-address-card",
                    "sdtmMethod"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Method
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmComment") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Comment",
                    "fa-solid fa-address-card",
                    "sdtmComment"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Comment
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmDocument") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Document",
                    "fa-solid fa-address-card",
                    "sdtmDocument"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Document
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmDictionaries") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Dictionaries",
                    "fa-solid fa-address-card",
                    "sdtmDictionaries"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Dictionaries
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmACRF") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "ACRF",
                    "fa-solid fa-address-card",
                    "sdtmACRF"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  ACRF
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  openSTDMTabulation && "selectedMenu"
                }`}
                onClick={() => setOpenSTDMTabulation(!openSTDMTabulation)}
              >
                <div className="hstack justify-content-between">
                  <span className="text-start h-auto tab_url w-100">
                    <span>
                      <i
                        className={`ps-0 small fa-solid ${
                          openSTDMTabulation ? "fa-folder-open" : "fa-folder"
                        }`}
                      ></i>
                    </span>
                    <span className="ms-2 fs-13">tabulation</span>
                  </span>
                  <span>
                    {openSTDMTabulation ? (
                      <VscChevronDown />
                    ) : (
                      <VscChevronRight />
                    )}
                  </span>
                </div>
              </div>
              <Collapse in={openSTDMTabulation}>
                <div>
                  <div
                    className={`CP small link__tab ps-3 ${
                      checkActiveTab("tabulationStdm") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "stdm",
                        "fa-solid fa-address-card",
                        "tabulationStdm"
                      );
                    }}
                  >
                    <div className="text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-building me-2 small"></i>
                      stdm
                    </div>
                  </div>
                  <div
                    className={`CP small link__tab ps-3 ${
                      checkActiveTab("tabulationAdam") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "adam",
                        "fa-solid fa-address-card",
                        "tabulationAdam"
                      );
                    }}
                  >
                    <div className="text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-building me-2 small"></i>
                      adam
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </Collapse>
          <div
            className={`CP small link__tab ${
              openStudySettings && "selectedMenu"
            }`}
            onClick={() => setOpenStudySettings(!openStudySettings)}
            title="Study Settings"
          >
            <div className="hstack justify-content-between">
              <span className="text-start h-auto tab_url w-100">
                <span>
                  <i
                    className={`ps-0 small fa-solid ${
                      openStudySettings ? "fa-folder-open" : "fa-folder"
                    }`}
                  ></i>
                </span>
              </span>
            </div>
          </div>
          <Collapse in={openStudySettings} dimension={"width"}>
            <div
              className="position-absolute bg-white border-top border-end w-200"
              style={{ zIndex: 99 }}
            >
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmStudyMetdata") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Study Metadata",
                    "fa-solid fa-address-card",
                    "sdtmStudyMetdata"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Study Metadata
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtmSDAData") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Source Data Analytics",
                    "fa-solid fa-address-card",
                    "sdtmSDAData"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Source Data Analytics (SDA)
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("sdtSettings") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "SDTM Settings",
                    "fa-solid fa-address-card",
                    "sdtSettings"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  SDTM Settings
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("studyValueLavel") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Value Lavel",
                    "fa-solid fa-address-card",
                    "studyValueLavel"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Value Lavel
                </div>
              </div>
              <div
                className={`CP small link__tab ${
                  checkActiveTab("studySupplementary") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    "Supplementary",
                    "fa-solid fa-address-card",
                    "studySupplementary"
                  );
                }}
              >
                <div className="text-start h-auto tab_url w-100">
                  <i className="fa-solid fa-building me-2 small"></i>
                  Supplementary
                </div>
              </div>
            </div>
          </Collapse>

          <div
            className={`CP small link__tab ${
              checkActiveTab("crfDataId") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                "CRF Data",
                "fa-solid fa-address-card",
                "crfDataId"
              );
            }}
            title=" CRF Data"
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building me-2 small"></i>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default CDISCSidebar;
