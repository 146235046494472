import React, { useState } from "react";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import { Table } from "react-bootstrap";

const EActivity = () => {
  const [loader, setLoadeer] = useState("");
  const data = Array.from({ length: 15 }, (v, i) => ({
    sr: i + 1,
    status: i % 2 === 0 ? "Completed" : "Pending",
    category: `Category ${(i % 3) + 1}`,
    type: `Type ${(i % 4) + 1}`,
    activities: `Activity ${i + 1}`,
    activityBy: `User ${i + 1}`,
    activityDateTime: new Date().toLocaleString(),
  }));
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : (
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              hover
              className="m-0 custom-table"
            >
              <thead className="thead-sticky top-0">
                <tr>
                  <th style={{ width: 45 }}>Sr.</th>
                  <th>Status</th>
                  <th>Category</th>
                  <th>Type</th>
                  <th>Activities</th>
                  <th>Activity By</th>
                  <th>Activity Date | Time</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.sr}</td>
                    <td>{item.status}</td>
                    <td>{item.category}</td>
                    <td>{item.type}</td>
                    <td>{item.activities}</td>
                    <td>{item.activityBy}</td>
                    <td>{item.activityDateTime}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </SplitPane>
    </>
  );
};

export default EActivity;
