import React, { memo } from "react";
import "../../Styles/IconStyles.scss";

export const OptionIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-list ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));


export const ConditionalIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-code-merge ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ChevronIcon = memo(
  ({ HideShowTable, TableShow, ProjectId, menuclose }) => {
    const buttonStyle = `${!ProjectId && "float-end"} ms-2 pe-2 ${menuclose || "cdashmenuclose"
      }`;

    return (
      <i
        className={`fa-solid fa-circle-chevron-${TableShow ? "down" : "right"
          } ${ProjectId && "gearIcon"} ${buttonStyle}`}
        onClick={HideShowTable}
      />
    );
  }
);

export const PlusIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <div className="add_puls" onClick={OnClick}>
  <i
    className={`fa-solid fa-square-plus d-flex  align-items-center ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
  /> 
  {/* <span className="fw-bold"> ADD </span> */}
  </div>
));


export const StoreIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-square-check ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const PlusIconWhite = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fas fa-plus white ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const UsersClientsIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-user-gear me-1 ps-2 ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const ClientsIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-user me-1 ps-2 ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const PenSquareIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-regular fa-pen-to-square activeclient ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const FloppyDiskIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-floppy-disk ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const BellIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-bell ${Class} ${Right && "me-2"} ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const MagnifierIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`far fa-search searchmagnifier ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const PenSquareSolidIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-pen-to-square ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);


export const BookmarkIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-bookmark ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);   


export const PaperPlaneIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-paper-plane ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const DropdownArrowRightIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-angle-right users p-0 ${Class} ${Right && "me-2"
        } ${Left && "ms-2"}`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const DropdownArrowDownIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-angle-down users p-0 ${Class} ${Right && "me-2"
        } ${Left && "ms-2"}`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const UsersTieIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-user-tie clients me-1 ps-1 ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const FolderIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-folder clients ps-2 ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

// export const FolderIcon = memo(({ OnClick, Class, Left, Right, ToolTip, isOpen }) => (
//   <i
//     className={`fa-solid fa- ${isOpen ? 'folder-open' : 'folder'} clients ps-2 ${Class} ${Right && "me-2"} ${Left && "ms-2"}`}
//     title={ToolTip}
//     onClick={OnClick}
//   />
// ));

export const TableIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-table small ps-2 ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const UserIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-user small ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const PersonRunningIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-person-running small ${Class} ${Right && "me-2"
        } ${Left && "ms-2"}`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const PaperClipIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-paperclip ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const TicketIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-comment-medical ${Class} ${Right && "me-2"} ${Left && "ms-2"
      } mx-2`}
    title={ToolTip}
    onClick={OnClick}
  />
));
export const ClipboardCheckIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-clipboard-check ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const ClipboardListIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fas fa-clipboard-list small ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const DatabaseIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-database mt-1 small ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ClipBoardIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip, Color }) => (
    <i
      className={`fas fa-clipboard-list ${Color || "clients"} ${Class} ${Right && "me-2"
        } ${Left && "ms-2"}`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const WalkingIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip, Table }) => (
    <i
      className={`fas fa-walking ${!Table && "clients"} ${Class} ${Right && "me-2"
        } ${Left && "ms-2"}`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const AccessIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-universal-access accessManage small ps-2 ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ChairIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-chair accessManage ps-2 small ${Class} ${Right && "me-2"
      } ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const UserPlusIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-user-plus ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const FormViewIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-remove-format CP ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const EyeViewIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-eye ${Class} ${Right && "me-2"} ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ManagementGroupIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-users-rectangle ${Class} ${Right && "me-2"} ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const GearIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-gear gearIcon ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const StrikeThroghIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-strikethrough ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const CirclePlusIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-circle-plus blue ${Class} ${Right && "mx-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const CircleMinusIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-circle-minus ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const SquarePlusIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fas fa-plus-square CP ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const MaximizeIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-maximize  ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const SignIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fas fa-file-signature mx-2 CP ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const RefreshIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-rotate ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ListIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-list ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const PagerIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-pager ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const FileExportIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-download ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const CrossIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-xmark inactiveclient ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const EnvelopCircleCheckIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-envelope-circle-check ${Class} ${Right && "me-2"
        } ${Left && "ms-2"}`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const FilePenIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-file-pen ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const FileIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-file ${Class} ${Right && "me-2"} ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ShareIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-share ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ReplyIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-reply ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ArrowDownIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-arrow-down ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const AtoZIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-arrow-down-a-z ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const BarsIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-bars ${Class} ${Right && "me-2"} ${Left && "ms-2"}`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const DiagramSuccesorIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-diagram-successor ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const BiohazardIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-biohazard ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const UserTagIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-user-tag ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const UploadIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-upload ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const VerticleGripIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-grip-vertical move ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const DownloadIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-download ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const PrintIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-print ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const FilterIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-filter ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const SquareCheckIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-square-check ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const SyncIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa fa-sync-alt activeclient CP ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const CircleCrossIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa fa-times-circle inactiveclient CP ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);

export const ArchiveIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-box-archive clients mx-2 ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const ClockIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-clock ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const CheckIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-circle-check ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const TrashIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-trash inactiveclient ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const CircleXIcon = memo(({ OnClick, Class, Left, Right, ToolTip }) => (
  <i
    className={`fa-solid fa-circle-xmark inactiveclient ${Class} ${Right && "me-2"} ${Left && "ms-2"
      }`}
    title={ToolTip}
    onClick={OnClick}
  />
));

export const FolderClosedIcon = memo(
  ({ OnClick, Class, Left, Right, ToolTip }) => (
    <i
      className={`fa-solid fa-folder-closed ${Class} ${Right && "me-2"} ${Left && "ms-2"
        }`}
      title={ToolTip}
      onClick={OnClick}
    />
  )
);
