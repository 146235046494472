import React from "react";
import { PlusIcon, CircleXIcon } from "../../Assets/Icons/GlobalIcons";

const ApeendInputsComponent = ({
  formValues,
  handleChange,
  removeFormFields,
  addFormFields,
  Append1,
  Append2,
  Append3,
}) => (
  <>
    {formValues
      ? formValues?.map(({ name, valueData, columnValue, types }, i) => (
          <div className="d-flex align-items-baseline" key={i}>
            <input
              className="form-control inputboxborder mb-3"
              placeholder="Enter Column Label"
              name="name"
              value={name}
              onChange={(e) => handleChange(e, i)}
            />

            {Append2 && (
              <input
                style={{width: "40%"}}
                className="form-control inputboxborder mb-3 mx-2"
                placeholder="Enter Field Value"
                name="valueData"
                value={valueData}
                onChange={(e) => handleChange(e, i)}
              />
            )}

            {Append1 && (
              <input
              style={{width: "40%"}}
                type="text"
                className="form-control inputboxborder mb-3 ms-2"
                placeholder="Enter Value"
                name="columnValue"
                value={columnValue}
                onChange={(e) => handleChange(e, i)}
              />
            )}

            {Append3 && (
              <select
              style={{width: "40%"}}
                className="form-select inputboxborder mb-3 mx-2"
                id="autoSizingSelect"
                name="types"
                value={types}
                onChange={(e) => handleChange(e, i)}
              >
                <option hidden>Select Type</option>
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="time">Time</option>
              </select>
            )}

            <CircleXIcon OnClick={() => removeFormFields(i)} />
          </div>
        ))
      : ""}
  </>
);

export default ApeendInputsComponent;
