import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import Pagination from "../../../../ETMF/Pagination/Pagination";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const ValueLabelTableData = ({
  addNewValueLabeldata,
  getClientValueLevelData,
  onMouseDown,
  tableRef,
  setIsFormOpen,
  setValueLabelData,
  studyId,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const ref = useRef(null);
  const deleteClientCodeList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Suppliment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyValueLevelData(
          id,
          studyId
        );
        if (results?.statusCode === 200) {
          getClientValueLevelData();
        }
      }
    });
  };

  const handleUpdateClick = (item) => {
    setValueLabelData(item);
    setIsFormOpen(true);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = addNewValueLabeldata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(addNewValueLabeldata?.length / itemsPerPage);

  return (
    <>
      <div className="overflow-auto">
        <Table
          striped
          hover
          id="resizable-table"
          className="custom-table tableLibrary m-0 overflow-auto"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Domain</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>
                Value Level Variable Name
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>
                Whereclause Variable Label
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>
                Value Level Whereclause
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Whereclause Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Length</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Significant</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Mandatory</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Comment</th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>Mathod</th>
              <th onMouseDown={(e) => onMouseDown(e, 16)}>Code List</th>
              <th onMouseDown={(e) => onMouseDown(e, 17)}>Update</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 18)}
                style={{ width: 70, right: -1 }}
                className="text-center position-sticky"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {result?.length > 0 &&
              result?.map((item, index) => (
                <tr
                  key={index}
                  className={`position-relative ${
                    highlightedRow === index && "activeMenu"
                  }`}
                  onClick={() => setHighlightedRow(index)}
                >
                  <td>{item.order}</td>
                  <td>{item.domain}</td>
                  <td>{item.domainValueLevelVeriableName}</td>
                  <td>{item.domainWhereclauseVeriableName}</td>
                  <td>{item.valueLevelWhereclause}</td>
                  <td>{item.whereclauseLabel}</td>
                  <td className="text-capitalize">{item.dataType}</td>
                  <td>
                    {!item.dataType ? "" : item.dataType === "text" ? 200 : 8}
                  </td>
                  <td>{item.dataType === "float" ? item.significant : ""}</td>
                  <td className="text-capitalize">{item.mandatory}</td>
                  <td>{item.origin}</td>
                  <td>{item.comment}</td>
                  <td>{item.mathod}</td>
                  <td>{item.codeListData}</td>
                  <td className="text-center">
                    <button
                      onClick={() => handleUpdateClick(item)}
                      title="Update"
                      className="text-white border-success bg-success border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                    >
                      Update
                    </button>
                  </td>
                  <td
                    className="position-sticky bg-light"
                    style={{ right: -1 }}
                  >
                    {item.mandatory === "no" ? (
                      <button
                        className="text-white border-danger bg-danger border-0"
                        style={{ fontSize: 10, borderRadius: 3 }}
                        title="Delete ValueLabel"
                        onClick={() => deleteClientCodeList(item?._id)}
                      >
                        Delete
                      </button>
                    ) : (
                      <button
                        className="border-danger-subtle bg-danger-subtle border-0"
                        style={{ fontSize: 10, borderRadius: 3 }}
                        title="Can't Delete this ValueLabel"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={result?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ValueLabelTableData;
