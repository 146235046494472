/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import { clientLevelFormServices } from "../Services/formServices/clientLevelFormServices";
import Swal from "sweetalert2";
import { globalFormServices } from "../Services/formServices/globalFormServices";
import { useEffect } from "react";
import { UseCrfForm } from "../Components/CommonComponents/DashboardBody/StudiesComponents/CrfBuilder/CrfView";
import moment from "moment";
import { useParams } from "react-router";
import UseHideShowColumn from "../Hooks/UseHideShowColumn";
import UseToggle from "../Hooks/UseToggle";
import { UseGetPermission } from "../Hooks/UseGetPermission";
import { UseSignature } from "../Components/CommonComponents/DashboardBody/StudyIdData/UseAppendInput";
import { StudyVisitFormServices } from "./../Services/formServices/StudyVisitFormServices";

export const StudyVisitContext = createContext();

export const StudyVisitContextProvider = ({ children }) => {
  const [studyVisitFormState, setStudyVisitFormState] = useState([]);
  const [studyVisitFormStateId, setStudyVisitFormStateId] = useState("");
  const [studyIdData, setStudyIdData] = useState("");

  const {
    toggle1: rowUpdateModal,
    setToggle1: setRowUpdateModal,
    toggle2: tableShow,
    setToggle2: setTableShow,
    toggle3: tableShow1,
    setToggle3: setTableShow1,
    toggle5: builderModal,
    setToggle5: setBuilderModal,
    toggle7: crfViewModal,
    setToggle7: setCrfViewModal,
    toggle5: reviewTableShow,
    setToggle5: setReviewTableShow,
    loader,
  } = UseToggle();

  const { validatorPermission } = UseGetPermission();

  const { setIdReviewRowTable, signFormProps } = UseSignature(
    "reviewStudyVisitsForm"
  );

  const [count, setCount] = useState("");

  const updateFormDetails = {
    type: "",
    label: "",
    code: "",
    history: "",
    uniqueId: "",
  };

  const [updateFormValues, setUpdateFormValues] = useState(updateFormDetails);

  const [currentPage, setCurrentPage] = useState(1);
  const [uploadedBy, setUploadedBy] = useState([]);
  const [jobCreatedDateTime, setJobCreatedDateTeme] = useState([]);
  const [version, setVersion] = useState([]);
  const [reviewedBy, setReviewedBy] = useState([]);
  const [reviewUpdatedDateTime, setReviewUpdatedDateTime] = useState([]);
  const [attributeDetails, setAttributeDetails] = useState([]);
  const [studyVisitHistoryLists, setStudyVisitHistoryLists] = useState([]);
  const [reviewHistoryLists, setReviewHistoryLists] = useState([]);

  const [status, setStatus] = useState([]);

  const limit = 100;

  const {
    handleCheck,
    handleCheck1,
    handleCheck2,
    handleCheck3,

    tableColumn,
    tableColumn1,

    showColumn,
  } = UseHideShowColumn();

  const { studyId } = useParams();

  const updateFormOnchange = (e, type) => {
    const { value } = e.target;
    setUpdateFormValues({ ...updateFormValues, [type]: value });
  };

  const setIdUpdateRowTable = (uniqueId, type, label, code) => {
    setUpdateFormValues({ uniqueId, type, label, code });
    setRowUpdateModal();
  };

  const getStudyLevelFormReviewData = async (id) => {
    let data = await StudyVisitFormServices.getStudyVisitsFormAttributeData(
      "getStudyVisitsFormReviewData",
      id
    );
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setReviewHistoryLists(finalData);
  };

  // .............................................................................

  const getStudyVisitFormData = async () => {
    let data = await clientLevelFormServices.getStudyVisitFormData(
      studyId,
      studyVisitFormStateId,
      currentPage,
      limit
    );
    let finalData = data?.data?.sort().reverse();
    setStudyVisitFormState(finalData);

    setCount(Math.ceil(data?.count / limit));
  };

  useEffect(() => {
    getStudyVisitFormData();
  }, []);

  // .............................................................................

  const getStudyRightSiteData = (id) => {
    getStudyLevelFormAttributeData(id);
    getStudyLevelFormReviewData(id);
    getStudyLevelFormsHistoryByID(id);
  };

  const exportStudyLevelFormData = async (extension) => {
    await clientLevelFormServices.exportStudyLevelFormData(studyId, extension);
  };

  const exportStudyLevelFormAuditTrialDataData = async (extension) => {
    await clientLevelFormServices.exportStudyLevelFormAuditTrialDataData(
      studyId,
      extension
    );
  };

  const getStudyLevelFormAttributeData = async (e) => {
    let data = await StudyVisitFormServices.getStudyVisitsFormAttributeData(
      "getStudyVisitsFormAttributeData",
      e
    );
    setAttributeDetails(data?.data);
    setUploadedBy(data?.data?.[0]?.uploadedBy);
    setJobCreatedDateTeme(
      moment(data?.data?.[0]?.createdAt).format("DD-MM-YYYY | HH:mm")
    );
    setVersion(data?.data?.[0]?.version);
    setReviewedBy(data?.data?.[0]?.reviewedBy);
    setStatus(data?.data?.[0]?.status);
    setReviewUpdatedDateTime(
      moment(data?.data?.[0]?.updatedAt).format("DD-MM-YYYY | HH:mm")
    );
  };

  const getStudyLevelFormsHistoryByID = async (e) => {
    let data = await StudyVisitFormServices.getStudyVisitsFormAttributeData(
      "getStudyVisitsFormsHistoryByID",
      e
    );
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setStudyVisitHistoryLists(finalData);
  };

  const pushClientLevelForm = async (e, uniqueId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Push in Global Form!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Push it!",
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let datas = {};
        datas.formUniqueId = uniqueId;
        globalFormServices.pushClientLevelForm(datas);
        getStudyVisitFormData();
      }
    });
  };

  const handleSubmit0 = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Form Details!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        StudyVisitFormServices.updateStudyVisitFormsMeta(updateFormValues);
        getStudyVisitFormData();
        setUpdateFormValues(updateFormDetails);
      }
    });
  };

  const handleSubmit3 = async (e, id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Form!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    });
    if (result.isConfirmed) {
      e.preventDefault();
      await StudyVisitFormServices.deleteStudyVisitFormsData(id);
    }
  };

  const {
    loader: crfLoader,
    selectedForm,
    setSelectedForm,
    fetchStudyVisitsForms,
  } = UseCrfForm();

  const fetchData = () => {
    setCrfViewModal();
    fetchStudyVisitsForms();
  };

  const [formUniqueId, setFormUniqueId] = useState("");

  const addFormMetadata = (id) => {
    setBuilderModal();
    setFormUniqueId(id);
  };

  const hideFormUpdatePopup = () => {
    setRowUpdateModal();
    getStudyVisitFormData();
  };

  const contextValue = {
    studyVisitFormState,
    setStudyVisitFormState,
    studyVisitFormStateId,
    setStudyVisitFormStateId,
    studyIdData,
    setStudyIdData,

    getStudyVisitFormData,

    signFormProps,
    crfViewModal,
    setCrfViewModal,
    crfLoader,
    setFormUniqueId,
    setSelectedForm,
    selectedForm,
    builderModal,
    // studyDynamicForm,
    setBuilderModal,
    formUniqueId,
    rowUpdateModal,
    hideFormUpdatePopup,
    updateFormValues,
    updateFormOnchange,
    handleSubmit0,
    handleCheck,
    handleCheck1,
    handleCheck2,
    handleCheck3,
    showColumn,
    setRowUpdateModal,
    exportStudyLevelFormData,
    exportStudyLevelFormAuditTrialDataData,
    currentPage,
    setCurrentPage,
    count,
    limit,
    loader,
    fetchData,
    addFormMetadata,
    validatorPermission,
    tableColumn,
    tableColumn1,
    setIdUpdateRowTable,
    setIdReviewRowTable,
    handleSubmit3,
    getStudyRightSiteData,
    pushClientLevelForm,
    uploadedBy,
    jobCreatedDateTime,
    version,
    reviewedBy,
    status,
    reviewUpdatedDateTime,
    setTableShow,
    tableShow,
    studyVisitHistoryLists,
    setTableShow1,
    tableShow1,
    reviewHistoryLists,
    setReviewTableShow,
    reviewTableShow,
    attributeDetails,
  };

  return (
    <StudyVisitContext.Provider value={contextValue}>
      {children}
    </StudyVisitContext.Provider>
  );
};

export const UseStudyVisitContext = () => useContext(StudyVisitContext);
