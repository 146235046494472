import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
    handleResponse,
    headersWithAuth,
    headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const ctmsServices = {
    createStudyTreatmentColumn,
    getStudyTreatmentColumnData,
    updateStudyTreatmentColumn,
    getStudyTreatmentRowData,
    AddStudyTreatmentRow,
    UpdateStudyTreatmentRow,
    deleteStudyTreatmentRowData,
    getTablePropertyData,
};

async function getTablePropertyData(category, columnId, id) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}getTablePropertyData/${category}/${columnId}/${id}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

async function createStudyTreatmentColumn(datas) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}createStudyTreatmentColumn`,
        headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
}

async function getStudyTreatmentColumnData(studyID) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}getStudyTreatmentColumnData/${studyID}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

async function AddStudyTreatmentRow(datas) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}AddStudyTreatmentRow`,
        headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
}

async function getStudyTreatmentRowData(studyId, columnId) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}getStudyTreatmentRowData/${studyId}/${columnId}`,
        headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
}

async function updateStudyTreatmentColumn(userData) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}updateStudyTreatmentColumn`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    return data;
}

async function UpdateStudyTreatmentRow(userData) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}UpdateStudyTreatmentRow`,
        headersWithAuth("PUT", userData, headers)
    );
    const data = await response.json();
    return data;
}

async function deleteStudyTreatmentRowData(studyId, id) {
    const response = await fetch(
        `${config.API_URL_USER_ONBOARDING}deleteStudyTreatmentRowData/${studyId}/${id}`,
        headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
}



