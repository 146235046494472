import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const projectLevelFormServices = {
  createProjectLevelFormMeta,
  sendForReviewProjectLevelForm,
  getProjectLevelFormReviewDatas,
  updateProjectLevelForms,
  getProjectFormsHistoryByID,
  deleteProjectFormsData,
  getProjectLevelFormData,
  getProjectLevelFormForReviewer,
  getProjectLevelFormAttributeData,
  exportProjectLevelFormData,
  exportProjectLevelFormAuditTrialData,
  getProjectFormValidationData,
};

async function createProjectLevelFormMeta(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}createProjectLevelFormMeta`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function sendForReviewProjectLevelForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}sendForReviewProjectLevelForm`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getProjectLevelFormData(studyId, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectLevelFormData/${studyId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjectLevelFormForReviewer(studyId, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectLevelFormForReviewer/${studyId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjectFormValidationData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectFormValidationData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjectLevelFormReviewDatas(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectLevelFormReviewData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateProjectLevelForms(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateProjectLevelFormsMeta`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getProjectFormsHistoryByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectFormsHistoryByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function deleteProjectFormsData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteProjectFormsData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function exportProjectLevelFormData(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportProjectLevelFormData/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportProjectLevelFormAuditTrialData(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportProjectLevelFormAuditTrialData/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function getProjectLevelFormAttributeData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectLevelFormAttributeData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

