import React, { useState, useEffect } from 'react';
import './ClockPicker.css';

function ClockPicker() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectionMode, setSelectionMode] = useState('hours'); // Tracks the selection mode

  useEffect(() => {
    setSelectedTime(`${hours === 0 ? 12 : hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
  }, [hours, minutes, seconds]);

  const updateTime = (event) => {
    if (isDragging) {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left - rect.width / 2;
      const y = event.clientY - rect.top - rect.height / 2;

      const angle = Math.atan2(y, x) * (180 / Math.PI) + 90;
      const normalizedAngle = (angle + 360) % 360; // Normalize angle to be in the range [0, 360)

      if (selectionMode === 'hours') {
        const hour = Math.round((normalizedAngle % 360) / 30) % 12;
        setHours(hour === 0 ? 12 : hour);
      } else if (selectionMode === 'minutes') {
        const minute = Math.round((normalizedAngle % 360) / 6);
        setMinutes(minute * 5 % 60);
        setSeconds(0); // Reset seconds when minutes are adjusted
      } else if (selectionMode === 'seconds') {
        const second = Math.round((normalizedAngle % 360) / 6);
        setSeconds(second);
      }
    }
  };

  const handleNumberClick = (number) => {
    if (selectionMode === 'hours') {
      setHours(number);
      setMinutes(0);
      setSeconds(0);
      setSelectionMode('minutes'); // Switch to minutes selection
    } else if (selectionMode === 'minutes') {
      setMinutes(number);
      setSeconds(0);
      setSelectionMode('seconds'); // Switch to seconds selection
    } else if (selectionMode === 'seconds') {
      setSeconds(number);
      setSelectionMode('hours'); // Optionally switch back to hours selection
    }
  };

  const generateIndicators = (type) => {
    const indicators = [];
    const majorMarkers = type === 'minutes' ? [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] : (type === 'seconds' ? [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] : []);

    for (let i = 0; i < 60; i++) {
      const angle = (i * 6) - 90; // Calculate angle for each marker

      if (type === 'minutes') {
        // Major markers (5, 10, 15, etc.) and minor dots
        if (majorMarkers.includes(i)) {
          const x = 85 * Math.cos(angle * (Math.PI / 180));
          const y = 85 * Math.sin(angle * (Math.PI / 180));
          indicators.push({ x, y, number: i, show: true });
        } else {
          const x = 85 * Math.cos(angle * (Math.PI / 180));
          const y = 85 * Math.sin(angle * (Math.PI / 180));
          indicators.push({ x, y, number: null, show: false });
        }
      } else if (type === 'seconds') {
        // Major markers (5, 10, 15, etc.) with dots between
        if (majorMarkers.includes(i)) {
          const x = 85 * Math.cos(angle * (Math.PI / 180));
          const y = 85 * Math.sin(angle * (Math.PI / 180));
          indicators.push({ x, y, number: i, show: true });
        } else {
          const x = 85 * Math.cos(angle * (Math.PI / 180));
          const y = 85 * Math.sin(angle * (Math.PI / 180));
          indicators.push({ x, y, number: null, show: true }); // Always show dots between major markers
        }
      }
    }

    return indicators;
  };

  const minuteIndicators = generateIndicators('minutes');
  const secondIndicators = generateIndicators('seconds');

  return (
    <div
      className="clock"
      onMouseDown={() => setIsDragging(true)}
      onMouseUp={() => setIsDragging(false)}
      onMouseMove={updateTime}
    >
      {/* Hour hand */}
      <div className="hands hour-hand" style={{ transform: `rotate(${(hours % 12) * 30 + (minutes / 2)}deg)` }} />
      {/* Minute hand */}
      <div className="hands minute-hand" style={{ transform: `rotate(${minutes * 6}deg)` }} />
      {/* Second hand */}
      <div className="hands second-hand" style={{ transform: `rotate(${seconds * 6}deg)` }} />
      <div className="center"></div>
      <p className="time-display">{selectedTime}</p>
      {selectionMode === 'hours' ? (
        Array.from({ length: 12 }).map((_, index) => {
          const angle = (index + 1) * 30; // 30 degrees per hour marker
          const x = 85 * Math.cos((angle - 90) * (Math.PI / 180));
          const y = 85 * Math.sin((angle - 90) * (Math.PI / 180));
          const number = index + 1;

          return (
            <div
              key={index}
              className={`hour-number ${number === hours ? 'active' : ''}`}
              onClick={() => handleNumberClick(number)}
              style={{
                transform: `translate(${x}px, ${y}px)`,
                position: 'absolute',
                top: '47%',
                left: '48%',
                transformOrigin: 'center center',
                cursor: 'pointer',
                fontSize: 10
              }}
            >
              {number}
            </div>
          );
        })
      ) : selectionMode === 'minutes' ? (
        minuteIndicators.map((indicator, index) => (
          <React.Fragment key={index}>
            {/* Dot for each minor minute interval */}
            {!indicator.show && (
              <div
                className="minute-dot"
                style={{
                  transform: `translate(${indicator.x}px, ${indicator.y}px)`,
                }}
              />
            )}
            {/* Number for major minute intervals */}
            {indicator.show && (
              <div
                className={`minute-number ${indicator.number === minutes ? 'active' : ''}`}
                onClick={() => handleNumberClick(indicator.number)}
                style={{
                  transform: `translate(${indicator.x}px, ${indicator.y}px)`,
                }}
              >
                {indicator.number}
              </div>
            )}
          </React.Fragment>
        ))
      ) : (
        secondIndicators.map((indicator, index) => (
          <React.Fragment key={index}>
            {/* Dot for each second interval */}
            {indicator.number === null && (
              <div
                className="second-dot"
                style={{
                  transform: `translate(${indicator.x}px, ${indicator.y}px)`,
                }}
              />
            )}
            {/* Number for major second intervals */}
            {indicator.number !== null && (
              <div
                className={`second-number ${indicator.number === seconds ? 'active' : ''}`}
                onClick={() => handleNumberClick(indicator.number)}
                style={{
                  transform: `translate(${indicator.x}px, ${indicator.y}px)`,
                }}
              >
                {indicator.number}
              </div>
            )}
          </React.Fragment>
        ))
      )}
    </div>
  );
}

export default ClockPicker;
