import React, { useState, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaRegSquareFull, FaPen, FaFont } from "react-icons/fa6"; // Use appropriate icons
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const ACRF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedTool, setSelectedTool] = useState(null); // Tool state
  const [drawing, setDrawing] = useState(false); // Whether drawing is in progress
  const [drawData, setDrawData] = useState([]); // Stores the drawing data (shapes/annotations)
  const fileInputRef = useRef(null);
  const pdfContainerRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPdfFile(fileURL);
    }
  };

  const handleToolSelect = (tool) => {
    setSelectedTool(tool);
    setDrawing(false); // Stop drawing if tool is changed
    setDrawData([]); // Reset drawn shapes when changing tool
  };

  const handleMouseDown = (e) => {
    if (!selectedTool) return; // No tool selected

    const rect = pdfContainerRef.current.getBoundingClientRect();
    const startX = e.clientX - rect.left;
    const startY = e.clientY - rect.top;

    setDrawing(true);
    setDrawData((prevData) => [
      ...prevData,
      { tool: selectedTool, startX, startY, endX: startX, endY: startY },
    ]);
  };

  const handleMouseMove = (e) => {
    if (!drawing) return;

    const rect = pdfContainerRef.current.getBoundingClientRect();
    const endX = e.clientX - rect.left;
    const endY = e.clientY - rect.top;

    setDrawData((prevData) => {
      const newData = [...prevData];
      const currentDrawing = newData[newData.length - 1];
      currentDrawing.endX = endX;
      currentDrawing.endY = endY;
      return newData;
    });
  };

  const handleMouseUp = () => {
    setDrawing(false); // Stop drawing when mouse is released
  };

  const renderShape = (draw) => {
    const { tool, startX, startY, endX, endY } = draw;

    if (tool === "rectangle") {
      return (
        <rect
          x={Math.min(startX, endX)}
          y={Math.min(startY, endY)}
          width={Math.abs(endX - startX)}
          height={Math.abs(endY - startY)}
          fill="rgba(255, 0, 0, 0.3)"
          stroke="red"
        />
      );
    }

    if (tool === "circle") {
      const radius = Math.sqrt(
        Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
      );
      return (
        <circle
          cx={startX}
          cy={startY}
          r={radius}
          fill="rgba(0, 0, 255, 0.3)"
          stroke="blue"
        />
      );
    }

    if (tool === "pen") {
      return (
        <line
          x1={startX}
          y1={startY}
          x2={endX}
          y2={endY}
          stroke="black"
          strokeWidth={2}
        />
      );
    }

    if (tool === "text") {
      return (
        <text
          x={startX}
          y={startY}
          fontSize="12"
          fill="black"
          textAnchor="start"
        >
          Sample Text
        </text>
      );
    }

    return null;
  };

  return (
    <Container fluid>
      <Row>
        {/* PDF Viewer Section */}
        <Form.Group
          as={Col}
          md={8}
          className="p-0 border-end position-relative"
          style={{ height: "calc(100vh - 156px)" }}
          ref={pdfContainerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {pdfFile ? (
            <Worker
              workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}
            >
              <Viewer fileUrl={pdfFile} />
              {/* Annotation overlay */}
              <div
                className="annotation-overlay"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                }}
              >
                {drawData.map((draw, index) => (
                  <svg
                    key={index}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                    }}
                  >
                    {renderShape(draw)}
                  </svg>
                ))}
              </div>
            </Worker>
          ) : (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 165px)" }}
            >
              <p>No PDF selected</p>
            </div>
          )}
        </Form.Group>

        {/* Toolbar Section */}
        <Form.Group
          as={Col}
          md={4}
          className="pt-2 overflow-auto"
          style={{ height: "calc(100vh - 156px)", backgroundColor: "#f4f4f4" }}
        >
          <button
            className="w-100 selectedMenu border p-1 fontSize12 position-relative mb-2"
            onClick={() => fileInputRef.current.click()}
          >
            Upload Document
          </button>
          <Form.Control
            type="file"
            accept=".pdf"
            ref={fileInputRef}
            className="d-none"
            onChange={handleFileChange}
          />

          {/* Shape Tool Options */}
          <Form.Group className="my-2">
            <Form.Label className="mb-1 fw-bold">Tools</Form.Label>
            <div className="d-flex gap-2">
              <button
                className={`border hstack p-2 ${
                  selectedTool === "rectangle" ? "selected" : ""
                }`}
                onClick={() => handleToolSelect("rectangle")}
                title="Rectangle"
              >
                <FaRegSquareFull />
              </button>
              <button
                className={`border hstack p-2 ${
                  selectedTool === "circle" ? "selected" : ""
                }`}
                onClick={() => handleToolSelect("circle")}
                title="Circle"
              >
                <FaRegSquareFull />
              </button>
              <button
                className={`border hstack p-2 ${
                  selectedTool === "pen" ? "selected" : ""
                }`}
                onClick={() => handleToolSelect("pen")}
                title="Pen"
              >
                <FaPen />
              </button>
              <button
                className={`border hstack p-2 ${
                  selectedTool === "text" ? "selected" : ""
                }`}
                onClick={() => handleToolSelect("text")}
                title="Text"
              >
                <FaFont />
              </button>
            </div>
          </Form.Group>
        </Form.Group>
      </Row>
    </Container>
  );
};

export default ACRF;
