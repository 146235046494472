import React from 'react';

const ADRGPdfDataDetails = () => {

    const styles = {
        mainContainer: {
            fontSize: '14px',
            lineHeight: '1.6',
            color: '#333',
            margin: '20px'
        },
        heading: {
            textAlign: 'left',
            marginBottom: '10px',
            fontSize: "20px"
        },
        subHeading: {
            margin: '10px 0',
            fontSize: "20px",
            marginLeft: "15px"
        },
        subSubHeading: {
            margin: '10px 0',
            fontSize: "18px",
            marginLeft: "28px"
        },
        paragraph: {
            marginLeft: '44px'
        },
        paragraphs: {
            marginLeft: '77px'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginLeft: '20px',
            border: '1px solid black',
        },
        th: {
            border: '1px solid black',
            padding: '5px',
            fontWeight: 'bold',
            textAlign: 'left',
            fontSize: "13px",
            backgroundColor: "#80808085",
        },
        td: {
            border: '1px solid black',
            padding: '5px',
            fontSize: "12px"
        },
    };

    return (
        <>
            <div style={styles.mainContainer}>
                <h1 style={styles.heading}>3. Introduction</h1>

                <h2 style={styles.subHeading}>3.1 Purpose</h2>
                <p style={styles.paragraph}>
                    This  document  provides  context  for  the  analysis  datasets  and  terminology  that  benefit  from
                    additional  explanation  beyond  the  Data Definition  document (define.xml).    In  addition,  this
                    document provides a summary of ADaM conformance findings.
                </p>

                <h2 style={styles.subHeading}>3.2 Acronyms</h2>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Acronym</th>
                            <th style={styles.th}>Translation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>ADaM</td>
                            <td style={styles.td}>Analysis Data Model</td>
                        </tr>
                    </tbody>
                </table>

                <h2 style={styles.subHeading}>3.3 Study Data Standards and Dictionary Inventory</h2>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Standard or Dictionary</th>
                            <th style={styles.th}>Versions Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>ADaM</td>
                            <td style={styles.td}>ADaM Implementation Guide v1.3 </td>
                        </tr>
                        <tr>
                            <td style={styles.td}>Controlled Terminology</td>
                            <td style={styles.td}>CDISC ADAM CT as of 29MAR2024 plus user defined</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>Data Definitions</td>
                            <td style={styles.td}>define.xml v2.0</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>Drug Dictionary</td>
                            <td style={styles.td}>Not Applicable</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>Adverse Events Dictionary</td>
                            <td style={styles.td}>Not Applicable</td>
                        </tr>
                    </tbody>
                </table>

                <h2 style={styles.subHeading}>3.4  Source Data Used for Analysis Dataset Creation</h2>
                <p style={styles.paragraph}>
                    The ADaM datasets for this analysis were derived from the study data tabulation model (SDTM) and
                    ADaM datasets for the study.
                </p>
                <h1 style={styles.heading}>4. Study Description</h1>
                <h2 style={styles.subHeading}>4.1 Protocol Number and Title</h2>
                <p style={styles.paragraph}>
                    Protocol Number: 006-24
                    Protocol Title: A randomized, open label, balanced, two treatment, two period, two sequence, cross
                    over, oral bioequivalence study of Primidone Oral Suspension, 250 mg/5 mL (Dose: 50 mg/1 mL) of
                    Rubicon  Research  Private  Limited.,  India  comparing  with  Mysoline®  (primidone,  USP)  Tablets  50
                    mg manufactured for Valeant Pharmaceuticals North America LLC Bridgewater, NJ 08807, USA in
                    Healthy males and nonpregnant females subjects under fasting conditions.
                    Protocol Versions: 1, dated 01FEB2024
                    Errata to Protocol: Errata to Protocol No. 01, dated 02MAY2024
                </p>
                <h2 style={styles.subHeading}>4.2 Protocol Design</h2>
                <p style={styles.paragraph}>
                    A randomized, open label, balanced, three treatment, single period, single dose, parallel, truncated,
                    oral bioequivalence study with pharmacokinetic endpoints under fasting condition.
                </p>
                <h1 style={styles.heading}>5. Analysis Considerations Related to Multiple Analysis Datasets</h1>
                <h2 style={styles.subHeading}>5.1 Comparison of SDTM and ADaM Content</h2>
                <ul>
                    <li>
                        <p style={styles.paragraph}>
                            Are  data  for  screen  failures,  including  data  for  run-in  screening  (for  example,  SDTM  values  of
                            ARMCD=’SCRNFAIL’) included in ADaM datasets?
                            No.
                        </p>
                    </li>
                    <li>
                        <p style={styles.paragraph}>
                            Are data taken from an ongoing study?
                            No.
                        </p>
                    </li>
                    <li>
                        <p style={styles.paragraph}>
                            Additional Content of Interest
                        </p>
                    </li>
                    <li>
                        <p style={styles.paragraph}>
                            Were all subject’s data captured with all visits in PK analysis? No
                            If No, Specify the subject number and reason.
                            • Subject no 012 Dropout due to not reported to the study facility on the day of check in.
                        </p>
                    </li>
                    <li>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.th}>Variable Name</th>
                                    <th style={styles.th}>Variable Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={styles.td}>STUDYID</td>
                                    <td style={styles.td}>Study Identifier used for this protocol </td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>USUBJID</td>
                                    <td style={styles.td}>Unique Subject Identifier </td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>SUBJID</td>
                                    <td style={styles.td}>Subject Identifier for the Study </td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>SITEID</td>
                                    <td style={styles.td}>Study Site Identifier </td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>SEX</td>
                                    <td style={styles.td}>Sex </td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>RACE</td>
                                    <td style={styles.td}>Race</td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>SAFFL</td>
                                    <td style={styles.td}>Safety Population Flag </td>
                                </tr>
                                <tr>
                                    <td style={styles.td}>PKFL</td>
                                    <td style={styles.td}>Pharmacokinetic Population Flag </td>
                                </tr>

                            </tbody>
                        </table>
                    </li>
                </ul>
                <h2 style={styles.subHeading}>5.2 Treatment Variables</h2>
                <ul>
                    <li>
                        ARM versus TRTxxP
                        • Are the values of ARM equivalent in meaning to values of TRTxxP?
                        No,  Combination  of  TRT01P  and  TRT02P  is  a  direct  representation  of  ARM  from
                        individual studies.
                    </li>
                    <li>
                        ACTARM versus TRTxxA
                        • If TRTxxA is used, then are the values of ACTARM equivalent in meaning to values
                        of TRTxxA?
                        No, Combination of TRT01A and TRT02A is a direct representation of ACTARM from
                        individual studies.

                    </li>
                </ul>
                <h2 style={styles.subHeading}>5.3  Subject Issues that Require Special Analysis Rules</h2>
                <p style={styles.paragraph}>
                    There were no subjects who required any special analysis rules in this study.
                </p>
                <h2 style={styles.subHeading}>5.4  Use of Visit Windowing, Unscheduled Visits, and Record Selection</h2>
                <ul>
                    <li>
                        <p style={styles.paragraphs}>
                            • Was windowing used in one or more analysis datasets?
                            No visit windowing was done in the analysis datasets.
                        </p>
                    </li>
                    <li>
                        <p style={styles.paragraphs}>
                            • Were unscheduled visits used in any analyses?
                            Unscheduled visits were not used in analysis.
                        </p>
                    </li>
                </ul>
                <h2 style={styles.subHeading}>5.5 Imputation/Derivation Methods </h2>
                <p style={styles.paragraphs}>
                    If date imputation was performed, were there rules that were used in multiple analysis datasets?
                    No, date imputation was not performed.
                </p>
                <h1 style={styles.heading}>6. Analysis Data Creation and Processing Issues</h1>
                <h2 style={styles.subHeading}>6.1  Split Datasets</h2>
                <p style={styles.paragraph}>
                    There are no datasets that required splitting due to size constraints.
                </p>
                <h2 style={styles.subHeading}>6.2  Data Dependencies </h2>
                <p style={styles.paragraph}>
                    ADSL was used in the creation of ADAE, ADCM, ADPC and ADPP dataset.
                </p>
                <h2 style={styles.subHeading}>6.3   Intermediate Datasets </h2>
                <p style={styles.paragraph}>
                    No intermediate analysis datasets were created.
                </p>

                <h1 style={styles.heading}>7. Analysis Dataset Descriptions</h1>
                <h2 style={styles.subHeading}>7.1  Overview</h2>
                <p style={styles.paragraph}>
                    Do the analysis datasets support all protocol- and statistical analysis plan-specified objectives?
                    Yes. All analyses were done using the ADaM datasets as input.
                </p>
                <h2 style={styles.subHeading}>7.2  Analysis Datasets</h2>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Dataset</th>
                            <th style={styles.th}>Dataset Label</th>
                            <th style={styles.th}>Class</th>
                            <th style={styles.th}>Efficacy</th>
                            <th style={styles.th}>Safety</th>
                            <th style={styles.th}>Baseline or
                                other subject
                                characteristics</th>
                            <th style={styles.th}>Structure</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>ADSL</td>
                            <td style={styles.td}>Subject-Level
                                Analysis Dataset </td>
                            <td style={styles.td}>Subject Level
                                Analysis
                                Dataset </td>
                            <td style={styles.td}></td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>X</td>
                            <td style={styles.td}>One observation per subject </td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADAE</td>
                            <td style={styles.td}>Adverse Event
                                Analysis Datase</td>
                            <td style={styles.td}>Occurrence
                                Data Structure
                                (ODS)</td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>X</td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>One record per adverse event per
                                subject  recorded  in  SDTM  AE
                                domain </td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADCM</td>
                            <td style={styles.td}>Concomitant
                                Medications
                                Analysis Dataset</td>
                            <td style={styles.td}>Occurrence
                                Data Structure
                                (ODS) </td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>X</td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>One record per recorded
                                intervention occurrence or
                                constant-dosing interval per
                                subject recorded in SDTM CM</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADPC</td>
                            <td style={styles.td}>PK
                                Concentrations
                                Analysis Dataset</td>
                            <td style={styles.td}>Basic Data
                                Structure
                                (BDS)</td>
                            <td style={styles.td}>X</td>
                            <td style={styles.td}></td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>One record per sample
                                characteristic or time-point
                                concentration  per  reference  time
                                point  or  per  analyte  per  subject
                                recorded in SDTM PC </td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADPP</td>
                            <td style={styles.td}>PK Parameters
                                Analysis Dataset</td>
                            <td style={styles.td}>Basic Data
                                Structure
                                (BDS</td>
                            <td style={styles.td}>X</td>
                            <td style={styles.td}></td>
                            <td style={styles.td}></td>
                            <td style={styles.td}>One record per PK parameter
                                per time-concentration profile
                                per  subject  recorded  in  SDTM
                                PP </td>
                        </tr>
                    </tbody>
                </table>

                <h3 style={styles.subSubHeading}>7.2.1  ADSL – Subject Level Analysis Dataset</h3>
                <p style={styles.paragraphs}>
                    This dataset  was  created  to  include  subject level  information  of  the  studies  and  create  relevant
                    variables and flags.
                    - The  variables  TRT01P  (Planned  Treatment  for  Period  01)  and  TRT02P  (Planned  Treatment
                    for Period 02) identifies planned treatment assignment for each period.
                    - The variables TRT01A (Actual Treatment for Period 01) and TRT02A (Actual Treatment for
                    Period 02) identifies actual treatment assignment for each period.
                </p>
                <p style={styles.paragraphs}>
                    The following variable information is included in the dataset:
                </p>
                <ul>
                    <li>
                        Study Identifiers: Study Identifier, Subject Identification Number and Site Number
                    </li>
                    <li>
                        Demographic variables: Race and Sex
                    </li>
                    <li>
                        Treatment related information:
                    </li>
                    <p style={styles.paragraphs}>
                        Planned treatment for periods - TRT01P and TRT02P
                        Actual treatment received - TRT01A and TRT02A
                        First  dose  date  and  last  dose  date  for  the  corresponding  periods  –  TR01SDT,
                        TR01EDT, TR02SDT and TR02EDT
                    </p>
                </ul>
                <h3 style={styles.subSubHeading}>7.2.2   ADAE – Adverse Events Analysis Dataset </h3>
                <p style={styles.paragraphs}>
                    This dataset includes all records of AEs from the study
                    The dataset includes information about the AEs as follows:
                    <ul>
                        <li>
                            erminology variables – reported term, dictionary term, body system or organ class
                        </li>
                        <li>
                            Classification variables – was it a serious event, severity/intensity as evaluated by the
                            investigator, causality, duration
                        </li>
                        <li>
                            Outcome variables – action take with study treatment, outcome of AEs
                            Variables  AELLT, AELLTCD,  AEPTCD,  AEHLT,  AEHLTCD,  AEHLGT,  AEHLGTCD,
                            AEBODSYS,  AEBDSYCD,  AESOC  and  AESOCCD  are  null  as  MedDRA  coding  is  not
                            mandatory
                        </li>
                    </ul>
                </p>
                <h3 style={styles.subSubHeading}>7.2.3   ADCM – Concomitant Medications Analysis Datase </h3>
                <p style={styles.paragraphs}>
                    This dataset includes all records of Concomitant Medications.
                    The dataset includes information about the CMs as follows:
                    <ul>
                        <li>
                            Terminology variables – reported term, and dictionary term
                        </li>
                        <li>
                            Classification variables – Start of concomitant medication and study day of
                            medication
                        </li>
                    </ul>
                </p>
                <h3 style={styles.subSubHeading}>7.2.4  ADPC – PK Concentrations Analysis Dataset </h3>
                <p style={styles.paragraphs}>
                    This dataset includes all records of Pharmacokinetic Concentrations.
                    The dataset includes information about the PCs as follows:
                    <ul>
                        <li>
                            Pharmacokinetic  Concentrations  variables  –  Pharmacokinetic  Concentrations  and
                            Pharmacokinetic Concentrations codes
                        </li>
                        <li>
                            Result variables – Result of Pharmacokinetic Concentrations
                        </li>
                    </ul>
                </p>
                <h3 style={styles.subSubHeading}>7.2.5  ADPP – PK Parameters Analysis Dataset  </h3>
                <p style={styles.paragraphs}>
                    This dataset includes all records of Pharmacokinetic Parameters.
                    The dataset includes information about the PPs as follows:
                    <ul>
                        <li>
                            Pharmacokinetic  Concentrations  variables  –  Pharmacokinetic  Concentrations  and
                            Pharmacokinetic Concentrations codes
                        </li>
                        <li>
                            Result variables – Result of Pharmacokinetic Parameters
                        </li>
                    </ul>
                </p>
                <h1 style={styles.heading}>8. Data Conformance Summary</h1>
                <h2 style={styles.subHeading}>8.1  Conformance Inputs  </h2>
                <p style={styles.paragraph}>
                    Were the analysis datasets evaluated for conformance with CDISC ADaM Validation
                    Checks? Yes <br />
                    If yes: Version of CDISC ADaM Validation Checks: Pinnacle 21 v4.1.0 <br />
                    • Were the ADaM datasets evaluated in relation to define.xml? Yes <br />
                    Yes, the ADaM datasets were evaluated in relation to define.xml.
                    • Was define.xml evaluated? Yes <br />
                    Yes, the define.xml was evaluated using config-define-2.0.0.xml.
                </p>
                <h2 style={styles.subHeading}>8.2  Issues Summary – ADaM Dataset </h2>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Dataset</th>
                            <th style={styles.th}>Diagnostic Message and/or Check ID</th>
                            <th style={styles.th}>Count/
                                Issue
                                Rate </th>
                            <th style={styles.th}>Explanation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>ADCM</td>
                            <td style={styles.td}>Required variable is not present</td>
                            <td style={styles.td}>1</td>
                            <td style={styles.td}>“--TERM”  is  not  requierd  variable  hence
                                ignore</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADCM</td>
                            <td style={styles.td}>Secondary variable
                                APERIODC is populated, but
                                its  primary  variable  APERIOD
                                is not populated</td>
                            <td style={styles.td}>1</td>
                            <td style={styles.td}>POST-STUDY is not a study period hence
                                APERIOD is missing</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADPC</td>
                            <td style={styles.td}>AVAL value is null when
                                (PARAMCD == 'A')</td>
                            <td style={styles.td}>2</td>
                            <td style={styles.td}>AVAL value is missing hence this
                                Warning can be ignore.</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADPC</td>
                            <td style={styles.td}>AVAL value is null when
                                (PARAMCD == 'B') </td>
                            <td style={styles.td}>9</td>
                            <td style={styles.td}>AVAL value is missing hence this
                                Warning can be ignore.</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADPP</td>
                            <td style={styles.td}>AVAL value is longer than
                                defined max length 8 when
                                (PARAMCD == 'AUCIFO') </td>
                            <td style={styles.td}>55</td>
                            <td style={styles.td}>AVAL  is numerical  variable  which can
                                have maximum length 8 but can store
                                value more than 8 digit hence ignore</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ADPP</td>
                            <td style={styles.td}>AVAL value is longer than
                                defined max length 8 when
                                (PARAMCD == 'AUCLST')</td>
                            <td style={styles.td}>54</td>
                            <td style={styles.td}>AVAL  is numerical  variable  which can
                                have maximum length 8 but can store
                                value more than 8 digit hence ignore</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ADRGPdfDataDetails;

