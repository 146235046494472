import React, { useEffect, useState } from "react";
import VisitReportChart from "./VisitReportChart";
import SplitPane from "react-split-pane";
import SubjectSDVDonutGraph from "./SubjectSDVDonutGraph";
import SubjectEntryProgressGraph from "./SubjectEntryProgressGraph";
import QueryReportChart from "./QueryReportChart";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../../../Services/studyServices/studySubjectsServices";

const SubjectReport = ({subjectUniqueId}) => {
  const [subjectEntryProgress, setSubjectEntryProgress] = useState(0);
  const [subjectSDVGraph, setSubjectSDVGraph] = useState([]);
  const [subjectQueryGraph, setSubjectQueryGraph] = useState([]);
  const [visitReportGraph, setVisitReportGraph] = useState([]);
  const { studyId } = useParams();
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const getStudySubjectEntryProgress = async () => {
    let data = await studyOnboardingService.getStudySubjectEntryProgress(
      studyId,
      subjectUniqueId,
      studyMode
    );
    setSubjectEntryProgress(data.data || 0);
  };

  const getStudySubjectSDVGraphData = async () => {
    let data = await studyOnboardingService.getStudySubjectSDVGraphData(
      studyId,
      subjectUniqueId
    );
    setSubjectSDVGraph(data.data || []);
  };

  const getStudySubjectQueryGraphData = async () => {
    let data = await studyOnboardingService.getStudySubjectQueryGraphData(
      studyId,
      subjectUniqueId,
      studyMode
    );
    setSubjectQueryGraph(data.data || []);
  };

  const getStudyVisitRowDataBySubjectGraphData = async () => {
    let data = await studyOnboardingService.getStudyVisitRowDataBySubjectGraphData(
      studyId,
      subjectUniqueId,
      studyMode,
    );
    setVisitReportGraph(data.data || []);
  };

  useEffect(() => {
    getStudySubjectEntryProgress();
    getStudySubjectSDVGraphData();
    getStudySubjectQueryGraphData();
    getStudyVisitRowDataBySubjectGraphData()
  }, [subjectUniqueId]);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "80%" }}
      >
        <div className="m-2">
          <div className="d-flex gap-2">
            <div className="bg-light border" style={{width: 350}}>
              <VisitReportChart visitReportGraph={visitReportGraph} />
            </div>
            <div className="bg-light border" style={{width: 350}}>
              <QueryReportChart subjectQueryGraph={subjectQueryGraph} />
            </div>
            <div className="bg-light border" style={{width: 350}}>
              <SubjectSDVDonutGraph subjectSDVGraph={subjectSDVGraph}/>
            </div>
            <div className="bg-light border" style={{width: 350}}>
              <SubjectEntryProgressGraph subjectEntryProgress={subjectEntryProgress} />
            </div>
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default SubjectReport;
