import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import Moment from "react-moment";
import UseToggle from "../../../../../../../Hooks/UseToggle";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySubjectsServices";
import SplitPane from "react-split-pane";

const UpdatedFieldHistory = ({ subjectUniqueId, tableRef, onMouseDown }) => {
  const [subjectHistory, setSubjectHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [comment, setComment] = useState([]);

  const { setToggle, toggle } = UseToggle();

  const getStudySubjectHistoryByID = async (subjectUniqueId) => {
    let data = await studyOnboardingService.getStudySubjectHistoryByID(
      subjectUniqueId
    );
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setSubjectHistory(finalData);
  };

  useEffect(() => {
    getStudySubjectHistoryByID(subjectUniqueId);
  }, [subjectUniqueId]);

  useEffect(() => {
    const rows = document.querySelectorAll("tr");

    const handleContextMenu = (event, row) => {
      event.preventDefault();
      rows.forEach((row) => row.classList.remove("highlighted"));
      row.classList.add("highlighted");
    };

    rows.forEach((row) => {
      row.addEventListener("contextmenu", (event) =>
        handleContextMenu(event, row)
      );
    });

    return () => {
      rows.forEach((row) => {
        row.removeEventListener("contextmenu", (event) =>
          handleContextMenu(event, row)
        );
      });
    };
  }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (i, item) => {
    setSelectedRow(i);
    setHistory(item);
  };

  const handleRowComment = (e, comment) => {
    setToggle();
    setComment(comment);
  };

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="30%">
        <div>
          <div
            className="text-center white position-sticky"
            style={{ fontSize: 13, background: "var(--blue)", top: -1 }}
          >
            Metadata
          </div>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0  custom-table"
              ref={tableRef}
              bordered
            >
              <thead className="thead-sticky" style={{ top: 18 }}>
                <tr>
                  <th
                    style={{ width: 55 }}
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    S No.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>
                    Management Name
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Latest version</th>
                </tr>
              </thead>
              <tbody>
                {subjectHistory?.map((item, i) => (
                  <tr
                    key={i}
                    onClick={(e) => {
                      handleRowClick(i, item?.items);
                    }}
                    className="CP"
                    style={{
                      background: selectedRow === i ? "#ccd6dd" : "",
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{item.columnName}</td>
                    <td>{item.version}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div>
          <div
            className="text-center white position-sticky"
            style={{ fontSize: 13, background: "var(--blue)", top: -1 }}
          >
            History
          </div>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0  custom-table"
              ref={tableRef}
              bordered
            >
              <thead className="thead-sticky" style={{ top: 18 }}>
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 0)}>Version</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Date | Time</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>User Id</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Comment</th>
                </tr>
              </thead>
              <tbody>
                {(history?.length === 0
                  ? subjectHistory?.[0]?.items
                  : history
                )?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.version}</td>
                    <td>{item.value}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm">
                        {item.createdAt}
                      </Moment>
                    </td>
                    <td>{item.userId}</td>
                    <td>
                      <div
                        onClick={(e) => handleRowComment(e, item.comment)}
                        style={{ cursor: "pointer" }}
                      >
                        view
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </SplitPane>

      <Modal
        show={!toggle}
        className={!toggle ? "" : "d-none"}
        onHide={setToggle}
        style={{ background: "#7f7f7fbf" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>{comment ? comment : "No Comment"}</>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatedFieldHistory;
