import React, { useState } from "react";
import SplitPane from "react-split-pane";
import DocumentsTableData from "./DocumentsTableData";
import DocumentsTableProperties from "./DocumentsTableProperties";
import DocumentsFilter from "./DocumentsFilter";
import UseToggle from "../../../../../Hooks/UseToggle";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../NoDataFoundTable/NoDataFoundTable";

const Documents = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const CodeList = Array.from({ length: 15 }, (_, index) => ({
    _id: index + 1,
    codeListId: ["List A", "List B", "List C"][Math.floor(Math.random() * 3)],
    codeListName: `Code List ${Math.floor(Math.random() * 15) + 1}`,
    nciClodeListCode: `NCI${Math.floor(10000 + Math.random() * 90000)}`,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }));

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-filter"
            title="Filter"
            onClick={setToggle1}
          ></i>
        </div>
        {isFullScreen ? (
          <i
            class="fa-solid fa-maximize"
            title="Original Mode"
            onClick={toggleFullScreen}
          ></i>
        ) : (
          <i
            class="fa-solid fa-minimize"
            title="Full Mode"
            onClick={toggleFullScreen}
          ></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : CodeList?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <DocumentsTableData
                  CodeList={CodeList}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <DocumentsTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <DocumentsFilter
        Show={!toggle1}
        Hide={setToggle1}
        // getCDISCDatasets={getCDISCDatasets}
        Title={"Filter Document"}
      />
    </div>
  );
};

export default Documents;
