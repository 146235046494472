import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck, FaXmark } from "react-icons/fa6";

const DocumentsFilter = ({ Show, Hide, Title, getCDISCComments }) => {
  const [domain, setDomain] = useState("");

  const clearFilterData = () => {
    setDomain("");
    Hide();
    getCDISCComments();
  };

  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="pt-2 px-3 overflow-auto"
            style={{ height: "calc(100vh - 84px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Domain Id</Form.Label>
              <Form.Select onChange={(e) => setDomain(e.target.value)}>
                <option value="">Select Domain Id</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="justify-content-end py-2 px-3 shadow-sm bg-light w-100 hstack gap-2">
            <button
              className="updateProfile"
              onClick={() => clearFilterData()}
            >
              <FaXmark /> Clear
            </button>
            <button className="updateProfile">
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DocumentsFilter;
