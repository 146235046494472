import React, { useEffect, useRef, useState } from "react";
import { PiTextColumns } from "react-icons/pi";
import { MdFormatAlignCenter } from "react-icons/md";
import { BsListColumns, BsListColumnsReverse } from "react-icons/bs";
import { Collapse, ListGroup } from "react-bootstrap";

const LayoutColumnDesign = ({ onSelectColumn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleColumnSelect = (columns) => {
    onSelectColumn(columns);
    setIsOpen(false);
  };

  return (
    <>
      <div ref={dropdownRef}>
        <div onClick={toggleOpen}>Columns</div>
        <Collapse in={isOpen}>
          <div
            className="position-absolute start-0"
            style={{ width: 150, top: 20 }}
          >
            <ListGroup>
              <ListGroup.Item
                onClick={() => {
                  setIsOpen(false);
                  handleColumnSelect(1);
                }}
              >
                One
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setIsOpen(false);
                  handleColumnSelect(2);
                }}
              >
                Two
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setIsOpen(false);
                  handleColumnSelect(3);
                }}
              >
                Three
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setIsOpen(false);
                  handleColumnSelect("left");
                }}
              >
                Left
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setIsOpen(false);
                  handleColumnSelect("right");
                }}
              >
                Right
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default LayoutColumnDesign;
