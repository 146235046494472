import React, { useEffect, useState } from "react";
import { Collapse, Form, InputGroup, Spinner } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import STDMCodeListTableProperties from "./STDMCodeListTableProperties";
import STDMCodeListTableData from "./STDMCodeListTableData";
import { CgChevronDown } from "react-icons/cg";
import Properties from "./Properties";
import { GoSearch } from "react-icons/go";
import UseToggle from "../../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { useParams } from "react-router";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../NoDataFoundTable/NoDataFoundTable";

const STDMCodeList = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [codeList, setCodeList] = useState([]);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const [codeListId, setCodeListId] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  const { studyId } = useParams();

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getCDISCCodeLists = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCCodeLists(studyId);
    setCodeList(data?.data || []);
    setLoader(false);
  };

  useEffect(() => {
    getCDISCCodeLists();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-magnifying-glass"
            title="Search"
            onClick={() => setOpenFilter(!openFilter)}
          ></i>
          <Collapse in={openFilter} dimension="width">
            <div>
              <InputGroup style={{ width: 200 }}>
                <Form.Control
                  type="search"
                  className="px-2"
                  placeholder="Search..."
                  style={{ fontSize: 10, borderRadius: "0", padding: 0 }}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  style={{ padding: "1px 5px" }}
                >
                  <GoSearch />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Collapse>
        </div>
        {isFullScreen ? (
          <i
            class="fa-solid fa-maximize"
            title="Original Mode"
            onClick={toggleFullScreen}
          ></i>
        ) : (
          <i
            class="fa-solid fa-minimize"
            title="Full Mode"
            onClick={toggleFullScreen}
          ></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={isOpenProperty ? "40%" : "100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : codeList?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <STDMCodeListTableData
                  studyId={studyId}
                  codeList={codeList}
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  getCDISCCodeLists={getCDISCCodeLists}
                  setIsOpenProperty={setIsOpenProperty}
                  setCodeListId={setCodeListId}
                />
              )}
            </>
          </SplitPane>
          {isOpenProperty && (
            <div
              className={`${isHalfScreen ? "full-screen" : ""}`}
              style={{ height: "100%" }}
            >
              <div
                className="hstack justify-content-between border-bottom px-2 pb-0 bg-light"
                style={{ padding: 5 }}
              >
                <div className="hstack me-1 gap-1">
                  {["properties"].map((tab) => (
                    <button
                      key={tab}
                      className={`buttonForTabs px-2 ${
                        activeTabs === tab ? "activee" : ""
                      }`}
                      onClick={() => setActiveTabs(tab)}
                      title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                      style={{ borderRadius: "3px 3px 0px 0px" }}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                <div className="hstack gap-1">
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={"Hide"}
                    onClick={() => setIsOpenProperty(false)}
                  >
                    <CgChevronDown />
                  </button>
                  <button
                    className={`tabButtons border-0 fs-13 px-2`}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleHalfScreen}
                  >
                    {isHalfScreen ? <BiCollapse /> : <BiExpand />}
                  </button>
                </div>
              </div>
              <SplitPane
                split="horizontal"
                className="position-relative"
                defaultSize="100%"
              >
                {activeTabs === "properties" ? (
                  <Properties codeListId={codeListId} />
                ) : (
                  ""
                )}
              </SplitPane>
            </div>
          )}
        </SplitPane>
      </div>
      <STDMCodeListTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default STDMCodeList;
