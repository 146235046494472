import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Swal from "sweetalert2";
import { useParams } from "react-router";

const AddSDAForm = ({
  getCDISCSDA,
  sourceDataId,
  setSourceDataId,
  versionId,
}) => {
  let [sdtmClass, setSDTMClass] = useState([]);
  let [domain, setDomain] = useState([]);
  let [domainId, setDomainId] = useState("");
  let [sourceDatasetName, setSourceDatasetName] = useState("");
  let [sourceDatasetLabel, setSourceDatasetLabel] = useState("");
  const { studyId } = useParams();

  const importCDISCSDA = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.targetDomainId = domainId;
    userData.sourceDatasetName = sourceDatasetName;
    userData.sourceDatasetLabel = sourceDatasetLabel;
    let data = await cdiscServices.importCDISCSDA(userData);
    if (data?.statusCode === 200) {
      getCDISCSDA();
    }
  };

  const updateCDISCSDA = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this SDA?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = sourceDataId._id;
        userData.studyId = studyId;
        userData.targetDomainId = domainId;
        userData.sourceDatasetName = sourceDatasetName;
        userData.sourceDatasetLabel = sourceDatasetLabel;
        let data = await cdiscServices.updateCDISCSDA(userData);
        if (data?.statusCode === 200) {
          getCDISCSDA();
          setSourceDataId("");
        }
      }
    });
  };

  const getActiveClass = async (versionId) => {
    let data = await cdiscServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await cdiscServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const clearFormData = () => {
    setSourceDatasetName("");
    setSourceDatasetLabel("");
    setSourceDataId("");
    setDomainId("");
  };

  useEffect(() => {
    setSourceDatasetName(sourceDataId?.sourceDatasetName);
    setSourceDatasetLabel(sourceDataId?.sourceDatasetLabel);
    setDomainId(sourceDataId?.targetDomainId);
  }, [sourceDataId]);

  useEffect(() => {
    getActiveClass(versionId);
  }, [versionId]);

  return (
    <div className="p-2">
      {!sourceDataId && (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Class <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
               
              style={{ fontSize: 11 }}
              onChange={(e) => {
                getActiveDomains(e.target.value);
              }}
            >
              <option value="">Select Class</option>
              {sdtmClass?.map((item, index) => (
                <option
                  selected={item.classId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.class}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Target Domain Name <span className="text-danger">*</span>
            </Form.Label>

            <Form.Select
               
              style={{ fontSize: 11 }}
              onChange={(e) => setDomainId(e.target.value)}
              disabled={sourceDataId}
            >
              <option value="">Select Domain</option>
              {domain?.map((item, index) => (
                <option
                  selected={item.targetDomainId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.domain}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}
      {sourceDataId && (
        <Form.Group className="mb-2">
          <div className="hstack justify-content-between">
            <Form.Label className="mb-1 fw-bold">
              Target Domain Name <span className="text-danger">*</span>
            </Form.Label>
            {sourceDataId && (
              <button
                className="text-white border-success bg-success border-0"
                style={{fontSize: 10, borderRadius: 3}}
                title="Add new SDA"
                onClick={() => clearFormData()}
              >
                <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
              </button>
            )}
          </div>
          <Form.Control
             
            type="text"
            disabled
            value={sourceDataId?.targetDomain}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Source Dataset Name <span className="text-danger">*</span>
        </Form.Label>

        <Form.Control
          style={{ fontSize: 11 }}
           
          required
          type="text"
          placeholder="Enter Source Dataset Name"
          value={sourceDatasetName}
          onChange={(e) => setSourceDatasetName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Source Dataset Label <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
           
          style={{ fontSize: 11 }}
          required
          type="text"
          placeholder="Enter Source Dataset Label"
          value={sourceDatasetLabel}
          onChange={(e) => setSourceDatasetLabel(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="text-end">
        <button
          className="updateProfile"
          onClick={() => (sourceDataId ? updateCDISCSDA() : importCDISCSDA())}
        >
          <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
          {!sourceDataId ? "Import" : "Update"} Dataset
        </button>
      </Form.Group>
    </div>
  );
};

export default AddSDAForm;
