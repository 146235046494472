import React from "react";
import ToolTips from "./ToolTip";

const ToolTipWrapper = ({ children, ToolTip, Right }) => (
  <span className="tooltips">
    {children}

    {ToolTip && <ToolTips Right={Right} ToolTip={ToolTip} />}
  </span>
);

export default ToolTipWrapper;
