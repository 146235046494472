import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const QueryReportChart = ({ subjectQueryGraph }) => {
  const [subjectQueryGraphBarGraph, setSubjectQueryGraphBarGraph] = useState({
    series: [
      {
        name: "No. of Query",
        data: subjectQueryGraph || [],
      },
    ],

    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      stroke: { width: 1, colors: ["#fff"] },
      plotOptions: {
        bar: { horizontal: true },
      },
      xaxis: {
        categories: ["Open", "Closed", "Cancelled", "Answered"],
        labels: {
          style: {
            colors: ["#103C5E8C", "#103C5E99", "#103C5E", "#103C5E"],
            fontSize: "12px",
          },
        },
      },
      fill: { opacity: 1 },
      colors: ["#103C5E8C", "#103C5E99", "#103C5E", "#103C5E"],
      legend: {
        position: "bottom",
        showForSingleSeries: true,
      },
    },
  });

  useEffect(() => {
    setSubjectQueryGraphBarGraph((prevChartData) => ({
      ...prevChartData,
      series: [{ data: subjectQueryGraph || [] }],
    }));
  }, [subjectQueryGraph]);

  return (
    <>
      <ReactApexChart
        options={subjectQueryGraphBarGraph.options}
        series={subjectQueryGraphBarGraph.series}
        type="bar"
        height={240}
      />
    </>
  );
};

export default QueryReportChart;
