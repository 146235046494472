import React from "react";
import footer from "../../Styles/Footer.module.scss"

const Footer = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const formattedDate = `${year}-${month}-${day}`;
  return (
    <div className={footer.footer}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className={footer.innerFooter}>
              <p>
                Copyright <i className="fas fa-copyright" /> Galax Crop
              </p>
              <p>{formattedDate}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;
