import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { UseCrfViewContext } from "../../../../../../../Contexts/UseCrfViewContext";
import SplitPane from "react-split-pane";
import { clientLevelFormServices } from "../../../../../../../Services/formServices/clientLevelFormServices";
import { useParams } from "react-router";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../../../NoDataFoundTable/NoDataFoundTable";

const SiteSubjectSpecialForm = ({
  handleAddNewTab,
  subjectUniqueId,
  selectedStudySiteId,
  selectedStudySubjectId,
  studySubjectState,
  siteIdData,
  subjectStudyUniqueId
}) => {
  const { studyId } = useParams();
  const {
    setQueryGenerateEndpoint,
    setGetTicketsEndPoint,
    setGetQueryListEndPoint,
    setValidationCheckListsEndpoint,
    setDataEntryFormByIdEndPoint,
    setReviewHistoryEndpoint,
    setFormFieldHistoryEndpoint,
    setReviewFormFieldEndpoint,
    setQueryReplyEndpoint,
    setQueryCommentEndpoint,
    setUpdateTicketStatusEndPoint,
    setDataEntryLockFreezeFormByIdEndPoint,
    setGetGlobalQueryStatusEndPoint,
    setDataEnteriesValueShow,
  } = UseCrfViewContext();
  const [studyDynamicForm, setStudyDynamicForm] = useState([]);
  const [loader, setLoader] = useState(false);

  const addCrfViewReportTabs = (item) => {
    let IdObject = {
      formName: item.name,
      formType: "specialForm",
      siteName: selectedStudySiteId,
      subjectName: selectedStudySubjectId,
      formUniqueId: item?.formUniqueId,
      selectedStudyVisitsForm: item?.form,
    };
    handleAddNewTab(
      `${item?.name} Report`,
      "fa-solid fa-file",
      `formReportManegement_${item?._id}`,
      IdObject
    );
  };

  const addCrfViewTabs = (item) => {
    let IdObject = {
      formName: item.name,
      formType: "specialFormType",
      formUniqueId: item?.formUniqueId,
      visitFormType: item?.formType,
      selectedStudyVisitsForm: item?.name,
      dataEntryFormMode: "specialForm",
      removeNevigation: false,
      studyUniqueId: subjectUniqueId,
      fieldReviewStatus: item?.fieldReviewedStatus,
    }
    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
    }
    let subjectBreadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject List` }
    ]
    let idSpecialObject = {
      studyUniqueId: subjectStudyUniqueId,
      selectedStudySubjectId: selectedStudySubjectId,
      siteIdData: siteIdData,
    };

    let specialBreadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject: ${selectedStudySubjectId}`, currentTabName: `${selectedStudySiteId} Subject`, uniqueId: `studySubject_${siteIdData}`, pageId: idSubjectObject, breadcrumb: subjectBreadcrumb },
      { tabName: `Special Form List` }
    ]

    let breadcrumb = [
      { tabName: `Site: ${selectedStudySiteId}`, currentTabName: `Study Site`, uniqueId: `studySite` },
      { tabName: `Subject: ${selectedStudySubjectId}`, currentTabName: `${selectedStudySiteId} Subject`, uniqueId: `studySubject_${siteIdData}`, pageId: idSubjectObject, breadcrumb: subjectBreadcrumb },
      { tabName: `Special Form`, currentTabName: `${selectedStudySubjectId} Subject Special Form`, uniqueId: `subjectSpecialForm_${siteIdData}`, pageId: idSpecialObject, breadcrumb: specialBreadcrumb },
      { tabName: item.name },
    ]

    handleAddNewTab(`${item?.name} Special Form`, "fa-solid fa-form", `studyForm_${item?._id}`, IdObject, breadcrumb)
    setDataEnteriesValueShow("getdataEntryFormByIdForSpecialForm");
    setDataEntryLockFreezeFormByIdEndPoint(
      "getVisitsDataEntryLockFreezeFormByFormField"
    );

    setQueryGenerateEndpoint("DataEntryQueryGenerator");
    setGetTicketsEndPoint("getDataEntryTickets");
    setQueryReplyEndpoint("DataEntryQueryReply");
    setQueryCommentEndpoint("DataEntryQueryComment");
    setGetQueryListEndPoint("getDataEntryQuery");
    setUpdateTicketStatusEndPoint("DataEntryUpdateTicketStatus");
    setReviewFormFieldEndpoint("reviewDataEntryFormField");
    setReviewHistoryEndpoint("getDataEntryFormFieldReviewHistoryByID");
    setGetGlobalQueryStatusEndPoint("getDataEntryTicketStatus");
    setValidationCheckListsEndpoint("getDataEntryFormFieldValidation");
    setDataEntryFormByIdEndPoint("getdataEntryFormByIdForSpecialForm");
    setFormFieldHistoryEndpoint("getDataEntryFormFieldValueHistoryById");
  };

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  let limit = 100
  let getStudyVisitsFormAPI =
    StudyMode === "DBP MODE" || StudyMode === "Live Mode" || StudyMode === "QC Mode"
      ? clientLevelFormServices.getStudySpecialFormData
      : clientLevelFormServices.getStudySpecialFormDataForReviewer;

  const getStudyVisitFormData = async () => {
    setLoader(true)
    let data = await getStudyVisitsFormAPI(
      studyId,
      subjectUniqueId,
      StudyMode,
      1,
      limit
    );
    let finalData = data?.data?.sort().reverse();
    setStudyDynamicForm(finalData || []);
    setLoader(false)
  };

  useEffect(() => {
    getStudyVisitFormData();
  }, [subjectUniqueId]);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        {loader ?
          <SpinerDataLoader />
          : studyDynamicForm?.length === 0 ?
            <NoDataFoundTable MSG="Special Form not found" />
            :
            <Table className="m-0 custom-table" hover striped>
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: 45 }}>S No.</th>
                  <th>Form Name</th>
                  <th>Form Label</th>
                  <th>Form Code</th>
                  <th>Domain Class</th>
                  <th>Form Type</th>
                  <th>Form Repeat Number</th>
                  <th>Repeat</th>
                  <th>Status</th>
                  <th>View Form</th>
                  <th>View Report</th>
                </tr>
              </thead>
              <tbody>
                {studyDynamicForm?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.label}
                    </td>
                    <td>
                      {item.code}
                    </td>
                    <td>
                      {item.type}
                    </td>
                    <td className="text-capitalize">
                      {item.formType}
                    </td>
                    <td>
                      {item?.__v}
                    </td>
                    <td className="text-capitalize">
                      {item.repeat}
                    </td>
                    <td>
                      {item?.status ? "Completed" : "Ongoing"}
                    </td>
                    <td>
                      <Button
                        disabled={!(studyPermissionData?.dataCollectioninDVMode ||
                          studyPermissionData?.dataCollectionDBPMode ||
                          studyPermissionData?.createDatabaseViewFormManagement ||
                          studyPermissionData?.siteIdForLive?.length > 0 ||
                          studyPermissionData?.siteIdForQC?.length > 0 ||
                          studyPermissionData?.siteIdForSDVLive?.length > 0 ||
                          studyPermissionData?.siteIdForSDVQC?.length > 0 ||
                          studyPermissionData?.createDatabaseForm)}
                        onClick={(e) => {
                          addCrfViewTabs(item);
                        }}
                        style={{ fontSize: 10, borderRadius: 3, padding: "2px 8px" }}
                        className="text-white border-success bg-success border">
                        View Form
                      </Button>
                    </td>
                    <td>
                      <button
                        disabled={!item?.status}
                        onClick={(e) => {
                          addCrfViewReportTabs(item);
                        }}
                        style={{ fontSize: 10, borderRadius: 3, padding: "2px 8px" }}
                        className="text-white border-primary bg-primary border"
                      >
                        View Report
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        }
      </SplitPane>
    </>
  );
};

export default SiteSubjectSpecialForm;
