import { configureStore } from "@reduxjs/toolkit";
import { customReducer } from "../Reducer/Reducer";
import { sidebarReducer } from "../Reducer/SidebarReducer";

const Store = configureStore({
  reducer: {
    customReducer,
    sidebarReducer,
  },
});

export default Store;
