import React from "react";
import { Offcanvas, Table } from "react-bootstrap";

const AttributeProperties = ({ Show, Hide, Title, variableListId }) => {
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table
            className="custom-table m-0 overflow-auto"
            bordered
            striped
            hover
          >
            <thead>
              <tr>
                <th style={{width: 120}}>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Order</td>
                <td>{variableListId?.order}</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>{variableListId?.version}</td>
              </tr>
              <tr>
                <td>Class</td>
                <td>{variableListId?.class}</td>
              </tr>
              <tr>
                <td>Domain</td>
                <td>{variableListId?.domain}</td>
              </tr>
              <tr>
                <td>Veriable Name</td>
                <td>{variableListId?.name}</td>
              </tr>
              <tr>
                <td>Veriable Label</td>
                <td className="text-wrap">{variableListId?.label}</td>
              </tr>
              <tr>
                <td>Veriable Type</td>
                <td>{variableListId?.type}</td>
              </tr>
              <tr>
                <td>Controlled Terms</td>
                <td className="text-wrap">{variableListId?.terms}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td className="text-capitalize">
                  {variableListId?.role === "recordQualifier"
                    ? "Record Qualifier"
                    : variableListId?.role === "synonymQualifier"
                    ? "Synonym Qualifier"
                    : variableListId?.role === "variableQualifier"
                    ? "Variable Qualifier"
                    : variableListId?.role === "resultQualifier"
                    ? "Result Qualifier"
                    : variableListId?.role}
                </td>
              </tr>
              <tr>
                <td>Origin</td>
                <td>{variableListId?.origin}</td>
              </tr>
              <tr>
                <td>Method</td>
                <td>{variableListId?.mathod}</td>
              </tr>
              <tr>
                <td>Comment</td>
                <td>{variableListId?.comment}</td>
              </tr>
              <tr>
                <td>Core</td>
                <td>{variableListId?.core}</td>
              </tr>
              <tr>
                <td>Code List</td>
                <td>{variableListId?.codeListData}</td>
              </tr>
              <tr>
                <td>Value Level</td>
                <td className="text-capitalize">
                  {variableListId?.valueLevel}
                </td>
              </tr>
              <tr>
                <td>Cdisc Note</td>
                <td className="text-wrap">{variableListId?.note}</td>
              </tr>
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AttributeProperties;
