import React from 'react'
import ADRGPdfDataDetails from './ADRGPdfDataDetails'

const ADRGParentFile = () => {
  return (
    <>
      <ADRGPdfDataDetails />
    </>
  )
}

export default ADRGParentFile
