import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import StudyLogin from "../Login/StudyLogin";
import { useParams } from "react-router";

const SwitchAccess = () => {
  const previousRole = JSON.parse(localStorage.getItem("previousRole"));
  const AppsRef = useRef(null);
  const { studyId } = useParams();
  const toggleApps = () => setAppsActive(!AppsActive);
  const [AppsActive, setAppsActive] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const previousMode = JSON.parse(localStorage.getItem("previousMode"));
  const loginMainDashboard = () => {
    if (previousRole === "user") {
      window.location.href = `/userRole`;
      localStorage.removeItem("previousRole");
      localStorage.setItem("role", JSON.stringify("user"));
      localStorage.removeItem("previousMode");
      localStorage.removeItem("StudyMode");
      localStorage.removeItem("studyTabs");
      localStorage.removeItem("studyPermissionData");
      localStorage.removeItem("etmfPermissionData");
      localStorage.removeItem("studyStatus");
      localStorage.removeItem("tabs");
      localStorage.removeItem("etmfDatabaseStatus");
    }else if (previousRole === "ctms") {
      window.location.href = `/ctms/${studyId}`;
      localStorage.setItem("previousRole", JSON.stringify("user"));
      localStorage.setItem("role", JSON.stringify("ctms"));
      localStorage.setItem("StudyMode", JSON.stringify(previousMode));
    }
    localStorage.removeItem("studyTabs");
  };

  const [show, setShow] = useState(false);
  const [assignStudyPermission, setAssignStudyPermission] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        ref={AppsRef}
        className={`Apps position-relative ${AppsActive ? "active" : ""}`}
      >
        <div className="icon_wrap hstack gap-1" onClick={toggleApps}>
          <div
            className={`title_name hstack gap-1 px-2 py-1 ${
              AppsActive ? "AppsNameActive" : ""
            }`}
            style={{ cursor: "pointer" }}
          >
            <div className="hstack gap-1">
              <i className="fa-solid fa-repeat me-1 text-info"></i>
              <p className="m-0" style={{ fontSize: 12 }}>
                Switch
              </p>
              <i className={`fa-solid fa-angle-${AppsActive ? "up" : "down"}`}></i>
            </div>
          </div>
        </div>
        <div className="Apps_dd shadow" style={{right: 0}}>
          <ul className="Apps_ul">
            <li>
              <a
                onClick={loginMainDashboard}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <i className="fa-solid fa-house-circle-check me-2"></i>Switch to Main Dashboard
              </a>
            </li>
            <li>
              <a
                onClick={handleShow}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
               <i className="fa-solid fa-book-open me-2"></i> Switch to Study
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Modal
        className={show ? "" : "d-none"}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Welcome to Galax</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudyLogin
            assignStudyPermission={assignStudyPermission}
            setAssignStudyPermission={setAssignStudyPermission}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SwitchAccess;
