import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { cdiscServices } from "../../../../../../../../Services/CDISC/cdiscServices";
import { FaTrashCan } from "react-icons/fa6";

const AddColumnToolsWithFunctionValue = ({
  studyId,
  derivationId,
  getDomainDerivationList,
}) => {
  let [newColumns, setNewColumns] = useState([]);

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    setNewColumns(derivationData?.newColumnsFunctionValue || []);
  }

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.newColumnsFunctionValue = newColumns;
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const handleAddNewColumn = () => {
    setNewColumns([
      ...newColumns,
      {
        id: newColumns.length + 1,
        name: "",
        label: "",
        dataType: "",
        permissionType: "",
        value: "",
      },
    ]);
  };

  const handleRemoveNewColumn = (id) => {
    const updatedSections = newColumns.filter((section) => section.id !== id);
    setNewColumns(updatedSections);
  };

  const handleSelectNewColoumnChange = (id, field, value) => {
    const updatedSections = newColumns.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setNewColumns(updatedSections);
  };

  const [functionList, setFunctionList] = useState([]);

  const getFunctionList = async () => {
    let data = await cdiscServices.getFunctionList(studyId, derivationId, "no")
    setFunctionList(data?.data)
  }

  useEffect(() => {
    getFunctionList()
  }, [derivationId]);

  return (
    <div>
      <div className="p-2 border-bottom hstack justify-content-between position-sticky top-0 z-1 bg-white">
        <button onClick={handleAddNewColumn} className="updateProfile">
          Add New Column
        </button>
        <button onClick={derivationCRFData} className="updateProfile">
          Save
        </button>
      </div>
      <div className="py-2 px-3">
        <Row>
          {newColumns?.length > 0 ? (
            newColumns?.map((data, index) => (
              <Form.Group as={Col} md={6}>
                <div className="position-relative shadow-sm border mb-3">
                  <div className="bg-light border-bottom p-1 px-3 hstack justify-content-between">
                    <Form.Label className="mb-1 fw-bold">
                      {data?.name || `New Column ${index + 1}`}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <FaTrashCan
                      onClick={() => handleRemoveNewColumn(data?.id)}
                      style={{ fontSize: 12 }}
                      className="CP text-danger"
                      title="Remove Statement"
                    />
                  </div>
                  <div className="p-3">
                    <div className="w-100">
                      <div key={index} className="hstack gap-1 w-100 mb-2">
                        <Form.Group className="w-100">
                          <Form.Control
                            value={data.name}
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "name",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Enter Column Name"
                          />
                        </Form.Group>
                        <Form.Group className="w-100">
                          <Form.Control
                            value={data.label}
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "label",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Enter Column Label"
                          />
                        </Form.Group>
                      </div>
                      <div className="hstack gap-1 w-100 mb-2">
                        <Form.Group className="w-100">
                          <Form.Select
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "dataType",
                                e.target.value
                              )
                            }
                          >
                            <option value="">
                              Select Column Data Type
                            </option>
                            <option
                              selected={data?.dataType === "text"}
                              value="text"
                            >
                              Text
                            </option>
                            <option
                              selected={data?.dataType === "number"}
                              value="number"
                            >
                              Number
                            </option>
                            <option
                              selected={data?.dataType === "date"}
                              value="date"
                            >
                              Date
                            </option>
                            <option
                              selected={data?.dataType === "time"}
                              value="time"
                            >
                              Time
                            </option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="w-100">
                          <Form.Select
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "permissionType",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select Permission Type</option>
                            <option
                              selected={
                                data?.permissionType === "permissible"
                              }
                              value="permissible"
                            >
                              Permissible
                            </option>
                            <option
                              selected={data?.permissionType === "require"}
                              value="require"
                            >
                              Require
                            </option>
                            <option
                              selected={data?.permissionType === "expected"}
                              value="expected"
                            >
                              Expected
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="w-100 mb-2">
                        <div className="my-2">
                          <InputGroup className="justify-content-center">
                            <InputGroup.Text
                              style={{
                                padding: "5px 9.7px",
                                fontSize: 10,
                                borderRadius: 3,
                              }}
                            >
                              =
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <Form.Group className="w-100">
                          <Form.Select
                            onChange={(e) =>
                              handleSelectNewColoumnChange(
                                data.id,
                                "value",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select Function</option>
                            {functionList?.map((option, index) => (
                              <option
                                selected={data?.value === option._id}
                                key={index}
                                value={option?._id}
                                className="text-capitalize"
                              >
                                {option.name} {`(${option.type} Function) `}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            ))
          ) : (
            <div className="hstack justify-content-center">No Columns</div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default AddColumnToolsWithFunctionValue;
