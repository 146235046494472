import React from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { Form, Offcanvas, Table } from "react-bootstrap";

const RequestmgmtOffcanvasData = ({ Show, Hide, onMouseDown, tableRef }) => {
    return (
        <>
            <Offcanvas show={Show} onHide={Hide} style={{ width: 400 }} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Randomization No. 8</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div className="table-container tableLibrarySection">
                        <Table
                            id="resizable-table" striped
                            className="m-0 custom-table"
                            ref={tableRef}
                            bordered
                        >
                            <thead className="thead-sticky">
                                <tr>
                                    <th onMouseDown={(e) => onMouseDown(e, 0)}>
                                        Attribute
                                    </th>
                                    <th onMouseDown={(e) => onMouseDown(e, 1)}>
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Randomization No.</td>
                                    <td>8</td>
                                </tr>

                                <tr>
                                    <td>Site</td>
                                    <td>SITE04: site04</td>
                                </tr>

                                <tr>
                                    <td>Subject-ID</td>
                                    <td>000002</td>
                                </tr>

                                <tr>
                                    <td>Treatment Code</td>
                                    <td >Treat001</td>
                                </tr>
                                <tr>
                                    <td>Date & Time</td>
                                    <td>
                                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                                            2024-05-26 07:03:43 UTC
                                        </Moment>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td className="text-success">Completed</td>
                                </tr>
                                <tr>
                                    <td>Remarks</td>
                                    <td>Name Changed</td>
                                </tr>
                                <tr>
                                    <td>Request by</td>
                                    <td style={{ fontWeight: "normal" }}>
                                        <NavLink
                                            to={"/"}
                                            className="randomizer-link"
                                            style={{ color: "blue", textDecoration: "none" }}
                                        >
                                            Gaurav Chauhan
                                        </NavLink>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <Form.Group className="p-2 mb-0">
                            <Form.Label style={{ fontWeight: 500 }}>Remarks:</Form.Label>
                            <Form.Control as="textarea" rows={4} placeholder="Add Remarks" />
                        </Form.Group>
                        <Form.Group className="p-2">
                            <Form.Label style={{ fontWeight: 500 }}>Authenticate:</Form.Label>
                            <Form.Control type="password" rows={4} placeholder="Authenticate Here" />
                        </Form.Group>
                        <div className="p-2 hstack justify-content-end gap-2 position-sticky bottom-0">
                            <button
                                className="updateProfile"
                                style={{ width: 93, fontSize: "14px" }}
                            >
                                Approve
                            </button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
};

export default RequestmgmtOffcanvasData;
