import React, { useEffect, useRef, useState } from "react";
import "./print.css";
import moment from "moment";
import { Rnd } from "react-rnd";
import AdrgData from "../../AdrgData";
import { useParams } from "react-router";
import DynamicRuler from "./DynamicRuler";
import ADRGParentFile from "../../ADRGParentFile";
import { Form, InputGroup, Table } from "react-bootstrap";
import { formReportServices } from "../../../../../../Services/studyServices/formReportServices";

const EditorWritingPage = ({
  size,
  margin,
  tables, 
  columns,
  imageSrc,
  formName,
  formType,
  siteName,
  formArray,
  shapeSize,
  visitName,
  showFooter,
  showHeader,
  orientation,
  subjectName,
  renderShape,
  setShapeSize,
  setActiveTab,
  isFullScreen,
  componentRef,
  imageToInsert,
  shapeToInsert,
  emojiToInsert,
  tableProperties,
  setSelectedCell,
  setSelectedTableIndex,
  getCellAlignmentStyles,
  handleLinePositionChange,
}) => {

  const pageRefs = useRef([]);
  const editorRef = useRef(null);
  const { studyId } = useParams();
  const [logo, setLogo] = useState("");
  const [width, setWidth] = useState("");
  const [pages, setPages] = useState([0]);
  const [height, setHeight] = useState("");
  const [footerTitle, setFooterTitle] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerTitleLength, setHeaderTitleLength] = useState(0);
  const GeneratedDate = moment(new Date()).format("DD-MM-YYYY | HH:mm:ss");
  // const MAX_PAGE_HEIGHT = 1060;

  // const editorStyle = { width: "100%", minHeight: `${MAX_PAGE_HEIGHT}px` };
  const editorStyle = { width: "100%" };
  const marginStyle = {
    paddingTop: margin.top,
    paddingBottom: margin.bottom,
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };
  const headermarginStyle = {
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };

  const pageStyle = {
    width: orientation === "portrait" ? size.width : size.height,
    height: orientation === "portrait" ? size.height : size.width,
    marginBottom: margin.bottom,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const columnData = {
    columnCount: typeof columns === "number" ? columns : 1,
    columnGap: "1em",
  };

  const contentStyle = {
    ...marginStyle,
    flexGrow: 1,
  };

  const insertImageAtCaret = (imageSrc) => {
    const img = document.createElement("img");
    img.src = imageSrc;
    img.style.maxWidth = "100%";
    img.style.maxHeight = "100%";

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false);
    }
  };

  const getReportStudyMetadataData = async () => {
    let data = await formReportServices.getReportStudyMetadataData(studyId);
    let arrayData = data?.data;
    let headerData = arrayData?.find(
      (item) => item.type === "header"
    )?.headerData;
    setHeaderTitleLength(headerData?.length || 0);
    let footerData = arrayData?.find(
      (item) => item.type === "footer"
    )?.footerData;
    let logoData = arrayData?.find((item) => item.type === "logo");
    let headerDataArray = headerData?.map((item) => item.text).join(`<br />`);
    setHeaderTitle(headerDataArray || "");
    setFooterTitle(footerData || "");
    setHeight(logoData?.height || 60);
    setWidth(logoData?.width || 60);
    setLogo(
      logoData?.logo ||
      "https://gas-admin-bucket.s3.ap-south-1.amazonaws.com/1729008098534.png"
    );
  };

  // const handleContentInput = (pageIndex) => {
  //   const currentPage = pageRefs.current[pageIndex];

  //   if (currentPage && currentPage.scrollHeight > MAX_PAGE_HEIGHT) {
  //     if (pageIndex === pages.length - 1) {
  //       currentPage.contentEditable = true;
  //       setPages([...pages, pages.length]);
  //       setTimeout(() => {
  //         const nextPage = pageRefs.current[pageIndex + 1];
  //         if (nextPage) {
  //           nextPage.focus();
  //         }
  //       }, 0);
  //     }
  //   }
  // };

  useEffect(() => {
    if (imageToInsert) {
      insertImageAtCaret(imageToInsert);
    }
  }, [imageToInsert]);

  const insertEmojiAtCaret = (emoji) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const emojiNode = document.createTextNode(emoji);
      range.insertNode(emojiNode);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (emojiToInsert) {
      insertEmojiAtCaret(emojiToInsert);
    }
  }, [emojiToInsert]);

  useEffect(() => {
    getReportStudyMetadataData();
  }, []);

  function toDigitOrCharArray(input) {
    let str = input?.toString();
    if (!isNaN(input) && str?.length === 1) {
      return [0, Number(str)];
    }
    return str?.split('');
  }

  return (
    <>
      <DynamicRuler
        orientation={orientation}
        size={size}
        onLinePositionChange={handleLinePositionChange}
      />
      <div
        style={{
          height: isFullScreen ? "calc(100vh - 130px)" : "calc(100vh - 285px)",
          overflow: "auto",
        }}
      >
        <div className="text-editor text-editor-container" ref={editorRef}>
          {pages?.map((pageIndex) => (
            <div
              key={pageIndex}
              // onInput={() => handleContentInput(pageIndex)}
              ref={(el) => (pageRefs.current[pageIndex] = el)}
              contentEditable={true}
              style={{
                border: "1px solid #eee",
                borderRadius: 3,
                outline: "none",
                minHeight: "11in",
                ...editorStyle,
                ...pageStyle,
              }}
              suppressContentEditableWarning={true}
            >
              <div ref={componentRef}>
                {showHeader && (
                  <div
                    className="header"
                    style={{ ...contentStyle, borderBottom: "2px dashed #eee" }}
                    contentEditable={true}
                  >
                    <div className="d-flex justify-content-between">
                     <p className="m-0 fw-bold">Study 006-24</p>
                     <p className="m-0 fw-bold">Analysis Data Reviewer’s Guide</p>
                      {/* <div>
                        <p className="m-0 fw-bold fontSize12">
                          {headerTitle ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: headerTitle }}
                            />
                          ) : (
                            "Study Title & Details"
                          )}
                        </p>
                        {!(formType === "studyform") && (
                          <p className="m-0 fw-bold fontSize12">
                            Folder: Site: {siteName} {subjectName && `/ Subject: ${subjectName}`} {visitName && `/ Visit: ${visitName}`}
                          </p>
                        )}
                        <p className="m-0 fw-bold fontSize12">
                          Form: {formName}
                        </p>
                        <p className="m-0 fw-bold fontSize12">
                          Generated On: {GeneratedDate}
                        </p>
                      </div>
                      <img
                        src={logo}
                        alt="Company Logo"
                        style={{ width: width, height: height }}
                      /> */}
                    </div>
                  </div>
                )}
                <div
                  style={{ ...contentStyle, ...columnData }}
                  className="content_Id"
                >
                  <Table>
                    {showHeader && (
                      <thead>
                        <tr className="spacingHeader">
                          <th className="border-0">
                            {!formType === "studyform" && (
                              <div className="t-head">&nbsp;</div>
                            )}
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                            {Array.from({ length: headerTitleLength })?.map(
                              (_, index) => (
                                <div key={index} className="t-head">
                                  &nbsp;
                                </div>
                              )
                            )}
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      <tr>
                        <td className="border-0 p-0">
                          <div className="content">
                            <div className="page">
                              {formArray?.map((item, index) => (
                                <>
                                  {item?.type === "heading" ? (
                                    <Form.Group
                                      key={index}
                                      style={{ marginBottom: 15 }}
                                    >
                                      <Form.Label
                                        style={{
                                          fontWeight: 600,
                                          marginBottom: 5,
                                        }}
                                      >
                                        {item?.label} | {item?.uid}
                                      </Form.Label>
                                    </Form.Group>
                                  ) : item?.type === "text" ? (
                                    <div className="">
                                      <Form.Group
                                        className="w-50"
                                        key={index}
                                        style={{ marginBottom: 15 }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item?.label} | {item?.uid}
                                        </Form.Label>
                                        <Form.Control
                                          readOnly
                                          type="text"
                                          value={item?.value?.newTextValue}
                                          placeholder={item.placeholder}
                                        />
                                      </Form.Group>
                                      {item?.filterUnit && (
                                        <Form.Group
                                          key={index}
                                          className="w-100"
                                          style={{ marginBottom: 15 }}
                                        >
                                          <Form.Label
                                            style={{
                                              fontWeight: 600,
                                              marginBottom: 5,
                                            }}
                                          >
                                            {item?.unitLabel} | {item?.unitName}
                                          </Form.Label>
                                          {item?.customType === "textCustom" ? (
                                            <Form.Control
                                              readOnly
                                              type="text"
                                              value={item?.value?.unit}
                                              placeholder={"Enter Custom Unit"}
                                            />
                                          ) : (
                                            item?.filterUnit?.map((el, index) => (
                                              <Form.Check
                                                key={index}
                                                checked={item?.value?.unit === el?.unit}
                                                label={el?.unit}
                                                name="group1"
                                                type="radio"
                                                id={`inline-$"radio"-1`}
                                              />
                                            ))
                                          )}
                                        </Form.Group>
                                      )}
                                      {item?.additionalCategory?.length > 0 && (
                                        item?.additionalCategory?.map((additionalData, key) => (
                                          <Form.Group
                                            className="w-50"
                                            key={index}
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {additionalData[`additionalCategoryLabel${key + 1}`]} | {additionalData[`additionalCategoryName${key + 1}`]}
                                            </Form.Label>
                                            {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ? (
                                              <Form.Select readOnly className="w-50">
                                                <option value="">Select</option>
                                                {additionalData?.options?.map((selectedOption) => (
                                                  <option
                                                    selected={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                  >
                                                    {selectedOption.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    label={selectedOption.value}
                                                    name="checkboxGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`]?.includes(selectedOption?.value)
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "radio" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="radio"
                                                    id={`radio-${index}`}
                                                    label={selectedOption.value}
                                                    name="radioGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : ["text", "number", "date", "time"].includes(
                                              additionalData[`additionalCategoryType${key + 1}`]
                                            ) ? (
                                              <Form.Control
                                                readOnly
                                                type={additionalData[`additionalCategoryType${key + 1}`]}
                                                value={item?.value?.[`additionalCategory${key + 1}`]}
                                                placeholder={item.placeholder}
                                              />
                                            ) : <Form.Control
                                              readOnly
                                              as="textarea"
                                              value={item?.value?.[`additionalCategory${key + 1}`]}
                                              placeholder={item.placeholder}
                                            />
                                            }
                                          </Form.Group>
                                        )))}
                                    </div>
                                  ) : item.type === "number" ? (
                                    <div className="">
                                      <Form.Group
                                        key={index}
                                        className="w-50"
                                        style={{ marginBottom: 15 }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item?.label} | {item?.uid}
                                        </Form.Label>
                                        <Form.Control
                                          readOnly
                                          type="number"
                                          value={item?.value?.newNumberValue}
                                          placeholder={item.placeholder}
                                        />
                                      </Form.Group>
                                      {item?.filterUnit && (
                                        <Form.Group
                                          key={index}
                                          className="w-100"
                                          style={{ marginBottom: 15 }}
                                        >
                                          <Form.Label
                                            style={{
                                              fontWeight: 600,
                                              marginBottom: 5,
                                            }}
                                          >
                                            {item?.unitLabel} | {item?.unitName}
                                          </Form.Label>
                                          {item?.customType === "textCustom" ? (
                                            <Form.Control
                                              readOnly
                                              type="text"
                                              value={item?.value?.unit}
                                              placeholder={"Enter Custom Unit"}
                                            />
                                          ) : (
                                            item?.filterUnit?.map((el, index) => (
                                              <Form.Check
                                                key={index}
                                                checked={item?.value?.unit === el?.unit}
                                                label={el?.unit}
                                                name="group1"
                                                type="radio"
                                                id={`inline-$"radio"-1`}
                                              />
                                            ))
                                          )}
                                        </Form.Group>
                                      )}
                                      {item?.additionalCategory?.length > 0 && (
                                        item?.additionalCategory?.map((additionalData, key) => (
                                          <Form.Group
                                            className="w-50"
                                            key={index}
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {additionalData[`additionalCategoryLabel${key + 1}`]} | {additionalData[`additionalCategoryName${key + 1}`]}
                                            </Form.Label>
                                            {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ? (
                                              <Form.Select readOnly className="w-50">
                                                <option value="">Select</option>
                                                {additionalData?.options?.map((selectedOption) => (
                                                  <option
                                                    selected={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                  >
                                                    {selectedOption.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    label={selectedOption.value}
                                                    name="checkboxGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`]?.includes(selectedOption?.value)
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "radio" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="radio"
                                                    id={`radio-${index}`}
                                                    label={selectedOption.value}
                                                    name="radioGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : ["text", "number", "date", "time"].includes(
                                              additionalData[`additionalCategoryType${key + 1}`]
                                            ) ? (
                                              <Form.Control
                                                readOnly
                                                type={additionalData[`additionalCategoryType${key + 1}`]}
                                                value={item?.value?.[`additionalCategory${key + 1}`]}
                                                placeholder={item.placeholder}
                                              />
                                            ) : <Form.Control
                                              readOnly
                                              as="textarea"
                                              value={item?.value?.[`additionalCategory${key + 1}`]}
                                              placeholder={item.placeholder}
                                            />
                                            }
                                          </Form.Group>
                                        )))}
                                    </div>
                                  ) : item.type === "date" ? (
                                    <>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.label} | {item.uid}
                                        </Form.Label>
                                      </Form.Group>
                                      <div className="d-flex gap-2">
                                        <Form.Group
                                          style={{ marginBottom: 15 }}
                                        >
                                          <Form.Label
                                            style={{
                                              fontWeight: 600,
                                              marginBottom: 5,
                                            }}
                                          >
                                            {item.dayLabel} | {item.dayName}
                                          </Form.Label>
                                          <div className="hstack gap-1">
                                            <Form.Control
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.day)?.[0]}
                                              className="text-center"
                                              type="text"
                                              placeholder="D"
                                            />
                                            <Form.Control
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.day)?.[1]}
                                              className="text-center"
                                              type="text"
                                              placeholder="D"
                                            />
                                          </div>
                                        </Form.Group>
                                        <Form.Group
                                          style={{ marginBottom: 15 }}
                                        >
                                          <Form.Label
                                            style={{
                                              fontWeight: 600,
                                              marginBottom: 5,
                                            }}
                                          >
                                            {item.monthLabel} | {item.monthName}
                                          </Form.Label>
                                          <div className="hstack gap-1">
                                            <Form.Control
                                              className="text-center"
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.month)?.[0]}
                                              type="text"
                                              placeholder="M"
                                            />
                                            <Form.Control
                                              className="text-center"
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.month)?.[1]}
                                              type="text"
                                              placeholder="M"
                                            />
                                            {toDigitOrCharArray(item?.value?.month)?.length === 3 &&
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.month)?.[2]}
                                                type="text"
                                                placeholder="M"
                                              />
                                            }
                                          </div>
                                        </Form.Group>
                                        <Form.Group
                                          style={{ marginBottom: 15 }}
                                        >
                                          <Form.Label
                                            style={{
                                              fontWeight: 600,
                                              marginBottom: 5,
                                            }}
                                          >
                                            {item.yearLabel} | {item.yearName}
                                          </Form.Label>
                                          <div className="hstack gap-1">
                                            <Form.Control
                                              className="text-center"
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.year)?.[0]}
                                              type="text"
                                              placeholder="Y"
                                            />
                                            <Form.Control
                                              className="text-center"
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.year)?.[1]}
                                              type="text"
                                              placeholder="Y"
                                            />
                                            <Form.Control
                                              className="text-center"
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.year)?.[2]}
                                              type="text"
                                              placeholder="Y"
                                            />
                                            <Form.Control
                                              className="text-center"
                                              style={{ width: 40 }}
                                              readOnly
                                              value={toDigitOrCharArray(item?.value?.year)?.[3]}
                                              type="text"
                                              placeholder="Y"
                                            />
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </>
                                  ) : item.type === "time" ? (
                                    <>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.label} | {item.uid}
                                        </Form.Label>
                                      </Form.Group>
                                      {item.timeFormating === "24" ? (
                                        <div className="d-flex gap-2">
                                          <Form.Group
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {item.hourLabel} | {item.hourName}
                                            </Form.Label>
                                            <div className="hstack gap-1">
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.hour)?.[0]}
                                                type="text"
                                                placeholder="H"
                                              />
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.hour)?.[1]}
                                                type="text"
                                                placeholder="H"
                                              />
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {item.minuteLabel} | {item.minuteName}
                                            </Form.Label>
                                            <div className="hstack gap-1">
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.minute)?.[0]}
                                                type="text"
                                                placeholder="M"
                                              />
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.minute)?.[1]}
                                                type="text"
                                                placeholder="M"
                                              />
                                            </div>
                                          </Form.Group>
                                        </div>
                                      ) : (
                                        <div className="d-flex gap-2">
                                          <Form.Group
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {item.hourLabel} | {item.hourName}
                                            </Form.Label>
                                            <div className="hstack gap-1">
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.hour)?.[0]}
                                                type="text"
                                                placeholder="H"
                                              />
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.hour)?.[1]}
                                                type="text"
                                                placeholder="H"
                                              />
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {item.minuteLabel} || {item.minuteName}
                                            </Form.Label>
                                            <div className="hstack gap-1">
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.minute)?.[0]}
                                                type="text"
                                                placeholder="M"
                                              />
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.minute)?.[1]}
                                                type="text"
                                                placeholder="M"
                                              />
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {item.secondLabel} || {item.secondName}
                                            </Form.Label>
                                            <div className="hstack gap-1">
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.second)?.[0]}
                                                type="text"
                                                placeholder="S"
                                              />
                                              <Form.Control
                                                className="text-center"
                                                style={{ width: 40 }}
                                                readOnly
                                                value={toDigitOrCharArray(item?.value?.second)?.[1]}
                                                type="text"
                                                placeholder="S"
                                              />
                                            </div>
                                          </Form.Group>
                                          {item?.value?.format &&
                                            <Form.Group
                                              style={{ marginBottom: 15 }}
                                            >
                                              <Form.Label
                                                style={{
                                                  fontWeight: 600,
                                                  marginBottom: 5,
                                                }}
                                              >
                                                Formate
                                              </Form.Label>
                                              <br />
                                              <Form.Label
                                                className="text-capitalize"
                                                style={{ marginBottom: 10 }}
                                              >
                                                {item?.value?.format}
                                              </Form.Label>
                                            </Form.Group>
                                          }
                                        </div>
                                      )}
                                    </>
                                  ) : item.type === "radio" ? (
                                    <div>
                                      <Form.Group
                                        key={index}
                                        style={{ marginBottom: 15 }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item?.label} | {item?.uid}
                                        </Form.Label>
                                        {item.options?.map(
                                          (selectedOption, index) => (
                                            <Form.Check
                                              readOnly
                                              key={index}
                                              type="radio"
                                              id={`radio-${index}`}
                                              label={selectedOption.value}
                                              name="radioGroup"
                                              checked={
                                                item?.value?.value ===
                                                selectedOption.value
                                              }
                                              value={selectedOption.value}
                                            />
                                          )
                                        )}
                                      </Form.Group>
                                      {item?.additionalCategory?.length > 0 && (
                                        item?.additionalCategory?.map((additionalData, key) => (
                                          <Form.Group
                                            className="w-50"
                                            key={index}
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {additionalData[`additionalCategoryLabel${key + 1}`]} | {additionalData[`additionalCategoryName${key + 1}`]}
                                            </Form.Label>
                                            {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ? (
                                              <Form.Select readOnly className="w-50">
                                                <option value="">Select</option>
                                                {additionalData?.options?.map((selectedOption) => (
                                                  <option
                                                    selected={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                  >
                                                    {selectedOption.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    label={selectedOption.value}
                                                    name="checkboxGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`]?.includes(selectedOption?.value)
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "radio" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="radio"
                                                    id={`radio-${index}`}
                                                    label={selectedOption.value}
                                                    name="radioGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : ["text", "number", "date", "time"].includes(
                                              additionalData[`additionalCategoryType${key + 1}`]
                                            ) ? (
                                              <Form.Control
                                                readOnly
                                                type={additionalData[`additionalCategoryType${key + 1}`]}
                                                value={item?.value?.[`additionalCategory${key + 1}`]}
                                                placeholder={item.placeholder}
                                              />
                                            ) : <Form.Control
                                              readOnly
                                              as="textarea"
                                              value={item?.value?.[`additionalCategory${key + 1}`]}
                                              placeholder={item.placeholder}
                                            />
                                            }
                                          </Form.Group>
                                        )))}
                                    </div>
                                  ) : item.type === "checkbox" ? (
                                    <div>
                                      <Form.Group
                                        key={index}
                                        style={{ marginBottom: 15 }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item?.label} | {item?.uid}
                                        </Form.Label>
                                        {item.options?.map(
                                          (selectedOption, index) => (
                                            <Form.Check
                                              readOnly
                                              key={index}
                                              type="checkbox"
                                              id={`checkbox-${index}`}
                                              label={selectedOption.value}
                                              name="checkboxGroup"
                                              checked={
                                                item?.value?.value?.includes(selectedOption?.value)
                                              }
                                              value={selectedOption.value}
                                            />
                                          )
                                        )}
                                      </Form.Group>
                                      {item?.additionalCategory?.length > 0 && (
                                        item?.additionalCategory?.map((additionalData, key) => (
                                          <Form.Group
                                            className="w-50"
                                            key={index}
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {additionalData[`additionalCategoryLabel${key + 1}`]} | {additionalData[`additionalCategoryName${key + 1}`]}
                                            </Form.Label>
                                            {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ? (
                                              <Form.Select readOnly className="w-50">
                                                <option value="">Select</option>
                                                {additionalData?.options?.map((selectedOption) => (
                                                  <option
                                                    selected={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                  >
                                                    {selectedOption.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    label={selectedOption.value}
                                                    name="checkboxGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`]?.includes(selectedOption?.value)
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "radio" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="radio"
                                                    id={`radio-${index}`}
                                                    label={selectedOption.value}
                                                    name="radioGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : ["text", "number", "date", "time"].includes(
                                              additionalData[`additionalCategoryType${key + 1}`]
                                            ) ? (
                                              <Form.Control
                                                readOnly
                                                type={additionalData[`additionalCategoryType${key + 1}`]}
                                                value={item?.value?.[`additionalCategory${key + 1}`]}
                                                placeholder={item.placeholder}
                                              />
                                            ) : <Form.Control
                                              readOnly
                                              as="textarea"
                                              value={item?.value?.[`additionalCategory${key + 1}`]}
                                              placeholder={item.placeholder}
                                            />
                                            }
                                          </Form.Group>
                                        )))}
                                    </div>
                                  ) : item.type === "dropdown" ? (
                                    <div>
                                      <Form.Group
                                        key={index}
                                        style={{ marginBottom: 15 }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item?.label} | {item?.uid}
                                        </Form.Label>
                                        <Form.Select readOnly className="w-50">
                                          <option value="">Select</option>
                                          {item.options?.map((selectedOption) => (
                                            <option
                                              selected={
                                                item?.value?.value ===
                                                selectedOption.value
                                              }
                                            >
                                              {selectedOption.value}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Form.Group>
                                      {item?.additionalCategory?.length > 0 && (
                                        item?.additionalCategory?.map((additionalData, key) => (
                                          <Form.Group
                                            className="w-50"
                                            key={index}
                                            style={{ marginBottom: 15 }}
                                          >
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {additionalData[`additionalCategoryLabel${key + 1}`]} | {additionalData[`additionalCategoryName${key + 1}`]}
                                            </Form.Label>
                                            {additionalData[`additionalCategoryType${key + 1}`] === "dropdown" ? (
                                              <Form.Select readOnly className="w-50">
                                                <option value="">Select</option>
                                                {additionalData?.options?.map((selectedOption) => (
                                                  <option
                                                    selected={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                  >
                                                    {selectedOption.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "checkbox" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    label={selectedOption.value}
                                                    name="checkboxGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`]?.includes(selectedOption?.value)
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : additionalData[`additionalCategoryType${key + 1}`] === "radio" ? (
                                              additionalData?.options?.map(
                                                (selectedOption, index) => (
                                                  <Form.Check
                                                    readOnly
                                                    key={index}
                                                    type="radio"
                                                    id={`radio-${index}`}
                                                    label={selectedOption.value}
                                                    name="radioGroup"
                                                    checked={
                                                      item?.value?.[`additionalCategory${key + 1}`] ===
                                                      selectedOption.value
                                                    }
                                                    value={selectedOption.value}
                                                  />
                                                )
                                              )
                                            ) : ["text", "number", "date", "time"].includes(
                                              additionalData[`additionalCategoryType${key + 1}`]
                                            ) ? (
                                              <Form.Control
                                                readOnly
                                                type={additionalData[`additionalCategoryType${key + 1}`]}
                                                value={item?.value?.[`additionalCategory${key + 1}`]}
                                                placeholder={item.placeholder}
                                              />
                                            ) : <Form.Control
                                              readOnly
                                              as="textarea"
                                              value={item?.value?.[`additionalCategory${key + 1}`]}
                                              placeholder={item.placeholder}
                                            />
                                            }
                                          </Form.Group>
                                        )))}
                                    </div>
                                  ) : item?.type === "scale" ? (
                                    <Form.Group
                                      key={index}
                                      style={{ marginBottom: 15 }}
                                    >
                                      <Form.Label
                                        style={{
                                          fontWeight: 600,
                                          marginBottom: 5,
                                        }}
                                      >
                                        {item?.label} | {item?.uid}
                                      </Form.Label>
                                      <Form.Group style={{ marginBottom: 15 }}>
                                        <InputGroup
                                          style={{
                                            background:
                                              "linear-gradient(to right, green, orange, red)",
                                            marginBottom: 20,
                                          }}
                                        >
                                          {item.options?.map(
                                            (selectedOption, index) => (
                                              <InputGroup.Text
                                                key={index}
                                                style={{
                                                  fontSize: 12,
                                                  color: "#fff",
                                                  background: "transparent",
                                                  borderRadius: 0,
                                                  justifyContent: "center",
                                                  width: "100%",
                                                }}
                                              >
                                                {selectedOption.value}
                                              </InputGroup.Text>
                                            )
                                          )}
                                        </InputGroup>
                                      </Form.Group>
                                    </Form.Group>
                                  ) : item.type === "upload" ? (
                                    <Form.Group
                                      key={index}
                                      style={{ marginBottom: 15 }}
                                    >
                                      <Form.Label
                                        style={{
                                          fontWeight: 600,
                                          marginBottom: 5,
                                        }}
                                      >
                                        {item?.label} | {item?.uid}
                                      </Form.Label>
                                      <Form.Control className="w-50" readOnly type="file" />
                                      {/* <a
                                        href="http://devxware.com"
                                        style={{ fontSize: 12 }}
                                      >
                                        UploadedFileUrl
                                      </a> */}
                                    </Form.Group>
                                  ) : item.type === "textarea" ? (
                                    <Form.Group
                                      key={index}
                                      style={{ marginBottom: 15 }}
                                    >
                                      <Form.Label
                                        style={{
                                          fontWeight: 600,
                                          marginBottom: 5,
                                        }}
                                      >
                                        {item?.label} | {item?.uid}
                                      </Form.Label>
                                      <Form.Control
                                        readOnly
                                        className="w-50"
                                        as="textarea"
                                        value={item?.value?.value}
                                        placeholder={item.placeholder}
                                      />
                                    </Form.Group>
                                  ) : item.type === "groupForm" ? (
                                    <>
                                      <Form.Group style={{ marginBottom: 15 }}>
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.categoryLabel} | {item.categoryName}
                                        </Form.Label>{" "}
                                        <br />
                                        {item?.field?.map((el, index) => (
                                          <Form.Check
                                            key={index}
                                            label={el?.label}
                                            name="group1"
                                            type="radio"
                                            id={`inline-$"radio"-1`}
                                          />
                                        ))}
                                      </Form.Group>
                                      <Form.Group style={{ marginBottom: 15 }}>
                                        <Form.Label
                                          style={{
                                            fontWeight: 600,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {item.categoryValueLabel} | {item.categoryValueName}
                                        </Form.Label>
                                        <br />
                                        {item.valueFieldType ?
                                          item?.options?.map((el, index) => (
                                            <Form.Check
                                              key={index}
                                              label={el?.label}
                                              name="group1"
                                              type="radio"
                                              id={`inline-$"radio"-1`}
                                            />
                                          ))
                                          :
                                          <Form.Control className="w-50" readOnly type="text" />
                                        }
                                      </Form.Group>
                                      {item.categoryUnitName &&
                                        <Form.Group style={{ marginBottom: 15 }}>
                                          <Form.Label
                                            style={{
                                              fontWeight: 600,
                                              marginBottom: 5,
                                            }}
                                          >
                                            {item.categoryUnitLabel} | {item.categoryUnitName}
                                          </Form.Label>{" "}
                                          <br />
                                          {item?.filterUnit?.map((el, index) => (
                                            <Form.Check
                                              key={index}
                                              label={el?.unit}
                                              name="group1"
                                              type="radio"
                                              id={`inline-$"radio"-1`}
                                            />
                                          ))}
                                        </Form.Group>
                                      }
                                      {(item?.additionalCategory)?.length > 0 &&
                                        item?.additionalCategory?.map((additionalData, key) => (
                                          <Form.Group key={key} style={{ marginBottom: 15 }}>
                                            <Form.Label
                                              style={{
                                                fontWeight: 600,
                                                marginBottom: 5,
                                              }}
                                            >
                                              {additionalData[`additionalCategoryLabel${key + 1}`]} | {additionalData[`additionalCategoryName${key + 1}`]}
                                            </Form.Label>
                                            <br />
                                            {(additionalData[`additionalCategoryType${key + 1}`] === "checkbox" || additionalData[`additionalCategoryType${key + 1}`] === "dropdown" || additionalData[`additionalCategoryType${key + 1}`] === "radio") ?
                                              additionalData?.options?.map((el, index) => (
                                                <Form.Check
                                                  key={index}
                                                  label={el?.value}
                                                  name="group1"
                                                  type="radio"
                                                  id={`inline-$"radio"-1`}
                                                />
                                              )) :
                                              (additionalData[`additionalCategoryType${key + 1}`] === "text" || additionalData[`additionalCategoryType${key + 1}`] === "number") ?
                                                <Form.Control placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]} className="w-50" readOnly type={additionalData[`additionalCategoryType${key + 1}`]} />
                                                :
                                                <Form.Control placeholder={additionalData[`additionalCategoryPlaceholder${key + 1}`]} className="w-50" readOnly as={"textarea"} />
                                            }
                                          </Form.Group>
                                        ))}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                            </div>
                            <div className="page">
                              {imageSrc && (
                                <Rnd
                                  default={{
                                    x: 50,
                                    y: 50,
                                    width: 200,
                                    height: 200,
                                  }}
                                  lockAspectRatio={false}
                                  bounds="parent"
                                >
                                  <img
                                    src={imageSrc}
                                    alt="Selected"
                                    style={{
                                      objectFit: "cover",
                                      width: 100,
                                      height: 100,
                                    }}
                                  />
                                </Rnd>
                              )}
                            </div>
                            <div className="page">
                              {shapeToInsert && (
                                <Rnd
                                  default={{
                                    x: 100,
                                    y: 100,
                                    width: 100,
                                    height: 100,
                                  }}
                                  lockAspectRatio={false}
                                  bounds="parent"
                                  onResize={(
                                    e,
                                    direction,
                                    ref,
                                    delta,
                                    position
                                  ) => {
                                    setShapeSize({
                                      width: ref.style.width,
                                      height: ref.style.height,
                                    });
                                  }}
                                >
                                  <div style={{ fontSize: 50 }}>
                                    {renderShape(
                                      shapeToInsert,
                                      shapeSize.width,
                                      shapeSize.height
                                    )}
                                  </div>
                                </Rnd>
                              )}
                            </div>
                            <div className="page">
                              {tables?.map((table, index) => (
                                <div
                                  key={index}
                                  className="table-container"
                                  onClick={() => {
                                    setActiveTab("Home");
                                    setSelectedTableIndex(index);
                                  }}
                                >
                                  <Table
                                    bordered
                                    style={{
                                      width: 100,
                                      tableLayout: "fixed",
                                      borderColor: `${tableProperties.borderColor}`,
                                      borderWidth: `${tableProperties.borderWidth}px`,
                                    }}
                                  >
                                    <tbody>
                                      {Array(table.rows)
                                        .fill(0)
                                        .map((_, rowIndex) => (
                                          <tr
                                            key={rowIndex}
                                            style={{
                                              minHeight: `${tableProperties.minRowHeight}px`,
                                              position:
                                                rowIndex <
                                                  tableProperties.pinHeaderRows
                                                  ? "sticky"
                                                  : "static",
                                              top:
                                                rowIndex <
                                                  tableProperties.pinHeaderRows
                                                  ? 0
                                                  : "auto",
                                              backgroundColor:
                                                rowIndex <
                                                  tableProperties.pinHeaderRows
                                                  ? "#f8f9fa"
                                                  : "inherit",
                                            }}
                                          >
                                            {Array(table.columns)
                                              .fill(0)
                                              .map((_, colIndex) => (
                                                <td
                                                  key={colIndex}
                                                  style={{
                                                    padding: `${tableProperties.cellPadding}px`,
                                                    height: `${tableProperties.minRowHeight}px`,
                                                    width: `${tableProperties.columnWidth}px`,
                                                    borderWidth: `${tableProperties.borderWidth}px`,
                                                    borderColor: `${tableProperties.borderColor}`,
                                                    ...getCellAlignmentStyles(
                                                      tableProperties.cellAlignment
                                                    ),
                                                    backgroundColor:
                                                      table.cells[rowIndex][
                                                        colIndex
                                                      ]?.backgroundColor ||
                                                      "#ffffff",
                                                  }}
                                                  contentEditable
                                                  onClick={() =>
                                                    setSelectedCell({
                                                      rowIndex,
                                                      colIndex,
                                                    })
                                                  }
                                                ></td>
                                              ))}
                                          </tr>
                                        ))}
                                    </tbody>
                                  </Table>
                                </div>
                              ))}
                            </div>
                            <div className="page sdrgData"><AdrgData /></div>
                            <div className="page"><ADRGParentFile /></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    {showFooter && (
                      <tfoot>
                        <tr className="spacingHeader">
                          <td className="border-0 p-0">
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </Table>
                </div>
                {showFooter && (
                  <div
                    className="footer bg-white text-center"
                    style={{
                      ...contentStyle,
                      borderTop: "1px dashed #eee",
                    }}
                  >
                    <div className="hstack justify-content-between">
                      {footerTitle?.title1 && (
                        <span contentEditable={true} className="text-start">
                          {footerTitle?.title1} <br />
                          {footerTitle?.title2}
                        </span>
                      )}
                      {!footerTitle && (
                        <span contentEditable={true}>Confidencials</span>
                      )}
                      <span style={{ pointerEvents: "none" }}>
                        Page : {pageIndex + 1} of {pages.length}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditorWritingPage;
