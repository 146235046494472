import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import ValueLabelTableData from "./ValueLabelTableData";
import ValueLabelTableProperties from "./ValueLabelTableProperties";
import ValueLabelFilter from "./ValueLabelFilter";
import UseToggle from "../../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { useParams } from "react-router";
import UpdateValueLavel from "./UpdateValueLavel";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../NoDataFoundTable/NoDataFoundTable";

const ValueLabel = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [valueLaveldata, setValueLaveldata] = useState([]);
  const { studyId } = useParams();
  const [isFormOpen, setIsFormOpen] = useState("");

  const getValueLevelData = async () => {
    setLoader(true);
    let data = await cdiscServices.getValueLevelData(studyId);
    setValueLaveldata(data?.data);
    setLoader(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    getValueLevelData();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          {isFormOpen && (
            <>
              <i
                title="Close Form"
                className="fa-solid fa-square-caret-left CP"
                onClick={() => setIsFormOpen("")}
              />
              <div className="vr"></div>
            </>
          )}
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-filter"
            title="Filter"
            onClick={setToggle1}
          ></i>
        </div>
        {isFullScreen ? (
          <i
            class="fa-solid fa-maximize"
            title="Original Mode"
            onClick={toggleFullScreen}
          ></i>
        ) : (
          <i
            class="fa-solid fa-minimize"
            title="Full Mode"
            onClick={toggleFullScreen}
          ></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <UpdateValueLavel
                studyId={studyId}
                isFormOpen={isFormOpen}
                getValueLevelData={getValueLevelData}
              />
            )}
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : valueLaveldata?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <ValueLabelTableData
                  studyId={studyId}
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  setIsFormOpen={setIsFormOpen}
                  valueLaveldata={valueLaveldata}
                  getValueLevelData={getValueLevelData}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <ValueLabelTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <ValueLabelFilter
        Show={!toggle1}
        Hide={setToggle1}
        valueLaveldata={valueLaveldata}
        setValueLaveldata={setValueLaveldata}
        studyId={studyId}
        getValueLevelData={getValueLevelData}
        Title={"Filter Value Label"}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  );
};

export default ValueLabel;
