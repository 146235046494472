import React, { useState } from "react";
import StudyEventAttributeTable from "./StudyEventAttributeTable";
import StudyEventQueryListComponent from "./QueryManagement/StudyEventQueryListComponent";
import StudyIdStudyVisitCrf from "./StudyIdStudyVisitCrf";
import UpdatedHistory from "./UpdatedHistory";
import StudyEventAuditLogs from "./StudyEventAuditLogs";
import EventReviewHistory from "./EventReviewHistory";
const EventTabProperties = ({
  visitUniqueId,
  viewTypeData,
  tableRef,
  onMouseDown,
  setIsSectionVisible,
  getStudyVisitRowData,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  let finalTabArray = viewTypeData === "ctms" ? [
    "properties",
    "review History",
    "query",
  ] : [
    "properties",
    "history",
    "assign Event Form",
    "activities",
  ]

  return (
    <>
      <div
        className={`pt-1 border-bottom bg-light ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between">
          <div className="hstack me-1 gap-1">
            {finalTabArray?.map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible(false)}
              >
              </i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
        {activeTabs === "properties" ? (
          <StudyEventAttributeTable
            visitUniqueId={visitUniqueId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "history" ? (
          <UpdatedHistory
            // studyMetadataHistoryLists={studyVisitHistoryLists}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "review History" ? (
          <EventReviewHistory
            studyUniqueId={visitUniqueId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "assign Event Form" ? (
          <StudyIdStudyVisitCrf
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            visitUniqueId={visitUniqueId}
          />
        ) : activeTabs === "query" ? (
          <StudyEventQueryListComponent
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            visitUniqueId={visitUniqueId}
            getStudyVisitRowData={getStudyVisitRowData}
          />
        ) : activeTabs === "activities" ? (
          <StudyEventAuditLogs
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            visitUniqueId={visitUniqueId}
          />
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default EventTabProperties;
