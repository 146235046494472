import React from "react";
import { useDnD } from "./DnDContext.jsx";

export default () => {
  const [_, setType] = useDnD();

  const onDragStart = (event, nodeType) => {
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside>
      <span
        className="CP ps-3 small linkClass"
        onDragStart={(event) => onDragStart(event, "Merge")}
        draggable
      >
        Merge
      </span>
      <span
        className="CP ps-3 small linkClass"
        onDragStart={(event) => onDragStart(event, "Append")}
        draggable
      >
        Append
      </span>
      <span
        className="CP ps-3 small linkClass"
        onDragStart={(event) => onDragStart(event, "Transpose")}
        draggable
      >
        Transpose
      </span>
      <span
        className="CP ps-3 small linkClass"
        onDragStart={(event) => onDragStart(event, "Sort")}
        draggable
      >
        Sort
      </span>
    </aside>
  );
};
