import React from "react";
import { Form, Offcanvas } from "react-bootstrap";

const RequestManagementFilterData = ({ Show, Hide }) => {
    return (
        <>
            <Offcanvas
                show={Show} onHide={Hide}
                style={{ width: 400 }} placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div
                        className="p-3 overflow-auto"
                        style={{ height: "calc(100vh - 89px)" }}
                    >
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                                <b>Site</b>
                            </Form.Label>
                            <Form.Select
                                className="p-1 px-2   text-capitalize"
                            >
                                <option value="">Select Site</option>
                                <option >
                                    Site 1
                                </option>
                                <option >
                                    Site 2
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                                <b>Treatment Code</b>
                            </Form.Label>
                            <Form.Select
                                className="p-1 px-2   text-capitalize"
                            >
                                <option value="">Select Treatment Code</option>
                                <option >
                                    Treatment 001
                                </option>
                                <option >
                                    Treatment 002
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                                <b>Status</b>
                            </Form.Label>
                            <Form.Select
                                className="p-1 px-2  "
                            >
                                <option value="">Select Status</option>
                                <option value="completed">
                                    Completed
                                </option>
                                <option value="pending">
                                    Pending
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
                        <button
                            className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
                        >
                            Reset
                        </button>
                        <button
                            className="border fontSize12   p-1 px-2 updateProfile"
                        >
                            Filter
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default RequestManagementFilterData;
