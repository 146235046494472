import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { UseCrfViewContext } from "../../../../../Contexts/UseCrfViewContext";
import { clientLevelFormServices } from "../../../../../Services/formServices/clientLevelFormServices";
import { UseSignature } from "../../StudyIdData/UseAppendInput";
import UpdateJsonData from "../../StudyIdData/StudyIdModal/updateJsonData";
import { Spinner } from "react-bootstrap";
import UseToggle from "../../../../../Hooks/UseToggle";
import DataEntryFormSignature from "./DataEntryFormSignature";

const CrfViewLeft = ({
  setDataEntryInput,
  selectedForm,
  setSelectedForm,
  setSelectedFormId,
  formUniqueId,
  dataEntry,
  dataLoader,
  inputData,
  finalDataEntryArray,
  confirm,
  addEntry,
  cleanAllDataEntryFormField,
  setInputFormDataEntry,
  ticketQuery,
  reviewedData,
  StudyMode,
  studyUniqueId,
  dataEntryMode,
  connectUniqueIdData,
  visitFormType,
  formType,
  formDataValue,
  getSingleConditionalLogic,
  dayCheck,
  monthCheck,
  yearCheck,
  hourCheck,
  minuteCheck,
  secondCheck,
  hasUnfreezed
}) => {

  const { fieldReviewStatus } = UseCrfViewContext();

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  const { toggle1, setToggle1 } = UseToggle();

  let role = JSON.parse(localStorage.getItem("role"));
  let previousRole = JSON.parse(localStorage.getItem("previousRole"));


  const inputFormDataEntryData = (uniqueId) => {
    setSelectedFormId(uniqueId)
    let data = inputData?.find(item => item.uniqueId === uniqueId)
    setInputFormDataEntry(data)
  }

  const { signFormProps } = UseSignature(
    "reviewStudyVisitsForm"
  );

  let verifiedReviewed = reviewedData?.filter(item => item.status === "verified")
  let checkReviewedFiled = reviewedData?.length === verifiedReviewed?.length

  let studyPermissionId = JSON.parse(localStorage.getItem("studyPermissionId"));

  useEffect(() => {
    if (selectedForm) {
      setSelectedForm("")
    }
  }, [])

  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    if (dayCheck || monthCheck || yearCheck || hourCheck || minuteCheck || secondCheck) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [dayCheck, monthCheck, yearCheck, hourCheck, minuteCheck, secondCheck]);

  return (
    <>
      <UpdateJsonData {...signFormProps()} />
      <div className="builderLeft w-100 position-relative">
        <div className="List__assessments overflow-auto" style={{ height: (fieldReviewStatus === true || checkReviewedFiled === true) ? `${formType === "studyForm" ? "calc(100vh - 176px)" : "calc(100vh - 206px)"}` : "calc(100vh - 186px)" }}>
          {visitFormType === "log" ?
            connectUniqueIdData &&
            <React.Fragment>
              {formDataValue?.map((item, i) => (
                item.type === "header" ? "" :
                  !(item?.access)?.includes(studyPermissionId) &&
                  <React.Fragment key={i}>
                    <button
                      data-tooltip-id={`${i}`}
                      className={`btn d-flex ${selectedForm?.uniqueId === item?.uniqueId
                        ? "crfTabsActive"
                        : "crfTabs"
                        }`}
                      onClick={() => {
                        setSelectedForm(item);
                        inputFormDataEntryData(item?.uniqueId);
                        getSingleConditionalLogic(item?.uniqueId);
                        setDataEntryInput("");
                        finalDataEntryArray(true);
                        cleanAllDataEntryFormField();
                      }}
                    >
                      {connectUniqueIdData ?
                        <span className="pe-2 gap-1">
                          {(ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData)))?.length === 2 ?
                            <i className="fa-solid fa-circle-question" data-tooltip-id="ManualAutoQuery" style={{ color: 'red' }}></i> :
                            (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData)))?.length === 1 ?
                              ((ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData && data.queryType === "auto")))?.length === 1 ?
                                <i className="fa-solid fa-circle-exclamation" data-tooltip-id="AutoQuery" style={{ color: 'red' }}></i> :
                                (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData && data.queryType === "manual")))?.length === 1 ?
                                  <i className="fa-solid fa-circle-question" data-tooltip-id="ManualQuery" style={{ color: 'red' }}></i> : "")
                              : reviewedData?.find((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData)) ?
                                <i className="fad fa-check-circle" data-tooltip-id="Verified" style={{ color: 'green' }}></i>
                                :
                                <i className="fad fa-clock" data-tooltip-id="Pending" style={{ color: 'gray' }}></i>
                          }
                        </span>
                        :
                        <span className="pe-2 gap-1">
                          {(ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId)))?.length === 2 ?
                            <i className="fa-solid fa-circle-question" data-tooltip-id="ManualAutoQuery" style={{ color: 'red' }}></i> :
                            (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId)))?.length === 1 ?
                              ((ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.queryType === "auto")))?.length === 1 ?
                                <i className="fa-solid fa-circle-exclamation" data-tooltip-id="AutoQuery" style={{ color: 'red' }}></i> :
                                (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.queryType === "manual")))?.length === 1 ?
                                  <i className="fa-solid fa-circle-question" data-tooltip-id="ManualQuery" style={{ color: 'red' }}></i> : "")
                              : reviewedData?.find((data) => (data.formFieldId === item?.uniqueId)) ?
                                <i className="fad fa-check-circle" data-tooltip-id="Verified" style={{ color: 'green' }}></i>
                                :
                                <i className="fad fa-clock" data-tooltip-id="Pending" style={{ color: 'gray' }}></i>
                          }
                        </span>
                      }
                      <ReactTooltip
                        id="Verified"
                        place="top"
                        style={{ fontSize: 11 }}
                        content="Verified"
                      />
                      <ReactTooltip
                        id="Pending"
                        place="top"
                        style={{ fontSize: 11 }}
                        content="Pending"
                      />
                      <ReactTooltip
                        id="ManualQuery"
                        place="top"
                        style={{ fontSize: 11 }}
                        content="Manual Query"
                      />
                      <ReactTooltip
                        id="AutoQuery"
                        place="top"
                        style={{ fontSize: 11 }}
                        content="Auto Query"
                      />
                      <ReactTooltip
                        id="ManualAutoQuery"
                        place="top"
                        style={{ fontSize: 11 }}
                        content="Manual & Auto Query"
                      />
                      {item?.required === "true" ? (
                        <span style={{ color: "red", marginRight: "5px" }}>
                          *
                        </span>
                      ) : (
                        ""
                      )}
                      <span style={{ textAlign: "initial" }}>{item?.uid}</span>
                    </button>
                    <ReactTooltip id={`${i}`}
                      place="top"
                      style={{ fontSize: 11 }}
                      content={item?.label} />
                  </React.Fragment>
              ))}
            </React.Fragment>
            :
            <React.Fragment>
              {formDataValue?.map((item, i) => (
                !(item?.access)?.includes(studyPermissionId) &&
                <React.Fragment key={i}>
                  <button
                    data-tooltip-id={`${i}`}
                    className={`btn d-flex ${selectedForm?.uid === item?.uid
                      ? "crfTabsActive"
                      : "crfTabs"
                      }`}
                    onClick={() => {
                      setSelectedForm(item);
                      inputFormDataEntryData(item?.uniqueId);
                      getSingleConditionalLogic(item?.uniqueId);
                      setDataEntryInput("");
                      finalDataEntryArray(true);
                      cleanAllDataEntryFormField();
                    }}
                  >
                    {formType === "studyform" ? "" :
                      connectUniqueIdData ?
                        <span className="pe-2 gap-1">
                          {(ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData)))?.length === 2 ?
                            <i className="fa-solid fa-circle-question" data-tooltip-id="ManualAutoQuery" style={{ color: 'red' }}></i> :
                            (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData)))?.length === 1 ?
                              ((ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData && data.queryType === "auto")))?.length === 1 ?
                                <i className="fa-solid fa-circle-exclamation" data-tooltip-id="AutoQuery" style={{ color: 'red' }}></i> :
                                (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData && data.queryType === "manual")))?.length === 1 ?
                                  <i className="fa-solid fa-circle-question" data-tooltip-id="ManualQuery" style={{ color: 'red' }}></i> : "")
                              : reviewedData?.find((data) => (data.formFieldId === item?.uniqueId && data.connectUniqueId === connectUniqueIdData)) ?
                                <i className="fad fa-check-circle" data-tooltip-id="Verified" style={{ color: 'green' }}></i>
                                :
                                <i className="fad fa-clock" data-tooltip-id="Pending" style={{ color: 'gray' }}></i>
                          }
                        </span>
                        :
                        <span className="pe-2 gap-1">
                          {(ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId)))?.length === 2 ?
                            <i className="fa-solid fa-circle-question" data-tooltip-id="ManualAutoQuery" style={{ color: 'red' }}></i> :
                            (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId)))?.length === 1 ?
                              ((ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.queryType === "auto")))?.length === 1 ?
                                <i className="fa-solid fa-circle-exclamation" data-tooltip-id="AutoQuery" style={{ color: 'red' }}></i> :
                                (ticketQuery?.filter((data) => (data.formFieldId === item?.uniqueId && data.queryType === "manual")))?.length === 1 ?
                                  <i className="fa-solid fa-circle-question" data-tooltip-id="ManualQuery" style={{ color: 'red' }}></i> : "")
                              : reviewedData?.find((data) => (data.formFieldId === item?.uniqueId)) ?
                                <i className="fad fa-check-circle" data-tooltip-id="Verified" style={{ color: 'green' }}></i>
                                :
                                <i className="fad fa-clock" data-tooltip-id="Pending" style={{ color: 'gray' }}></i>
                          }
                        </span>
                    }
                    <ReactTooltip
                      id="Verified"
                      place="top"
                      style={{ fontSize: 11 }}
                      content="Verified"
                    />
                    <ReactTooltip
                      id="Pending"
                      place="top"
                      style={{ fontSize: 11 }}
                      content="Pending"
                    />
                    <ReactTooltip
                      id="ManualQuery"
                      place="top"
                      style={{ fontSize: 11 }}
                      content="Manual Query"
                    />
                    <ReactTooltip
                      id="AutoQuery"
                      place="top"
                      style={{ fontSize: 11 }}
                      content="Auto Query"
                    />
                    <ReactTooltip
                      id="ManualAutoQuery"
                      place="top"
                      style={{ fontSize: 11 }}
                      content="Manual & Auto Query"
                    />
                    {item?.required === "true" ? (
                      <span style={{ color: "red", marginRight: "5px" }}>
                        *
                      </span>
                    ) : (
                      ""
                    )}
                    <span style={{ textAlign: "initial" }}>{item?.uid}</span>
                  </button>
                  <ReactTooltip id={`${i}`}
                    place="top"
                    style={{ fontSize: 11 }}
                    content={item?.label} />
                </React.Fragment>
              ))}
            </React.Fragment>
          }
        </div>
        {formType === "studyform" ? "" :
            (formType === "studyform" || StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode") ? "" : (confirm ?
              (!!addEntry?.length && !hasUnfreezed) && (
                <button disabled={isFilled} className={`btn rounded-0 text-white w-100 position-sticky fontSize12 bg-main`} style={{ bottom: -1 }} onClick={() => finalDataEntryArray(false)} title="Add Data for Entry">Confirm to Submit</button>
              ) :
              !!inputData?.length && (
                <>
                  {dataLoader ?
                    <button className={`btn rounded-0 text-white w-100 position-sticky fontSize12 bg-main`} style={{ bottom: -1, cursor: "no-drop" }} title="Add Data for Entry">
                      {dataLoader && (
                        <Spinner
                          className="me-1"
                          style={{ width: 12, height: 12 }}
                          animation="border"
                        />
                      )}
                      Submit</button>
                    :
                    <button className={`btn rounded-0 text-white w-100 position-sticky fontSize12 bg-main`} style={{ bottom: -1 }} onClick={() => { dataEntry(); cleanAllDataEntryFormField() }} title="Add Data for Entry">Submit</button>
                  }
                </>
              )
          )
        }
        {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode") && ((studyPermissionData?.verifyDatabaseViewFormManagement ||
          (studyPermissionData?.siteIdForSDVLive)?.length > 0 ||
          (studyPermissionData?.siteIdForSDVQC)?.length > 0) &&
          (role === "studyId" && previousRole === "ctms") && (fieldReviewStatus === true || checkReviewedFiled === true) ?
          <div className="text-center position-absolute bottom-0 CP p-1 border-top fontSize12 w-100 text-white overflow-hidden" style={{ background: "var(--blue)" }}>
            <div
              onClick={setToggle1}
              title="Signature Verification">Signature
            </div>
          </div>
          : "")}
      </div>
      <DataEntryFormSignature
        Show={!toggle1}
        Hide={setToggle1}
        formUniqueId={formUniqueId}
        dataEntryMode={dataEntryMode}
        studyUniqueId={studyUniqueId}
        Title={"Signature Required"} />
    </>
  );
};

export default CrfViewLeft;
