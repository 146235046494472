import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import RandomizeTabData from "./RandomizeTabData";
import UseToggle from "../../../Hooks/UseToggle";
import { Form, Table } from "react-bootstrap";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import Moment from "react-moment";

const Randomization = ({ onMouseDown, tableRef }) => {
  const {toggle, setToggle} = UseToggle()
  const [updateType, setUpdateType] = useState("");
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [randomizationData, setRandomizationData] = useState("");
  const [seedNumber, setSeedNumber] = useState("");
  const [description, setDescription] = useState("");
  const [randomizerMatadata, setRandomizerMatadata] = useState([]);
  const [isSectionVisible, setIsSectionVisible] = useState("");

  const { studyId } = useParams();

  const handleUpdate = (data) => {
    setRandomizationData(data)
    setDescription(data?.description)
    setTitle(data?.name)
    setUpdateType("update");
  };

  const fullModeTable = () => {
    setRandomizationData("")
    setDescription("")
    setTitle("")
    setUpdateType("");
  };

  const getRandomizerMetadataData = async () => {
    setLoader(true)
    let data = await randomizerServices.getRandomizerMetadataData(studyId)
    setRandomizerMatadata(data?.data || [])
    setLoader(false)
  }

  const addRandomizerMetadata = async () => {
    let userData = {}
    userData.studyId = studyId
    userData.name = title
    userData.description = description
    let data = await randomizerServices.addRandomizerMetadata(userData)
    if (data?.statusCode === 200) {
      getRandomizerMetadataData()
      setDescription("")
      setTitle("")
    }
  }

  const findRandomizerStudyParameter = async () => {
    let data = await randomizerServices.findRandomizerStudyParameter(studyId);
    setSeedNumber(data?.data?.randomNumberSeed);
  };

  const updateRandomizerMetadataData = async () => {
    let userData = {}
    userData.id = randomizationData?._id
    userData.studyId = studyId
    userData.name = title
    userData.description = description
    let data = await randomizerServices.updateRandomizerMetadataData(userData)
    if (data?.statusCode === 200) {
      getRandomizerMetadataData()
    }
  }

  const deleteRandomizerMetadataData = async (id) => {
    let data = await randomizerServices.deleteRandomizerMetadataData(studyId, id)
    if (data?.statusCode === 200) {
      getRandomizerMetadataData()
    }
  }

  useEffect(() => {
    getRandomizerMetadataData();
    findRandomizerStudyParameter();
  }, [studyId]);

  return (
    <>
      <div className="position-relative">
        <div className="p-1 border-bottom">
          <div className="hstack gap-2 toolbar">
            {updateType ? (
              <i
                onClick={fullModeTable}
                title="Table Full Mode"
                className="fa-solid fa-table-list CP"
              ></i>
            ) : (
              <i
                onClick={() => setUpdateType("add")}
                title="Add New"
                className="fa-solid fa-plus CP"
              />
            )}
            <div className="vr"></div>
            <i
              onClick={setToggle}
              title="Filter"
              className="fa-solid fa-filter CP"
            ></i>
          </div>
        </div>
        <SplitPane
          split="horizontal"
          style={{ height: "calc(100vh - 187px)" }}
          className="position-relative"
          defaultSize={isSectionVisible ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updateType ? "25%" : "100%"}
          >
            {loader ? (
              <SpinerDataLoader />
            ) : (
              updateType && (
                <div className="p-2">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      type="text"
                      placeholder="Write Title Here"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      as="textarea"
                      placeholder="Write Description Here"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 text-end">
                    <button disabled={!title || !description} onClick={updateType === "add" ? addRandomizerMetadata : updateRandomizerMetadataData} className="updateProfile text-capitalize">{updateType}</button>
                  </Form.Group>
                </div>
              )
            )}
            {loader ? (
              <SpinerDataLoader />
            ) : (
              <>
                <Table className="custom-table ">
                  <thead>
                    <tr>
                      <th style={{width: 50}}>Sr No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Seed No.</th>
                      <th>Total Randomization</th>
                      <th>Randomization Date | Time</th>
                      <th style={{ width: 70 }} className="text-center">
                        Update
                      </th>
                      <th style={{ width: 70 }} className="text-center">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {randomizerMatadata?.map((item, index) => (
                      <tr className={isSectionVisible?._id === item?._id ? "selectedMenu CP" : "CP"} key={index}>
                        <td onClick={() => setIsSectionVisible(item)}>{index + 1}</td>
                        <td onClick={() => setIsSectionVisible(item)}>
                          {item.name}
                        </td>
                        <td onClick={() => setIsSectionVisible(item)}>
                          {item.description}
                        </td>
                        <td onClick={() => setIsSectionVisible(item)}>{seedNumber}</td>
                        <td onClick={() => setIsSectionVisible(item)}>{item?.totalRandomized}</td>
                        <td onClick={() => setIsSectionVisible(item)}>
                          <Moment format="DD MMM YYYY | HH:mm:ss">{item.updatedAt}</Moment>
                        </td>
                        <td className="text-center">
                          <button
                            onClick={() => handleUpdate(item)}
                            className="text-white border-success bg-success border  "
                            style={{ fontSize: 10 }}
                          >
                            Update
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            onClick={() => deleteRandomizerMetadataData(item._id)}
                            className="text-white border-danger bg-danger border  "
                            style={{ fontSize: 10 }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </SplitPane>
          {isSectionVisible && (
            <RandomizeTabData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              randomizerData={isSectionVisible}
              randomizerId={isSectionVisible?._id}
              setIsSectionVisible={setIsSectionVisible}
            />
          )}
        </SplitPane>
      </div>
    </>
  );
};

export default Randomization;
