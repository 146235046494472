import React from "react";
import { Form, Spinner } from "react-bootstrap";

export const NoDataFoundTable = ({ MSG }) => (
  <div
    className={`hstack justify-content-center align-items-center`}
    style={{ height: "100%" }}
  >
    <Form.Label className="fw-bold">{MSG}</Form.Label>
  </div>
);

export const SpinerDataLoader = ({ width, height }) => (
  <div
    className="hstack justify-content-center align-items-center"
    style={{ height: "100%" }}
  >
    <Spinner
      className="me-1"
      style={{ width: width || 20, height: height || 20, color: "var(--blue)" }}
      animation="border"
    />
  </div>
);

export const IdDetails = ({ CrfView, NoData }) => (
  <span className={CrfView ? "crfHight" : "tableRightContent"}>
    {NoData || "Please Select Any Item To Show Details"}
  </span>
);
