import React from "react";
import { FaGlobeAfrica } from "react-icons/fa";
import { NavLink } from "react-router-dom";
const currentYear = new Date().getFullYear();

const CopyRight = () => (
  <div className="mt-3">
    <NavLink
      target="_blank"
      to="https://www.galaxbiotech.com/"
      className="galaxsite hstack justify-content-center gap-1 pb-3 fontSize12"
    >
      <FaGlobeAfrica style={{ marginBottom: -2 }} /> www.galaxbiotech.com
    </NavLink>
    <p className="copyright text-center m-0 pb-1 fontSize12">
      Copyright© {currentYear} Galaxbiotech All Rights Reserved
    </p>
    <div className="hstack justify-content-between gap-3">
      <NavLink
        to="#"
        className="galaxsite hstack justify-content-center fontSize12"
      >
        Terms of Use & Legal Information
      </NavLink>
      |
      <NavLink
        to="#"
        className="galaxsite hstack justify-content-center fontSize12"
      >
        Privacy Statement
      </NavLink>
    </div>
  </div>
);

export default CopyRight;
