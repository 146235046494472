import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";
import { Badge, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { elearningServices } from "../../../../Services/elearningServices";

const AddTrainingData = ({
  Show,
  Hide,
  Title,
  studyId,
  getStudyELearningQuestionData,
  traningId,
}) => {
  let [loader, setLoader] = useState(false);
  let [title, setTitle] = useState("");
  let [description, setDescription] = useState("");
  let [type, setType] = useState("");
  let [fileType, setFileType] = useState("");
  let [file, setFile] = useState(null);
  let [categories, setCategories] = useState("");
  let [questionNO, setQuestionNO] = useState("");
  const [questions, setQuestions] = useState([
    { questionId: Math.random().toString().substr(2, 10), question: "", options: ["", "", "", ""], answerType: "", answered: "" },
  ]);

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleMultiSelectChange = (qIndex, selectedList) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answered = selectedList.map(item => item.value);
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAnswerTypeChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answerType = value;
    setQuestions(newQuestions);
  };

  const handleAnsweredChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answered = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { questionId: Math.random().toString().substr(2, 10), question: "", options: ["", "", "", ""], answerType: "" },
    ]);
  };

  const deleteQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const addOption = (qIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.push("");
    setQuestions(newQuestions);
  };

  const deleteOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options = newQuestions[qIndex].options.filter(
      (_, i) => i !== oIndex
    );
    setQuestions(newQuestions);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(`.${fileType}`)) {
        setFile(file);
      } else {
        setFile(null);
      }
    }
  };

  const addStudyElearningQuestion = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("studyID", studyId);
    formData.append("traningId", traningId);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("type", type);
    if (type === "file") {
      formData.append("file", file);
      formData.append("extension", fileType);
    } else if (type === "quiz") {
      formData.append("questionNO", questionNO);
      formData.append("category", categories);
      formData.append("quizes", questions);
    }
    let data = await elearningServices.addStudyElearningQuestion(formData);
    if (data?.statusCode === 200) {
      getStudyELearningQuestionData();
      Hide();
      setTitle("");
      setDescription("");
      setType("");
      setFileType("");
      setFile("");
      setCategories("");
      setQuestionNO("");
      setQuestions([
        {
          question: "",
          options: ["", "", "", ""],
          answerType: "",
          answered: "",
        },
      ]);
    }
    setLoader(false);
  };

  const updateStudyELearningQuestion = async () => {
    setLoader(true);
    let userData = {};
    userData.studyID = studyId;
    userData.traningId = traningId;
    userData.title = title;
    userData.description = description;
    userData.type = type;
    userData.file = file;
    userData.extension = fileType;
    userData.questionNO = questionNO;
    userData.category = categories;
    userData.quizes = questions;
    let data = await elearningServices.updateStudyELearningQuestion(userData);
    if (data?.statusCode === 200) {
      getStudyELearningQuestionData();
      Hide();
      setTitle("");
      setDescription("");
      setType("");
      setFileType("");
      setFile("");
      setCategories("");
      setQuestionNO("");
      setQuestions([
        {
          question: "",
          options: ["", "", "", ""],
          answerType: "",
          answered: "",
        },
      ]);
    }
    setLoader(false);
  };

  return (
    <div>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 650 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={title}

                placeholder="Enter Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                value={description}

                placeholder="Enter Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select

                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option selected={type === "file" && "file"} value="file">
                  File
                </option>
                <option selected={type === "quiz" && "quiz"} value="quiz">
                  Quiz
                </option>
              </Form.Select>
            </Form.Group>
            {type === "file" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    File Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select

                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <option value="">Select File Type</option>
                    <option
                      selected={fileType === "video" && "video"}
                      value="video"
                    >
                      Video
                    </option>
                    <option selected={fileType === "pdf" && "pdf"} value="pdf">
                      Pdf
                    </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    File Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleUpload}

                    disabled={!fileType}
                    accept={`${fileType === "video" ? ".mp4" : ".pdf"}`}
                  />
                </Form.Group>
              </>
            )}
            {type === "quiz" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    No.of Quiz for Question{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter No. of Quiz for Question"

                    value={questionNO}
                    onChange={(e) => setQuestionNO(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    Categories <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select

                    onChange={(e) => setCategories(e.target.value)}
                  >
                    <option value="">Select Categories</option>
                    <option
                      selected={categories === "finalQuiz" && "finalQuiz"}
                      value="finalQuiz"
                    >
                      Final Quiz
                    </option>
                    <option
                      selected={
                        categories === "knowledgeQuiz" && "knowledgeQuiz"
                      }
                      value="knowledgeQuiz"
                    >
                      Knowledge Checking Quiz
                    </option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
            {type === "quiz" && categories && (
              <Form.Group className="mb-2">
                <div className="border   p-3 mt-4 pt-4 position-relative">
                  <Badge
                    className="position-absolute   text-muted bg-success-subtle"
                    style={{ top: -10 }}
                  >
                    Quiz
                  </Badge>
                  <div>
                    {questions.map((q, qIndex) => (
                      <div key={qIndex} className="mb-2">
                        <div className="mt-2">
                          <div className="shadow-sm p-2 border  ">
                            <Form.Group className="mb-2">
                              <div className="hstack justify-content-between">
                                <Form.Label className="fw-bold my-1 fontSize12">
                                  Question {qIndex + 1}{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <i
                                  className="fa-solid fa-trash-can text-danger fontSize12 CP"
                                  title="Delete Question"
                                  onClick={() => deleteQuestion(qIndex)}
                                ></i>
                              </div>
                              <Form.Control
                                as="textarea"

                                value={q.question}
                                placeholder="Type Question"
                                onChange={(e) =>
                                  handleQuestionChange(qIndex, e.target.value)
                                }
                              />
                            </Form.Group>
                            <Row>
                              {q.options.map((option, oIndex) => (
                                <React.Fragment key={oIndex}>
                                  <Form.Group as={Col} md={3} className="mb-2">
                                    <Form.Label className="fw-bold mb-1 fontSize12">
                                      Option {oIndex + 1}{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md={9}
                                    className="hstack gap-2 mb-2"
                                  >
                                    <Form.Control
                                      type="text"

                                      value={option}
                                      placeholder="Type Answer"
                                      onChange={(e) =>
                                        handleOptionChange(
                                          qIndex,
                                          oIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <i
                                      className="fa-solid fa-trash-can text-danger fontSize12 CP"
                                      title={`Delete Option ${oIndex + 1}`}
                                      onClick={() =>
                                        deleteOption(qIndex, oIndex)
                                      }
                                    ></i>
                                  </Form.Group>
                                </React.Fragment>
                              ))}
                              <Form.Group className="mb-2">
                                <button
                                  className="border fontSize12   p-1 px-2 updateProfile"
                                  onClick={() => addOption(qIndex)}
                                >
                                  + Add More Options
                                </button>
                              </Form.Group>
                            </Row>
                            <Form.Group className="mb-2">
                              <Form.Label className="mb-1 fw-bold fontSize12">
                                Answer Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select

                                value={q.answerType}
                                onChange={(e) =>
                                  handleAnswerTypeChange(qIndex, e.target.value)
                                }
                              >
                                <option value="">Select Answer Type</option>
                                <option value="singleSelect">
                                  Single Select
                                </option>
                                <option value="multiSelect">
                                  Multi Select
                                </option>
                              </Form.Select>
                            </Form.Group>
                            {q.answerType === "multiSelect" && (
                              <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold fontSize12">
                                  Answer <span className="text-danger">*</span>
                                </Form.Label>
                                <Multiselect
                                  displayValue="label"
                                  options={q.options.map((option) => ({
                                    label: option,
                                    value: option,
                                  }))}
                                  selectedValues={q.answered.map(answer => ({ label: answer, value: answer }))}
                                  onSelect={(selectedList) => handleMultiSelectChange(qIndex, selectedList)}
                                  onRemove={(selectedList) => handleMultiSelectChange(qIndex, selectedList)}
                                />
                              </Form.Group>
                            )}
                            {q.answerType === "singleSelect" && (
                              <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold fontSize12">
                                  Answer <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  onChange={(e) =>
                                    handleAnsweredChange(qIndex, e.target.value)
                                  }

                                >
                                  <option value="">Select Answer</option>
                                  {q.options.map((option, oIndex) => (
                                    <option key={oIndex} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={addQuestion}
                      className="submitbutton   border mt-3"
                    >
                      <i className="fa-solid fa-square-plus fontSize12 CP me-1"></i>{" "}
                      Add Question
                    </button>
                  </div>
                </div>
              </Form.Group>
            )}
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={() => {
                Hide();
                setLoader(false);
              }}
              className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
            >
              Clear
            </button>
            <button
              onClick={addStudyElearningQuestion}
              className="border fontSize12   p-1 px-2 updateProfile"
            >
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 14, height: 14 }}
                  animation="border"
                />
              ) : (
                <i className={` fa-solid fa-square-pen me-2`}></i>
              )}
              {`Add Training`}
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddTrainingData;
