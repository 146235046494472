import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const regularFormDataEntryServices = {
  getAssignedVisitForm,
  getAssignedSpecialForm,
  getformDataWithEntry,
  getNumberUnitByFormUniqueId,
  getTextUnitByFormUniqueId,
  queryGenerator,
  getVisitDetails,
  getSubjectDetails,
  getLogFormListForRegular,
  findLogFormListForRegular,
  queryGeneratorLogForm,
  findFormNameAndFieldName,
};

async function getAssignedVisitForm(studyId, studyUniqueId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAssignedVisitForm/${studyId}/${studyUniqueId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAssignedSpecialForm(studyId, studyUniqueId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAssignedSpecialForm/${studyId}/${studyUniqueId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getLogFormListForRegular(studyId, studyUniqueId, formUniqueId, dataEntryMode, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getLogFormListForRegular/${studyId}/${studyUniqueId}/${formUniqueId}/${dataEntryMode}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findLogFormListForRegular(studyId, id, formUniqueId, studyUniqueId, dataEntryMode, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findLogFormListForRegular/${studyId}/${id}/${formUniqueId}/${studyUniqueId}/${dataEntryMode}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findFormNameAndFieldName(studyId, formFieldId, formUniqueId, studyUniqueId, dataEntryMode, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findFormNameAndFieldName/${studyId}/${formFieldId}/${formUniqueId}/${studyUniqueId}/${dataEntryMode}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getVisitDetails(studyId, studyUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getVisitDetails/${studyId}/${studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSubjectDetails(studyId, studyUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSubjectDetails/${studyId}/${studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getNumberUnitByFormUniqueId(formUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getNumberUnitByFormUniqueId/${formUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTextUnitByFormUniqueId(formUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTextUnitByFormUniqueId/${formUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getformDataWithEntry(studyId, studyUniqueId, formUniqueId, studyMode, formType) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getformDataWithEntry/${studyId}/${studyUniqueId}/${formUniqueId}/${studyMode}/${formType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function queryGenerator(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}DataEntryQueryGenerator`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function queryGeneratorLogForm(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}DataEntryQueryGeneratorLogForm`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}