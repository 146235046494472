/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studySubjectsServices";
import { globalFormServices } from "../../../../../../Services/formServices/globalFormServices";
import { clientLevelFormServices } from "../../../../../../Services/formServices/clientLevelFormServices";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";
import { Spinner, Table } from "react-bootstrap";

const StudyIdStudyVisitCrf = ({
  tableRef,
  onMouseDown,
  visitUniqueId,
}) => {
  const { studyId } = useParams();

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const getAssignedFormOfVisits = async () => {
    let data = await studyOnboardingService.getAssignedFormOfVisits(
      studyId,
      visitUniqueId
    );
    setCheckboxValues(data?.data || []);
  };

  const [checkAssignInitiatedForm, setCheckAssignInitiatedForm] = useState([]);

  const checkFormAssignInInitiatedForm = async () => {
    setLoader(true)
    let data = await clientLevelFormServices.checkFormAssignInInitiatedForm(
      studyId
    );
    setCheckAssignInitiatedForm(data?.data || []);
    setLoader(false)
  };

  const handleSelectAllCheckboxes = (e) => {
    const isChecked = e.target.checked;

    const updatedCheckboxValues = isChecked
      ? checkAssignInitiatedForm.map((item) => item.formUniqueId)
      : [];

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = checkAssignInitiatedForm.map((item) => ({
      ...item,
      isChecked,
    }));

    setCheckAssignInitiatedForm(updatedForms);
  };

  const handleCheckboxChange = (id, isChecked) => {
    const updatedCheckboxValues = isChecked
      ? [...checkboxValues, id]
      : checkboxValues.filter((item) => item !== id);

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = checkAssignInitiatedForm.map((item) =>
      item.formUniqueId === id ? { ...item, isChecked } : item
    );

    setCheckAssignInitiatedForm(updatedForms);
  };

  const assignStudy = async () => {
    setLoading(true);
    const finalData = {
      studyVisitId: visitUniqueId,
      studyID: studyId,
      formUniqueId: checkboxValues,
    };
    await globalFormServices.pushGlobalForm(finalData, "assignForm");
    let removeData = {
      studyId: studyId,
      formUniqueIds: checkboxValues,
    };
    await studyOnboardingService.removeFromFieldInSubjectData(removeData);
    getAssignedFormOfVisits();
    setLoading(false);
  };

  let assignedForm = (checkboxValues?.length === 0 || checkboxValues?.length === undefined) ? [] : checkboxValues

  useEffect(() => {
    checkFormAssignInInitiatedForm();
  }, [studyId]);

  useEffect(() => {
    getAssignedFormOfVisits();
  }, [visitUniqueId]);


  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
      >
        {loader ? (
          <SpinerDataLoader />
        ) : checkAssignInitiatedForm?.length === 0 ?
          (
            <NoDataFoundTable MSG="Form not found" />
          )
          : (
            <>
              <Table
                className="m-0 custom-table"
                ref={tableRef}
                id="resizable-table"
                striped
              >
                <thead className="thead-sticky">
                  <tr>
                    <th onMouseDown={(e) => onMouseDown(e, 0)}>
                      <input
                        type="checkbox"
                        className="m-0"
                        onChange={(e) => handleSelectAllCheckboxes(e)}
                        checked={
                          checkAssignInitiatedForm?.length === 0 ? false : (assignedForm?.length === checkAssignInitiatedForm?.length)
                        }
                      />
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>Form Name</th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>Form Label</th>
                    <th onMouseDown={(e) => onMouseDown(e, 3)}>Form Code</th>
                    <th onMouseDown={(e) => onMouseDown(e, 4)}>Domain Class</th>
                    <th onMouseDown={(e) => onMouseDown(e, 5)}>Form Type</th>
                    <th onMouseDown={(e) => onMouseDown(e, 6)}>Repeat</th>
                  </tr>
                </thead>
                <tbody>
                  {checkAssignInitiatedForm?.map((item, i) => (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          name={item?.formUniqueId}
                          id={item?._id}
                          checked={assignedForm?.includes(
                            item?.formUniqueId
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e.target.name,
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td>
                        {item?.name}
                      </td>
                      <td>
                        {item?.label}
                      </td>
                      <td>
                        {item?.code}
                      </td>
                      <td>
                        {item?.type}
                      </td>
                      <td className="textCapitialize">
                        {item?.formType}
                      </td>
                      <td className="capitalize">
                        {item?.repeat}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {checkAssignInitiatedForm?.length > 0 &&
                <div className="position-sticky w-100 bg-white" style={{ bottom: 25 }}>
                  {checkboxValues?.length > 0 && (
                    <div className="text-end p-2">
                      <button
                        disabled={loading}
                        className="submitbutton save__all__data_action"
                        onClick={() => assignStudy()}
                      >
                        {loading ? (
                          <Spinner
                            className="me-1"
                            style={{ width: 15, height: 15, color: "var(--blue)" }}
                            animation="border"
                          />
                        ) : (
                          <i className="fa-solid fa-right-to-bracket me-1"></i>
                        )}
                        Assign Form
                      </button>
                    </div>
                  )}
                </div>
              }
            </>
          )}
      </SplitPane>
    </>
  );
};

export default StudyIdStudyVisitCrf;
