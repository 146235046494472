import { toast } from "react-toastify";
import util from 'util';

export const headersWithAuthWithoutBody = (method, auth) => ({
  method: method,
  headers: auth,
});

export const headersWithAuth = (method, userData, auth) => ({
  method: method,
  headers: auth,
  body: JSON.stringify(userData),
});

export const headersWithAuth1 = (method, userData, auth) => ({
  method: method,
  headers: auth,
  body: util.inspect(userData, { depth: null }), // Serialize userData with circular references
});

export const toastProps = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const swalProps = (text, cText) => ({
  title: "Are you sure?",
  text,
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#103C5E",
  cancelButtonColor: "#d33",
  confirmButtonText: cText,
});

export const handleResponse = (response) =>
  response.statusCode === 200
  ? toast.success(`${response.customMessage ?? "Success"}`, toastProps)
  : toast.error(
    `${response.customMessage ?? "Please try again later...!"}`,
    toastProps
  );

// export const swalProps = (icons, titles, responses) => ({
//   icon: icons,
//   title: titles,
//   text: responses,
//   showConfirmButton: false,
//   timer: 1500,
// });

// export const handleResponse = (response) =>
//   response.statusCode === 200
//     ? Swal.fire(swalProps("success", "Success", response.customMessage))
//     : Swal.fire(
//         swalProps("error", response.customMessage, "Please try again later...!")
//       );
