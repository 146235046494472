import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import SplitPane from "react-split-pane";
import { NoDataFoundTable } from "../../../../../NoDataFoundTable/NoDataFoundTable";

const UpdatedHistory = ({
  studyMetadataHistoryLists,
  tableRef,
  onMouseDown,
}) => {
  const [history, setHistory] = useState([]);
  const [comment, setComment] = useState([]);

  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  useEffect(() => {
    const rows = document.querySelectorAll("tr");

    const handleContextMenu = (event, row) => {
      event.preventDefault();
      rows.forEach((row) => row.classList.remove("highlighted"));
      row.classList.add("highlighted");
    };

    rows.forEach((row) => {
      row.addEventListener("contextmenu", (event) =>
        handleContextMenu(event, row)
      );
    });

    return () => {
      rows.forEach((row) => {
        row.removeEventListener("contextmenu", (event) =>
          handleContextMenu(event, row)
        );
      });
    };
  }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (i, item) => {
    setSelectedRow(i);
    setHistory(item);
  };

  const handleRowComment = (e, comment) => {
    handleShow1();
    setComment(comment);
  };

  return (
    <>
      {studyMetadataHistoryLists?.length > 0 ? (
        <SplitPane split="vertical" minSize={20} defaultSize="30%">
          <div>
            <div
              className="text-center white position-sticky"
              style={{ fontSize: 13, background: "var(--blue)", top: -1 }}
            >
              Metadata
            </div>
            <div className="table-container tableLibrarySection">
              <Table
                id="resizable-table" striped
                className="m-0  custom-table"
                ref={tableRef}
                bordered
              >
                <thead className="thead-sticky" style={{ top: 24 }}>
                  <tr>
                    <th
                      style={{ width: 55 }}
                      onMouseDown={(e) => onMouseDown(e, 0)}
                    >
                      S No.
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>
                      Management Name
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>
                      Latest version
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studyMetadataHistoryLists?.map((item, i) => (
                    <tr
                      key={i}
                      onClick={(e) => {
                        handleRowClick(i, item?.items);
                      }}
                      className="CP"
                      style={{
                        background: selectedRow === i ? "#ccd6dd" : "",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{item.columnName}</td>
                      <td>{item.version}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            </div>
          <div>
            <div
              className="text-center white position-sticky"
              style={{ fontSize: 13, background: "var(--blue)", top: -1 }}
            >
              History
            </div>
            <div className="table-container tableLibrarySection">
              <Table
                id="resizable-table" striped
                className="m-0  custom-table"
                ref={tableRef}
                bordered
              >
                <thead className="thead-sticky" style={{ top: 24 }}>
                  <tr>
                    <th onMouseDown={(e) => onMouseDown(e, 0)}>Version</th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>Date | Time</th>
                    <th onMouseDown={(e) => onMouseDown(e, 3)}>User Id</th>
                    <th onMouseDown={(e) => onMouseDown(e, 4)}>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {history?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.version}</td>
                      <td>{item.value}</td>
                      <td>
                        <Moment format="DD MMM YYYY | HH:mm">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td>{item.userId}</td>
                      <td>
                        <div
                          onClick={(e) => handleRowComment(e, item.comment)}
                          style={{ cursor: "pointer" }}
                        >
                          view
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </SplitPane>
      ) : (
        <SplitPane split="vertical" minSize={20} defaultSize="100%">
          <NoDataFoundTable MSG={"History Not Found!"}/>
        </SplitPane>
      )}
      <Modal
        show={show1}
        className={show1 ? "" : "d-none"}
        onHide={handleClose1}
        style={{ background: "#7f7f7fbf" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>{comment ? comment : "No Comment"}</>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatedHistory;
