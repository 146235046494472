import React, { useEffect, useRef, useState } from "react";
import Toolbar from "./Toolbar";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router";
import EditorWritingPage from "./EditorWritingPage";
import { clientLevelFormServices } from "../../../../../../Services/formServices/clientLevelFormServices";
import { regularFormDataEntryServices } from "../../../../../../Services/studyServices/regularFormDataEntryServices";

const ADRGWordEditorData = ({
  formUniqueId,
  formName,
  formType,
  selectedStudyVisitsForm,
  visitUniqueId,
  siteName,
  subjectName,
  visitName
}) => {
  const [pages, setPages] = useState([0]);
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [activeTab, setActiveTab] = useState("Home");
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [imageToInsert, setImageToInsert] = useState(null);
  const [emojiToInsert, setEmojiToInsert] = useState(null);
  const [shapeToInsert, setshapeToInsert] = useState(null);
  const [tableToInsert, setTableToInsert] = useState(null);
  const [orientation, setOrientation] = useState("portrait");
  const [selectedTableIndex, setSelectedTableIndex] = useState(null);
  const [shapeSize, setShapeSize] = useState({ width: 100, height: 100 });
  // const [pageSize, setPageSize] = useState({ width: "8.5in", height: "11in" });
  const [pageSize, setPageSize] = useState({ width: "8.5in" });
  const componentRef = useRef();

  const [margin, setMargin] = useState({
    top: ".25in",
    bottom: ".25in",
    left: ".5in",
    right: ".5in",
  });

  const handleLinePositionChange = (newLeftMargin) => {
    setMargin((prev) => ({
      ...prev,
      left: `${newLeftMargin}px`,
    }));
  };

  const handleMarginChange = (newMargin) => {
    setMargin(newMargin);
  };

  const handleEmojiInsert = (emoji) => {
    setEmojiToInsert(emoji);
  };

  const handleShapeInsert = (shape) => {
    setshapeToInsert(shape);
  };

  const handleSizeChange = (newSize) => {
    setPageSize(newSize);
  };

  const handleColumnChange = (columnLayout) => {
    setColumns(columnLayout);
  };

  const handleImageInsert = (imageSrc) => {
    setImageToInsert(imageSrc);
  };

  const toggleHeader = () => {
    setShowHeader((prev) => !prev);
  };

  const toggleFooter = () => {
    setShowFooter((prev) => !prev);
  };

  const handleConvertToPDF = () => {
    const element = document.querySelector(".text-editor-container");
    html2pdf().from(element).save();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setIsOpen(false);
  };

  const [tableProperties, setTableProperties] = useState({
    borderWidth: 1,
    columnWidth: 80,
    pinHeaderRows: 1,
    cellPadding: 5,
    minRowHeight: 40,
    borderColor: "#000000",
    cellAlignment: "TopLeft",
    cellBackgroundColor: "#ffffff",
  });

  const getCellAlignmentStyles = (alignment) => {
    switch (alignment) {
      case "TopLeft":
        return { verticalAlign: "top", textAlign: "left" };
      case "TopCenter":
        return { verticalAlign: "top", textAlign: "center" };
      case "TopRight":
        return { verticalAlign: "top", textAlign: "right" };
      case "MiddleLeft":
        return { verticalAlign: "middle", textAlign: "left" };
      case "MiddleCenter":
        return { verticalAlign: "middle", textAlign: "center" };
      case "MiddleRight":
        return { verticalAlign: "middle", textAlign: "right" };
      case "BottomLeft":
        return { verticalAlign: "bottom", textAlign: "left" };
      case "BottomCenter":
        return { verticalAlign: "bottom", textAlign: "center" };
      case "BottomRight":
        return { verticalAlign: "bottom", textAlign: "right" };
      default:
        return { verticalAlign: "top", textAlign: "left" };
    }
  };

  const handleInsertTable = (table) => {
    setTableToInsert(table);
  };

  useEffect(() => {
    if (tableToInsert) {
      const cells = Array(tableToInsert.rows)
        .fill(0)
        .map(() =>
          Array(tableToInsert.columns).fill({
            backgroundColor: tableProperties.cellBackgroundColor,
          })
        );

      const newTable = { ...tableToInsert, cells };
      setTables([...tables, newTable]);
    }
  }, [tableToInsert]);

  const handleCellChange = (rowIndex, colIndex, property, value) => {
    const updatedTables = [...tables];
    updatedTables[selectedTableIndex].cells[rowIndex][colIndex] = {
      ...updatedTables[selectedTableIndex].cells[rowIndex][colIndex],
      [property]: value,
    };
    setTables(updatedTables);
  };

  const updateTableProperties = (updatedProperties) => {
    setTableProperties(updatedProperties);
  };

  const handleAddRow = (index) => {
    const updatedTables = [...tables];
    updatedTables[index].rows += 1;
    const newCellsRow = Array(updatedTables[index].columns).fill({
      backgroundColor: tableProperties.cellBackgroundColor,
    });
    updatedTables[index].cells.push(newCellsRow);
    setTables(updatedTables);
  };

  const handleAddColumn = (index) => {
    const updatedTables = [...tables];
    updatedTables[index].columns += 1;
    updatedTables[index].cells.forEach((row) => {
      row.push({ backgroundColor: tableProperties.cellBackgroundColor });
    });
    setTables(updatedTables);
  };

  const handleDeleteRow = (index) => {
    const updatedTables = [...tables];
    if (updatedTables[index].rows > 1) {
      updatedTables[index].rows -= 1;
      updatedTables[index].cells.pop();
    }
    setTables(updatedTables);
  };

  const handleDeleteColumn = (index) => {
    const updatedTables = [...tables];
    if (updatedTables[index].columns > 1) {
      updatedTables[index].columns -= 1;
      updatedTables[index].cells.forEach((row) => row.pop());
    }
    setTables(updatedTables);
  };

  const renderShape = (shape, width, height) => {
    const commonStyle = {
      width: `${width}px`,
      height: `${height}px`,
      backgroundColor: "gray",
    };

    switch (shape) {
      case "circle":
        return <div style={{ ...commonStyle, borderRadius: "50%" }}></div>;
      case "rectangle":
        return <div style={commonStyle}></div>;
      case "square":
        return <div style={commonStyle}></div>;
      case "star":
        return (
          <div
            style={{
              ...commonStyle,
              clipPath:
                "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
            }}
          ></div>
        );
      case "triangle":
        return (
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: `${width / 2}px solid transparent`,
              borderRight: `${width / 2}px solid transparent`,
              borderBottom: `${height}px solid gray`,
            }}
          ></div>
        );
      case "rhombus":
      case "diamond":
        return (
          <div style={{ ...commonStyle, transform: "rotate(45deg)" }}></div>
        );
      case "line":
        return (
          <div
            style={{ width: "100%", height: "3px", backgroundColor: "gray" }}
          ></div>
        );
      case "heart":
        return (
          <div
            style={{
              position: "relative",
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div
              style={{
                ...commonStyle,
                width: `${width / 2}px`,
                height: `${height / 2}px`,
                borderRadius: "50% 50% 0 0",
                transform: "rotate(-45deg)",
                position: "absolute",
                top: "50%",
                left: "0",
              }}
            ></div>
            <div
              style={{
                ...commonStyle,
                width: `${width / 2}px`,
                height: `${height / 2}px`,
                borderRadius: "50% 50% 0 0",
                transform: "rotate(45deg)",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            ></div>
          </div>
        );
      case "hash":
        return <div style={commonStyle}></div>;
      default:
        return null;
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const { studyId } = useParams();
  const [formDataValue, setFormDataValue] = useState([])
  const [connectUniqueId, setConnectUniqueId] = useState("");
  const [formEntryType, setFormEntryType] = useState("regular");
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const getSingleConditionalLogic = async (id, formEntryType, formType) => {
    const formUniqueIdOrId = id || formUniqueId;
    let conditionalData = [];
    if (!connectUniqueId) {
      if (formEntryType === "regular") {
        conditionalData = await clientLevelFormServices.getEntriedConditionalOptionId(studyId, formUniqueIdOrId, visitUniqueId, formType, studyMode);
      }
    } else {
      conditionalData = await clientLevelFormServices.getEntriedConditionalLogFormOptionId(studyId, connectUniqueId, formUniqueIdOrId, visitUniqueId, formType, studyMode);
    }
    const finalConditionalData = conditionalData?.data || [];
    const mergedOptionValueArray = Array.from(new Set([...finalConditionalData]));
    const data = await clientLevelFormServices.getSingleConditionalLogic("getSingleConditionalLogic", formUniqueId, formType);
    const conditionalLogicForm = formType !== "studyform" ? data?.data1 || [] : [];
    const conditionalLogicFormId = formType !== "studyform" ? data?.data2 || [] : [];
    const conditionalLogicFormIdData = conditionalLogicFormId?.map(item => item.conditionalData) || [];
    let formFieldData;
    if (connectUniqueId) {
      formFieldData = await regularFormDataEntryServices.findLogFormListForRegular(studyId, connectUniqueId, formUniqueIdOrId, visitUniqueId, "visitForm", studyMode);
    } else {
      if(visitUniqueId && formUniqueId && formType){
        formFieldData = await regularFormDataEntryServices.getformDataWithEntry(studyId, visitUniqueId, formUniqueIdOrId, studyMode, formType);
      }
      
    }
    const formData = formFieldData?.data || [];
    if (!conditionalLogicForm.length) {
      setFormDataValue(formData);
      return;
    }
    if (!mergedOptionValueArray.length) {
      const finalFormDataValue = formData.filter(item => !conditionalLogicFormIdData.includes(item.uniqueId));
      setFormDataValue(finalFormDataValue);
      return;
    }
    const conditionalDataFiltered = conditionalLogicForm.filter(item => mergedOptionValueArray.includes(item.optionId));
    const optionUniqueIds = formData.map(item => item.uniqueId);
    const formOptionUniqueId = optionUniqueIds.filter(id => !conditionalLogicFormIdData.includes(id));
    const conditionalLogicIds = Array.from(new Set(conditionalDataFiltered.flatMap(data => data.conditionalId)));
    const finalConditionalDataFiltered = conditionalLogicFormIdData.filter(id => conditionalLogicIds.includes(id));
    const filteredConditionalData = conditionalLogicFormId.filter(item => conditionalLogicIds.includes(item.conditionalData));
    const finalDataValue = filteredConditionalData
      .filter(element => mergedOptionValueArray.includes(element?.optionId))
      .map(element => element?.conditionalData);
    const mergedArray = [...finalConditionalDataFiltered, ...formOptionUniqueId];
    const countOccurrences = (array, id) => array.filter(item => item === id).length;
    const filteredData2 = mergedArray.filter(id => {
      if (finalDataValue.includes(id)) {
        return countOccurrences(finalDataValue, id) === countOccurrences(mergedArray, id);
      }
      return true;
    });
    const finalFormDataValue = formData.filter(item => filteredData2.includes(item.uniqueId));
    setFormDataValue(finalFormDataValue);
  };

  let finalFormData =
    formType === "studyform" ? selectedStudyVisitsForm : formDataValue;

  const [formArray, setFormArray] = useState([]);
  const [type, setType] = useState("");

  const checkFormData = () => {
    let allField = [];
    for (const element of finalFormData) {
      if (element?.groupCategory === "yes" && element?.categoryName) {
        let objectData = {
          order: Number(element.orderNumber || 0),
          label: element.label,
          name: element.uid,
          type: element.type,
          categoryLabel: element.categoryLabel,
          categoryName: element.categoryName,
          categoryValueLabel: element.categoryValueLabel,
          categoryValueName: element.categoryValueName,
          categoryUnitLabel: element.categoryUnitLabel,
          categoryUnitName: element.categoryUnitName,
          filterUnit: element.filterUnit || [],
          options: element.options || [],
          additionalCategory: element.additionalCategory || [],
        };
        allField.push(objectData);
      }
    }

    let getWithoutGroupData = finalFormData?.filter(item => item.groupCategory !== "yes");

    let finalData = convertData(allField);

    let finalGroupData = [];
    for (const data of finalData) {
      const lowestOrder = data?.field?.reduce((min, obj) => (obj.order < min.order ? obj : min));
      const seenUnits = new Set();
      const filterUnit = data?.filterUnit?.filter(item => {
        if (seenUnits.has(item.unit)) {
          return false;
        } else {
          seenUnits.add(item.unit);
          return true;
        }
      });
      const options = data?.options?.filter(item => {
        if (seenUnits.has(item.label)) {
          return false;
        } else {
          seenUnits.add(item.label);
          return true;
        }
      });
      data.orderNumber = lowestOrder?.order;
      data.filterUnit = filterUnit;
      data.options = options;
      finalGroupData.push(data);
    }

    let finalFormArray = [...finalGroupData, ...getWithoutGroupData];
    finalFormArray?.sort((a, b) => parseFloat(a.orderNumber || 0) - parseFloat(b.orderNumber || 0));

    setFormArray(finalFormArray);
  };

  const convertData = (inputData) => {
    const filteredData = inputData.filter(item =>
      (item.type === 'text' || item.type === 'number')
    );

    const result = {};
    inputData.forEach(item => {
      if (!result[item?.categoryName]) {
        let findUnitData = filteredData?.find(data => (data?.categoryName === item?.categoryName) && data?.categoryUnitName)
        let checkValueType = inputData?.some(data => data?.categoryName === item?.categoryName && (data?.type === "checkbox" || data?.type === "radio" || data?.type === "dropdown"))
        result[item?.categoryName] = {
          type: "groupForm",
          valueFieldType: checkValueType,
          categoryName: item?.categoryName,
          categoryLabel: item?.categoryLabel,
          categoryValueLabel: item?.categoryValueLabel,
          categoryValueName: item?.categoryValueName,
          categoryUnitLabel: findUnitData?.categoryUnitLabel,
          categoryUnitName: findUnitData?.categoryUnitName,
          filterUnit: [...item.filterUnit],
          additionalCategory: item.additionalCategory,
          options: [...item.options],
          field: []
        };
      } else {
        result[item?.categoryName].filterUnit = [...new Set([...result[item?.categoryName].filterUnit, ...item.filterUnit])];
        result[item?.categoryName].options = [...new Set([...result[item?.categoryName].options, ...item.options])];
      }

      result[item?.categoryName].field.push({
        order: item.order,
        label: item.label,
        name: item.name
      });
    });

    return Object.values(result);
  };

  const loadFormData = (type) => {
    if (type === "single") {
      setFormArray(finalFormData)
    } else if (type === "group") {
      checkFormData()
    } else {
      setFormArray([])
    }
  }

  useEffect(() => {
    loadFormData(type)
  }, [type]);

  useEffect(() => {
    getSingleConditionalLogic(formUniqueId, formEntryType, formType)
  }, [studyId, formUniqueId, connectUniqueId, formEntryType, formType]);

  return (
    <div
      className={`${isFullScreen ? "full-screen" : ""}`}
      style={{ height: "100%" }}
    >
      <Toolbar
        className="mb-1"
        activeTab={activeTab}
        componentRef={componentRef}
        setActiveTab={setActiveTab}
        isFullScreen={isFullScreen}
        selectedCell={selectedCell}
        onToggleHeader={toggleHeader}
        onToggleFooter={toggleFooter}
        setOrientation={setOrientation}
        onSizeChange={handleSizeChange}
        onImageInsert={handleImageInsert}
        tableProperties={tableProperties}
        onEmojiInsert={handleEmojiInsert}
        onShapeInsert={handleShapeInsert}
        onInsertTable={handleInsertTable}
        toggleFullScreen={toggleFullScreen}
        onColumnChange={handleColumnChange}
        handleCellChange={handleCellChange}
        onConvertToPDF={handleConvertToPDF}
        handleImageUpload={handleImageUpload}
        handleMarginChange={handleMarginChange}
        updateTableProperties={updateTableProperties}
        addRow={() => handleAddRow(selectedTableIndex)}
        addColumn={() => handleAddColumn(selectedTableIndex)}
        deleteRow={() => handleDeleteRow(selectedTableIndex)}
        deleteColumn={() => handleDeleteColumn(selectedTableIndex)}
        loadFormData={setType}
      />
      <EditorWritingPage
        margin={margin}
        tables={tables}
        size={pageSize}
        columns={columns}
        imageSrc={imageSrc}
        formName={formName}
        formType={formType}
        shapeSize={shapeSize}
        setMargin={setMargin}
        setTables={setTables}
        showHeader={showHeader}
        showFooter={showFooter}
        renderShape={renderShape}
        orientation={orientation}
        setShapeSize={setShapeSize}
        setActiveTab={setActiveTab}
        isFullScreen={isFullScreen}
        formArray={formArray}
        componentRef={componentRef}
        tableToInsert={tableToInsert}
        shapeToInsert={shapeToInsert}
        emojiToInsert={emojiToInsert}
        imageToInsert={imageToInsert}
        tableProperties={tableProperties}
        siteName={siteName}
        subjectName={subjectName}
        visitName={visitName}
        setSelectedCell={setSelectedCell}
        setSelectedTableIndex={setSelectedTableIndex}
        getCellAlignmentStyles={getCellAlignmentStyles}
        handleLinePositionChange={handleLinePositionChange}
      />
    </div>
  );
};
export default ADRGWordEditorData;
