import React, { useEffect, useState } from "react";
import AddValueLabel from "./AddValueLabel";
import SplitPane from "react-split-pane";
import { Spinner } from "react-bootstrap";
import ValueLabelTableData from "./ValueLabelTableData";
import ValueLabelTableProperties from "./ValueLabelTableProperties";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import UseToggle from "../../../../../Hooks/UseToggle";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../Services/studyServices/studyOnboarding";

const ValueLavel = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [addNewValueLabeldata, setaddNewValueLabeldata] = useState([]);
  const [valueLabelData, setValueLabelData] = useState("");
  const [versionId, setVersionId] = useState("");

  const { studyId } = useParams();

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getSingleStudies = async () => {
    let data = await studyOnboardingService.getSingleStudies(studyId);
    setVersionId(data?.data?.[0]?.version || "");
  };

  const getClientValueLevelData = async () => {
    setLoader(true);
    let data = await cdiscServices.getValueLevelData(studyId);
    setaddNewValueLabeldata(data?.data);
    setLoader(false);
  };

  useEffect(() => {
    getClientValueLevelData();
  }, []);

  useEffect(() => {
    getSingleStudies(studyId);
  }, [studyId]);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          {isFormOpen ? (
            <i
              title="Close Form"
              className="fa-solid fa-square-caret-left CP"
              onClick={() => setIsFormOpen("")}
            />
          ) : (
            <i
              title="Add ValueLavel"
              className="fa-solid fa-plus CP"
              onClick={() => setIsFormOpen(!isFormOpen)}
            />
          )}
          <div className="vr"></div>
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
        </div>
        {isFullScreen ? (
          <i
            class="fa-solid fa-maximize"
            title="Original Mode"
            onClick={toggleFullScreen}
          ></i>
        ) : (
          <i
            class="fa-solid fa-minimize"
            title="Full Mode"
            onClick={toggleFullScreen}
          ></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 185px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <AddValueLabel
                studyId={studyId}
                valueLabelData={valueLabelData}
                setValueLabelData={setValueLabelData}
                getClientValueLevelData={getClientValueLevelData}
                versionId={versionId}
              />
            )}
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100">
                  <Spinner />
                </div>
              ) : addNewValueLabeldata?.length === 0 ? (
                <div className="hstack justify-content-center h-100">
                  Data not found!
                </div>
              ) : (
                <ValueLabelTableData
                studyId={studyId}
                setIsFormOpen={setIsFormOpen}
                  addNewValueLabeldata={addNewValueLabeldata}
                  setValueLabelData={setValueLabelData}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  getClientValueLevelData={getClientValueLevelData}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <ValueLabelTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      {/* <ValueLabelFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Value Level"}
        studyId={studyId}
        valueLaveldata={addNewValueLabeldata}
        setValueLaveldata={setaddNewValueLabeldata}
        getValueLevelData={getClientValueLevelData}
        filter={filter}
        setFilter={setFilter}
      /> */}
    </div>
  );
};

export default ValueLavel;
