import React, { useState } from 'react'
import { IoIosArrowDropleft } from 'react-icons/io';
import { TiPin } from 'react-icons/ti';
import RightClick from '../../../LeftComponent/RightClick';
import { ContextMenuTrigger } from 'react-contextmenu';

const EnevelopNewDesign = () => {
    const [openEnvelopeIndex, setOpenEnvelopeIndex] = useState(null);
    const envelopes = new Array(10).fill(0);

    const toggleEnvelope = (index) => {
        if (openEnvelopeIndex === index) {
            setOpenEnvelopeIndex(null);
        } else {
            setOpenEnvelopeIndex(index);
        }
    };

    return (
        <div>
            <div className="d-flex gap-2 flex-wrap justify-content-center p-3">
                {envelopes?.map((_, index) => (
                    <div key={index}>
                        <ContextMenuTrigger id={`contextmenu_${index}`}>
                            <div
                                className={`envelope ${openEnvelopeIndex === index ? 'open' : ''}`}
                                onClick={() => toggleEnvelope(index)}
                            >
                                <div className="flap"></div>
                                <div className={`card_enevelop ${openEnvelopeIndex === index ? 'show' : ''}`}>
                                    <p>1234567</p>
                                </div>
                            </div>
                        </ContextMenuTrigger>
                        <RightClick
                            ContextId={`contextmenu_${index}`}
                            Menu1
                            MenuName1={"Pin"}
                            icons1={<TiPin className="text-white" />}
                            className1="text-white"
                            Menu2
                            MenuName2={"Move Left"}
                            icons2={<IoIosArrowDropleft className="text-white" />}
                            className2="text-white"
                            Menu3
                            MenuName3={"Move Left"}
                            icons3={<IoIosArrowDropleft className="text-white" />}
                            className3="text-white"
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EnevelopNewDesign