import React, { useEffect, useState } from "react";
import { Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { studySettingControlServices } from "../../../../Services/studyServices/studySettingControlServices";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";

const GenerateQuery = ({ SHOW, HIDE, studyId, templateId, getStudyDataTemplates }) => {
  
  const [subject, setSubject] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [message, setMessage] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loader, setLoader] = useState("");

  const getActiveQuerySubjectListByCategory = async() => {
    let data = await studySettingControlServices.getActiveQuerySubjectListByCategory("cdisc")
    setSubjectList(data?.data || [])
  }

  const getActiveQueryMessageList = async(subjectId) => {
    setSubjectId(subjectId)
    let data = await studySettingControlServices.getActiveQueryMessageList(subjectId)
    setMessageList(data?.data || [])
  }

  const generateCDISCDataEntryQuery = async() => {
    setLoader(true)
    let userData = {}
    userData.studyId = studyId
    userData.templateId = templateId
    userData.subject = subject
    userData.query = message
    userData.subjectId = subjectId
    let data = await cdiscServices.generateCDISCDataEntryQuery(userData)
    if(data?.statusCode === 200){
      closeGenerateQueryModal();
      getStudyDataTemplates()
    }
    setLoader(false)
  }

  const closeGenerateQueryModal = () => {
    setSubjectId("")
    setSubject("")
    setMessage("")
    setSubjectType("")
    setMessageType("")
    HIDE()
    setLoader(false)
  }

  useEffect(() => {
    getActiveQuerySubjectListByCategory();
  }, []);

  return (
    <>
      <Offcanvas
        show={SHOW}
        className={SHOW ? "" : "d-none"}
        onHide={HIDE}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Generate Query</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Row className="mb-2">
              <Form.Group as={Col} md={12} className="mb-2">
                <Form.Label className="mb-1">
                  <b>Query Subject</b> <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                   
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setSubjectType(e.target[e.target.selectedIndex].id);
                    getActiveQueryMessageList(
                      e.target[e.target.selectedIndex].title
                    );
                  }}
                >
                  <option value="">Select Subject</option>
                  <option id="customSubject" value="">
                    Custom Subject
                  </option>
                  {subjectList?.map((item, index) => (
                    <option
                      id="dynamicSubject"
                      key={index}
                      value={item?.subject}
                      title={item?._id}
                    >
                      {item?.subject}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {subjectType === "dynamicSubject" ? (
                <Form.Group as={Col} md={12} className="mb-2">
                  <Form.Label className="mb-1">
                    <b>Query Message</b> <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                     
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageType(e.target[e.target.selectedIndex].id);
                    }}
                  >
                    <option value="">Select Message</option>
                    <option id="customMessage" value="">
                      Custom Message
                    </option>
                    {messageList?.map((item, index) => (
                      <option
                        id="dynamicMessage"
                        key={index}
                        value={item?.message}
                      >
                        {item?.message}
                      </option>
                    ))}
                  </Form.Select>
                  {messageType === "customMessage" && (
                    <Form.Group as={Col} md={12} className="mt-2">
                      <Form.Label className="mb-1">
                        <b>Custom Query Message</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                         
                        placeholder="Enter Custom Query Message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                    </Form.Group>
                  )}
                </Form.Group>
              ) : (
                subjectType === "customSubject" && (
                  <>
                    <Form.Group as={Col} md={12} className="mb-2">
                      <Form.Label className="mb-1">
                        <b>Custom Query Subject</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                         
                        placeholder="Enter Custom Query Subject"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className="mb-2">
                      <Form.Label className="mb-1">
                        <b>Custom Query Message</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                         
                        placeholder="Enter Custom Query Message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                    </Form.Group>
                  </>
                )
              )}
            </Row>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={() => closeGenerateQueryModal()}
              className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
            >
              Cancel
            </button>
            <button
              disabled={!subject || !message || loader}
              onClick={() => generateCDISCDataEntryQuery()}
              className="border   p-1 px-2 updateProfile"
            >
              {loader && (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              )}
              Generate
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default GenerateQuery;
