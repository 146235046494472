import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FaSquarePlus } from "react-icons/fa6";
import SplitPane from "react-split-pane";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import Swal from "sweetalert2";
import Moment from "react-moment";

const FunctionTools = ({
  studyId,
  derivationId
}) => {
  let [uniqueColumnKey, setUniqueColumnKey] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [arrayData, setArrayData] = useState([{ id: 1, column: "" }]);
  const [templateIds, setTemplateIds] = useState([]);
  const [columns, setColumns] = useState([]);
  const [primaryDataType, setPrimaryDataType] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [functionList, setFunctionList] = useState([]);
  const [concatArrayList, setConcatArrayList] = useState([]);

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    if (derivationData?.primaryDataset || derivationData?.secondaryDataType || derivationData?.cellDataset) {
      setTemplateIds([
        { templateId: derivationData?.primaryDataset || "" },
        { templateId: derivationData?.secondaryDataset || "" },
        { templateId: derivationData?.cellDataset || "" },
      ])
    }
    setPrimaryDataType(derivationData?.primaryDataType);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(derivationData?.primaryDataset, derivationData?.primaryDataType)
    }
  }

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const handleArrayDataAddSection = () => {
    setArrayData([...arrayData, { id: arrayData.length + 1, column: "" }]);
  };

  const handleArrayDataSelectChange = (id, field, value) => {
    const updatedSections = arrayData.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setArrayData(updatedSections);
  };

  const handleArrayDataRemoveSection = (id) => {
    const updatedSections = arrayData.filter((section) => section.id !== id);
    setArrayData(updatedSections);
  };

  const handleConcatArrayDataAddSection = () => {
    setConcatArrayList([...concatArrayList, { id: concatArrayList.length + 1, column: "", seperator: "" }]);
  };

  const handleConcatArrayDataSelectChange = (id, field, value) => {
    const updatedSections = concatArrayList.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setConcatArrayList(updatedSections);
  };

  const handleConcatArrayDataRemoveSection = (id) => {
    const updatedSections = concatArrayList.filter((section) => section.id !== id);
    setConcatArrayList(updatedSections);
  };

  const getMultiDataEntryColumList = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateIds = templateIds || [];
    let data = await cdiscServices.getMultiDataEntryColumList(userData);
    setUniqueColumnKey(data?.uniqueColumnKey || []);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  useEffect(() => {
    if (templateIds?.length > 0) {
      getMultiDataEntryColumList();
    }
  }, [templateIds?.length]);

  const getFunctionList = async () => {
    let data = await cdiscServices.getFunctionList(studyId, derivationId, activeTab)
    setFunctionList(data?.data)
  }

  const handleUpdateFunctionData = (item) => {
    setId(item?._id)
    setName(item?.name)
    setArrayData(item?.columns)
    setConcatArrayList(item?.columns)
  }

  const addNewFunction = async () => {
    let userData = {}
    userData.derivationId = derivationId
    userData.name = name
    userData.columns = activeTab === "concatenate" ? concatArrayList : arrayData
    userData.studyId = studyId
    userData.type = activeTab
    let data = await cdiscServices.addNewFunction(userData)
    if (data?.statusCode === 200) {
      getFunctionList()
      setName("")
      setArrayData([{ id: 1, column: "" }])
    }
  }

  const updateFunction = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update this function!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = id
        userData.name = name
        userData.columns = activeTab === "concatenate" ? concatArrayList : arrayData
        userData.studyId = studyId
        let data = await cdiscServices.updateFunction(userData)
        if (data?.statusCode === 200) {
          getFunctionList()
          setId("")
          setName("")
          setArrayData([{ id: 1, column: "" }])
        }
      }
    });
  };

  const deleteFunction = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this function!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await cdiscServices.deleteFunction(studyId, id)
        if (data?.statusCode === 200) {
          getFunctionList()
        }
      }
    });
  };

  const handleActiveFunctionTab = (type) => {
    setActiveTab(type)
    setId("")
    setName("")
    setArrayData([])
    setConcatArrayList([])
  }

  useEffect(() => {
    getFunctionList()
  }, [derivationId, activeTab]);
  return (
    <>
      <SplitPane
        split="vertical"
        className="position-relative"
        defaultSize="15%"
      >
        <div>
          <span onClick={() => handleActiveFunctionTab("min")} className={`CP ps-3 small linkClass ${activeTab === "min" && "selectedMenu"}`}>Min Function</span>
          <span onClick={() => handleActiveFunctionTab("max")} className={`CP ps-3 small linkClass ${activeTab === "max" && "selectedMenu"}`}>Max Function</span>
          <span onClick={() => handleActiveFunctionTab("sum")} className={`CP ps-3 small linkClass ${activeTab === "sum" && "selectedMenu"}`}>Sum Function</span>
          <span onClick={() => handleActiveFunctionTab("multiply")} className={`CP ps-3 small linkClass ${activeTab === "multiply" && "selectedMenu"}`}>Multiply Function</span>
          <span onClick={() => handleActiveFunctionTab("concatenate")} className={`CP ps-3 small linkClass ${activeTab === "concatenate" && "selectedMenu"}`}>Concatenate Function</span>
          <span onClick={() => handleActiveFunctionTab("dayPart")} className={`CP ps-3 small linkClass ${activeTab === "dayPart" && "selectedMenu"}`}>Day Part Function</span>
          <span onClick={() => handleActiveFunctionTab("monthPart")} className={`CP ps-3 small linkClass ${activeTab === "monthPart" && "selectedMenu"}`}>Month Part Function</span>
          <span onClick={() => handleActiveFunctionTab("yearPart")} className={`CP ps-3 small linkClass ${activeTab === "yearPart" && "selectedMenu"}`}>Year Part Function</span>
          <span onClick={() => handleActiveFunctionTab("hourPart")} className={`CP ps-3 small linkClass ${activeTab === "hourPart" && "selectedMenu"}`}>Hour Part Function</span>
          <span onClick={() => handleActiveFunctionTab("minutePart")} className={`CP ps-3 small linkClass ${activeTab === "minutePart" && "selectedMenu"}`}>Minute Part Function</span>
          <span onClick={() => handleActiveFunctionTab("secondPart")} className={`CP ps-3 small linkClass ${activeTab === "secondPart" && "selectedMenu"}`}>Second Part Function</span>
        </div>
        {activeTab &&
          <SplitPane
            split="vertical"
            className="position-relative"
            defaultSize="70%"
          >
            <div>
              <Table
                id="resizable-table"
                className="text-nowrap custom-table"
                hover
                striped
              >
                <thead className="position-sticky z-1" style={{ top: -1 }}>
                  <tr>
                    <th style={{ width: 40 }}>Sr.</th>
                    <th>Function Name</th>
                    <th>Columns</th>
                    <th>Modified Date | Time</th>
                    <th style={{ width: 70, textAlign: "center" }}>Edit</th>
                    <th style={{ width: 70, textAlign: "center" }} >Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {functionList?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-capitalize">1</td>
                      <td>{item.name}</td>
                      {activeTab === "concatenate" ?
                        <td>
                          {item?.columns?.map((col, index) =>
                            col.column + ((index + 1) === item.columns.length ? "" : col.seperator)
                          ).join('')
                          }
                        </td>
                        :
                        <td>{item?.columns?.map(item => item.column).join(", ")}</td>
                      }
                      <td><Moment format="DD MMM YYYY | HH:mm:ss">
                        {item?.updatedAt}
                      </Moment></td>
                      <td className="text-center">
                        <button
                          onClick={(e) => handleUpdateFunctionData(item)}
                          className="p-0 px-2 text-white border-primary bg-primary border-0"
                          style={{ fontSize: 10, borderRadius: 3 }}
                          title="View Derivation"
                        >
                          Edit
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={(e) => deleteFunction(item?._id)}
                          className="p-0 px-2 text-white border-danger bg-danger border-0"
                          style={{ fontSize: 10, borderRadius: 3 }}
                          title="Delete Supplementary"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {derivationId &&
              <div className="p-2">
                <Form.Group className="mb-2">
                  <Form.Label className="fw-bold mb-1 text-capitalize">Function Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Enter Function Name" />
                </Form.Group>
                <Form.Group className="mb-2">
                  <div className="hstack gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      Add Column <span className="text-danger">*</span>
                    </Form.Label>
                    {(activeTab === "dayPart" || activeTab === "monthPart" || activeTab === "yearPart" || activeTab === "hourPart" || activeTab === "minutePart" || activeTab === "secondPart") && arrayData?.length < 1 ?
                      <FaSquarePlus
                        className="cursor-pointer"
                        style={{ fontSize: 12 }}
                        title="Add More"
                        onClick={handleArrayDataAddSection}
                      />
                      : (activeTab === "min" || activeTab === "max" || activeTab === "sum" || activeTab === "multiply") ?
                        <FaSquarePlus
                          className="cursor-pointer"
                          style={{ fontSize: 12 }}
                          title="Add More"
                          onClick={handleArrayDataAddSection}
                        />
                        : activeTab === "concatenate" ?
                          <FaSquarePlus
                            className="cursor-pointer"
                            style={{ fontSize: 12 }}
                            title="Add More"
                            onClick={handleConcatArrayDataAddSection}
                          />
                          : ""
                    }
                  </div>
                </Form.Group>
                {activeTab !== "concatenate" ?
                  arrayData?.map((section, index) => (
                    <div key={index} className="hstack gap-1 w-100 mb-2">
                      <Form.Group className="w-100">
                        <Form.Select
                          className="w-100"
                          onChange={(e) =>
                            handleArrayDataSelectChange(
                              section.id,
                              "column",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Column</option>
                          {((primaryDataType === "Merge" || primaryDataType === "Append") ? uniqueColumnKey : columns)?.map((data, key) => (
                            <option
                              key={key}
                              selected={section?.column === data?.name}
                              value={data?.name}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        {arrayData.length > 1 && (
                          <FaTrashAlt
                            className="text-danger fontSize12 cursor-pointer"
                            title="Remove Section"
                            onClick={() => handleArrayDataRemoveSection(section.id)}
                          />
                        )}
                      </Form.Group>
                    </div>
                  ))
                  :
                  concatArrayList?.map((section, index) => (
                    <div key={index} className="hstack gap-1 w-100 mb-2">
                      <Form.Group className="w-100 hstack gap-1">
                        <Form.Select
                          className=""
                          onChange={(e) =>
                            handleConcatArrayDataSelectChange(
                              section.id,
                              "column",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Column</option>
                          {((primaryDataType === "Merge" || primaryDataType === "Append") ? uniqueColumnKey : columns)?.map((data, key) => (
                            <option
                              key={key}
                              selected={section?.column === data?.name}
                              value={data?.name}
                            >
                              {data?.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            handleConcatArrayDataSelectChange(
                              section.id,
                              "seperator",
                              e.target.value
                            )
                          }
                          value={section?.seperator}
                          placeholder="Enter Seperator" />
                      </Form.Group>
                      {activeTab === "concatenate" ?
                        <Form.Group>
                          {concatArrayList.length > 1 && (
                            <FaTrashAlt
                              className="text-danger fontSize12 cursor-pointer"
                              title="Remove Section"
                              onClick={() => handleConcatArrayDataRemoveSection(section.id)}
                            />
                          )}
                        </Form.Group>
                        :
                        <Form.Group>
                          {arrayData.length > 1 && (
                            <FaTrashAlt
                              className="text-danger fontSize12 cursor-pointer"
                              title="Remove Section"
                              onClick={() => handleArrayDataRemoveSection(section.id)}
                            />
                          )}
                        </Form.Group>
                      }
                    </div>
                  ))}
                <div className="text-end">
                  <button
                    disabled={!name}
                    onClick={() => { id ? updateFunction() : addNewFunction() }}
                    className="updateProfile">{id ? "Update" : "Add"}</button>
                </div>
              </div>
            }
          </SplitPane>
        }
      </SplitPane>
    </>
  );
};

export default FunctionTools;
