import React, { useEffect, useRef, useState } from "react";
import { ImProfile } from "react-icons/im";
import UpdateProfile from "./UpdateProfile";
import { FaShieldAlt } from "react-icons/fa";
import UseToggle from "../../Hooks/UseToggle";
import ChangePassword from "./ChangePassword";
import { RiLockPasswordFill } from "react-icons/ri";
import TwoFactorAuth from "./TwoFactorAuth/TwoFactorAuth";
import { userServices } from "../../Services/userServices";

const ProfileActions = ({ firstName, lastName }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  const [authTypeData, setAuthTypeData] = useState({});
  const AppsRef = useRef(null);
  const toggleApps = () => setAppsActive(!AppsActive);
  const [AppsActive, setAppsActive] = useState("");
  const getTwoFactorAuthType = async () => {
    const data = await userServices.getTwoFactorAuthType();
    setAuthTypeData(data);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    getTwoFactorAuthType();
  }, []);

  return (
    <>
      <div
        ref={AppsRef}
        className={`Apps position-relative ${AppsActive ? "active" : ""}`}
      >
        <div className="icon_wrap hstack gap-1" onClick={toggleApps}>
          <div
            className={`title_name hstack gap-1 px-2 py-1 ${
              AppsActive ? "AppsNameActive" : ""
            }`}
            style={{ cursor: "pointer"}}
          >
            <div className="hstack gap-1">
              <i className="fa-solid fa-user-tie me-1 text-info"></i>
              <p className="m-0" style={{fontSize: 12}}>{`${firstName} ${lastName}`}</p>
              <i className={`fa-solid fa-angle-${AppsActive ? "up" : "down"}`}></i>
            </div>
          </div>
        </div>
        <div className="Apps_dd shadow">
          <ul className="Apps_ul">
            <li>
              <a
                onClick={setToggle}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <ImProfile className="me-2" style={{width: 15, height: 15}}/>Profile
              </a>
            </li>
            <li>
              <a
                onClick={setToggle1}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <RiLockPasswordFill className="me-2" style={{width: 15, height: 15}}/>Change Password
              </a>
            </li>
            <li>
              {authTypeData?.authType && (
                <a
                  onClick={setToggle2}
                  className="dropdown-item"
                  style={{ fontSize: 12, cursor: "pointer" }}
                >
                  <FaShieldAlt className="me-2" style={{width: 15, height: 15, marginLeft: .5}} />Two Factor Auth
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
      <UpdateProfile SHOW={!toggle} HIDE={setToggle} TITLE="User Profile" />
      <ChangePassword
        SHOW={!toggle1}
        HIDE={setToggle1}
        TITLE="Change Password"
      />
      <TwoFactorAuth
        SHOW={!toggle2}
        HIDE={setToggle2}
        TITLE="Two Factor Auth"
        getTwoFactorAuthType={getTwoFactorAuthType}
        {...authTypeData}
      />
    </>
  );
};

export default ProfileActions;
