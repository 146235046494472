import React from 'react'

const AdrgData = () => {
  return (
    <>
      <div className='text-center'>
        <div style={{fontSize: 30}} className='mb-1'>Analysis Data Reviewer’s Guide</div>
        <div style={{fontSize: 24}} className='mb-1' ><b>Protocol Number: 006-24 </b></div>
        <div style={{fontSize: 24}}><b>Rubicon Research Private Limited., India. </b></div>
      </div>
    </>
  )
}

export default AdrgData

