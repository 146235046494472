import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const globalFormServices = {
  createGlobalFormFolders,
  updateGlobalFormFolderName,
  hideUnhideGlobalFormFolder,
  getGlobalFormFolderName,
  getVisibleGlobalFormFolderName,
  getGlobalFormFolderDataById,
  getListData,
  getGlobalFormReviewData,
  getGlobalLevelFormData,
  getGLobalFormForReviewer,
  getGLobalFormForClients,
  reviewGlobalForm,
  pushGlobalForm,
  dataEntryVisitsForm,
  addNewGlobalForm,
  pushClientLevelForm,
  pushProjectLevelForm,
  updateGlobalLevelForms,
  getGlobalLevelFormsHistoryByID,
  deleteGlobalLevelFormsData,
  getGlobalLevelFormAttributeData,
  exportGlobalLevelFormData,
  exportGlobalFormAuditTrialData,
  getGlobalFormValidationData,
  showHideFormForAnyone,
  sendForReviewGlobalForm,
  dataEntryInitiateVisits,
  dataEntrySpecialForm
};

//Create Global Form Folder
async function createGlobalFormFolders(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createGlobalFormFolders`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//Create Global Form Folder
async function updateGlobalFormFolderName(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateGlobalFormFolderName`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//Send For Review Global Form
async function sendForReviewGlobalForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendForReviewGlobalForm`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//Hide and Unhide Form Folder
async function hideUnhideGlobalFormFolder(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}hideUnhideGlobalFormFolder`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Global Form Folder
async function getGlobalFormFolderName() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalFormFolderName`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Visible Global Form Folder
async function getVisibleGlobalFormFolderName() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getVisibleGlobalFormFolderName`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Global Form Folder Data By Id
async function getGlobalFormFolderDataById(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalFormFolderDataById/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// async function getGlobalLevelFormData(page, limit) {
async function getListData( endPoint ) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}${endPoint}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getGlobalFormValidationData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalFormValidationData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getGlobalFormReviewData( id ) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalFormReviewData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getGlobalLevelFormData(id, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalLevelFormData/${id}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getGLobalFormForReviewer(id, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGLobalFormForReviewer/${id}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getGLobalFormForClients(id, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGLobalFormForClients/${id}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function reviewGlobalForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}reviewGlobalForm`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function showHideFormForAnyone(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}showHideFormForAnyone`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function pushGlobalForm(datas, endPoint) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}${endPoint}`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function dataEntryVisitsForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}dataentry`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function dataEntrySpecialForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}dataEntrySpecialForm`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function dataEntryInitiateVisits(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}dataEntryInitiateVisits`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function addNewGlobalForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createGlobalFormMeta`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function pushClientLevelForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}pushClientLevelForm`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function pushProjectLevelForm(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}pushProjectLevelForm`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateGlobalLevelForms(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateGlobalLevelFormsMeta`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function getGlobalLevelFormsHistoryByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalLevelFormsHistoryByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function deleteGlobalLevelFormsData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteGlobalLevelFormsData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function exportGlobalLevelFormData(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportGlobalLevelFormData/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportGlobalFormAuditTrialData(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportGlobalFormAuditTrialData/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function getGlobalLevelFormAttributeData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGlobalLevelFormAttributeData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
