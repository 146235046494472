import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import RandomizationTableData from "./RandomizationTableData";
import FactorTableData from "./FactorTableData";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../Services/studyServices/studySubjectsServices";

const NewTrialfactorsDesign = ({ onMouseDown, tableRef }) => {
  const [factorData, setFactorData] = useState([])
  const [options, setOptions] = useState([])
  const { studyId } = useParams()
  const getStudySubjectColumnForFactor = async () => {
    let data = await studyOnboardingService.getStudySubjectColumnForFactor(studyId)
    setFactorData(data?.data || [])
  }

  useEffect(() => {
    getStudySubjectColumnForFactor();
  }, [studyId]);
  return (
    <>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{ height: "calc(100vh - 155px)" }}
          className="position-relative"
          defaultSize={"100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"70%"}>
            <RandomizationTableData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              studyId={studyId}
              factorData={factorData}
              setOptions={setOptions}
            />
            {options?.length > 0 &&
              <FactorTableData
                onMouseDown={onMouseDown}
                tableRef={tableRef}
                options={options}
              />
            }
          </SplitPane>
        </SplitPane>
      </div>
    </>
  );
};

export default NewTrialfactorsDesign;
