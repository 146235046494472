import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const StudyEventTableProperty = ({
  Show,
  Hide,
  Title,
  selectedNames,
  setSelectedNames,
  studyDynamicColumn,
  handleSubmitTableProperty,
  allArray,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  let totalColumnLength = allArray?.length + studyDynamicColumn?.length;
  const handleSelectAll = () => {
    if (!selectAll) {
      const allNames = studyDynamicColumn?.map(({ name }) => name);
      setSelectedNames([...allNames, ...allArray]);
    } else {
      setSelectedNames([]);
    }
    setSelectAll(!selectAll);
  };
  const handleCheckboxChange = (name) => {
    let updatedNames;
    if (selectedNames.includes(name)) {
      updatedNames = selectedNames.filter(
        (selectedName) => selectedName !== name
      );
    } else {
      updatedNames = [...selectedNames, name];
    }
    setSelectedNames(updatedNames);
    if (totalColumnLength === updatedNames?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Check
            type="checkbox"
            checked={selectAll || totalColumnLength === selectedNames?.length}
            onChange={handleSelectAll}
            label="Select All"
          />
          <Form.Check
            type="checkbox"
            name="Visit Id"
            checked={selectedNames?.includes("Visit Id")}
            onChange={() => handleCheckboxChange("Visit Id")}
            id="edit_label"
            label="Visit Id"
          />
          <Form.Check
            type="checkbox"
            name="Repeat"
            checked={selectedNames?.includes("Repeat")}
            onChange={() => handleCheckboxChange("Repeat")}
            id="edit_label"
            label="Repeat"
          />
          <Form.Check
            type="checkbox"
            name="Time Point Id"
            checked={selectedNames?.includes("Time Point Id")}
            onChange={() => handleCheckboxChange("Time Point Id")}
            id="edit_label"
            label="Time Point Id"
          />
          <Form.Check
            type="checkbox"
            name="Initiate"
            checked={selectedNames?.includes("Initiate")}
            onChange={() => handleCheckboxChange("Initiate")}
            id="edit_label"
            label="Initiate"
          />
          {studyDynamicColumn
            ? studyDynamicColumn.map(({ name }, i) => (
                <Form.Check
                  type="checkbox"
                  name={name}
                  checked={selectedNames?.includes(name)}
                  onChange={() => handleCheckboxChange(name)}
                  label={name}
                />
              ))
            : ""}
            <div className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                name="Query Status"
                checked={selectedNames?.includes("Query Status")}
                onChange={() => handleCheckboxChange("Query Status")}
                id="edit_label"
                label="Query Status"
              />
            </div>
            <div className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                name="Query Date | Time"
                checked={selectedNames?.includes("Query Date | Time")}
                onChange={() => handleCheckboxChange("Query Date | Time")}
                id="edit_label"
                label="Query Date | Time"
              />
            </div>
          <div className="border-top text-end my-2 pt-2">
            <button
              onClick={() => handleSubmitTableProperty()}
              className="submitbutton save__all__data_action"
            >
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default StudyEventTableProperty;
