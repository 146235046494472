import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studySubjectsServices";

const UpdateSubjectStatus = ({ Show, Hide, getStudySubjectRowData, studyId, mode, id, status, setStatus }) => {
  const [date, setDate] = useState("");
  const [reason, setReason] = useState("");

  const UpdateStudySubjectRowStatus = async () => {
    let userData = {}
    userData.status = status
    userData.updatedDate = date
    userData.reason = reason
    userData.studyID = studyId
    userData.mode = mode
    let data = await studyOnboardingService.UpdateStudySubjectRowStatus(id, userData)
    if (data?.statusCode === 200) {
      await getStudySubjectRowData()
      Hide()
      setDate("")
      setReason("")
      setStatus("")
    }
  }
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Update Subject Status</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 88px)" }}
          >
            <div className="pt-2 px-3">
              <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">Status</Form.Label>
                <Form.Select onChange={(e) => setStatus(e.target.value)}>
                  <option value="">
                    Select Status
                  </option>
                  <option selected={status === "ongoing"} value="ongoing">
                    Ongoing
                  </option>
                  <option
                    selected={status === "completed"}
                    value="completed"
                  >
                    Completed
                  </option>
                  <option
                    selected={status === "discontinuation"}
                    value="discontinuation"
                  >
                    Discontinuation
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">Date</Form.Label>
                <Form.Control
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="fw-bold mb-1">Reason</Form.Label>
                <Form.Control
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  as="textarea"
                  rows={8}
                />
              </Form.Group>
            </div>
          </div>
          <div className="border-top text-end p-2">
            <button
              disabled={!status || !reason || !date}
              onClick={UpdateStudySubjectRowStatus}
              className="updateProfile">Update</button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UpdateSubjectStatus;
