import { createContext, useContext, useState } from "react";
import { projectLevelFormServices } from "../../Services/formServices/projectLevelFormServices";

export const ProjectLevelFormContext = createContext();

export const ProjectLevelFormContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [studyDynamicForm, setStudyDynamicForm] = useState([]);

  const [count, setCount] = useState("");

  const limit = 100;

  const getProjectLevelFormData = async (project_id) => {
    let data = await projectLevelFormServices.getProjectLevelFormData(
      project_id,
      currentPage,
      limit
    );

    let finalData = data?.data?.sort().reverse();
    setStudyDynamicForm(finalData);
    setCount(Math.ceil(data?.count / limit));
    // setLoader(false);
  };

  const contextValue = {
    currentPage,
    setCurrentPage,
    getProjectLevelFormData,
    studyDynamicForm,
    setStudyDynamicForm,
    count,
    setCount,
  };

  return (
    <ProjectLevelFormContext.Provider value={contextValue}>
      {children}
    </ProjectLevelFormContext.Provider>
  );
};

export const UseProjectLevelFormContext = () =>
  useContext(ProjectLevelFormContext);
