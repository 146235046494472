import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FaSquarePlus } from "react-icons/fa6";
import { cdiscServices } from "../../../../../../../Services/CDISC/cdiscServices";
import SplitPane from "react-split-pane";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const SortTransposeConfigurationToolsTab = ({
  studyId,
  crfDatasets,
  derivationId,
  type,
  getDomainDerivationList,
  derivedData,
}) => {
  let [primaryDataType, setPrimaryDataType] = useState("");
  let [primaryDataset, setPrimaryDataset] = useState("");
  let [prefix, setPrefix] = useState("");
  let [suffix, setSuffix] = useState("");
  let [label, setLabel] = useState("");
  let [columns, setColumns] = useState([]);
  let [templateIds, setTemplateIds] = useState([]);
  let [statements, setStatements] = useState([]);
  let [retain, setRetain] = useState(false);
  let [retainColumns, setRetainColumns] = useState([]);
  let [retainCustoms, setRetainCustoms] = useState("");
  let [keep, setKeep] = useState(false);
  let [keepColumns, setKeepColumns] = useState([]);
  let [keepCustoms, setKeepCustoms] = useState("");
  let [drop, setDrop] = useState(false);
  let [dropColumns, setDropColumns] = useState([]);
  let [dropCustoms, setDropCustoms] = useState("");
  let [statementString, setStatementString] = useState("");

  const findDomainDerivationData = async () => {
    let result = await cdiscServices.findDomainDerivationData(
      studyId,
      derivationId
    );
    let derivationData = result?.data;
    setPrimaryDataType(derivationData?.primaryDataType);
    setPrimaryDataset(derivationData?.primaryDataset);
    if (type === "Sort") {
      setFormSections(derivationData?.sortData);
    } else {
      setIdVar(derivationData?.idVar);
      setByVar(derivationData?.byVar);
      setVars(derivationData?.vars);
      setPrefix(derivationData?.prefix);
      setSuffix(derivationData?.suffix);
    }
    setLabel(derivationData?.label);
    if (derivationData?.primaryDataset || derivationData?.primaryDataType) {
      getDatasetColumList(
        derivationData?.primaryDataset,
        derivationData?.primaryDataType
      );
    }
    if (
      derivationData?.keepColumns?.length > 0 ||
      derivationData?.keepCustoms
    ) {
      setKeepColumns(derivationData?.keepColumns || []);
      setKeepCustoms(derivationData?.keepCustoms || "");
      setKeep(true);
    } else {
      setKeepColumns([]);
      setKeepCustoms("");
      setKeep(false);
    }
    if (
      derivationData?.dropColumns?.length > 0 ||
      derivationData?.dropCustoms
    ) {
      setDropColumns(derivationData?.dropColumns || []);
      setDropCustoms(derivationData?.dropCustoms || "");
      setDrop(true);
    } else {
      setDropColumns([]);
      setDropCustoms("");
      setDrop(false);
    }
    if (derivationData?.retainColumns) {
      setRetain(true);
      setRetainColumns(
        derivationData?.retainColumns?.split("||")?.map((key) => ({
          label: key.trim(),
          value: key.trim(),
        }))
      );
    }
    if (derivationData?.retainCustoms) {
      setRetainCustoms(derivationData?.retainCustoms);
      setRetain(true);
    }
    setStatements(derivationData?.statements);
  }

  useEffect(() => {
    findDomainDerivationData();
  }, [derivationId]);

  const [formSections, setFormSections] = useState([
    { id: 1, column: "", sortType: "" },
  ]);
  const [idVar, setIdVar] = useState([{ id: 1, column: "" }]);
  const [byVar, setByVar] = useState([{ id: 1, column: "" }]);
  const [vars, setVars] = useState([{ id: 1, column: "" }]);

  const handleAddSection = () => {
    if (type === "Sort") {
      setFormSections([
        ...formSections,
        { id: formSections.length + 1, column: "", sortType: "" },
      ]);
    } else {
      setIdVar([...idVar, { id: idVar.length + 1, column: "" }]);
    }
  };

  const handleRemoveSection = (id) => {
    if (type === "Sort") {
      const updatedSections = formSections.filter(
        (section) => section.id !== id
      );
      setFormSections(updatedSections);
    } else {
      const updatedSections = idVar.filter((section) => section.id !== id);
      setIdVar(updatedSections);
    }
  };

  const handleSelectChange = (id, field, value) => {
    if (type === "Sort") {
      const updatedSections = formSections.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      );
      setFormSections(updatedSections);
    } else {
      const updatedSections = idVar.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      );
      setIdVar(updatedSections);
    }
  };

  const handleByVarAddSection = () => {
    setByVar([...byVar, { id: byVar.length + 1, column: "" }]);
  };

  const handleByVarRemoveSection = (id) => {
    const updatedSections = byVar.filter((section) => section.id !== id);
    setByVar(updatedSections);
  };

  const handleByVarSelectChange = (id, field, value) => {
    const updatedSections = byVar.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setByVar(updatedSections);
  };

  const handleVarsAddSection = () => {
    setVars([...vars, { id: vars.length + 1, column: "" }]);
  };

  const handleVarsRemoveSection = (id) => {
    const updatedSections = vars.filter((section) => section.id !== id);
    setVars(updatedSections);
  };

  const handleVarsSelectChange = (id, field, value) => {
    const updatedSections = vars.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setVars(updatedSections);
  };

  const getDatasetColumList = async (primaryDataset, type) => {
    setPrimaryDataset(primaryDataset);
    let data = await cdiscServices.getDatasetColumList(
      studyId,
      primaryDataType || type,
      primaryDataset
    );
    setColumns(data?.data);
  };

  const derivationCRFData = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.derivationId = derivationId;
    userData.primaryDataType = primaryDataType;
    userData.primaryDataset = primaryDataset;
    if (type === "Sort") {
      userData.sortData = formSections;
    } else {
      userData.idVar = idVar;
      userData.byVar = byVar;
      userData.vars = vars;
      userData.prefix = prefix;
      userData.suffix = suffix;
    }
    userData.label = label;
    userData.procedureType = type === "Sort" ? "sort" : "transpose";
    await cdiscServices.derivationSaveCRFData(userData);
    getDomainDerivationList();
  };

  const updateTemplateId = (templateId) => {
    let checkId = templateIds?.some((item) => item.templateId === templateId);
    if (checkId) {
      setTemplateIds((preDerivation) =>
        preDerivation.map((item) =>
          item.templateId === templateId
            ? {
              ...item,
              templateId: templateId,
            }
            : item
        )
      );
    } else {
      if (templateId) {
        setTemplateIds((prevItems) => [
          ...prevItems,
          { templateId: templateId },
        ]);
      }
    }
  };

  const conditionString = () => {
    let result = "";
    if (keep) {
      let keepColumn = keepColumns?.length
        ? keepColumns.map((k) => k.label).join("', '")
        : "";
      let keepCustom = keepCustoms
        ? keepCustoms
          .split(" || ")
          .map((item) => item.trim())
          .join("', '")
        : "";

      if (keepColumn || keepCustom) {
        result += `KEEP COLUMN = ['${[keepColumn, keepCustom]
          .filter(Boolean)
          .join("', '")}'] \n`;
      }
    }
    if (drop) {
      let dropColumn = dropColumns?.length
        ? dropColumns.map((k) => k.label).join("', '")
        : "";
      let dropCustom = dropCustoms
        ? dropCustoms
          .split(" || ")
          .map((item) => item.trim())
          .join("', '")
        : "";

      if (dropColumn || dropCustom) {
        result += `DROP COLUMN = ['${[dropColumn, dropCustom]
          .filter(Boolean)
          .join("', '")}'] \n`;
      }
    }
    if (retain) {
      let retainColumn = retainColumns?.length
        ? retainColumns.map((item) => item.label).join(", ")
        : "";
      let retainCustom = retainCustoms
        ? retainCustoms
          .split("||")
          .map((item) => item.trim())
          .join(", ")
        : "";

      let retainValues = [retainColumn, retainCustom]
        .filter(Boolean)
        .join(", ");

      if (retainValues) {
        result += `RETAIN = ['${retainValues}'] \n`;
      }
    }
    statements?.forEach((item) => {
      if (item.type === "if") {
        const keys = item.keys.map((k) => k.label).join("', '");
        if (item.keyType === "column") {
          result += `IF ${item.key} = ${item.value} \n`;
        } else {
          result += `IF ${item.keyType} = ${`['${keys}']`
            } \n`;
        }
      } else if (item.type === "elseif") {
        const keys = item.keys.map((k) => k.label).join("', '");
        if (item.keyType === "column") {
          result += `ELSEIF ${item.key} = ${item.value} \n`;
        } else {
          result += `ELSEIF ${item.keyType} = ${`['${keys}']`
            } \n`;
        }
      } else if (item.type === "then") {
        result += `THENDO ${item.columnName} = '${item.value}' \n`;
      } else if (item.type === "else") {
        result += `ELSE ${item.columnName} = '${item.value}'\n`;
      } else if (item.type === "end") {
        result += `ENDDO \n`;
      }
    });
    setStatementString(result);
  };

  useEffect(() => {
    if (statements?.length > 0 || keep || drop || retain) {
      conditionString();
    }
  }, [statements]);

  return (
    <>
      <SplitPane
        split="vertical"
        className="position-relative overflow-hidden"
        defaultSize="70%"
      >
        <div className="py-2 px-3">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 fw-bold">
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </Form.Group>
          <div
            className="py-2 px-3 mb-3 rounded position-relative"
            style={{ border: "1px dashed #dee2e6" }}
          >
            <Form.Label
              className="m-0 fw-bold position-absolute"
              style={{ top: -12, left: 10 }}
            >
              Dataset Configuration
            </Form.Label>
            <Row>
              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label className="mb-1 fw-bold">
                  Primary Dataset <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="mb-1"
                  onChange={(e) => setPrimaryDataType(e.target.value)}
                >
                  <option value="">Select Dataset Type</option>
                  <option selected={primaryDataType === "crf"} value="crf">
                    CRF Datasets
                  </option>
                  <option
                    selected={primaryDataType === "derivation"}
                    value="derivation"
                  >
                    Derivation Datasets
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-2">
                {primaryDataType === "crf" && (
                  <>
                    <Form.Label className="mb-1 fw-bold">
                      Primary CRF Dataset Name{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        getDatasetColumList(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select CRF Data</option>
                      {crfDatasets?.map((item, index) => (
                        <option
                          selected={primaryDataset === item.uniqueId}
                          key={index}
                          value={item?.uniqueId}
                        >
                          {item.datasetName}
                        </option>
                      ))}
                    </Form.Select>
                  </>
                )}
                {primaryDataType === "derivation" && (
                  <>
                    <Form.Label className="mb-1 fw-bold">
                      Primary Derivation Dataset Name{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        getDatasetColumList(e.target.value);
                        updateTemplateId(e.target.value);
                      }}
                      className="mb-1"
                    >
                      <option value="">Select Derivation Data</option>
                      {derivedData?.map(
                        (item, index) =>
                          item.logStatus === 200 && (
                            <option
                              selected={primaryDataset === item.id}
                              key={index}
                              value={item?.id}
                            >
                              {item.label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
            </Row>
            {type === "Sort" ? (
              <>
                <Form.Group className="mb-2">
                  <div className="hstack gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      Sort By <span className="text-danger">*</span>
                    </Form.Label>
                    <FaSquarePlus
                      className="cursor-pointer"
                      style={{ fontSize: 12 }}
                      title="Add More"
                      onClick={handleAddSection}
                    />
                  </div>
                </Form.Group>
                {formSections?.map((section, index) => (
                  <div key={index} className="hstack gap-1 w-100 mb-2">
                    <Form.Group className="w-100">
                      <Form.Select
                        className="w-100"
                        onChange={(e) =>
                          handleSelectChange(
                            section.id,
                            "column",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Column</option>
                        {columns?.map((data, key) => (
                          <option
                            key={key}
                            selected={section?.column === data?.name}
                            value={data?.name}
                          >
                            {data?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="w-100">
                      <Form.Select
                        className="w-100"
                        value={section.sortType}
                        onChange={(e) =>
                          handleSelectChange(
                            section.id,
                            "sortType",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Sort type</option>
                        <option
                          selected={section?.sortType === "ascending"}
                          value="ascending"
                        >
                          Sort Ascending
                        </option>
                        <option
                          selected={section?.sortType === "descending"}
                          value="descending"
                        >
                          Sort Descending
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group>
                      {formSections.length > 1 && (
                        <FaTrashAlt
                          className="text-danger fontSize12 cursor-pointer"
                          title="Remove Section"
                          onClick={() => handleRemoveSection(section.id)}
                        />
                      )}
                    </Form.Group>
                  </div>
                ))}
              </>
            ) : (
              <>
                <Form.Group className="mb-2">
                  <div className="hstack gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      Transpose Settings <span className="text-danger">*</span>
                    </Form.Label>
                  </div>
                </Form.Group>
                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label className="mb-1 fw-bold">
                      Prefix <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Prefix"
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label className="mb-1 fw-bold">
                      Suffix <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Suffix"
                      value={suffix}
                      onChange={(e) => setSuffix(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-2">
                  <div className="hstack gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      Id Var <span className="text-danger">*</span>
                    </Form.Label>
                    <FaSquarePlus
                      className="cursor-pointer"
                      style={{ fontSize: 12 }}
                      title="Add More"
                      onClick={handleAddSection}
                    />
                  </div>
                </Form.Group>
                {idVar?.map((section, index) => (
                  <div key={index} className="hstack gap-1 w-100 mb-2">
                    <Form.Group className="w-100">
                      <Form.Select
                        className="w-100"
                        onChange={(e) =>
                          handleSelectChange(
                            section.id,
                            "column",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Column</option>
                        {columns?.map((data, key) => (
                          <option
                            key={key}
                            selected={section?.column === data?.name}
                            value={data?.name}
                          >
                            {data?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      {idVar.length > 1 && (
                        <FaTrashAlt
                          className="text-danger fontSize12 cursor-pointer"
                          title="Remove Section"
                          onClick={() => handleRemoveSection(section.id)}
                        />
                      )}
                    </Form.Group>
                  </div>
                ))}

                <Form.Group className="mb-2">
                  <div className="hstack gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      By Var <span className="text-danger">*</span>
                    </Form.Label>
                    <FaSquarePlus
                      className="cursor-pointer"
                      style={{ fontSize: 12 }}
                      title="Add More"
                      onClick={handleByVarAddSection}
                    />
                  </div>
                </Form.Group>
                {byVar?.map((section, index) => (
                  <div key={index} className="hstack gap-1 w-100 mb-2">
                    <Form.Group className="w-100">
                      <Form.Select
                        className="w-100"
                        onChange={(e) =>
                          handleByVarSelectChange(
                            section.id,
                            "column",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Column</option>
                        {columns?.map((data, key) => (
                          <option
                            key={key}
                            selected={section?.column === data?.name}
                            value={data?.name}
                          >
                            {data?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      {byVar.length > 1 && (
                        <FaTrashAlt
                          className="text-danger fontSize12 cursor-pointer"
                          title="Remove Section"
                          onClick={() => handleByVarRemoveSection(section.id)}
                        />
                      )}
                    </Form.Group>
                  </div>
                ))}

                <Form.Group className="mb-2">
                  <div className="hstack gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      Var <span className="text-danger">*</span>
                    </Form.Label>
                    <FaSquarePlus
                      className="cursor-pointer"
                      style={{ fontSize: 12 }}
                      title="Add More"
                      onClick={handleVarsAddSection}
                    />
                  </div>
                </Form.Group>
                {vars?.map((section, index) => (
                  <div key={index} className="hstack gap-1 w-100 mb-2">
                    <Form.Group className="w-100">
                      <Form.Select
                        className="w-100"
                        onChange={(e) =>
                          handleVarsSelectChange(
                            section.id,
                            "column",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Column</option>
                        {columns?.map((data, key) => (
                          <option
                            key={key}
                            selected={section?.column === data?.name}
                            value={data?.name}
                          >
                            {data?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      {vars.length > 1 && (
                        <FaTrashAlt
                          className="text-danger fontSize12 cursor-pointer"
                          title="Remove Section"
                          onClick={() => handleVarsRemoveSection(section.id)}
                        />
                      )}
                    </Form.Group>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="text-end">
            <button onClick={derivationCRFData} className="updateProfile">
              Save
            </button>
          </div>
        </div>
        <div className="overflow-hidden h-100">
          <SyntaxHighlighter
            language="javascript"
            style={dracula}
            className="h-100"
          >
            {(statements?.length > 0 || keep || drop || retain) &&
              statementString}
          </SyntaxHighlighter>
        </div>
      </SplitPane>
    </>
  );
};

export default SortTransposeConfigurationToolsTab;
