import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import "../../Styles/SidebarDropdownStyle.scss";
import { useLocation } from "react-router";

const SidebarDropdowns = ({
  RemoveDropdowns,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  Dropdown7,
  Dropdown8,
  Dropdown9,
}) => {
  const {
    addDrop1,
    addDrop2,
    addDrop3,
    addDrop4,
    addDrop5,
    addDrop6,
    addDrop7,
    addDrop8,
    addDrop9,
    removeDrop1,
    removeDrop2,
    removeDrop3,
    removeDrop4,
    removeDrop5,
    removeDrop6,
    removeDrop7,
    removeDrop8,
    removeDrop9,
  } = UseSidebarReducer();

  const { Item } = Dropdown;
  const AppsRef = useRef(null);
  const [AppsActive, setAppsActive] = useState(false);
  const toggleApps = () => setAppsActive(!AppsActive);
  const location = useLocation();
  const shouldHideToggle =
    location.pathname.includes("studyId") ||
    location.pathname.includes("etmf") ||
    location.pathname.includes("cdisc") ||
    location.pathname.includes("ctms") ||
    location.pathname.includes("iwrs");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleDropdownClick = (add, removes) => {
    removes.forEach((remove) => remove());
    toggleApps();
    add();
  };

  return (
    <div
      ref={AppsRef}
      className={`Apps position-relative ${AppsActive ? "active" : ""}`}
    >
      {!shouldHideToggle && (
        <div className="icon_wrap hstack gap-1" onClick={toggleApps}>
          <div
            className={`title_name hstack gap-1 px-2 ${
              AppsActive ? "AppsNameActive" : ""
            }`}
            style={{ cursor: "pointer" }}
          >
            <div className="hstack gap-1">
              <i className="fa-solid fa-grip"></i>
              <p className="m-0">Apps</p>
              <i
                className={`fa-solid fa-angle-${AppsActive ? "up" : "down"}`}
              ></i>
            </div>
          </div>
        </div>
      )}
      <div className="Apps_dd shadow">
        {RemoveDropdowns && (
          <ul className="Apps_ul">
            {[
              Dropdown1,
              Dropdown2,
              Dropdown3,
              Dropdown4,
              Dropdown5,
              Dropdown6,
              Dropdown7,
              Dropdown8,
              Dropdown9,
            ].map(
              (Dropdown, index) =>
                Dropdown && (
                  <li key={index}>
                    <Item
                      onClick={() => {
                        handleDropdownClick(
                          [
                            addDrop1,
                            addDrop2,
                            addDrop3,
                            addDrop4,
                            addDrop5,
                            addDrop6,
                            addDrop7,
                            addDrop8,
                            addDrop9,
                          ][index],
                          [
                            removeDrop1,
                            removeDrop2,
                            removeDrop3,
                            removeDrop4,
                            removeDrop5,
                            removeDrop6,
                            removeDrop7,
                            removeDrop8,
                            removeDrop9,
                          ].filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <i
                        className={`fa-solid ${
                          [
                            "fa-users",
                            "fa-envelope",
                            "fa-users-gear",
                            "fa-building-lock",
                            "fa-book-open",
                            "fa-database",
                            "fa-shield",
                            "fa-comment",
                            "fa-clock-rotate-left",
                          ][index]
                        } me-2`}
                      ></i>
                      {Dropdown}
                    </Item>
                  </li>
                )
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SidebarDropdowns;
