import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const RandomizerDashboard = () => {
    return (
        <>
            <Container fluid>
                <Row className="chart__overview">
                    <Col md={12} className="border-bottom mb-2">
                        <div className="text-center py-2">
                            <h5 className="m-0" style={{ fontWeight: 500 }}>
                                Good Morning, James Camroon
                            </h5>
                            <p className="m-0">Welcome to your APP dashboard.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RandomizerDashboard