import React, { useEffect, useRef, useState } from "react";
import "./print.css";
import moment from "moment";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import DynamicRuler from "./DynamicRuler";
import CdrgData from "../../../CDRG/CdrgData";
import CDRGParentFile from "../../../CDRG/CDRGParentFile";
import { formReportServices } from "../../../../../../Services/studyServices/formReportServices";

const EditorWritingPage = ({
  size,
  margin,
  columns,
  showFooter,
  showHeader,
  orientation,
  isFullScreen,
  componentRef,
  imageToInsert,
  emojiToInsert,
  handleLinePositionChange,
}) => {

  const editorRef = useRef(null);
  const { studyId } = useParams();
  const [logo, setLogo] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [footerTitle, setFooterTitle] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerTitleLength, setHeaderTitleLength] = useState(0);
  const GeneratedDate = moment(new Date()).format("DD-MM-YYYY | HH:mm:ss");
  // const MAX_PAGE_HEIGHT = 1060;
  const [pages, setPages] = useState([0]);
  const pageRefs = useRef([]);

  // const editorStyle = { width: "100%", minHeight: `${MAX_PAGE_HEIGHT}px` };
  const editorStyle = { width: "100%" };
  const marginStyle = {
    paddingTop: margin.top,
    paddingBottom: margin.bottom,
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };
  const headermarginStyle = {
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };

  const pageStyle = {
    width: orientation === "portrait" ? size.width : size.height,
    height: orientation === "portrait" ? size.height : size.width,
    marginBottom: margin.bottom,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const columnData = {
    columnCount: typeof columns === "number" ? columns : 1,
    columnGap: "1em",
  };

  const contentStyle = {
    ...marginStyle,
    flexGrow: 1,
  };

  const insertImageAtCaret = (imageSrc) => {
    const img = document.createElement("img");
    img.src = imageSrc;
    img.style.maxWidth = "100%";
    img.style.maxHeight = "100%";

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false);
    }
  };

  const getReportStudyMetadataData = async () => {
    let data = await formReportServices.getReportStudyMetadataData(studyId);
    let arrayData = data?.data;
    let headerData = arrayData?.find(
      (item) => item.type === "header"
    )?.headerData;
    setHeaderTitleLength(headerData?.length || 0);
    let footerData = arrayData?.find(
      (item) => item.type === "footer"
    )?.footerData;
    let logoData = arrayData?.find((item) => item.type === "logo");
    let headerDataArray = headerData?.map((item) => item.text).join(`<br />`);
    setHeaderTitle(headerDataArray || "");
    setFooterTitle(footerData || "");
    setHeight(logoData?.height || 60);
    setWidth(logoData?.width || 60);
    setLogo(
      logoData?.logo ||
      "https://gas-admin-bucket.s3.ap-south-1.amazonaws.com/1729008098534.png"
    );
  };

  useEffect(() => {
    if (imageToInsert) {
      insertImageAtCaret(imageToInsert);
    }
  }, [imageToInsert]);

  const insertEmojiAtCaret = (emoji) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const emojiNode = document.createTextNode(emoji);
      range.insertNode(emojiNode);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (emojiToInsert) {
      insertEmojiAtCaret(emojiToInsert);
    }
  }, [emojiToInsert]);

  useEffect(() => {
    getReportStudyMetadataData();
  }, []);

  function toDigitOrCharArray(input) {
    let str = input?.toString();
    if (!isNaN(input) && str?.length === 1) {
      return [0, Number(str)];
    }
    return str?.split('');
  }

  return (
    <>
      <DynamicRuler
        orientation={orientation}
        size={size}
        onLinePositionChange={handleLinePositionChange}
      />
      <div
        style={{
          height: isFullScreen ? "calc(100vh - 130px)" : "calc(100vh - 285px)",
          overflow: "auto",
        }}
      >
        <div className="text-editor text-editor-container" ref={editorRef}>
          {pages?.map((pageIndex) => (
            <div
              key={pageIndex}
              ref={(el) => (pageRefs.current[pageIndex] = el)}
              contentEditable={true}
              style={{
                border: "1px solid #eee",
                borderRadius: 3,
                outline: "none",
                minHeight: "11in",
                ...editorStyle,
                ...pageStyle,
              }}
              suppressContentEditableWarning={true}
            >
              <div ref={componentRef}>
                {showHeader && (
                  <div
                    className="header"
                    style={{ ...contentStyle, borderBottom: "2px solid #1413138f" }}
                    contentEditable={true}
                  >
                    <div className="d-flex justify-content-between">
                      <p className="m-0 fw-bold">Study 039-24</p>
                      <p className="m-0 fw-bold">Clinical Study Data Reviewer’s Guide</p>
                    </div>
                  </div>
                )}
                <div
                  style={{ ...contentStyle, ...columnData }}
                  className="content_Id"
                >
                  <Table>
                    {showHeader && (
                      <thead>
                        <tr className="spacingHeader">
                          <th className="border-0">
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      <tr>
                        <td className="border-0 p-0">
                          <div className="content">
                            <div className="page sdrgData" style={{ height: (showHeader && showFooter) ? "77vh" : showFooter ? "90vh" : showHeader ? "85vh" : "100vh" }}><CdrgData /></div>
                            <div className="page"><CDRGParentFile /></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    {showFooter && (
                      <tfoot>
                        <tr className="spacingHeader" style={{ borderTop: "2px solid #1413138f" }}>
                          <td className="border-0 p-0" >
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </Table>
                </div>
                {showFooter && (
                  <div
                    className="footer bg-white text-center"
                    style={{
                      ...contentStyle,
                      borderTop: "1px dashed #eee",
                    }}
                  >
                    <div className="hstack justify-content-between">
                      {footerTitle?.title1 && (
                        <span contentEditable={true} className="text-start">
                          {footerTitle?.title1} <br />
                          {footerTitle?.title2}
                        </span>
                      )}
                      {!footerTitle && (
                        <span contentEditable={true}>Confidencials</span>
                      )}
                      <span style={{ pointerEvents: "none" }}>
                        Page : {pageIndex + 1} of {pages.length}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditorWritingPage;
