import React from "react";
import { Modal } from "react-bootstrap";
import UserProfile from "../../Pages/Modules/UserProfile/UserProfile";
const UpdateProfile = ({ SHOW, HIDE, TITLE }) => {
  return (
    <Modal className={SHOW ? "" : "d-none"} show={SHOW} onHide={HIDE} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <UserProfile
          HIDE={HIDE}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProfile;
