import React, { useEffect, useRef, useState } from "react";
import { Collapse, ListGroup } from "react-bootstrap";
import { BsTabletLandscape } from "react-icons/bs";
import { VscCopy } from "react-icons/vsc";

const LayoutOrientationDesign = ({ setOrientation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div ref={dropdownRef}>
        <div onClick={toggleOpen}>Orientation</div>
        <Collapse in={isOpen}>
          <div
            className="position-absolute start-0"
            style={{ width: 150, top: 20 }}
          >
            <ListGroup>
              <ListGroup.Item
                onClick={() => {
                  setOrientation("portrait");
                  setIsOpen(false);
                }}
              >
                {/* <VscCopy /> */}
                Portrait
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setOrientation("landscape");
                  setIsOpen(false);
                }}
              >
                {/* <BsTabletLandscape />  */}
                Landscape
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default LayoutOrientationDesign;
