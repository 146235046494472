import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const UpdateValueLavel = ({
  studyId,
  isFormOpen,
  getValueLevelData,
}) => {
  let [formate, setFormate] = useState("");
  let [pages, setPages] = useState("");
  let [predecessor, setPredecessor] = useState("");
  let [joinComment, setJoinComment] = useState("");

  const updateStudyUpdateValueLevelMetadata = async () => {
    let userData = {}
    userData.studyId = studyId
    userData.uniqueId = isFormOpen?._id
    userData.joinComment = joinComment
    userData.format = formate
    userData.pages = pages
    userData.predecessor = predecessor
    let data = await cdiscServices.updateStudyUpdateValueLevelMetadata(userData)
    if (data?.statusCode === 200) {
      getValueLevelData()
    }
  }

  useEffect(() => {
    setJoinComment(isFormOpen?.joinComment);
    setFormate(isFormOpen?.format);
    setPredecessor(isFormOpen?.predecessor);
    setPages(isFormOpen?.pages);
  }, [isFormOpen]);
  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Format <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Formate"
          onChange={(e) => setFormate(e.target.value)}
          value={formate}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Predecessor <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Predecessor"
          onChange={(e) => setPredecessor(e.target.value)}
          value={predecessor}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Join Comment <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Join Comment	"
          onChange={(e) => setJoinComment(e.target.value)}
          value={joinComment}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button onClick={updateStudyUpdateValueLevelMetadata} className="updateProfile">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </div>
  );
};

export default UpdateValueLavel;
