import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const randomizerServices = {
  studyRandomizerAtOnce,
  findRandomizedData,
  addUpdateRandomizerFactorOrder,
  findUpdateRandomizerFactorOrder,
  addRandomizerStudyParameter,
  findRandomizerStudyParameter,
  getRandomizerMetadataData,
  addRandomizerMetadata,
  updateRandomizerMetadataData,
  deleteRandomizerMetadataData,
};

async function studyRandomizerAtOnce(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}studyRandomizerAtOnce`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addUpdateRandomizerFactorOrder(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addUpdateRandomizerFactorOrder`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addRandomizerStudyParameter(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addRandomizerStudyParameter`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addRandomizerMetadata(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addRandomizerMetadata`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getRandomizerMetadataData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRandomizerMetadataData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findRandomizedData(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findRandomizedData/${studyId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findRandomizerStudyParameter(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findRandomizerStudyParameter/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findUpdateRandomizerFactorOrder(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findUpdateRandomizerFactorOrder/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateRandomizerMetadataData(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateRandomizerMetadataData`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function deleteRandomizerMetadataData(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteRandomizerMetadataData/${studyId}/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}
