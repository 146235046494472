import React, { useState } from "react";
import { Alert, Form, InputGroup, Spinner } from "react-bootstrap";

const AddUpdateEventRows = ({
  handleSubmit2,
  handleSubmit1,
  visitId,
  setVisitId,
  repeat,
  setRepeat,
  initiate,
  setInitiate,
  studyDynamicColumnData,
  UpdateHistoryOnChange,
  updateHandleChange,
  addHandleChange,
  studyUniqueId,
  studyAddDynamicColumnData,
  loader,
  statusCode,
  customMessage,
  studyDynamicRow,
  timePointData,
  setTimePointData,
  addTimePoints,
  removeTimePoints,
  timePoints,
  setTimePoints
}) => {

  let checkAddDunamicValue = (studyAddDynamicColumnData || [])?.map((item) =>
    item.valueData ? true : false
  );
  let checkAddFilledData = checkAddDunamicValue?.filter((item) => item === true);
  let dynamicAddConditionData =
    studyAddDynamicColumnData?.length === checkAddFilledData?.length;

  let checkUpdateDunamicValue = (studyDynamicColumnData || [])?.map((item) =>
    item.valueData ? true : false
  );
  let checkUpdateFilledData = checkUpdateDunamicValue?.filter((item) => item === true);
  let dynamicUpdateConditionData =
    studyDynamicColumnData?.length === checkUpdateFilledData?.length;

  const initiateList = studyDynamicRow?.filter(item => item.initiate !== 'anyTime' && item.initiate !== 'afterFirstVisits');

  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="fw-bold mb-1">Time Point ID <span className="text-danger">*</span></Form.Label>
        <Form.Select
          onChange={(e) => setTimePointData(e.target.value)}
          title="Select Initiate">
          <option value="">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="fw-bold mb-1">Visit Id <span className="text-danger">*</span></Form.Label>
        </div>
        <Form.Control
          type="text"
          className="inputboxborder"
          placeholder="Enter Visit Id"
          value={visitId}
          onChange={(e) => setVisitId(e.target.value)}
        />
      </Form.Group>
      {studyUniqueId
        ? studyDynamicColumnData?.length > 0 && (
          studyDynamicColumnData?.map(
            ({ name, types, valueData, history }, i) => (
              <>
                <div key={i}>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold mb-1">{name} <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      key={i}
                      type={types}
                      className="inputboxborder"
                      name="valueData"
                      value={valueData}
                      onChange={(e) => updateHandleChange(e, i)}
                      placeholder={`Enter ${name}`}
                      required
                    />
                  </Form.Group>
                </div>
                <div key={i}>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Comment
                    </Form.Label>
                    <input
                      key={i}
                      type={"text"}
                      className="form-control inputboxborder mb-3 w-100"
                      name="history"
                      value={history}
                      onChange={(e) => UpdateHistoryOnChange(e, i)}
                      placeholder={"Enter Comment"}
                      required
                    />
                  </Form.Group>
                </div>
              </>
            )))
        : studyAddDynamicColumnData?.length > 0 &&
        studyAddDynamicColumnData?.map(({ name, types, valueData }, i) => (
          <div key={i}>
            <Form.Group className="mb-2">
              <Form.Label className="fw-bold mb-1">{name} <span className="text-danger">*</span></Form.Label>
              <Form.Control
                key={i}
                type={types}
                className="inputboxborder"
                name="valueData"
                value={valueData}
                onChange={(e) => addHandleChange(e, i)}
                placeholder={`Enter ${name}`}
                required
              />
            </Form.Group>
          </div>
        ))}
      {timePointData != "yes" &&
        <Form.Group className="mb-2">
          <Form.Label className="fw-bold mb-1">Initiate <span className="text-danger">*</span></Form.Label>
          <Form.Select
            className="inputboxborder"
            value={initiate}
            onChange={(e) => setInitiate(e.target.value)}
          >
            <option>Select</option>
            <option value={"afterFirstVisits"}>After First Visits</option>
            <option value={"anyTime"}>Any Time</option>
            {studyUniqueId ?
              initiateList?.map((data, index) => (
                <option key={index} value={index + 1}>{index + 1}</option>
              ))
              :
              <option value={initiateList?.length + 1}>{initiateList?.length + 1}</option>
            }
          </Form.Select>
        </Form.Group>
      }
      <Form.Group className="mb-2">
        <Form.Label className="fw-bold mb-1">Repeat <span className="text-danger">*</span></Form.Label>
        <Form.Select
          className="inputboxborder"
          onChange={(e) => setRepeat(e.target.value)}
        >
          <option>Select Repeat</option>
          <option selected={repeat == "yes"} value={"yes"}>Yes</option>
          <option selected={repeat == "no"} value={"no"}>No</option>
        </Form.Select>
      </Form.Group>
      {timePointData === "yes" &&
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">
            Time Point ID <span className="text-danger">*</span>
            <i
              className="fa fa-circle-plus ms-2 CP"
              title="Add More Time Points ID"
              onClick={addTimePoints}
              style={{ cursor: "pointer" }}
            ></i>
          </Form.Label>
          {timePoints?.map((timePoint, index) => (
            <InputGroup className="mb-2 hstack" key={timePoint.id}>
              <Form.Control
                type="text"
                placeholder="Time Point ID"
                title="Enter Time Point ID"
                value={timePoint.timePoint}
                onChange={(e) => {
                  const updatedTimePoints = timePoints.map((tp) =>
                    tp.id === timePoint.id ? { ...tp, timePoint: e.target.value } : tp
                  );
                  setTimePoints(updatedTimePoints);
                }}
              />
              <Form.Select
                title="Select Initiate"
                value={timePoint.initiate}
                onChange={(e) => {
                  const updatedTimePoints = timePoints.map((tp) =>
                    tp.id === timePoint.id ? { ...tp, initiate: e.target.value } : tp
                  );
                  setTimePoints(updatedTimePoints);
                }}
              >
                <option value="">Select Initiate</option>
                <option value={"afterFirstVisits"}>After First Visits</option>
                <option value={"anyTime"}>Any Time</option>
                {studyUniqueId ?
                  initiateList?.map((data, index) => (
                    <option key={index} value={index + 1}>{index + 1}</option>
                  ))
                  :
                  timePoints?.map((data, index) => (
                    <option value={(initiateList?.length + 1 + index)}>{(initiateList?.length + 1 + index)}</option>
                  ))
                }
              </Form.Select>
              {index !== 0 && (
                <i
                  className="fa fa-circle-xmark ms-2 CP text-danger"
                  title="Remove Time Points"
                  onClick={() => removeTimePoints(timePoint.id)}
                  style={{ cursor: "pointer" }}
                ></i>
              )}
            </InputGroup>
          ))}
        </Form.Group>
      }
      <div className="text-end">
        {studyUniqueId ? (
          <button
            disabled={loader || !visitId || !repeat || !dynamicUpdateConditionData}
            title={
              !visitId || !repeat || !dynamicUpdateConditionData
                ? "Fill all mandatory fields"
                : ""
            }
            className="submitbutton"
            onClick={handleSubmit2}
          >
            {loader ? (
              <Spinner
                className="me-1"
                style={{ width: 15, height: 15 }}
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-pen-to-square me-1"></i>
            )}
            Update Event
          </button>
        ) : (
          <button
            className="submitbutton"
            onClick={handleSubmit1}
            disabled={loader || !visitId || !repeat || !dynamicAddConditionData}
            title={
              !visitId || !repeat || !dynamicAddConditionData
                ? "Fill all mandatory fields"
                : ""
            }
          >
            {loader ? (
              <Spinner
                className="me-1"
                style={{ width: 15, height: 15 }}
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-square-plus me-1"></i>
            )}
            Add Event
          </button>
        )}
      </div>
      {customMessage && (
        <Alert variant={statusCode === 200 ? "success" : "danger"}>
          {customMessage}
        </Alert>
      )}
    </div>
  );
};

export default AddUpdateEventRows;
