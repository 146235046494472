import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const UpdateVariable = ({ isFormOpen, studyId, getCDISCVariablesList }) => {
  let [significant, setSignificant] = useState("");
  let [format, setFormat] = useState("");
  let [pages, setPages] = useState("");
  let [predecessor, setPredecessor] = useState("");

  const updateStudyVariableMetadata = async () => {
    let userData = {}
    userData.studyId = studyId
    userData.uniqueId = isFormOpen?._id
    userData.significant = significant
    userData.format = format
    userData.pages = pages
    userData.predecessor = predecessor
    let data = await cdiscServices.updateStudyVariableMetadata(userData)
    if (data?.statusCode === 200) {
      getCDISCVariablesList()
    }
  }

  useEffect(() => {
    setSignificant(isFormOpen?.significant);
    setFormat(isFormOpen?.format);
    setPredecessor(isFormOpen?.predecessor);
    setPages(isFormOpen?.pages);
  }, [isFormOpen]);

  return (
    <div className="p-2">
      {isFormOpen?.type === "float" &&
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">
            Significant <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Significant"
            onChange={(e) => setSignificant(e.target.value)}
            value={significant}
          />
        </Form.Group>
      }
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Format <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Format"
          onChange={(e) => setFormat(e.target.value)}
          value={format}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Predecessor <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Predecessor"
          onChange={(e) => setPredecessor(e.target.value)}
          value={predecessor}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button onClick={updateStudyVariableMetadata} className="updateProfile">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </div>
  );
};

export default UpdateVariable;
