import React, { useState } from "react";
import { userServices } from "../../../Services/userServices";
const TwoFactorAuthByEmail = ({ emailTwoFA, getTwoFactorAuthType }) => {

  const [OTPSent, setOTPSent] = useState(false)
  const [OTP, setOTP] = useState("")
  const sendOTPForEmailTwoFa = async () => {
    let data = await userServices.emailOTPFor2FA()
    if(data?.statusCode === 200){
      setOTPSent(true)
    }
  }

  const enableTwoFactorAuthByEmail = async () => {
    let userData = {}
    userData.otp = OTP
    let data = await userServices.enableTwoFactorAuthByEmail(userData)
    if(data?.statusCode === 200){
      getTwoFactorAuthType()
      setOTPSent(false)
    }
  }

  const disableTwoFactorAuthByEmail = async () => {
    let userData = {}
    userData.otp = OTP
    let data = await userServices.disableTwoFactorAuthByEmail(userData)
    if(data?.statusCode === 200){
      getTwoFactorAuthType()
      setOTPSent(false)
    }
  }

  return (
    <>
      {!emailTwoFA ?
        <>
          {!OTPSent ?
            <>
              <div className="qr__imag">
                <div>
                  <div className="twoFactor">
                    <i className="fa-solid fa-shield-halved red"></i>
                  </div>
                  <div className="twoFATitle">Disabled</div>
                  <p style={{ fontSize: 12, textAlign: "center" }}>To enable Email 2FA, send OTP to the registered email address. If you click on Send OTP button, OTP will be automatically sent to your registered email address.</p>
                </div>
              </div>
              <div className="hstack gap-3 justify-content-end pt-3">
                <button className="mt-2 updateProfile" type="button" onClick={() => sendOTPForEmailTwoFa()}>{"Send OTP"}</button>
              </div>
            </>
            :
            <>
              <label className="small mb-1" for="inputCountry">
                Enter OTP
              </label>
              <input
                type="number"
                className="form-control inputborder inputsize w-100"
                onChange={(e) => setOTP(e.target.value)}
                value={OTP}
                required
                placeholder="Enter OTP" />
              <div className="hstack gap-3 justify-content-end pt-3 footer_button">
                <button className="mt-2 updateProfile" type="button" onClick={() => enableTwoFactorAuthByEmail()}>Verify with Enable</button>
              </div>
            </>
          }
        </>
        :
        <>
          {!OTPSent ?
            <>
              <div className="twoFactor">
                <i className="fa-solid fa-shield-halved"></i>
              </div>
              <div className="twoFATitle">Enabled (2FA by Email)</div>
              <div className="hstack gap-3 justify-content-end pt-3 footer_button">
                <button className="mt-2 updateProfile" type="button" onClick={() => sendOTPForEmailTwoFa()}>Disable</button>
              </div>
            </>
            :
            <>
              <label className="small mb-1" for="inputCountry">
                Enter OTP
              </label>
              <input
                type="number"
                className="form-control inputborder inputsize w-100"
                onChange={(e) => setOTP(e.target.value)}
                value={OTP}
                required
                placeholder="Enter OTP" />
              <div className="hstack gap-3 justify-content-end pt-3 footer_button">
                <button className="mt-2 updateProfile" type="button" onClick={() => disableTwoFactorAuthByEmail()}>Disable 2FA</button>
              </div>
            </>
          }
        </>
      }
    </>
  );
};

export default TwoFactorAuthByEmail;
