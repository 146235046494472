import React from "react";
import { Dropdown } from "react-bootstrap";
import { BarsIcon, CircleMinusIcon } from "../../../Assets/Icons/GlobalIcons";
import "../../../Styles/HideShowColumnStyle.scss";

const ShowHideColumn = ({
  ColumnLabelTitle1,
  ColumnLabelTitle2,
  ColumnLabelTitle3,
  ColumnLabelTitle4,
  ColumnLabelTitle5,
  ColumnLabelTitle6,
  ColumnLabelTitle7,
  ColumnLabelTitle8,
  ColumnLabelTitle9,
  ColumnLabelTitle10,
  ColumnLabelTitle11,
  ColumnLabelTitle12,
  ColumnLabelTitle13,
  ColumnLabelTitle14,
  ColumnLabelTitle15,
  ColumnLabelTitle16,
  ColumnLabelTitle17,
  ColumnLabelTitle18,
  ColumnLabelTitle19,
  ColumnLabelTitle20,
  ColumnLabelTitle21,
  ColumnLabelTitle22,
  ColumnLabelTitle23,
  ColumnLabelTitle24,
  ColumnLabelTitle25,

  handleCheck1,
  handleCheck2,
  handleCheck3,
  handleCheck4,
  handleCheck5,
  handleCheck6,
  handleCheck7,
  handleCheck8,
  handleCheck9,
  handleCheck10,
  handleCheck11,
  handleCheck12,
  handleCheck13,
  handleCheck14,
  handleCheck15,
  handleCheck16,
  handleCheck17,
  handleCheck18,
  handleCheck19,
  handleCheck20,
  handleCheck21,
  handleCheck22,
  handleCheck23,
  handleCheck24,
  handleCheck25,

  showColumn,
  makeSticky,

  Sticky,
}) => {
  const { Toggle, Menu } = Dropdown;

  const ColumnHideDiv = (Label, ColumnCheck) => (
    <>
      {Label && (
        <div className="d-flex justify-content-between px-2">
          <label className="pe-2" htmlFor={+1}>
            {Label}
          </label>

          <input type="checkbox" onClick={ColumnCheck} id={+1} />
        </div>
      )}
    </>
  );

  return (
    <>
      <Dropdown>
        <Toggle variant="" className="p-0">
          {Sticky ? <BarsIcon /> : <CircleMinusIcon />}
        </Toggle>

        <Menu>
          <ul className="columnMenuSubjectStatus">
            <h4 className="text-center columnHead">
              {Sticky ? "Sticky Columns" : "Show/Hide Column"}
            </h4>

            <div className="mainColumn">
              {ColumnHideDiv(ColumnLabelTitle1, handleCheck1)}
              {ColumnHideDiv(ColumnLabelTitle2, handleCheck2)}
              {ColumnHideDiv(ColumnLabelTitle3, handleCheck3)}
              {ColumnHideDiv(ColumnLabelTitle4, handleCheck4)}
              {ColumnHideDiv(ColumnLabelTitle5, handleCheck5)}
              {ColumnHideDiv(ColumnLabelTitle6, handleCheck6)}
              {ColumnHideDiv(ColumnLabelTitle7, handleCheck7)}
              {ColumnHideDiv(ColumnLabelTitle8, handleCheck8)}
              {ColumnHideDiv(ColumnLabelTitle9, handleCheck9)}
              {ColumnHideDiv(ColumnLabelTitle10, handleCheck10)}
              {ColumnHideDiv(ColumnLabelTitle11, handleCheck11)}
              {ColumnHideDiv(ColumnLabelTitle12, handleCheck12)}
              {ColumnHideDiv(ColumnLabelTitle13, handleCheck13)}
              {ColumnHideDiv(ColumnLabelTitle14, handleCheck14)}
              {ColumnHideDiv(ColumnLabelTitle15, handleCheck15)}
              {ColumnHideDiv(ColumnLabelTitle16, handleCheck16)}
              {ColumnHideDiv(ColumnLabelTitle17, handleCheck17)}
              {ColumnHideDiv(ColumnLabelTitle18, handleCheck18)}
              {ColumnHideDiv(ColumnLabelTitle19, handleCheck19)}
              {ColumnHideDiv(ColumnLabelTitle20, handleCheck20)}
              {ColumnHideDiv(ColumnLabelTitle21, handleCheck21)}
              {ColumnHideDiv(ColumnLabelTitle22, handleCheck22)}
              {ColumnHideDiv(ColumnLabelTitle23, handleCheck23)}
              {ColumnHideDiv(ColumnLabelTitle24, handleCheck24)}
              {ColumnHideDiv(ColumnLabelTitle25, handleCheck25)}
            </div>

            <div className="py-3">
              <button
                className="btn dropdown-item text-center deleteSave"
                onClick={Sticky ? showColumn : makeSticky}
              >
                Save
              </button>
            </div>
          </ul>
        </Menu>
      </Dropdown>
    </>
  );
};

export default ShowHideColumn;
