import React from "react";
import { Button } from "react-bootstrap";

const SignOut = ({ logout }) => (
  <Button
    onClick={logout}
    variant="outline-danger"
    className="p-1 px-2 border-0  "
    style={{ fontSize: 13 }}
    title="Sign out from panel"
  >
    <i className="fa-solid fa-right-from-bracket me-1"></i>Signout
  </Button>
);

export default SignOut;
