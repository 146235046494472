import React from "react";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";

const ResizerXManager = ({ collapseSidebar }) => {
  const { showSidebar } = UseSidebarReducer();

  const resizerXStyle = showSidebar ? "resizer-x" : "resizer-x";

  return (
    <>
      {collapseSidebar ? (
        <div className="me-1"></div>
      ) : (
        <div className={resizerXStyle} id="resizer-x" />
      )}
    </>
  );
};

export default ResizerXManager;
