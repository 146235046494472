import React, { useEffect, useRef, useState } from "react";
import { FaRegSmile } from "react-icons/fa";
import {
  BsEmojiAngry,
  BsEmojiFrown,
  BsEmojiGrimace,
  BsEmojiHeartEyes,
  BsEmojiSmile,
  BsEmojiSmileUpsideDown,
  BsEmojiSunglasses,
  BsEmojiTear,
  BsEmojiWink,
} from "react-icons/bs";
import { Collapse } from "react-bootstrap";

const InsertEmoji = ({ onEmojiInsert }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleEmojiClick = (emoji) => {
    setIsOpen(false);
    onEmojiInsert(emoji);
  };

  return (
    <>
      <div ref={dropdownRef}>
        <div
          onClick={toggleOpen}
          className="position-relative"
          title="Insert Emoji"
        >
          <FaRegSmile />
        </div>
        <Collapse in={isOpen}>
          <div
            className="bg-white border p-3 start-0 rounded position-absolute"
            style={{ width: 300, top: 20 }}
          >
            <div onClick={() => setIsOpen(false)}>
              <div style={{ fontSize: 15 }} className="text-start">
                Recently Used
              </div>
              <div className="hstack flex-wrap gap-2" style={{ fontSize: 35 }}>
                <div className="m-0" onClick={() => handleEmojiClick("😡")}>
                  <BsEmojiAngry />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😄")}>
                  <BsEmojiSmile />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😢")}>
                  <BsEmojiTear />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😉")}>
                  <BsEmojiWink />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😖")}>
                  <BsEmojiFrown />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😁")}>
                  <BsEmojiGrimace />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("🙃")}>
                  <BsEmojiSmileUpsideDown />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("🤐")}>
                  <BsEmojiGrimace />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😎")}>
                  <BsEmojiSunglasses />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😍")}>
                  <BsEmojiHeartEyes />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("🤩")}>
                  <i className="fa-regular fa-face-grin-stars"></i>
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😛")}>
                  <i className="fa-regular fa-face-grin-tongue"></i>
                </div>
                <div className="m-0" onClick={() => handleEmojiClick("😅")}>
                  <i className="fa-regular fa-face-grin-beam-sweat"></i>
                </div>
                {/* <div className="m-0" onClick={() => handleEmojiClick("🥺")}>
                  <BsFillEmojiGrinFill />
                </div> */}
                {/* <div className="m-0" onClick={() => handleEmojiClick('🤔')}>
                    <BsEmojiThinking />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick('😇')}>
                    <BsEmojiAngel />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick('🤯')}>
                    <BsEmojiExplodingHead />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick('🥳')}>
                    <BsEmojiParty />
                </div>
                <div className="m-0" onClick={() => handleEmojiClick('😴')}>
                    <BsEmojiSleep />
                </div> */}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default InsertEmoji;
