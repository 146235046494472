import React, { useEffect, useState } from "react";
import {Table, Offcanvas } from "react-bootstrap";
import Moment from "react-moment";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySitesServices";
import UseToggle from "../../../../../../../Hooks/UseToggle";
import SplitPane from "react-split-pane";

const UpdatedFieldHistory = ({ siteUniqueId, tableRef, onMouseDown }) => {
  const [siteHistory, setSiteHistory] = useState([]);
  const [siteHistoryData, setSiteHistoryData] = useState("");
  const [history, setHistory] = useState([]);
  const [comment, setComment] = useState([]);
  const [loader, setLoader] = useState(false);

  const { setToggle, toggle } = UseToggle();

  const getStudySiteHistoryByID = async (siteUniqueId) => {
    setLoader(true);
    let data = await studyOnboardingService.getStudySiteHistoryByID(
      siteUniqueId
    );
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setSiteHistory(finalData);
    setLoader(false);
  };

  useEffect(() => {
    getStudySiteHistoryByID(siteUniqueId);
  }, [siteUniqueId]);

  useEffect(() => {
    const rows = document.querySelectorAll("tr");

    const handleContextMenu = (event, row) => {
      event.preventDefault();
      rows.forEach((row) => row.classList.remove("highlighted"));
      row.classList.add("highlighted");
    };

    rows.forEach((row) => {
      row.addEventListener("contextmenu", (event) =>
        handleContextMenu(event, row)
      );
    });

    return () => {
      rows.forEach((row) => {
        row.removeEventListener("contextmenu", (event) =>
          handleContextMenu(event, row)
        );
      });
    };
  }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (i, item) => {
    setSelectedRow(i);
    setHistory(item);
  };

  const handleRowComment = (e, item) => {
    setToggle();
    setSiteHistoryData(item)
    setComment(comment);
  };

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="30%">
        <div>
          <div
            className="text-center white position-sticky"
            style={{ fontSize: 13, background: "var(--blue)", top: 0 }}
          >
            Metadata
          </div>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0  custom-table"
              ref={tableRef}
              bordered
            >
              <thead className="thead-sticky" style={{ top: 21 }}>
                <tr>
                  <th
                    style={{ width: 55 }}
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    S No.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>
                    Management Name
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Latest version</th>
                </tr>
              </thead>
              <tbody>
                {siteHistory?.map((item, i) => (
                  <tr
                    key={i}
                    onClick={(e) => {
                      handleRowClick(i, item?.items);
                    }}
                    className="CP"
                    style={{
                      background: selectedRow === i ? "#ccd6dd" : "",
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{item.columnName}</td>
                    <td>{item.version}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div>
          <div
            className="text-center white position-sticky"
            style={{ fontSize: 13, background: "var(--blue)", top: 0 }}
          >
            History
          </div>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0  custom-table"
              ref={tableRef}
              bordered
            >
              <thead className="thead-sticky" style={{ top: 21 }}>
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 0)}>Version</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Date | Time</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>User Id</th>
                </tr>
              </thead>
              <tbody>
                {(history?.length === 0
                  ? siteHistory?.[0]?.items
                  : history
                )?.map((item, i) => (
                  <tr key={i} onClick={(e) => handleRowComment(e, item)} className="CP">
                    <td>{item.version}</td>
                    <td>{item.value}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm">
                        {item.createdAt}
                      </Moment>
                    </td>
                    <td>{item.userId}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </SplitPane>
      <Offcanvas
        show={!toggle}
        className={!toggle ? "" : "d-none"}
        onHide={setToggle}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0  custom-table"
              ref={tableRef}
              bordered
            >
              <thead className="thead-sticky">
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 0)}>Attribute</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Version</td>
                  <td>{siteHistoryData?.version}</td>
                </tr>
                <tr>
                  <td>Current Value</td>
                  <td>{siteHistoryData?.value}</td>
                </tr>
                <tr>
                  <td>Date | Time</td>
                  <td>
                  <Moment format="DD MMM YYYY | HH:mm">{siteHistoryData?.createdAt}</Moment></td>
                </tr>
                <tr>
                  <td>User Id</td>
                  <td onClick={setToggle}>{siteHistoryData?.userId}</td>
                </tr>
                <tr>
                  <td>Comment</td>
                  <td><div style={{
                    whiteSpace: 'pre-wrap',
                    overflowY: 'auto',
                    maxHeight: '150px',
                    padding: '5px',
                  }}>
                    {siteHistoryData?.comment}
                  </div></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UpdatedFieldHistory;
