import axios from "axios";
import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";
import saveAs from "file-saver";

export const cdiscServices = {
  addNewCDISCVariable,
  addNewSupplimentalComment,
  addNewSupplimentalMathod,
  updateSupplimentalComments,
  updateSupplimentalMathods,
  deleteSupplimentalComments,
  deleteSupplimentalMathods,
  deleteCDISCVariables,
  getCDISCVariablesList,
  getActiveVersions,
  getActiveClass,
  getActiveDomains,
  getSupplimentalMathodsList,
  getSupplimentalCommentsList,
  getActiveSupplimentalMathods,
  getActiveSupplimentalComments,
  changeSupplimentalCommentsStatus,
  changeSupplimentalMathodsStatus,
  searchSupplimentalComment,
  searchSupplimentalMathod,
  getSupplimentalsList,
  getStudySupplimentalsList,
  getValueLevelData,
  filterStudyValueLevelList,
  deleteStudyValueLevelData,

  // SDA
  getCDISCSDA,
  deleteCDISCSDA,
  updateCDISCSDA,
  importCDISCSDA,

  // STDM
  getCDISCDatasets,
  deleteStudyDataByDomainId,
  getCDISCCodeLists,
  deleteStudyDataByCodeList,

  filterValueLevelList,
  filterCDISCVariablesList,
  filterWhereClauseData,
  filterCDISCCodeLists,
  filterStudySupplimentalsList,

  getCDISCMathods,
  deleteStudyDataByMathodId,
  getCDISCComments,
  deleteStudyDataByCommentId,
  getWhereClauseData,

  // Study Value Label

  updateStudyValueLevelData,
  addNewStudyValueLevel,
  findStudyDetails,
  getActiveVariableComments,
  getActiveVariableMathods,
  getValueLevelVariablesList,
  getWithoutValueLevelVariablesList,
  getSTDMCodeList,
  deleteStudySupplimentals,
  getClientLevelCodeList,

  // CRF DATA
  getActiveDataTemplate,
  getStudyDataTemplates,
  assignedUserStudyDataTemplates,
  importDataTemplateinStudy,
  deleteStudyDataTemplates,
  updateStudyDataTemplates,
  getStudyDataTemplateEntryData,
  addDataTemplateEntryData,
  updateStudyDataEntryData,
  deleteStudyDataTemplateEntryData,
  reviewStatusChangeUserStudyDataTemplates,
  lockStatusChangeUserStudyDataTemplates,
  getStudyDataTemplateReviewHistory,
  getStudyDataTemplateLockedHistory,

  uploadFileinCRFData,
  uploadFileinCRFRowData,
  exportDataTemplateFileMultiFormat,
  addNewStudySupplimental,
  updateStudySupplimentals,

  //Query Management
  generateCDISCDataEntryQuery,
  assignCDISCDataEntryQuery,
  updateCDISCSDataEntryQueryStatus,
  sendCDISCDataEntryQueryDiscussion,
  getDataEntryQueryList,
  getDataEntryQueryDiscussionList,

  //Study Metadata Update
  updateStudyMathodMetadata,
  updateStudyCommentMetadata,
  updateStudyVariableMetadata,
  updateStudyUpdateValueLevelMetadata,
  exportCRFDataFileMultiFormat,

  //Derivation
  derivationCRFData,
  derivationSaveCRFData,
  getDataEntryDerivationList,
  getMultiDataEntryColumList,
  exportDerivationDataFileMultiFormat,
  addNewDomainDerivation,
  getDomainDerivationList,
  deleteDomainDerivationData,
  updateDomainDerivation,
  findDomainDerivationData,
  getDatasetColumList,
  getCDISCCellVariable,

  //Function
  addNewFunction,
  updateFunction,
  getFunctionList,
  deleteFunction,

  extractStudyCIDSCData
};

async function extractStudyCIDSCData(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}extractStudyCIDSCData`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  let responseData;
  if(data?.foundStatus?.statusCode === 200){
    responseData = {
      statusCode: 200,
      customMessage: "CDISC Study has been Successfully Backuped",
    };
  }else{
    responseData = {
      statusCode: 400,
      customMessage: "Opps! CDISC Study not Backup",
    };
  }
  handleResponse(responseData);
  return data;
}

// Add New Function
async function addNewFunction(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewFunction`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Function
async function updateFunction(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateFunction`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Derivation
async function getFunctionList(studyId, derivationId, activeTab) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getFunctionList/${studyId}/${derivationId}/${activeTab}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Derivation
async function deleteFunction(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteFunction/${studyId}/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Client Code List
async function addNewDomainDerivation(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewDomainDerivation`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

// Update Client Code List
async function updateDomainDerivation(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateDomainDerivation`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Client Code List
async function derivationCRFData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}derivationCRFData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  let customMessage = {
    statusCode: data?.foundStatus?.statusCode || 400,
    customMessage: data?.foundStatus?.statusCode === 200 ? "Dataset has been successfully run" : "Invalid Settings, Please Check now...",
  }
  if(userData?.type){
    handleResponse(customMessage);
  }
  return data;
}

// Update Client Code List
async function derivationSaveCRFData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}derivationSaveCRFData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Derivation
async function getDataEntryDerivationList(studyId, domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDataEntryDerivationList/${studyId}/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Derivation
async function deleteDomainDerivationData(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteDomainDerivationData/${studyId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Derivation
async function getDomainDerivationList(studyId, domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDomainDerivationList/${studyId}/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Derivation
async function findDomainDerivationData(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findDomainDerivationData/${studyId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Derivation
async function getDatasetColumList(studyId, primaryDataType, primaryDataset) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDatasetColumList/${studyId}/${primaryDataType}/${primaryDataset}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Derivation
async function getCDISCCellVariable(studyId, domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCCellVariable/${studyId}/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Client Code List
async function getMultiDataEntryColumList(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getMultiDataEntryColumList`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Client Code List
async function updateStudySupplimentals(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudySupplimentals`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// add New Supplimental
async function addNewStudySupplimental(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudySupplimental`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//  upload File in CRF Data
async function uploadFileinCRFRowData(formData) {
  const response = await axios.post(
    `${config.API_URL_USER_ONBOARDING}uploadFileinCRFRowData`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Export Datasets in Multiformat
async function exportDataTemplateFileMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportDataTemplateFileMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

//  upload File in CRF Data
async function uploadFileinCRFData(formData) {
  const response = await axios.post(
    `${config.API_URL_USER_ONBOARDING}uploadFileinCRFData`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Update Protocol Status
async function deleteStudyDataTemplateEntryData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyDataTemplateEntryData`,
    headersWithAuth("DELETE", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Protocol Status
async function addDataTemplateEntryData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addDataTemplateEntryData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Protocol Status
async function updateStudyDataEntryData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyDataEntryData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// add import Data Template in Study
async function importDataTemplateinStudy(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}importDataTemplateinStudy`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Active Data Template
async function getStudyDataTemplateEntryData(studyId, uniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyDataTemplateEntryData/${studyId}/${uniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Active Data Template
async function getStudyDataTemplateReviewHistory(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyDataTemplateReviewHistory/${id}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Active Data Template
async function getStudyDataTemplateLockedHistory(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyDataTemplateLockedHistory/${id}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Active Data Template
async function getActiveDataTemplate() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveDataTemplate`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Study Data Templates
async function getStudyDataTemplates(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyDataTemplates/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Study Data Templates
async function deleteStudyDataTemplates(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyDataTemplates/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

// Update Study Data Templates
async function updateStudyDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Study Data Templates
async function assignedUserStudyDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignedUserStudyDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Review Study Data Templates
async function reviewStatusChangeUserStudyDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}reviewStatusChangeUserStudyDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}



// Review Study Data Templates
async function lockStatusChangeUserStudyDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}lockStatusChangeUserStudyDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Get Client Level CodeList
async function getClientLevelCodeList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientLevelCodeList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete STDM Code
async function deleteStudySupplimentals(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudySupplimentals/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get STDM Code List
async function getSTDMCodeList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSTDMCodeList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Without Value Level Variable List for Table
async function getWithoutValueLevelVariablesList(domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getWithoutValueLevelVariablesList/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Value Level Variable List for Table
async function getValueLevelVariablesList(domainId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getValueLevelVariablesList/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable Method
async function getActiveVariableMathods() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveVariableMathods`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable Comment
async function getActiveVariableComments() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveVariableComments`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findStudyDetails(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findStudyDetails/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Study Value Level
async function addNewStudyValueLevel(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewStudyValueLevel`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Study Value Level Data
async function updateStudyValueLevelData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyValueLevelData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Filter Variable List
async function filterCDISCVariablesList(userData) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterCDISCVariablesList`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Filter Variable List
async function filterWhereClauseData(userData) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterWhereClauseData`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Filter Variable List
async function filterCDISCCodeLists(userData) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterCDISCCodeLists`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Filter Variable List
async function filterStudySupplimentalsList(userData) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterStudySupplimentalsList`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}
// Filter Value Label List
async function filterValueLevelList(userData) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterValueLevelList`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Get Where Clause Data
async function getWhereClauseData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getWhereClauseData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get CDISC Method
async function getCDISCComments(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCComments/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Method
async function deleteStudyDataByCommentId(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyDataByCommentId/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC Method
async function getCDISCMathods(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCMathods/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Method
async function deleteStudyDataByMathodId(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyDataByMathodId/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC Code List
async function getCDISCCodeLists(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCCodeLists/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Code List
async function deleteStudyDataByCodeList(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyDataByCodeList/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC STDM
async function getCDISCDatasets(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCDatasets/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete CDISC STDM
async function deleteStudyDataByDomainId(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyDataByDomainId/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC SDA
async function getCDISCSDA(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCSDA/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// import CDISC SDA
async function importCDISCSDA(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}importCDISCSDA`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update SDA
async function updateCDISCSDA(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateCDISCSDA`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Delete SDA
async function deleteCDISCSDA(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteCDISCSDA/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add Sponser
async function addNewCDISCVariable(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewCDISCVariable`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Project
async function addNewSupplimentalMathod(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewSupplimentalMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Project
async function addNewSupplimentalComment(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewSupplimentalComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Project
async function searchSupplimentalComment(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}searchSupplimentalComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Add Project
async function searchSupplimentalMathod(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}searchSupplimentalMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Sponser
async function updateSupplimentalComments(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSupplimentalComments`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Project
async function updateSupplimentalMathods(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSupplimentalMathods`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function changeSupplimentalCommentsStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}changeSupplimentalCommentsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Project Status
async function changeSupplimentalMathodsStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}changeSupplimentalMathodsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All sponser for Table
async function getCDISCVariablesList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCDISCVariablesList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All sponser for Table
async function getActiveVersions() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveVersions`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All sponser for Table
async function getActiveClass(versionId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveClass/${versionId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All sponser for Table
async function getActiveDomains(classId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveDomains/${classId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalCommentsList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSupplimentalCommentsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalMathodsList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSupplimentalMathodsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalsList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSupplimentalsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getStudySupplimentalsList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySupplimentalsList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Value Level Data
async function getValueLevelData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyValueLevelData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Filter Value Level
async function filterStudyValueLevelList(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}filterStudyValueLevelList`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getActiveSupplimentalMathods() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveSupplimentalMathods`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getActiveSupplimentalComments() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveSupplimentalComments`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Sponser
async function deleteSupplimentalComments(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteSupplimentalComments/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Value Level Data
async function deleteStudyValueLevelData(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyValueLevelData/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Project
async function deleteSupplimentalMathods(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteSupplimentalMathods/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Study
async function deleteCDISCVariables(id, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteCDISCVariables/${id}/${studyId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// add New Supplimental
async function generateCDISCDataEntryQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateCDISCDataEntryQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Client Code List
async function assignCDISCDataEntryQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignCDISCDataEntryQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Client Code List
async function updateCDISCSDataEntryQueryStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateCDISCSDataEntryQueryStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// add New Supplimental
async function sendCDISCDataEntryQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendCDISCDataEntryQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function getDataEntryQueryList(studyId, templateId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDataEntryQueryList/${studyId}/${templateId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getDataEntryQueryDiscussionList(studyId, queryId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDataEntryQueryDiscussionList/${studyId}/${queryId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Client Code List
async function updateStudyMathodMetadata(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyMathodMetadata`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Client Code List
async function updateStudyCommentMetadata(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyCommentMetadata`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Client Code List
async function updateStudyVariableMetadata(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyVariableMetadata`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Client Code List
async function updateStudyUpdateValueLevelMetadata(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyUpdateValueLevelMetadata`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Export Datasets in Multiformat
async function exportCRFDataFileMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportCRFDataFileMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}
// Export Datasets in Multiformat
async function exportDerivationDataFileMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportDerivationDataFileMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}