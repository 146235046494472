import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const studyOnboardingService = {
  currentTime,
  createStudySubjectColumn,
  updateStudySubjectColumn,
  getStudySubjectColumnData,
  getStudySubjectColumnForFactor,
  AddStudySubjectRow,
  UpdateStudySubjectRow,
  UpdateStudySubjectRowStatus,
  getStudySubjectStatusHistoryData,
  getStudySubjectRowData,
  filterStudySubjectRowData,
  deleteStudySubjectRowData,
  getStudySubjectAttributeByID,
  getStudySubjectHistoryByID,
  exportStudySubjectDataInMultiFormat,
  reviewStudySubject,
  getStudySubjectReviewData,
  exportStudySubjectAuditTrialDataInMultiFormat,
  getStudySubjectStatusHistoryGraphData,
  getStudySubjectAttributes,
  addFromFieldInSubject,
  removeFromFieldInSubject,
  removeFromFieldInSubjectData,
  getFromFieldInSubject,
  getAssignedFormOfVisits,
  addTableProperty,
  getReviewedStudyLevelForm,
  getTablePropertyData,
  addTableAndRegularView,
  getStudySiteWithSubjectData,
  getStudySiteEntryProgress,
  getStudySiteSDVGraphData,
  getStudySiteQueryGraphData,
  getStudySubjectEntryProgress,
  getStudySubjectSDVGraphData,
  getStudySubjectQueryGraphData,
  getSubjectDetailsData,
  getSubjectViewTimeline,
  generateStudySubjectQuery,
  getStudySubjectQueryList,
  assignStudySubjectQuery,
  updateStatusStudySubjectQuery,
  getStudySubjectQueryDiscussionList,
  sendStudySubjectQueryDiscussion,
  getStudyVisitRowDataBySubjectGraphData,
  getStudyVisitsFormGraphData,
  getStudyVisitFormSDVGraphData,
  getStudyVisitFormQueryGraphData,
  getStudyVisitFormEntryProgress
};

async function getStudyVisitFormEntryProgress(studyId, studyMode, studyUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitFormEntryProgress/${studyId}/${studyMode}/${studyUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitFormQueryGraphData(studyId, studyMode, studyUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitFormQueryGraphData/${studyId}/${studyMode}/${studyUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormGraphData(studyId, visitId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitsFormGraphData/${studyId}/${visitId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitFormSDVGraphData(studyId, visitId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitFormSDVGraphData/${studyId}/${visitId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitRowDataBySubjectGraphData(
  studyId,
  subjectId,
  studyMode,
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitRowDataBySubjectGraphData/${studyId}/${subjectId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getStudySiteEntryProgress(studyId,studyUniqueId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteEntryProgress/${studyId}/${studyUniqueId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSubjectDetailsData(studyId,studyUniqueId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSubjectDetailsData/${studyId}/${studyUniqueId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSubjectViewTimeline(studyId,subjectId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSubjectViewTimeline/${studyId}/${subjectId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteSDVGraphData(studyId,studyUniqueId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteSDVGraphData/${studyId}/${studyUniqueId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteQueryGraphData(studyId,studyUniqueId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteQueryGraphData/${studyId}/${studyUniqueId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectEntryProgress(studyId,studyUniqueId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectEntryProgress/${studyId}/${studyUniqueId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectSDVGraphData(studyId,studyUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectSDVGraphData/${studyId}/${studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectQueryGraphData(studyId,studyUniqueId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectQueryGraphData/${studyId}/${studyUniqueId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySiteWithSubjectData(studyId, StudyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySiteWithSubjectData/${studyId}/${StudyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function addTableAndRegularView(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addTableAndRegularView`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function addTableProperty(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addTableProperty`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data
}

async function getTablePropertyData(category, columnId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTablePropertyData/${category}/${columnId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function reviewStudySubject(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}reviewStudySubject`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getFromFieldInSubject(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getFromFieldInSubject/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAssignedFormOfVisits(studyId, studyUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAssignedFormOfVisits/${studyId}/${studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getReviewedStudyLevelForm(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getReviewedStudyLevelForm/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectReviewData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectReviewData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectAttributes() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectAttributes`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function currentTime() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let currentDate = yyyy + "-" + mm + "-" + dd;
  return currentDate;
}

async function createStudySubjectColumn(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createStudySubjectColumn`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data;
}

async function addFromFieldInSubject(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addFromFieldInSubject`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function removeFromFieldInSubject(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}removeFromFieldInSubject`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function removeFromFieldInSubjectData(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}removeFromFieldInSubjectData`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function getStudySubjectColumnData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectColumnData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectColumnForFactor(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectColumnForFactor/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function AddStudySubjectRow(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}AddStudySubjectRow`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectRowData(
  studyId,
  StudyMode,
  columnId,
  siteUniqueId,
  page,
  limit
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectRowData/${studyId}/${StudyMode}/${columnId}/${siteUniqueId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectStatusHistoryData(
  studyId,
  StudyMode,
  studyUniqueId,
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectStatusHistoryData/${studyId}/${StudyMode}/${studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectStatusHistoryGraphData(
  studyId,
  StudyMode,
  studyUniqueId,
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectStatusHistoryGraphData/${studyId}/${StudyMode}/${studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateStudySubjectColumn(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudySubjectColumn/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function UpdateStudySubjectRow(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}UpdateStudySubjectRow/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function UpdateStudySubjectRowStatus(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}UpdateStudySubjectRowStatus/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function filterStudySubjectRowData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}filterStudySubjectRowData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function deleteStudySubjectRowData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudySubjectRowData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectAttributeByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectAttributeByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectHistoryByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectHistoryByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function exportStudySubjectDataInMultiFormat(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportStudySubjectDataInMultiFormat/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportStudySubjectAuditTrialDataInMultiFormat(
  studyId,
  extension
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportStudySubjectAuditTrialDataInMultiFormat/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}


//Generate Subject Query
async function generateStudySubjectQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateStudySubjectQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Generate Subject Query
async function assignStudySubjectQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudySubjectQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Update Status Subject Query
async function updateStatusStudySubjectQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusStudySubjectQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

async function getStudySubjectQueryList(studyUniqueId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectQueryList/${studyUniqueId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudySubjectQueryDiscussionList(queryId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudySubjectQueryDiscussionList/${queryId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Send Study Subject Query Discussion
async function sendStudySubjectQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendStudySubjectQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}