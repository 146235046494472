import React from "react";
import { Alert, Form, Spinner } from "react-bootstrap";

const AddUpdateCTMSRows = ({
    formValues,
    name,
    setName,
    loading,
    statusCode,
    handleSubmit2,
    rowId,
    customMessage,
    handleSubmit1,
    addHandleChange,
    updateHandleChange,
    studyDynamicColumnData,
    studyAddDynamicColumnData,
}) => {

    return (
        <div className="p-2">
            <Form.Group className="mb-2">
                <div className="hstack justify-content-between">
                    <Form.Label className="fw-bold mb-1">
                        Name<span className="text-danger">*</span>
                    </Form.Label>
                </div>
                <Form.Control
                    type="text"
                    className="inputboxborder"
                    placeholder="Enter Column Label"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>

            {rowId
                ? studyDynamicColumnData?.length > 0 &&
                studyDynamicColumnData?.map(
                    ({ name, types, valueData }, i) => (
                        <>
                            <div key={i}>
                                <Form.Group className="mb-2">
                                    <Form.Label className="fw-bold mb-1">
                                        {name} <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        key={i}
                                        type={types}
                                        className="inputboxborder"
                                        name="valueData"
                                        value={valueData ? valueData : ""}
                                        onChange={(e) => updateHandleChange(e, i)}
                                        placeholder={`Enter ${name}`}
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </>
                    )
                )
                : studyAddDynamicColumnData?.length > 0 &&
                studyAddDynamicColumnData?.map(({ name, types, valueData }, i) => (
                    <div key={i}>
                        <Form.Group className="mb-2">
                            <Form.Label className="fw-bold mb-1">
                                {name} <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                key={i}
                                type={types}
                                className="inputboxborder"
                                name="valueData"
                                value={valueData ? valueData : ""}
                                onChange={(e) => addHandleChange(e, i)}
                                placeholder={`Enter ${name}`}
                                required
                            />
                        </Form.Group>
                    </div>
                ))}
            <div className="text-end mb-2">
                {rowId ? (
                    <button
                        className="submitbutton"
                        onClick={handleSubmit2}
                        disabled={loading || !formValues}
                        title={
                            !formValues
                                ? "Fill all mandatory fields"
                                : ""
                        }
                    >
                        {loading ? (
                            <Spinner
                                className="me-1"
                                style={{ width: 15, height: 15 }}
                                animation="border"
                            />
                        ) : (
                            <i className="fa-solid fa-pen-to-square me-1"></i>
                        )}
                        Update Row
                    </button>
                ) : (
                    <button
                        onClick={handleSubmit1}
                        className="submitbutton"
                        disabled={loading || !formValues}
                        title={
                            !formValues
                                ? "Fill all mandatory fields"
                                : ""
                        }
                    >
                        {loading ? (
                            <Spinner
                                className="me-1"
                                style={{ width: 15, height: 15 }}
                                animation="border"
                            />
                        ) : (
                            <i className="fa-solid fa-square-plus me-1"></i>
                        )}
                        Add Row
                    </button>
                )}
            </div>
            {customMessage && (
                <Alert variant={statusCode === 200 ? "success" : "danger"}>
                    {customMessage}
                </Alert>
            )}
        </div>
    );
};

export default AddUpdateCTMSRows;
