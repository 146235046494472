import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import SplitPane from "react-split-pane";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
import { TbFreezeColumn } from "react-icons/tb";

const FreezingLockingManagement = ({
  studyPermissionData,
  StudyMode,
  freezeLock,
  updateDatabaseFreezLock,
  visitFormType,
  databaseLockFreezeHistory,
  loaderFreezeData,
  getdatabaseUnFreezeForDataEntry,
  selectedFormId
}) => {
  useEffect(() => {
    getdatabaseUnFreezeForDataEntry(selectedFormId);
  }, [selectedFormId]);
  return (
    <div>
      {(studyPermissionData?.unFreezeFormField ||
          StudyMode === "DBP MODE" || StudyMode === "Live Mode" || StudyMode === "QC Mode" ||
          StudyMode === "DV Mode") && (
          <div className="hstack gap-2 all___buton_style">
            {freezeLock === "freezed" ? (
              <div className="p-1">
                <div
                  className="hstack border-success border fontSize12   bg-blue text-white CP p-1 px-2"
                  title="Unfreeze"
                  style={{ fontSize: 10 }}
                  onClick={(e) => updateDatabaseFreezLock(e, "unfreezed")}
                >
                  <TbFreezeColumn style={{ fontSize: 15 }} className="pe-1" />Unfreeze
                </div>
              </div>
            ) : freezeLock === "locked" ? (
              <div className="p-1">
                <div
                  className="border-success border fontSize12   bg-blue text-white CP p-1 px-2"
                  title="Unlock"
                  onClick={(e) => updateDatabaseFreezLock(e, "unlocked")}
                >
                  Unlock
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        {loaderFreezeData ? (
          <SpinerDataLoader />
        ) : databaseLockFreezeHistory?.length > 0 ? (
          <Table striped className="m-0 custom-table">
            <thead className="thead-sticky">
              <tr>
                <th>Action By</th>
                {visitFormType === "log" && <th>Record Id</th>}
                <th>Date | Time</th>
                <th>Status</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {databaseLockFreezeHistory?.map((item, index) => (
                <tr key={index}>
                  <td>{item.actionBy}</td>
                  {visitFormType === "log" && <td>{item.recordId}</td>}
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm">
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <div
                      className={`text-capitalize ${item?.status === "freezed" ? "green" : "orange"
                        }`}
                    >
                      {item.status}
                    </div>
                  </td>
                  <td>{item.comment}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoDataFoundTable MSG="Freezing and locking Data not found!" />
        )}
      </SplitPane>
    </div>
  );
};

export default FreezingLockingManagement;
