import { useEffect, useState } from "react";

/**
 * Set localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  return window.localStorage.setItem(name, content);
};

/**
 * Get localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  return JSON.parse(window.localStorage.getItem(name));
};

/**
 * Clear localStorage
 */
export const removeItem = (name) => {
  if (!name) return;
  return window.localStorage.removeItem(name);
};

/**
 * Validate Email address
 */
export const isValidEmail = (value) => {
  return !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value));
};

/**
 * Format Phone Number
 */
export const formatPhoneNumber = (value) => {
  if (!value) return;
  const currentValue = value.replace(/[^\d]/g, "");
  const mobileNoLength = currentValue.length;
  if (mobileNoLength >= 7) {
    if (mobileNoLength < 4) return currentValue;
    if (mobileNoLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  } else {
    return currentValue;
  }
};

export const convertDataFormat = (date) => {
  const parts = date.split("-");
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
}

export const timeSince = (dateString) => {
  const date = new Date(dateString);

  if (!(date instanceof Date && !isNaN(date))) {
    return 'Invalid date';
  }

  const currentDate = new Date();
  const difference = currentDate.getTime() - date.getTime();
  const seconds = Math.floor(difference / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};



export const dateStatus = (inputDate) => {
  const today = new Date();
  const inputDateObj = new Date(inputDate);
  const millisecondsInADay = 1000 * 60 * 60 * 24;

  today.setHours(0, 0, 0, 0);
  inputDateObj.setHours(0, 0, 0, 0);

  if (inputDateObj.getTime() === today.getTime()) {
    return 'Today';
  } else if (inputDateObj.getTime() === today.getTime() - millisecondsInADay) {
    return 'Yesterday';
  } else {
    const daysDifference = Math.ceil((today.getTime() - inputDateObj.getTime()) / millisecondsInADay);
    return `${daysDifference} days ago`;
  }
}

export const calculateDuration = (DateTime) => {
  const givenDate = new Date(DateTime);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - givenDate);
  const diffSeconds = Math.floor(diffTime / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);
  if (diffYears > 0) {
    return(`${diffYears} year${diffYears > 1 ? 's' : ''} ago`);
  } else if (diffMonths > 0) {
    return(`${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`);
  } else if (diffDays > 0) {
    return(`${diffDays} day${diffDays > 1 ? 's' : ''} ago`);
  } else if (diffHours > 0) {
    return(`${diffHours} hour${diffHours > 1 ? 's' : ''} ago`);
  } else if (diffMinutes > 0) {
    return(`${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`);
  } else {
    return(`${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`);
  }
};

const TimeDuration = ({ DateTime }) => {
  const [duration, setDuration] = useState("");
  const calculateDuration = (DateTime) => {
    const givenDate = new Date(DateTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - givenDate);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffMonths / 12);
    if (diffYears > 0) {
      setDuration(`${diffYears} year${diffYears > 1 ? "s" : ""} ago`);
    } else if (diffMonths > 0) {
      setDuration(`${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`);
    } else if (diffDays > 0) {
      setDuration(`${diffDays} day${diffDays > 1 ? "s" : ""} ago`);
    } else if (diffHours > 0) {
      setDuration(`${diffHours} hour${diffHours > 1 ? "s" : ""} ago`);
    } else if (diffMinutes > 0) {
      setDuration(`${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`);
    } else {
      setDuration(`${diffSeconds} second${diffSeconds > 1 ? "s" : ""} ago`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
        calculateDuration(DateTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [DateTime]);

  return duration;
};

export default TimeDuration;