import React, { useEffect, useRef, useState } from "react";
import { Collapse, Form, ListGroup } from "react-bootstrap";
import { SlPicture } from "react-icons/sl";

const InsertPictures = ({ handleImageUpload }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef}>
      <div
        onClick={toggleOpen}
        className="position-relative"
        title="Insert Image"
      >
        Pictures
        {/* <SlPicture /> */}
      </div>
      <Collapse in={isOpen}>
        <div
          className="position-absolute start-0"
          style={{ width: 200, top: 20 }}
        >
          <ListGroup>
            <ListGroup.Item action>
              <Form.Label className="hstack gap-2 m-0">
                <SlPicture />
                Select From This Device
              </Form.Label>
              <Form.Control
                className="position-absolute top-0 start-0 opacity-0 p-3"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Collapse>
    </div>
  );
};

export default InsertPictures;
