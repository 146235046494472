import { useDispatch } from "react-redux";

const UseAddTabs = () => {
  const dispatch = useDispatch();

  const addGlobalFormCrfEditorTab = () => {
    dispatch({
      type: "addGlobalFormCrfEditorTab",
    });
  };

  const addControlQueryResponseMessageTab = () => {
    dispatch({
      type: "addControlQueryResponseMessageTab",
    });
  };

  const addSubjectManagementScheduleTab = () => {
    dispatch({
      type: "addSubjectManagementScheduleTab",
    });
  };

  const addStudySubjectSpecialFormFormTab = () => {
    dispatch({
      type: "addStudySubjectSpecialFormFormTab",
    });
  };

  const addAssignStudyPermissionTab = () => {
    dispatch({
      type: "addAssignStudyPermissionTab",
    });
  };

  const addStudyAaccessTab = () => {
    dispatch({
      type: "addStudyAaccessTab",
    });
  };

  const addETMFAaccessTab = () => {
    dispatch({
      type: "addETMFAaccessTab",
    });
  };

  const addEtmfDocData = () => {
    dispatch({
      type: "addEtmfDocData",
    });
  };

  const addViewStudyTableDataTab = () => {
    dispatch({
      type: "addViewStudyTableDataTab",
    });
  };

  const addViewStudyDetailsTableDataTab = () => {
    dispatch({
      type: "addViewStudyDetailsTableDataTab",
    });
  };

  const addClientFormCrfEditorTab = () => {
    dispatch({
      type: "addClientFormCrfEditorTab",
    });
  };

  const addProjectFormCrfEditorTab = () => {
    dispatch({
      type: "addProjectFormCrfEditorTab",
    });
  };

  const addStudyVisitsTab = () => {
    dispatch({
      type: "addStudyVisitsTab",
    });
  };

  const addFolderUniqueIdTab = () => {
    dispatch({
      type: "addFolderUniqueIdTab",
    });
  };

  const addLogsHistoryData = () => {
    dispatch({
      type: "addLogsHistoryData",
    });
  };

  const addFailedLoginData = () => {
    dispatch({
      type: "addFailedLoginData",
    });
  };

  const addUserTab = () => {
    dispatch({
      type: "addUserTab",
    });
  };

  const addQueryManagementtTab = () => {
    dispatch({
      type: "addQueryManagementtTab",
    });
  };

  const addQuerySummaryyTab = () => {
    dispatch({
      type: "addQuerySummaryyTab",
    });
  };

  const addVerifyStudyMetaData = () => {
    dispatch({
      type: "addVerifyStudyMetaData",
    });
  };

  const addInactiveUserTab = () => {
    dispatch({
      type: "addInactiveUserTab",
    });
  };

  const addGlobalFormValidationStatus = () => {
    dispatch({
      type: "addGlobalFormValidationStatus",
    });
  };
  const addProjectFormValidationStatus = () => {
    dispatch({
      type: "addProjectFormValidationStatus",
    });
  };

  const addArchivedUserTab = () => {
    dispatch({
      type: "addArchivedUserTab",
    });
  };

  const addFreezedUserTab = () => {
    dispatch({
      type: "addFreezedUserTab",
    });
  };

  const addOnBoardingUserTab = () => {
    dispatch({
      type: "addOnBoardingUserTab",
    });
  };

  const addNewUserTab = () => {
    dispatch({
      type: "addNewUserTab",
    });
  };

  // CLIENTS..........................................
  const addClientTab = () => {
    dispatch({
      type: "addClientTab",
    });
  };
  const addClientPermissionTab = () => {
    dispatch({
      type: "addClientPermissionTab",
    });
  };
  const addInactiveClientTab = () => {
    dispatch({
      type: "addInactiveClientTab",
    });
  };

  const addArchivedClientTab = () => {
    dispatch({
      type: "addArchivedClientTab",
    });
  };

  const addFreezedClientTab = () => {
    dispatch({
      type: "addFreezedClientTab",
    });
  };

  const addOnBoardingClientTab = () => {
    dispatch({
      type: "addOnBoardingClientTab",
    });
  };

  const addNewClientTab = () => {
    dispatch({
      type: "addNewClientTab",
    });
  };

  // ACCESS......................................

  const addAccessTab = () => {
    dispatch({
      type: "addAccessTab",
    });
  };

  // Users Request Tabs......................................

  const userRequestsTab = () => {
    dispatch({
      type: "userRequestsTab",
    });
  };

  // Clients Request Tabs......................................

  const clientRequestsTab = () => {
    dispatch({
      type: "clientRequestsTab",
    });
  };

  // NEW USER CREATION......................................

  const addUserCreationTab = () => {
    dispatch({
      type: "addUserCreationTab",
    });
  };

  // DESIGNATION......................................

  const addDesignationTab = () => {
    dispatch({
      type: "addDesignationTab",
    });
  };

  // Approved Useer......................................

  const addApprovedUserTab = () => {
    dispatch({
      type: "addApprovedUserTab",
    });
  };

  // Approved Useer......................................

  const addApprovedClientTab = () => {
    dispatch({
      type: "addApprovedClientTab",
    });
  };

  // Approved Useer......................................

  const addRejectedUserTab = () => {
    dispatch({
      type: "addRejectedUserTab",
    });
  };

  // Approved Useer......................................

  const addRejectedClientTab = () => {
    dispatch({
      type: "addRejectedClientTab",
    });
  };

  const addArchivedPendingUserTab = () => {
    dispatch({
      type: "addArchivedPendingUserTab",
    });
  };

  const addArchivedApprovedUserTab = () => {
    dispatch({
      type: "addArchivedApprovedUserTab",
    });
  };

  const addArchivedRejectedUserTab = () => {
    dispatch({
      type: "addArchivedRejectedUserTab",
    });
  };

  const addArchivedPendingClientTab = () => {
    dispatch({
      type: "addArchivedPendingClientTab",
    });
  };

  const addArchivedApprovedClientTab = () => {
    dispatch({
      type: "addArchivedApprovedClientTab",
    });
  };

  const addArchivedRejectedClientTab = () => {
    dispatch({
      type: "addArchivedRejectedClientTab",
    });
  };

  const defaultTab = () => {
    dispatch({
      type: "defaultTab",
    });
  };

  const addClientCreationTab = () => {
    dispatch({
      type: "addClientCreationTab",
    });
  };

  const addKeyMilestoneTab = () => {
    dispatch({
      type: "addKeyMilestoneTab",
    });
  };

  const addSiteStatusTab = () => {
    dispatch({
      type: "addSiteStatusTab",
    });
  };

  const addStudyManageTab = () => {
    dispatch({
      type: "addStudyManageTab",
    });
  };

  const addMedicalCodingSiteTab = () => {
    dispatch({
      type: "addMedicalCodingSiteTab",
    });
  };

  const addReportsSiteTab = () => {
    dispatch({
      type: "addReportsSiteTab",
    });
  };

  const addSubjectStatusTab = () => {
    dispatch({
      type: "addSubjectStatusTab",
    });
  };

  const addSubjectFormStatusTab = () => {
    dispatch({
      type: "addSubjectFormStatusTab",
    });
  };

  const addStudyVisitStatusTab = () => {
    dispatch({
      type: "addStudyVisitStatusTab",
    });
  };

  const addMonitoringVisitTab = () => {
    dispatch({
      type: "addMonitoringVisitTab",
    });
  };

  const addSubmissionPlanTab = () => {
    dispatch({
      type: "addSubmissionPlanTab",
    });
  };

  const addRequestTableTab = () => {
    dispatch({
      type: "addRequestTableTab",
    });
  };

  const addDatabaseLockTab = () => {
    dispatch({
      type: "addDatabaseLockTab",
    });
  };

  const addDatabaseApproveTab = () => {
    dispatch({
      type: "addDatabaseApproveTab",
    });
  };

  const addSubjectFormTab = () => {
    dispatch({
      type: "addSubjectFormTab",
    });
  };

  const addStudySummaryTab = () => {
    dispatch({
      type: "addStudySummaryTab",
    });
  };

  const addCrfDesigningTab = () => {
    dispatch({
      type: "addCrfDesigningTab",
    });
  };

  const addControlGenderDesigningTab = () => {
    dispatch({
      type: "addControlGenderDesigningTab",
    });
  };

  const addManageQueryManagementTab = () => {
    dispatch({
      type: "addManageQueryManagementTab",
    });
  };

  const addCRFDatasets = () => {
    dispatch({
      type: "addCRFDatasets",
    });
  };

  const addQueryStatiscsDeaignTab = () => {
    dispatch({
      type: "addQueryStatiscsDeaignTab",
    });
  }; 
  
  const addManageSDVManagementTab = () => {
    dispatch({
      type: "addManageSDVManagementTab",
    });
  };
  
  const addAuditManageSDVManagementTab = () => {
    dispatch({
      type: "addAuditManageSDVManagementTab",
    });
  };

  const addSDVFieldDeaignTab = () => {
    dispatch({
      type: "addSDVFieldDeaignTab",
    });
  };

  const addSDVStatiscsDeaignTab = () => {
    dispatch({
      type: "addSDVStatiscsDeaignTab",
    });
  };

  const addKeyMilestonesTab = () => {
    dispatch({
      type: "addKeyMilestonesTab",
    });
  };

  const addMonitoringVisitsTab = () => {
    dispatch({
      type: "addMonitoringVisitsTab",
    });
  };

  const addDerivedVeriableTab = () => {
    dispatch({
      type: "addDerivedVeriableTab",
    });
  };

  const addControlAgeGroupDesigningTab = () => {
    dispatch({
      type: "addControlAgeGroupDesigningTab",
    });
  };

  const addControlLabUnitsTabs = () => {
    dispatch({
      type: "addControlLabUnitsTabs",
    });
  };

  const addFormFieldMetadataTab = () => {
    dispatch({
      type: "addFormFieldMetadataTab",
    });
  };

  const addTrialCriteriaTab = () => {
    dispatch({
      type: "addTrialCriteriaTab",
    });
  };

  const addAutoFieldTab = () => {
    dispatch({
      type: "addAutoFieldTab",
    });
  };

  const addStudyScheduleTab = () => {
    dispatch({
      type: "addStudyScheduleTab",
    });
  };

  const addStudyRandomisationTab = () => {
    dispatch({
      type: "addStudyRandomisationTab",
    });
  };

  const addStudySiteTab = () => {
    dispatch({
      type: "addStudySiteTab",
    });
  };

  const addSubjectVisitFormTab = () => {
    dispatch({
      type: "addSubjectVisitFormTab",
    });
  };

  const addSubjectSpecialFormTab = () => {
    dispatch({
      type: "addSubjectSpecialFormTab",
    });
  };

  const addSubjectViewTab = () => {
    dispatch({
      type: "addSubjectViewTab",
    });
  };

  const addSampleReportPDFTab = () => {
    dispatch({
      type: "addSampleReportPDFTab",
    });
  };

  const addSubjectReportPDFTab = () => {
    dispatch({
      type: "addSubjectReportPDFTab",
    });
  };

  const addControlQueryMessageTab = () => {
    dispatch({
      type: "addControlQueryMessageTab",
    });
  };

  const addControlQuerySubjectTab = () => {
    dispatch({
      type: "addControlQuerySubjectTab",
    });
  };

  const addStudyFormValidationStatus = () => {
    dispatch({
      type: "addStudyFormValidationStatus",
    });
  };

  const addCrfViewTab = () => {
    dispatch({
      type: "addCrfViewTab",
    });
  };

  const addVisitsCrfViewTab = () => {
    dispatch({
      type: "addVisitsCrfViewTab",
    });
  };

  const addVisitFormCrfEditorTab = () => {
    dispatch({
      type: "addVisitFormCrfEditorTab",
    });
  };

  const addStudyFormCrfEditorTab = () => {
    dispatch({
      type: "addStudyFormCrfEditorTab",
    });
  };

  const addGlobalFormCrfViewTab = () => {
    dispatch({
      type: "addGlobalFormCrfViewTab",
    });
  };

  const addAdverseEventTab = () => {
    dispatch({
      type: "addAdverseEventTab",
    });
  };

  const addDispositionEventTab = () => {
    dispatch({
      type: "addDispositionEventTab",
    });
  };

  const addLabParametersTab = () => {
    dispatch({
      type: "addLabParametersTab",
    });
  };

  const addBackupTab = () => {
    dispatch({
      type: "addBackupTab",
    });
  };

  const addClientBackupTab = () => {
    dispatch({
      type: "addClientBackupTab",
    });
  };

  const addUserBackupTab = () => {
    dispatch({
      type: "addUserBackupTab",
    });
  };

  const addRoleBackupTab = () => {
    dispatch({
      type: "addRoleBackupTab",
    });
  };

  const addStudyBackupTab = () => {
    dispatch({
      type: "addStudyBackupTab",
    });
  };

  const addSystemBackupTab = () => {
    dispatch({
      type: "addSystemBackupTab",
    });
  };

  const addCrfsBackupTab = () => {
    dispatch({
      type: "addCrfsBackupTab",
    });
  };

  const addStudySchemaBackupTab = () => {
    dispatch({
      type: "addStudySchemaBackupTab",
    });
  };

  const addParameterBackupTab = () => {
    dispatch({
      type: "addParameterBackupTab",
    });
  };

  const addSubjectMilestoneSettingScheduleTab = () => {
    dispatch({
      type: "addSubjectMilestoneSettingScheduleTab",
    });
  };

  const addSubjectVisitPlannedScheduleTab = () => {
    dispatch({
      type: "addSubjectVisitPlannedScheduleTab",
    });
  };

  const addTermBackupTab = () => {
    dispatch({
      type: "addTermBackupTab",
    });
  };

  const addArchiveTab = () => {
    dispatch({
      type: "addArchiveTab",
    });
  };

  const addClientArchiveTab = () => {
    dispatch({
      type: "addClientArchiveTab",
    });
  };

  const addUserArchiveTab = () => {
    dispatch({
      type: "addUserArchiveTab",
    });
  };

  const addRoleArchiveTab = () => {
    dispatch({
      type: "addRoleArchiveTab",
    });
  };

  const addStudyArchiveTab = () => {
    dispatch({
      type: "addStudyArchiveTab",
    });
  };

  const addCrfsArchiveTab = () => {
    dispatch({
      type: "addCrfsArchiveTab",
    });
  };

  const addStudySchemaArchiveTab = () => {
    dispatch({
      type: "addStudySchemaArchiveTab",
    });
  };

  const addParameterArchiveTab = () => {
    dispatch({
      type: "addParameterArchiveTab",
    });
  };

  const addTermArchiveTab = () => {
    dispatch({
      type: "addTermArchiveTab",
    });
  };

  const addDataEntrySiteTab = () => {
    dispatch({
      type: "addDataEntrySiteTab",
    });
  };

  const addDataEntrySubjectTab = () => {
    dispatch({
      type: "addDataEntrySubjectTab",
    });
  };

  const addDataEntryVisitTab = () => {
    dispatch({
      type: "addDataEntryVisitTab",
    });
  };

  const addDataEntryFormTab = () => {
    dispatch({
      type: "addDataEntryFormTab",
    });
  };

  const addDataTransferTableTab = () => {
    dispatch({
      type: "addDataTransferTableTab",
    });
  };

  const addDataExtractTab = () => {
    dispatch({
      type: "addDataExtractTab",
    });
  };

  const addDemographic2Tab = () => {
    dispatch({
      type: "addDemographic2Tab",
    });
  };

  const addBulkUploadTab = () => {
    dispatch({
      type: "addBulkUploadTab",
    });
  };

  const addQueryManagementTab = () => {
    dispatch({
      type: "addQueryManagementTab",
    });
  };

  const addQuerySummaryTab = () => {
    dispatch({
      type: "addQuerySummaryTab",
    });
  };

  const addDiscrepancyManagementTab = () => {
    dispatch({
      type: "addDiscrepancyManagementTab",
    });
  };

  const addDiscrepancySummaryTab = () => {
    dispatch({
      type: "addDiscrepancySummaryTab",
    });
  };

  const addProtocolMetadataTab = () => {
    dispatch({
      type: "addProtocolMetadataTab",
    });
  };

  const addStudyDatabase = () => {
    dispatch({
      type: "addStudyDatabase",
    });
  };

  const addElearningSiteTab = () => {
    dispatch({
      type: "addElearningSiteTab",
    });
  };

  const addCustomEditCheck = () => {
    dispatch({
      type: "addCustomEditCheck",
    });
  };

  const addGlobalFormTab = () => {
    dispatch({
      type: "addGlobalFormTab",
    });
  };

  const addGlobalFormTab1 = () => {
    dispatch({
      type: "addGlobalFormTab1",
    });
  };

  const addClientLevelFormTab = () => {
    dispatch({
      type: "addClientLevelFormTab",
    });
  };

  const addProjectLevelFormTab = () => {
    dispatch({
      type: "addProjectLevelFormTab",
    });
  };

  const addClientSeparateLevelFormTab = () => {
    dispatch({
      type: "addClientSeparateLevelFormTab",
    });
  };

  const addStudyVisitsFormTab = () => {
    dispatch({
      type: "addStudyVisitsFormTab",
    });
  };

  return {
    addFolderUniqueIdTab,
    addFailedLoginData,
    addLogsHistoryData,
    addStudyVisitsTab,
    addGlobalFormCrfEditorTab,
    addControlQueryResponseMessageTab,
    addSubjectManagementScheduleTab,
    addStudySubjectSpecialFormFormTab,
    addViewStudyTableDataTab,
    addViewStudyDetailsTableDataTab,
    addStudyAaccessTab,
    addETMFAaccessTab,
    addEtmfDocData,
    addAssignStudyPermissionTab,
    addClientFormCrfEditorTab,
    addProjectFormCrfEditorTab,
    addProtocolMetadataTab,
    addStudyDatabase,
    addElearningSiteTab,
    addCustomEditCheck,
    addNewUserTab,
    addInactiveUserTab,
    addArchivedUserTab,
    addFreezedUserTab,
    addOnBoardingUserTab,
    addAccessTab,
    addDesignationTab,
    addNewClientTab,
    addInactiveClientTab,
    addArchivedClientTab,
    addFreezedClientTab,
    addOnBoardingClientTab,
    addUserCreationTab,
    addGlobalFormValidationStatus,
    addProjectFormValidationStatus,
    userRequestsTab,
    addApprovedUserTab,
    addRejectedUserTab,
    clientRequestsTab,
    addApprovedClientTab,
    addRejectedClientTab,
    addArchivedRejectedClientTab,
    addArchivedApprovedClientTab,
    addArchivedPendingClientTab,
    addArchivedRejectedUserTab,
    addArchivedApprovedUserTab,
    addArchivedPendingUserTab,
    addClientTab,
    addClientPermissionTab,
    addUserTab,
    addVerifyStudyMetaData,
    addStudySummaryTab,
    addSubjectFormTab,
    addDatabaseApproveTab,
    addDatabaseLockTab,
    addRequestTableTab,
    addSubmissionPlanTab,
    addKeyMilestoneTab,
    addClientCreationTab,
    addLabParametersTab,
    addDispositionEventTab,
    addAdverseEventTab,
    addStudyFormValidationStatus,
    addCrfViewTab,
    addVisitsCrfViewTab,
    addStudyFormCrfEditorTab,
    addVisitFormCrfEditorTab,
    addStudySiteTab,
    addSubjectVisitFormTab,
    addSubjectSpecialFormTab,
    addSubjectViewTab,
    addSampleReportPDFTab,
    addSubjectReportPDFTab,
    addControlQueryMessageTab,
    addControlQuerySubjectTab,
    addStudyRandomisationTab,
    addStudyScheduleTab,
    addGlobalFormCrfViewTab,
    addCrfDesigningTab,
    addControlGenderDesigningTab,
    addManageQueryManagementTab,
    addCRFDatasets,
    addControlAgeGroupDesigningTab,
    addControlLabUnitsTabs,
    addQueryStatiscsDeaignTab,
    addMonitoringVisitsTab,
    addDerivedVeriableTab,
    addKeyMilestonesTab,
    addSDVStatiscsDeaignTab,
    addSDVFieldDeaignTab,
    addManageSDVManagementTab,
    addAuditManageSDVManagementTab,
    addFormFieldMetadataTab,
    addTrialCriteriaTab,
    addAutoFieldTab,
    addSiteStatusTab,
    addStudyManageTab,
    addMedicalCodingSiteTab,
    addReportsSiteTab,
    addMonitoringVisitTab,
    addSubjectFormStatusTab,
    addStudyVisitStatusTab,
    addSubjectStatusTab,
    addBackupTab,
    addClientBackupTab,
    addUserBackupTab,
    addRoleBackupTab,
    addStudyBackupTab,
    addSystemBackupTab,
    addCrfsBackupTab,
    addStudySchemaBackupTab,
    addParameterBackupTab,
    addSubjectMilestoneSettingScheduleTab,
    addSubjectVisitPlannedScheduleTab,
    addTermBackupTab,
    addArchiveTab,
    addClientArchiveTab,
    addUserArchiveTab,
    addRoleArchiveTab,
    addStudyArchiveTab,
    addCrfsArchiveTab,
    addStudySchemaArchiveTab,
    addParameterArchiveTab,
    addTermArchiveTab,
    defaultTab,
    addDataEntrySiteTab,
    addDataEntrySubjectTab,
    addDataEntryVisitTab,
    addDataEntryFormTab,
    addDataTransferTableTab,
    addDataExtractTab,
    addDemographic2Tab,
    addBulkUploadTab,
    addQueryManagementTab,
    addQuerySummaryTab,
    addDiscrepancyManagementTab,
    addDiscrepancySummaryTab,

    addGlobalFormTab1,
    addGlobalFormTab,
    addClientLevelFormTab,
    addProjectLevelFormTab,
    addClientSeparateLevelFormTab,
    addStudyVisitsFormTab,
    addQueryManagementtTab,
    addQuerySummaryyTab,

  };
};

export default UseAddTabs;
