import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { usersOnboardingService } from "../../Services/usersOnboarding";
import { useNavigate, useParams } from "react-router";
import { userServices } from "../../Services/userServices";
import { handleResponse } from "../../Helpers/Responses";
const options = [
  { value: " -- Select --", label: " -- Select --" },
  { value: "Option 1", label: "Option 1" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
  { value: "Option 4", label: "Option 4" },
];
const StudyLogin = ({
  selectedItem,
  selectStudyLoginType,
  setSelectStudyLoginType,
  assignStudyPermission,
  setAssignStudyPermission,
  DBPCheck,
  DVCheck,
  QCCheck,
  SDVLiveCheck,
  SDVQCCheck,
  LiveCheck,
  ETMFDBPCheck,
  ETMFQCCheck,
  ETMFLiveCheck,
  CTMSDBPCheck,
  CTMSDVCheck,
  CTMSAPPCheck,
}) => {
  const { studyId } = useParams();
  const [selectedOptionHistory, setSelectedOptionHistory] = useState(null);
  const [selectedOptionClient, setSelectedOptionClient] = useState(null);
  const [selectedOptionProject, setSelectedOptionProject] = useState(null);
  const [selectedOptionStudy, setSelectedOptionStudy] = useState(null);
  const [selectedOptionRole, setSelectedOptionRole] = useState(null);

  const [clientLists, setClientLists] = useState([]);
  const [projectLists, setProjectLists] = useState([]);
  const [studyLists, setStudyLists] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [projectIdLabel, setProjectIdLabel] = useState("");
  const [studyLabel, setstudyLabel] = useState("");
  const [clientName, setClientName] = useState("");
  const [studyRole, setStudyRole] = useState(null);

  const [studyLoginType, setStudyLoginType] = useState("");

  const [studyPermissionData, setStudyPermissionData] = useState([]);
  const [DBPPermission, setDBPPermission] = useState([]);
  const [DVPermission, setDVPermission] = useState([]);
  const [LivePermission, setLivePermission] = useState([]);
  const [QCPermission, setQCPermission] = useState([]);
  const [SDVLivePermission, setSDVLivePermission] = useState([]);
  const [SDVQCPermission, setSDVQCPermission] = useState([]);

  const [DBPSwitchCheck, setDBPSwitchCheck] = useState("");
  const [DVSwitchCheck, setDVSwitchCheck] = useState("");
  const [LiveSwitchCheck, setLiveSwitchCheck] = useState("");
  const [QCSwitchCheck, setQCSwitchCheck] = useState("");
  const [SDVLiveSwitchCheck, setSDVLiveSwitchCheck] = useState("");
  const [SDVQCSwitchCheck, setSDVQCSwitchCheck] = useState("");
  const [permissionId, setPermissionId] = useState("");

  const viewSelectedItemWithSign = async (projectId) => {
    let permissionData = await userServices.getStudyUserPermission(
      studyId,
      projectId
    );
    let etmfPermissionData = await userServices.getETMFUserPermission(
      studyId,
      projectId
    );
    setStudyPermissionData(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.permission
        : permissionData?.permission
    );
    setDBPPermission(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.DBPPermission
        : permissionData?.DBPPermission
    );
    setDVPermission(
      selectStudyLoginType === "etmf" ? [] : permissionData?.DVPermission
    );
    setLivePermission(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.LivePermission
        : permissionData?.LivePermission
    );
    setQCPermission(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.QCPermission
        : permissionData?.QCPermission
    );
    setSDVLivePermission(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.SDVLivePermission
        : permissionData?.SDVLivePermission
    );
    setSDVQCPermission(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.SDVQCPermission
        : permissionData?.SDVQCPermission
    );
    setDBPSwitchCheck(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.DBPCheck
        : permissionData?.DBPCheck
    );
    setDVSwitchCheck(
      selectStudyLoginType === "etmf" ? "" : permissionData?.DVCheck
    );
    setLiveSwitchCheck(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.LiveCheck
        : permissionData?.LiveCheck
    );
    setQCSwitchCheck(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.QCCheck
        : permissionData?.QCCheck
    );
    setSDVLiveSwitchCheck(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.SDVLiveCheck
        : permissionData?.SDVLiveCheck
    );
    setSDVQCSwitchCheck(
      selectStudyLoginType === "etmf"
        ? etmfPermissionData?.SDVQCCheck
        : permissionData?.SDVQCCheck
    );
  };

  const getStudyUserPermission = async (selectStudyLoginType) => {
    let permission;

    if (selectStudyLoginType === "ctms") {
      permission = await userServices.getCTMSUserPermission(
        selectedItem?.study_id,
        selectedItem?.project_id
      );
    } else if (selectStudyLoginType === "etmf") {
      permission = await userServices.getETMFUserPermission(
        selectedItem?.study_id,
        selectedItem?.project_id
      );
    } else {
      permission = await userServices.getStudyUserPermission(
        selectedItem?.study_id,
        selectedItem?.project_id
      );
    }
    setPermissionId(permission.permissionId);
    setStudyRole(permission.permissionRole);
    setStudyPermissionData(permission?.permission);
    setDBPPermission(permission?.DBPPermission);
    setDVPermission(permission?.DVPermission);
    setLivePermission(permission?.LivePermission);
    setQCPermission(permission?.QCPermission);
    setSDVLivePermission(permission?.SDVLivePermission);
    setSDVQCPermission(permission?.SDVQCPermission);
    setDBPSwitchCheck(permission?.DBPCheck);
    setDVSwitchCheck(permission?.DVCheck);
    setLiveSwitchCheck(permission?.LiveCheck);
    setQCSwitchCheck(permission?.QCCheck);
    setSDVLiveSwitchCheck(permission?.SDVLiveCheck);
    setSDVQCSwitchCheck(permission?.SDVQCCheck);
  };

  let studyid = selectedOptionStudy?.value;

  let getAssignedActiveApprovedClients = async () => {
    let data = await usersOnboardingService.getAssignedActiveApprovedClients();
    setClientLists(data?.data);
  };

  let getProjectIdBasedonClient = async (clientId) => {
    let data = await usersOnboardingService.getProjectIdBasedonClient(clientId);
    setProjectLists(data?.data);
  };

  let getStudyIdBasedonClient = async (projectId) => {
    let data = await usersOnboardingService.getStudyIdBasedonClient(projectId);
    setStudyLists(data?.data);
  };

  let getStudyRoleBasedonStudyId = async (studyId) => {
    let data = await usersOnboardingService.getStudyRoleBasedonStudyId(studyId);
    setStudyRole(data?.data);
  };

  let getProjectIdBasedonStudyId = async () => {
    let data = await usersOnboardingService.getProjectIdBasedonStudyId(
      studyId || selectedItem?.study_id
    );
    setProjectId(data?.projectId);
    setProjectIdLabel(data?.projectLabel);
    setstudyLabel(data?.studyLabel);
    viewSelectedItemWithSign(data?.projectId);
    setClientName(data?.clientName);
  };

  const handleChangeHistory = (selectedOption) => {
    setSelectedOptionHistory(selectedOption);
  };

  const handleChangeClient = (selectedOption) => {
    setSelectedOptionClient(selectedOption);
    getProjectIdBasedonClient(selectedOption?.value);
  };

  const handleChangeProject = (selectedOption) => {
    setSelectedOptionProject(selectedOption);
    getStudyIdBasedonClient(selectedOption?.value);
  };
  const handleChangeStudy = (selectedOption) => {
    setSelectedOptionStudy(selectedOption);
    getStudyRoleBasedonStudyId(selectedOption?.value);
  };
  const handleChangeRole = (selectedOption) => {
    setSelectedOptionRole(selectedOption);
  };

  const role = JSON.parse(localStorage.getItem("role"));

  const navigate = useNavigate();
  const loginStudyModule = () => {
    if (studyRole) {
      localStorage.removeItem("studyTabs");
      navigate(`/${selectStudyLoginType || studyLoginType}/${studyid}`);
      window.location.reload();
      localStorage.setItem(
        "StudyMode",
        JSON.stringify(
          assignStudyPermission === "DBP"
            ? "DBP MODE"
            : assignStudyPermission === "DV"
            ? "DV Mode"
            : assignStudyPermission === "LIVE"
            ? "Live Mode"
            : assignStudyPermission === "QC"
            ? "QC Mode"
            : assignStudyPermission === "SDVLIVE"
            ? "SDV Live Mode"
            : assignStudyPermission === "SDVQC"
            ? "SDV QC Mode"
            : "No Mode"
        )
      );
      localStorage.setItem(
        "studyPermissionData",
        JSON.stringify(
          assignStudyPermission === "DBP"
            ? DBPPermission[0]
            : assignStudyPermission === "DV"
            ? DVPermission[0]
            : assignStudyPermission === "LIVE"
            ? LivePermission[0]
            : assignStudyPermission === "QC"
            ? QCPermission[0]
            : assignStudyPermission === "SDVLIVE"
            ? SDVLivePermission[0]
            : assignStudyPermission === "SDVQC"
            ? SDVQCPermission[0]
            : ""
        )
      );
      localStorage.setItem(
        "studyPermissionId",
        JSON.stringify(permissionId ? permissionId : "")
      );
      localStorage.setItem("studyManagement", JSON.stringify(studyRole));
    } else {
      let response = {
        statusCode: 400,
        customMessage:
          "You have not login, Because you have study role not assign",
      };
      handleResponse(response);
    }
  };

  useEffect(() => {
    getAssignedActiveApprovedClients();
    getProjectIdBasedonStudyId();
    getStudyUserPermission("ctms");
    setSelectStudyLoginType("ctms");
  }, [studyId || selectedItem?.study_id, selectedItem?.project_id]);

  return (
    <>
      {role === "userId" && (
        <div className="hstack mb-2 gap-4">
          <p className="mb-1 fw-bold"> Select Application</p>
          <Form>
            <div className="hstack">
              {role === "user" ? (
                <Form.Check
                  inline
                  onChange={(e) => {
                    setSelectStudyLoginType(e.target.value);
                    setAssignStudyPermission("");
                    getStudyUserPermission(e.target.value);
                  }}
                  value={"ctms"}
                  label="CTMS"
                  name="group1"
                  type="radio"
                  id="studyId"
                />
              ) : (
                <>
                  <Form.Check
                    inline
                    onChange={(e) => {
                      setSelectStudyLoginType(e.target.value);
                      setAssignStudyPermission("");
                      getStudyUserPermission(e.target.value);
                    }}
                    value={"studyId"}
                    label="EDC"
                    name="group1"
                    type="radio"
                    id="studyId"
                  />
                  <Form.Check
                    inline
                    onChange={(e) => {
                      setSelectStudyLoginType(e.target.value);
                      setAssignStudyPermission("");
                      getStudyUserPermission(e.target.value);
                    }}
                    value={"etmf"}
                    label="ETMF"
                    name="group1"
                    type="radio"
                    id="etmf"
                  />
                </>
              )}
            </div>
          </Form>
        </div>
      )}
      <Row className="">
        {role === "userId" && (
          <div className="hstack mb-2" style={{ gap: "20px" }}>
            <p className="mb-1 fw-bold"> Select Application</p>
            <Form>
              <div className="hstack">
                <Form.Check
                  inline
                  onChange={(e) => {
                    setStudyLoginType(e.target.value);
                    getStudyUserPermission(e.target.value);
                  }}
                  value={"studyId"}
                  label="EDC"
                  name="group1"
                  type="radio"
                  id="studyId"
                />
              </div>
            </Form>
          </div>
        )}
        <Form.Group as={Col} md={12}>
          <div className="hstack justify-content-center mb-2">
            <img
              src="https://osteosarcomanow.org/wp-content/uploads/2021/12/clinincal-trials.png"
              alt="Clinical Trial"
              srcset=""
              style={{width: 150 , height: 150}}
            />
          </div>
        </Form.Group>
        <Col md={6}>
          <div>
            <Form.Label className="mb-1 fw-bold">Client</Form.Label>
            <Form.Select
               
              value={selectedOptionClient}
              onChange={handleChangeClient}
            >
              <option value="">{clientName || "Open this select menu"}</option>
              {clientLists?.map((client, index) => (
                <option key={index} value={client?.value}>
                  {client?.label}
                </option>
              ))}
            </Form.Select>
            {/* <Select
              value={selectedOptionClient}
              onChange={handleChangeClient}
              options={clientLists}
              isSearchable={true}
              placeholder={clientName}
            /> */}
          </div>
        </Col>
        <Col md={6}>
          <div>
            <Form.Label className="mb-1 fw-bold">Project ID</Form.Label>
            <Form.Select
               
              value={selectedOptionProject}
              onChange={handleChangeProject}
              disabled={projectLists?.length === 0}
            >
              <option value="">{projectIdLabel || "Select a project"}</option>
              {projectLists?.map((project, index) => (
                <option key={index} value={project.value}>
                  {project.label}
                </option>
              ))}
            </Form.Select>
            {/* <Select
              value={selectedOptionProject}
              onChange={handleChangeProject}
              options={projectLists}
              isDisabled={projectLists?.length === 0 ? true : false}
              isSearchable={true}
              placeholder={projectIdLabel}
            /> */}
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <div>
            <Form.Label className="mb-1 fw-bold">Study Id</Form.Label>
            <Form.Select
              aria-label="Study select"
              value={selectedOptionStudy}
              onChange={handleChangeStudy}
              disabled={studyLists.length === 0}
            >
              <option value="">
                {studyLabel || selectedItem?.study_id || "Select a study"}
              </option>
              {studyLists.map((study, index) => (
                <option key={index} value={study.value}>
                  {study.label}
                </option>
              ))}
            </Form.Select>

            {/* <Select
              value={selectedOptionStudy}
              onChange={handleChangeStudy}
              options={studyLists}
              isSearchable={true}
              isDisabled={studyLists.length === 0 ? true : false}
              placeholder={studyLabel || selectedItem?.study_id}
            /> */}
          </div>
        </Col>
        <Col md={6}>
          <div>
            <Form.Label className="mb-1 fw-bold">Role</Form.Label>
            <Form.Select
               
              value={selectedOptionRole}
              onChange={handleChangeRole}
              disabled
            >
              <option value="">{studyRole || "Not Assign"}</option>
              {options.map((role, index) => (
                <option key={index} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Form.Select>
            {/* <Select
              value={selectedOptionRole}
              onChange={handleChangeRole}
              options={options}
              isDisabled
              isSearchable={true}
              placeholder={studyRole || "Not Assign"}
            /> */}
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <div>
            <Form.Label className="mb-1 fw-bold">Environment</Form.Label>
            <Form.Select
              onChange={(e) => setAssignStudyPermission(e.target.value)}
            >
              <option>Select Environment</option>
              {selectStudyLoginType === "studyId" ? (
                <>
                  {DBPCheck || DBPSwitchCheck ? (
                    <option value={"DBP"}>DBP Mode</option>
                  ) : (
                    ""
                  )}
                  {DVCheck || DVSwitchCheck ? (
                    <option value={"DV"}>DV Mode</option>
                  ) : (
                    ""
                  )}
                  {LiveCheck || LiveSwitchCheck ? (
                    <option value={"LIVE"}>Live Mode</option>
                  ) : (
                    ""
                  )}
                  {SDVLiveCheck || SDVLiveSwitchCheck ? (
                    <option value={"SDVLIVE"}>SDV Live Mode</option>
                  ) : (
                    ""
                  )}
                  {QCCheck || QCSwitchCheck ? (
                    <option value={"QC"}>QC Mode</option>
                  ) : (
                    ""
                  )}
                  {SDVQCCheck || SDVQCSwitchCheck ? (
                    <option value={"SDVQC"}>SDV QC Mode</option>
                  ) : (
                    ""
                  )}
                </>
              ) : selectStudyLoginType === "etmf" ? (
                <>
                  {ETMFDBPCheck || DBPSwitchCheck ? (
                    <option value={"DBP"}>DBP Mode</option>
                  ) : (
                    ""
                  )}
                  {ETMFLiveCheck || LiveSwitchCheck ? (
                    <option value={"LIVE"}>Live Mode</option>
                  ) : (
                    ""
                  )}
                  {ETMFQCCheck || QCSwitchCheck ? (
                    <option value={"QC"}>QC Mode</option>
                  ) : (
                    ""
                  )}
                </>
              ) : selectStudyLoginType === "ctms" ? (
                <>
                  {CTMSDBPCheck || DBPSwitchCheck ? (
                    <option value={"DBP"}>DBP Mode</option>
                  ) : (
                    ""
                  )}
                  {CTMSDVCheck || LiveSwitchCheck ? (
                    <option value={"DV"}>DV Mode</option>
                  ) : (
                    ""
                  )}
                  {CTMSAPPCheck || QCSwitchCheck ? (
                    <option value={"APP"}>APP Mode</option>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Form.Select>
          </div>
        </Col>
      </Row>
      {role === "studyId" && (
        <Button
          className="btn submitbutton me-2 mt-3"
          onClick={() => loginStudyModule()}
          disabled={studyRole && assignStudyPermission ? false : true}
        >
          Login
        </Button>
      )}
    </>
  );
};

export default StudyLogin;
