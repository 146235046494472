import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import { useParams } from "react-router";

const NewTrialParametersDesign = () => {
  const { studyId } = useParams();
  const [blinding, setBlinding] = useState("");
  const [randomMethods, setRandomMethods] = useState("completelyRandom");
  const [codeBlock, setCodeBlock] = useState("");
  const [blindingCodePrefix, setBlindingCodePrefix] = useState("");
  const [blindingCodeSuffix, setBlindingCodeSuffix] = useState("");
  const [plannedUnblind, setPlannedUnblind] = useState("");
  const [approvalRequired, setApprovalRequired] = useState("");
  const [unblindingRequired, setUnblindingRequired] = useState("");
  const [randomizeStatus, setRandomizeStatus] = useState("");
  const [randomizationStartingNumber, setRandomizationStartingNumber] =
    useState("");
  const [randomizationCodePrefix, setRandomizationCodePrefix] = useState("");
  const [randomizationCodeSuffix, setRandomizationCodeSuffix] = useState("");
  const [randomNumberSeed, setRandomNumberSeed] = useState("");
  const [dataEntryStartingNumber, setDataEntryStartingNumber] = useState("");
  const [dataEntryCodePrefix, setDataEntryCodePrefix] = useState("");
  const [dataEntryCodeSuffix, setDataEntryCodeSuffix] = useState("");
  const [dataEntryOption, setDataEntryOption] = useState("");
  // const [error, setError] = useState("");
  // const [blockSize, setBlockSize] = useState("");

  // const validateBlockSize = (value) => {
  //   const num = Number(value);
  //   if (
  //     !Number.isInteger(num) ||
  //     num < 4 ||
  //     num > 2147483647 ||
  //     num % 4 !== 0
  //   ) {
  //     return "This value should be between 4 and 2147483647 and a multiple of 4*.";
  //   }
  //   return "";
  // };

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   setBlockSize(value);
  //   setError(validateBlockSize(value));
  // };

  const findRandomizerStudyParameter = async () => {
    let data = await randomizerServices.findRandomizerStudyParameter(studyId);
    setBlinding(data?.data?.blinding);
    setRandomMethods(data?.data?.randomMethods);
    setCodeBlock(data?.data?.codeBlock);
    setBlindingCodePrefix(data?.data?.blindingCodePrefix);
    setBlindingCodeSuffix(data?.data?.blindingCodeSuffix);
    setPlannedUnblind(data?.data?.plannedUnblind);
    setApprovalRequired(data?.data?.approvalRequired);
    setUnblindingRequired(data?.data?.unblindingRequired);
    setRandomizeStatus(data?.data?.randomizeStatus);
    setRandomizationStartingNumber(data?.data?.randomizationStartingNumber);
    setRandomizationCodePrefix(data?.data?.randomizationCodePrefix);
    setRandomizationCodeSuffix(data?.data?.randomizationCodeSuffix);
    setRandomNumberSeed(data?.data?.randomNumberSeed);
    setDataEntryStartingNumber(data?.data?.dataEntryStartingNumber);
    setDataEntryCodePrefix(data?.data?.dataEntryCodePrefix);
    setDataEntryCodeSuffix(data?.data?.dataEntryCodeSuffix);
    setDataEntryOption(data?.data?.dataEntryOption);
  };

  const addRandomizerStudyParameter = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.blinding = blinding;
    userData.randomMethods = randomMethods;
    userData.codeBlock = codeBlock;
    userData.blindingCodePrefix = blindingCodePrefix;
    userData.blindingCodeSuffix = blindingCodeSuffix;
    userData.plannedUnblind = plannedUnblind;
    userData.approvalRequired = approvalRequired;
    userData.unblindingRequired = unblindingRequired;
    userData.randomizeStatus = randomizeStatus;
    userData.randomizationStartingNumber = randomizationStartingNumber;
    userData.randomizationCodePrefix = randomizationCodePrefix;
    userData.randomizationCodeSuffix = randomizationCodeSuffix;
    userData.randomNumberSeed = randomNumberSeed;
    userData.dataEntryStartingNumber = dataEntryStartingNumber;
    userData.dataEntryCodePrefix = dataEntryCodePrefix;
    userData.dataEntryCodeSuffix = dataEntryCodeSuffix;
    userData.dataEntryOption = dataEntryOption;
    let result = await randomizerServices.addRandomizerStudyParameter(userData);
    if (result?.statusCode === 200) {
      findRandomizerStudyParameter();
    }
  };

  useEffect(() => {
    findRandomizerStudyParameter();
  }, [studyId]);

  return (
    <>
      <div className="p-3">
        <div className="d-flex justify-content-center gap-2">
          <Card className="mb-3 bg-light border" style={{ width: 410 }}>
            <Card.Body className="p-0">
              <div className="border-bottom p-2 text-center">Blinding</div>
              <div
                className="p-3 overflow-auto bg-white"
                style={{ height: "calc(100vh - 250px)" }}
              >
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Blinding <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={(e) => setBlinding(e.target.value)}
                  >
                    <option>None (open trial)</option>
                    <option
                      selected={blinding === "singleBlind"}
                      value="singleBlind"
                    >
                      Single Blind Trial
                    </option>
                    <option
                      selected={blinding === "doubleBlind"}
                      value="doubleBlind"
                    >
                      Double Blind Trial
                    </option>
                    <option
                      selected={blinding === "tripleBlind"}
                      value="tripleBlind"
                    >
                      Triple Blind Trial
                    </option>
                  </Form.Select>
                </Form.Group>
                <div id="showDoubleBlock">
                  <Form.Label className="mb-1 fw-bold">
                    Blinding Table Layout
                  </Form.Label>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      Code Block Size
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setCodeBlock(e.target.value)}
                      value={codeBlock}
                      required
                      type="number"
                      placeholder="Enter Code Block Size"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      Code Prefix <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setBlindingCodePrefix(e.target.value)}
                      value={blindingCodePrefix}
                      required
                      type="text"
                      placeholder="Enter Code Prefix"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      Code Suffix <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setBlindingCodeSuffix(e.target.value)}
                      value={blindingCodeSuffix}
                      required
                      type="text"
                      placeholder="Enter Code Suffix"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      Planned Unblind Date
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setPlannedUnblind(e.target.value)}
                      value={plannedUnblind}
                      type="date"
                      placeholder="Enter Planned Unblind Code"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      How Many of Level Approval Required{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setApprovalRequired(e.target.value)}
                    >
                      <option>Select Lavel Approval</option>
                      <option selected={approvalRequired === "one"} value="one">
                        1
                      </option>
                      <option selected={approvalRequired === "two"} value="two">
                        2
                      </option>
                      <option
                        selected={approvalRequired === "three"}
                        value="three"
                      >
                        3
                      </option>
                      <option
                        selected={approvalRequired === "four"}
                        value="four"
                      >
                        4
                      </option>
                      <option
                        selected={approvalRequired === "five"}
                        value="five"
                      >
                        5
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      Unblinding To <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setUnblindingRequired(e.target.value)}
                    >
                      <option>Select Unblinding For</option>
                      <option
                        selected={unblindingRequired === "allUser"}
                        value="allUser"
                      >
                        All Users
                      </option>
                      <option
                        selected={unblindingRequired === "requestedUser"}
                        value="requestedUser"
                      >
                        User Who Requested For Unblinding
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                      Randomize At Once <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setRandomizeStatus(e.target.value)}
                    >
                      <option>Select Randomize</option>
                      <option selected={randomizeStatus === "yes"} value="yes">
                        Yes
                      </option>
                      <option selected={randomizeStatus === "no"} value="no">
                        No
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="mb-3 bg-light border" style={{ width: 410 }}>
            <Card.Body className="p-0">
              <div className="border-bottom p-2 text-center">Randomization</div>
              <div
                className="p-3 overflow-auto bg-white"
                style={{ height: "calc(100vh - 250px)" }}
              >
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Randomization Method <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    disabled
                    onChange={(e) => setRandomMethods(e.target.value)}
                  >
                    <option value="">Select Method</option>
                    <option
                      value="completelyRandom"
                      selected={randomMethods === "completelyRandom"}
                    >
                      Completely at Random
                    </option>
                    <option
                      value="permutedBlocks"
                      selected={randomMethods === "permutedBlocks"}
                    >
                      Permuted Blocks
                    </option>
                  </Form.Select>
                </Form.Group>
                {/* {randomMethods === "permuted-blocks" && (
                  <>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1">
                        Block Size (1) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Block Size"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1">
                        Block Size (2) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Block Size"
                        value={blockSize}
                        onChange={handleChange}
                        isInvalid={!!error}
                      />
                      {error && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "11px",
                            marginTop: "5px",
                          }}
                        >
                          {error}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1">
                        Block Size (3) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Block Size"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1">
                        Block Size (4) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Block Size"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1">
                        Rejections <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        placeholder="Enter Rejections"
                        label={"Compensate imbalance introduced by rejections"}
                      />
                    </Form.Group>
                  </>
                )} */}
                <Form.Label className="mb-1 fw-bold">Random Number</Form.Label>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Starting Number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setRandomizationStartingNumber(e.target.value)
                    }
                    value={randomizationStartingNumber}
                    type="number"
                    placeholder="Enter Starting Number"
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Code Prefix <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => setRandomizationCodePrefix(e.target.value)}
                    value={randomizationCodePrefix}
                    type="text"
                    placeholder="Enter Code Prefix"
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Code Suffix <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => setRandomizationCodeSuffix(e.target.value)}
                    value={randomizationCodeSuffix}
                    type="text"
                    placeholder="Enter Code Suffix"
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Random Number Seed <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => setRandomNumberSeed(e.target.value)}
                    value={randomNumberSeed}
                    type="number"
                    placeholder="Enter Random Number Seed"
                  />
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card className="mb-3 bg-light border" style={{ width: 410 }}>
            <Card.Body className="p-0">
              <div className="border-bottom p-2 text-center">
                Data Entry and Randomization Numbering
              </div>
              <div
                className="p-3 overflow-auto bg-white"
                style={{ height: "calc(100vh - 250px)" }}
              >
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Starting Number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={(e) => setDataEntryStartingNumber(e.target.value)}
                    value={dataEntryStartingNumber}
                    type="number"
                    placeholder="Enter Starting Number"
                  />
                </Form.Group>

                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Code Prefix <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => setDataEntryCodePrefix(e.target.value)}
                    value={dataEntryCodePrefix}
                    type="text"
                    placeholder="Enter Code Prefix"
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Code Suffix <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => setDataEntryCodeSuffix(e.target.value)}
                    value={dataEntryCodeSuffix}
                    type="text"
                    placeholder="Enter Code Suffix"
                  />
                </Form.Group>

                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">
                    Data Entry Options <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Check
                    onChange={(e) =>
                      setDataEntryOption("subjectEligibility")
                    }
                    type="checkbox"
                    checked={dataEntryOption === "subjectEligibility"}
                    label="Confirm Subject Eligibility"
                  />
                  <Form.Check
                    onChange={(e) => setDataEntryOption("subjectUniqueId")}
                    type="checkbox"
                    checked={dataEntryOption === "subjectUniqueId"}
                    label="Ensure that Subject-ID is unique within the entire trial"
                  />
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="p-2 hstack justify-content-end gap-2 position-sticky bottom-0 bg-white">
          <button
            className="updateProfile"
            onClick={addRandomizerStudyParameter}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default NewTrialParametersDesign;
