import React, { useState, useRef, useEffect } from "react";
import InsertCustomTable from "./InsertCustomTable";
import { Collapse } from "react-bootstrap";

const InsertTableDesign = ({ onInsertTable }) => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCol, setHoveredCol] = useState(null);
  const [tableDimensions, setTableDimensions] = useState({
    rows: 0,
    columns: 0,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dropdownRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleTableSelection = (rows, columns) => {
    setTableDimensions({ rows, columns });
    onInsertTable({ rows, columns });
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef}>
      <div
        onClick={toggleOpen}
        className="position-relative"
        title="Insert Table"
      >
        Table
      </div>
      <Collapse in={isOpen}>
        <div
          className="table-grid-dropdown position-absolute start-0 bg-white p-2 border rounded"
          style={{ top: 20 }}
        >
          <div className="table-grid-selection">
            {Array(15)
              .fill(0)
              .map((_, rowIndex) => (
                <div key={rowIndex} className="d-flex">
                  {Array(15)
                    .fill(0)
                    .map((_, colIndex) => (
                      <div
                        key={colIndex}
                        onClick={() =>
                          handleTableSelection(rowIndex + 1, colIndex + 1)
                        }
                        onMouseEnter={() => {
                          setHoveredRow(rowIndex);
                          setHoveredCol(colIndex);
                        }}
                        onMouseLeave={() => {
                          setHoveredRow(null);
                          setHoveredCol(null);
                        }}
                        className="table-grid-cell"
                        style={{
                          width: 15,
                          height: 15,
                          border: "1px solid #ccc",
                          cursor: "pointer",
                          backgroundColor:
                            // rowIndex < tableDimensions.rows && colIndex < tableDimensions.columns
                            //   ? '#ddd'
                            //   :
                            rowIndex <= hoveredRow && colIndex <= hoveredCol
                              ? "#ddd"
                              : "#fff",
                        }}
                      />
                    ))}
                </div>
              ))}
          </div>
          <div className="toolbar_button pt-2">
            <button className="border" onClick={handleShow}>
              Insert Custom Table
            </button>
          </div>
        </div>
      </Collapse>
      <InsertCustomTable
        handleClose={handleClose}
        show={show}
        handleTableSelection={handleTableSelection}
      />
    </div>
  );
};

export default InsertTableDesign;
