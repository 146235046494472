import React, { useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { MdOutlineFilterAlt } from "react-icons/md";
import { LiaSortSolid } from "react-icons/lia";
import { PiSortAscendingLight, PiSortDescendingLight } from "react-icons/pi";
import RightClick from "../../../../LeftComponent/RightClick";
import { ContextMenuTrigger } from "react-contextmenu";
import UseToggle from "../../../../../Hooks/UseToggle";
import { CiExport } from "react-icons/ci";
import ExportTableDataByColumn from "./ExportTableDataByColumn";
import TemplateColumnProperties from "./TemplateColumnProperties";
import { useParams } from "react-router";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const DerivedData = ({ tableRef, onMouseDown, studyTabs, setStudyTabs }) => {
  const { studyId } = useParams();
  const ref = useRef(null);
  const { toggle1, setToggle1, toggle2, setToggle2 } = UseToggle();
  const [viewType, setViewType] = useState("name");
  const [loading, setLoading] = useState(false);
  const [rowList, setRowList] = useState([]);
  const [showTemplateColumn, setShowTemplateColumn] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const sortColumnAscendingDescendingData = (key, order) => {
    setLoading(true);
    setTimeout(() => {
      let sortedData = rowList.sort((a, b) => {
        if (order === "asc") {
          return a[key] > b[key] ? 1 : -1;
        } else if (order === "desc") {
          return a[key] < b[key] ? 1 : -1;
        }
      });

      setRowList([...sortedData]);
      setLoading(false);
    }, 200);
  };

  const refreshDerivedDatasets = () => {
    setLoading(true);
    setTimeout(() => {
      let data = studyTabs?.find((item) => item.status === "active");
      setColumnList(data?.ids?.column);
      setRowList(data?.ids?.row);
      setShowTemplateColumn(data?.ids?.showColumn);
      setCheckboxValues(data?.ids?.showColumn);
      setLoading(false);
    }, 200);
  };

  useEffect(() => {
    refreshDerivedDatasets();
  }, [studyTabs]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = rowList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(rowList?.length / itemsPerPage);

  return (
    <div>
      <div className="border-bottom py-1 px-2 hstack gap-2">
        <button
          title="Export"
          className="tabButtons fontSize12 border-0 "
          style={{ padding: "0px 5px 2px", borderRadius: 3 }}
        >
          <CiExport onClick={() => setToggle1()} />
        </button>
        <div className="vr"></div>
        <button
          title={viewType === "label" ? "By Name" : "By Label"}
          className="tabButtons border-0"
          style={{
            padding: "2px 10px",
            fontSize: 10,
            borderRadius: 3,
          }}
          onClick={() => setViewType(viewType === "name" ? "label" : "name")}
        >
          {viewType === "label" ? "By Name" : "By Label"}
        </button>
        <div className="vr"></div>
        <button
          title="Template Properties"
          className="tabButtons border-0"
          style={{
            padding: "2px 10px",
            fontSize: 10,
            borderRadius: 3,
          }}
          onClick={setToggle2}
        >
          Template Properties
        </button>
      </div>
      <SplitPane
        split="horizental"
        className="position-relative"
        style={{ height: "calc(100vh - 185px)" }}
        minSize={25}
        defaultSize={"100%"}
      >
        <SplitPane split="vertical" minSize={25} defaultSize={"100%"}>
          {loading ? (
            <div className="hstack justify-content-center h-100 w-100 py-5">
              <Spinner />
            </div>
          ) : rowList?.length === 0 ? (
            <div className="hstack justify-content-center h-100 w-100 py-5 fs-13">
              Datasets not Found
            </div>
          ) : (
            <div>
              <div className="overflow-auto">
                <Table
                  ref={tableRef}
                  className="m-0 custom-table tableLibrary"
                  id="resizable-table"
                  striped
                  hover
                >
                  <thead className="position-sticky" style={{ top: -1 }}>
                    <tr>
                      {columnList?.map(
                        (column, index) =>
                          showTemplateColumn.includes(column?.name) && (
                            <th
                              key={index}
                              onMouseDown={(e) => onMouseDown(e, index)}
                            >
                              <ContextMenuTrigger
                                id={`crfData_${index}`}
                                collect={() => ({ id: index })}
                              >
                                {viewType === "name"
                                  ? column?.name
                                  : column?.label}
                              </ContextMenuTrigger>
                              <RightClick
                                ContextId={`crfData_${index}`}
                                Menu1
                                MenuName1={"Sort Ascending"}
                                icons1={
                                  <PiSortAscendingLight
                                    style={{ marginBottom: -3 }}
                                  />
                                }
                                handleClick1={() =>
                                  sortColumnAscendingDescendingData(
                                    column?.name,
                                    "asc"
                                  )
                                }
                                Menu2
                                MenuName2={"Sort Descending"}
                                icons2={
                                  <PiSortDescendingLight
                                    style={{ marginBottom: -3 }}
                                  />
                                }
                                handleClick2={() =>
                                  sortColumnAscendingDescendingData(
                                    column?.name,
                                    "desc"
                                  )
                                }
                                Menu3
                                MenuName3={"Sort by Data Order"}
                                icons3={
                                  <LiaSortSolid style={{ marginBottom: -3 }} />
                                }
                                handleClick3={() =>
                                  sortColumnAscendingDescendingData(
                                    "orderNo",
                                    "asc"
                                  )
                                }
                                Menu4
                                MenuName4={"Add Filter"}
                                icons4={
                                  <MdOutlineFilterAlt
                                    style={{ marginBottom: -3 }}
                                  />
                                }
                                // handleClick4={setToggle1}
                              />
                            </th>
                          )
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody ref={ref}>
                    {result?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(row).map(([key, value], colIndex) =>
                          key === "uniqueId" || key === "orderNo"
                            ? null
                            : showTemplateColumn.includes(key) && (
                                <td
                                  className={`p-0 px-2 ${
                                    columnList?.find(
                                      (item) => item.name === key
                                    )?.check === "require"
                                      ? "bg-danger-subtle"
                                      : columnList?.find(
                                          (item) => item.name === key
                                        )?.check === "expected"
                                      ? "bg-success-subtle"
                                      : columnList?.find(
                                          (item) => item.name === key
                                        )?.check === "permissible"
                                      ? ""
                                      : ""
                                  } py-1`}
                                  key={colIndex}
                                >
                                  {value}
                                </td>
                              )
                        )}
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="position-sticky bottom-0">
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={rowList?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          )}
        </SplitPane>
      </SplitPane>
      <TemplateColumnProperties
        Show={!toggle2}
        Hide={setToggle2}
        Title={"Template Column Properties"}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
        checkboxValues={checkboxValues}
        setCheckboxValues={setCheckboxValues}
        columnList={columnList}
        setColumnList={setColumnList}
        studyTabs={studyTabs}
        setStudyTabs={setStudyTabs}
        refreshDerivedDatasets={refreshDerivedDatasets}
      />

      <ExportTableDataByColumn
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Export Derivation CRF Data By Column"}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
        studyId={studyId}
        crfData={rowList}
        columnList={columnList}
        setColumnList={setColumnList}
      />
    </div>
  );
};

export default DerivedData;
